import React from 'react'
import { Select } from 'antd'

const { Option } = Select

const VendorSelector = ({ pmData, selectVendor, selectedVendorIndex, settingsTitle }) => {
  return (
    <div className="four-options">
      <h4>{settingsTitle}</h4>
      <div className='option-inputs'>
        <Select
          style={{ width: '100%' }}
          value={selectedVendorIndex}
          onChange={vendorKey => selectVendor(vendorKey)}
        >
          {pmData.vendors.map(({ name }, i) => (
            <Option key={name} value={i}>
              {name}
            </Option>
          ))}
        </Select>
      </div>
    </div>
  )
}

export default VendorSelector