import React, { useEffect, useState } from "react";
import { CloseCircleFilled, CheckCircleFilled, DeleteFilled, EditFilled, ExportOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { verifyMSPASinatoryId } from "../../../../../../api/usnat/vendor_list";
import { OPERATE_MODE_OPTIONS, renderInfoTip, SIGNATORY_ID_TOOLTIP } from "../../../helper";
import { Modal, Button, Input, Switch, Radio, Popconfirm, Space } from "antd";

const MSPA_COVER_META_DATA = {
  MspaCoveredTransaction: false,
  MspaOptOutOptionMode: false,
  MspaServiceProviderMode: false,
}

const getVerificationSucessMessage = (name) => (
  <>
    <div className="signatory-name">
      <span className="green">
        {" "}
        <CheckCircleFilled /> Verified{"     "}
      </span>
      <span>
        {"   "}
        Name: <b>&nbsp;{name}</b>
      </span>
    </div>
  </>
)

const SignatoryIdModal = (props) => {
  const [signatoryId, setSignatoryId] = useState(props.signatoryId);
  const [tempId, setTempId] = useState(null);
  const [signatoryName, setSignatoryName] = useState(props.signatoryName);
  const [metaData, setMetaData] = useState(props.metaData);
  const [mspaCovered, setMspaCovered] = useState(props.metaData.MspaCoveredTransaction);
  const [operateUnder, setOperateUnder] = useState(props.metaData.MspaServiceProviderMode ? "MspaServiceProviderMode" : "MspaOptOutOptionMode");
  const [verificationMessage, setVerificationMessage] = useState(props.signatoryId ? getVerificationSucessMessage(props.signatoryName) : null);
  const [tempVerificationMessage, setTempVerificationMessage] = useState(null);
  const [disableInput, setDisableInput] = useState(Boolean(props.signatoryId));
  const [isInvalid, setIsInvalid] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deletePopConfirmVisible, setDeletePopConfirmVisible] = useState(false);

  const hanldeSignatoryIdVerifcation = () => {
    verifyMSPASinatoryId(signatoryId)
      .then((resp) => {
        if (resp.id) {
          setSignatoryId(resp.id);
          setSignatoryName(resp.name);
          setMspaCovered(false);
          setIsInvalid(false)
          setVerificationMessage(getVerificationSucessMessage(resp.name));
        }
        setDisableInput(true);
        setEditMode(false);
      })
      .catch((err) => {
        if (err) {
          setVerificationMessage(   
            <div className="signatory-name error">
              {<CloseCircleFilled />}
              {<>&nbsp; Invalid Identification ID.</>}
            </div>
          );
          setIsInvalid(true)
        }
        setSignatoryId(null);
        setSignatoryName(null);
        setMspaCovered(false);
      });
      setOperateUnder("MspaOptOutOptionMode")
  };

  const handleSave = () => {
    if (!isInvalid) {
      props.handleSave(signatoryId, metaData, signatoryName);
      props.onClose();
    }
  };

  const handleClose = () => {
    setMspaCovered(props.metaData.MspaCoveredTransaction);
    setOperateUnder(
      props.metaData.MspaServiceProviderMode
        ? "MspaServiceProviderMode"
        : "MspaOptOutOptionMode"
    );
    setSignatoryId(props.signatoryId);
    setMetaData(props.metaData);
    setVerificationMessage(null);
    setDisableInput(Boolean(props.signatoryId));
    setEditMode(false);
    setSignatoryName(props.signatoryName);
    props.onClose();
  };

  const handleEdit = () => {
    setTempId(signatoryId)
    setEditMode(true);
    setDisableInput(false);
    setTempVerificationMessage(verificationMessage)
  };

  const handleDelete = () => {
    setSignatoryId("")
    setSignatoryName(null)
    setDisableInput(false);
    setDeletePopConfirmVisible(false)
    setVerificationMessage(null)
    setMetaData(MSPA_COVER_META_DATA);
    // props.handleSave("", MSPA_COVER_META_DATA, "");
    // setTimeout(() => props.onClose(), 0);
  }

  const handleCancel = () => {
    setDisableInput(true);
    setEditMode(false);
    setSignatoryId(tempId);
    setSignatoryName(props.signatoryName);
    setVerificationMessage(tempVerificationMessage);
    setIsInvalid(false);
  }

  useEffect(() => {
    setMetaData({
      MspaCoveredTransaction: mspaCovered,
      MspaOptOutOptionMode: operateUnder === "MspaOptOutOptionMode",
      MspaServiceProviderMode: operateUnder === "MspaServiceProviderMode",
    });
  }, [operateUnder, mspaCovered]);

  const handleMspaCovered = (checked) => {
    setMspaCovered(checked);
    if (checked) {
      setMetaData({
        MspaCoveredTransaction: checked,
        MspaOptOutOptionMode: operateUnder === "MspaOptOutOptionMode",
        MspaServiceProviderMode: operateUnder === "MspaServiceProviderMode",
      });
    } else {
      setMetaData({
        MspaCoveredTransaction: checked,
        MspaOptOutOptionMode: false,
        MspaServiceProviderMode: false,
      });
    }
  };

  let buttonGroup = null;
  if (editMode) {
    buttonGroup = (
      <Space size={4}>
        <Button icon={<CheckOutlined/>} type="link" disabled={!signatoryId} onClick={hanldeSignatoryIdVerifcation} />
        <Button icon={<CloseOutlined/>} type="link" onClick={handleCancel}/>
      </Space>
    );
  } else if (signatoryId && disableInput) {
    buttonGroup = (
      <Space size={4}>
        <Button
          type="link"
          onClick={handleEdit}
          icon={<EditFilled/>}
        />
        <Popconfirm
          title={"Are you sure remove the Identification ID?"}
          open={deletePopConfirmVisible}
          trigger="click"
          icon={null}
          onConfirm={handleDelete}
          onCancel={() => setDeletePopConfirmVisible(false)}
          onOpenChange={(visible) => setDeletePopConfirmVisible(visible)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="link"
            icon={<DeleteFilled />}
          />
        </Popconfirm>
      </Space>
    );
  } else {
    buttonGroup = (
      <div>
        <Button type="primary" disabled={!signatoryId} onClick={hanldeSignatoryIdVerifcation}>
          Add +
        </Button>
      </div>
    );
  }

  let mspaCover = null;
  if(signatoryId && disableInput) {
    mspaCover = (
      <div className="mspa-covered-transactions">
        <Switch
          checked={mspaCovered}
          onChange={(checked) => handleMspaCovered(checked)}
        />
        {"  "}
        <label>
          In ,
          <a href="https://sourcepoint-public-api.readme.io/reference/sections" target="_blank">
            MSPS <ExportOutlined />
          </a>{" "}
          set MSPACoveredTransaction to YES
        </label>
        {mspaCovered ? (
          <div>
            <div>Operated under:</div>
            <Radio.Group
              onChange={(e) => setOperateUnder(e.target.value)}
              defaultValue={"MspaOptOutOptionMode"}
              value={operateUnder}
              options={OPERATE_MODE_OPTIONS}
            />
          </div>
        ) : null}
      </div>
    )
  };

  return (
    <Modal
      open={props.open}
      title={<div>{props.signatoryId ? "Edit" : "Add"} Multi State Privacy Identification ID{renderInfoTip(SIGNATORY_ID_TOOLTIP)}</div>}
      closable
      onCancel={handleClose}
      okText={"Save"}
      className="signatory-modal"
      footer={
        <Button
          type="primary"
          disabled={isInvalid || (!disableInput && signatoryId) || editMode}
          onClick={handleSave}
        >
          Save
        </Button>
      }
      width={"630px"}
    >
      <label>Enter Identification ID</label>
      <div className="signatory-input">
        <Input
          value={signatoryId}
          onChange={({ target: { value } }) => setSignatoryId(value)}
          disabled={disableInput}
        />
        {buttonGroup}
      </div>
      {disableInput || isInvalid ? verificationMessage : null}
      {mspaCover}
    </Modal>
  );
}
export default SignatoryIdModal;
