import { EditOutlined, LeftOutlined, MinusSquareOutlined, RightOutlined, SafetyOutlined, SearchOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Flex, Input, Row, Select, Space, Spin, Table, Tag, Tooltip, Typography, theme } from "antd";
import React, { useEffect, useState } from "react";
import { GEO_LIST_TREE_STRUCTURE, region_title_index_map } from "../../../../../../constants";
import BulkRegionMappingModal from "./BulkRegionMappingModal";
import { LEGALBASIS_OPTIONS, STATE_SPECIFIC_STRING_STATE_CODES, validateAndGetStateStringIcon } from "../../../helper";
import { useDebouncedSearch } from "../../../../../../hooks";
import RespectGpcModal from "./RespectGpcModal";
import { useSelector } from "react-redux";

const {Title, Text, Paragraph} = Typography;

const RegionMapping = (props) => {
  const [categories, setCategories] = useState(props.usPrivacyRegulation.categories ?? []);
  const [regionCategoryMapping, setRegionCategoryMapping] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [openRespectGpcModal, setOpenRespectGpcModal] = useState(false);
  const [isLeftDisabled, setIsLeftDisabled] = useState(true);
  const [isRightDisabled, setIsRightDisabled] = useState(true);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']))
  const systemPurposesPending = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'pending']))
  const { token } = theme.useToken();

  useEffect(() => {
    // setCategories(props.usPrivacyRegulation.categories ?? []);
    props.usPrivacyRegulation.categories = categories;
  }, [categories])

  function handleLegalBasisChange(categoryId, regionCode, legalBasisValue) {
    setCategories(categories.map((cat) => {
      if(cat.id === categoryId) {
        cat.configByGeo[regionCode] = {defaultLegalBasis: legalBasisValue};
      } 
      return cat;
    }))
  }

  const columns = [
    {
      title: "Region",
      fixed: "left",
      dataIndex: "region",
      width: 200,
      sorter: (a,b) => a.title?.localeCompare(b.title),
      filters: props.isNoFrameworkFlow ? null : [
        {
          text: <>Support state string <img src="/svg_icons/trademark-s.svg"/></>,
          value: "state-string"
        },
        {
          text: "Doesn't support state string",
          value: "no-state-string"
        }
      ],
      filterMultiple: false,
      onFilter: (value, record) => {
        const isStateSpecific = STATE_SPECIFIC_STRING_STATE_CODES.includes(record.region.replace("US_", "STATE_"));
        return value === "state-string" ? isStateSpecific : !isStateSpecific;
      },
      render: (stateCode, record) => {
        return (
          <Flex gap={token.marginSM} align="center">
            <Text>{record.title}</Text>
            {props.isNoFrameworkFlow ? null :  validateAndGetStateStringIcon(stateCode.replace("US_", "STATE_"))}
          </Flex>
        );
      },
    },
  ];

  categories.sort((a,b) => a.purposeRef?.systemId - b.purposeRef?.systemId).forEach((cat) => {
    const isIabCat = cat.type === "SYSTEM_PURPOSE"
    columns.push({
      title: (
        <Space direction="vertical">
          <Paragraph style={{maxWidth: 150}} ellipsis={{tooltip:{title: cat.privacyChoice ?? cat.name}, rows: 2}}>{cat.privacyChoice ?? cat.name}</Paragraph>
          <Tag color={isIabCat ? "orange" : "magenta"}> {isIabCat ? props.isNoFrameworkFlow ? "Standard" : "IAB" : "Custom"}</Tag>
        </Space>
      ),
      dataIndex: ["legalBasis", cat.id],
      width: 200,
      sorter: (a,b) => {
        const legalBasisOrder = LEGALBASIS_OPTIONS.reduce((acc, option, index) => {
          acc[option.value] = index;
          return acc;
        }, {});
        const aOrder = legalBasisOrder[a.legalBasis[cat.id]] ?? 3; // 3 is the index for not_applicable
        const bOrder = legalBasisOrder[b.legalBasis[cat.id]] ?? 3;
        return aOrder - bOrder;
      },
      showSorterTooltip: {target: 'sorter-icon'},
      render: (value, record) => {
        const iabPurposeOriginal = systemPurposes.find((purpose) => purpose.purposeRef.id === cat.purposeRef?.id)
        const disabledIabChoice = !props.isNoFrameworkFlow && iabPurposeOriginal && !Object.keys(iabPurposeOriginal.configByGeo).includes(record.region)
        let isRespectGPC = cat.respectGPC && cat.respectGPC.includes(record.region.replace("US_", "STATE_"));
        return (
          <Tooltip
            title={isRespectGPC ? "GPC is enabled" : disabledIabChoice ? "Changing this not applicable in this region" : null}
          >
            <Select
              value={value}
              defaultValue={!Boolean(value) ? "NOT_APPLICABLE" : null}
              placeholder="Select"
              options={LEGALBASIS_OPTIONS}
              disabled={disabledIabChoice || props.readOnly || isRespectGPC}
              onChange={(value) => handleLegalBasisChange(cat.id, record.region, value)}
              style={{minWidth: 150}}
            />
          </Tooltip>
        )
      }
    })
  })
  
  useEffect(() => {
    let selectedRegionCodes = []
    GEO_LIST_TREE_STRUCTURE.forEach((option) => {
      if (props.usPrivacyRegulation.appliesGeos.includes(option.key)) {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes);
      } else {
        const childCodes = option.children.map(c => c.code);
        const filteredChildCodes = childCodes.filter(code => props.usPrivacyRegulation.appliesGeos.includes(code) && !props.usPrivacyRegulation.exclusionGeos.includes(code));
        selectedRegionCodes = selectedRegionCodes.concat(filteredChildCodes)
      }
    });
    let regionCategoryMapping = selectedRegionCodes.map((code) => ({region: code?.replace("STATE_", "US_"), key: code?.replace("STATE_", "US_"), title: region_title_index_map[code], legalBasis: {}}));
    categories.forEach((cat) => {
      if(cat.configByGeo) {
        regionCategoryMapping = regionCategoryMapping.map((data) => {
          const defLegBasisRecord = cat.configByGeo[data.region]
          if(defLegBasisRecord) {
            data["legalBasis"][cat.id] = defLegBasisRecord.defaultLegalBasis
          } 
          return data;
        })
      } 
    })
    setRegionCategoryMapping(regionCategoryMapping);
  }, [categories]);

  const rowSelection = {
    selectedRowKeys,
    onSelect : (record, selected, selectedRows, nativeEvent) => {
      if(selected) {
        setSelectedRowKeys((selectedKeys) => [... new Set(selectedKeys.concat(record.region))])
      } else {
        setSelectedRowKeys((selectedKeys) => selectedKeys.filter(key => key !== record.region))
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
        if(selected){
            setSelectedRowKeys((selectedKeys) => [...new Set(selectedKeys.concat(regionCategoryMapping.map(v => v.region)))]);
        }else{
            setSelectedRowKeys((selectedKeys) => selectedKeys.filter((key) => !regionCategoryMapping.map(v => v.region).includes(key)));
        }
    },
  }

  function handleClearSelection() {
    setSelectedRowKeys([]);
  }

  function handleBulkEdit() {
    setOpenBulkModal(true);
  }

  function handleGpcEdit() {
    setOpenRespectGpcModal(true);
  }

  const scrollTable = (scrollBy) => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      tableBody.scrollTo({
        left: tableBody.scrollLeft + scrollBy,
        behavior: 'smooth' // Enables smooth scrolling
      });
    }
  };

  const updateButtonState = () => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      setIsLeftDisabled(tableBody.scrollLeft <= 0);
      setIsRightDisabled(tableBody.scrollLeft >= maxScrollLeft - 0);
    }
  };

  useEffect(() => {
    const tableBody = document.querySelector('.usnat-region-mapping .ant-table-content');
    if (tableBody) {
      tableBody.addEventListener('scroll', updateButtonState);
      // Initial check to set button states
      updateButtonState();
    }
    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', updateButtonState);
      }
    };
  }, []);

  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  let dataSource = regionCategoryMapping
  if(searchValue.trim().length) {
    dataSource = regionCategoryMapping?.filter(record => record.title?.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1)
  }

  const gpcButton = categories.some((cat) => cat.purposeRef?.systemId === 3) ?  <Button type="primary" icon={<SafetyOutlined/>} onClick={handleGpcEdit}>GPC</Button> : null;

  return systemPurposesPending ? <Spin/> :  (
    <>
      {props.changesDetectedWarning}
      <Row gutter={[0, token.margin]}>
        <Col span={24}>
          <Space direction="vertical" size={token.marginXXS}>
          {!props.readOnly ? 
            <Title level={4}>Region Mapping</Title> 
          : null}
            <Text>
              {props.isNoFrameworkFlow
                ? "We have configured the default consent options for privacy choices on a per state basis, based on the latest legislation. You can make modifications using the drop-down menus in the matrix below."
                : "We have configured the default consent options for privacy choices on a per state basis, based on the state-level technical specifications that were developed by IAB's Tech Lab. You can make modifications using the drop-down menus in the matrix below."}
            </Text>
          </Space>
        </Col>
        <Col span={8}>
          <Input.Search
            placeholder="search"
            type="search"
            onChange={({ target: { value } }) => {
              debouncedChangeHandler(value);
            }}
          />
        </Col>
        <Col span={8} offset={8}>
          <Flex align='center' justify="flex-end">
            <Space size={token.margin}>
              {selectedRowKeys.length 
              ? (
                <>
                  <Button type="link" icon={<MinusSquareOutlined/>} onClick={handleClearSelection} disabled={props.readOnly}> Clear Selection</Button>
                  <Button type="primary" icon={<EditOutlined/>} onClick={handleBulkEdit} disabled={!selectedRowKeys.length || props.readOnly}> Bulk Edit ({selectedRowKeys.length})</Button>
                </>
              ) : gpcButton}
            </Space>
            {Boolean(gpcButton) || selectedRowKeys.length ? <Divider type="vertical" /> : null}
            <Space size={token.margin}>
              <Button  disabled={isLeftDisabled} icon={<LeftOutlined/>} onClick={() => scrollTable(-1000)}></Button>
              <Button  disabled={isRightDisabled} icon={<RightOutlined/>} onClick={() => scrollTable(1000)}></Button>
            </Space>
          </Flex>
        </Col>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={dataSource}
            className="usnat-region-mapping"
            rowSelection={!props.readOnly ? rowSelection : null}
            scroll={{ x: 1000}}
            pagination={{
              position: ['bottomCenter'],
              showTotal: (total) => <div>Total Regions :{' '}{total}</div>,
              size: 'small',
              defaultPageSize: 7,
              showSizeChanger: true,
              pageSizeOptions: ['7', '10', '15', '20'],
            }}
          />
        </Col>
      </Row>
      {openBulkModal ? (
        <BulkRegionMappingModal
          selectedRegions={regionCategoryMapping.filter(({region}) => selectedRowKeys.includes(region))}
          categories={categories}
          setCategories={setCategories}
          openModal={openBulkModal}
          closeModal={() => setOpenBulkModal(false)}
          isNoFrameworkFlow={props.isNoFrameworkFlow}
        />
      ) : null}
      {openRespectGpcModal ? (
        <RespectGpcModal
          respectGpcCategory={categories.find((cat) => cat.purposeRef?.systemId === 3) ?? {respectGPC: null}}
          regionsInVendorList={regionCategoryMapping.map((opt) => opt.region)}
          setCategories={setCategories}
          openModal={openRespectGpcModal}
          closeModal={() => setOpenRespectGpcModal(false)}
          readOnly={props.readOnly}
          isNoFrameworkFlow={props.isNoFrameworkFlow}
        />
      ) : null}
    </>
  );
}

export default RegionMapping;