import React, { useEffect, useState } from "react";
import { Col, Input, Row, Space, Switch, Typography } from "antd";
import { COOKIE_EXPIRATION_TOOLTIP, renderInfoTip } from "../../../helper";

const {Text} = Typography;

const SignalSharing = (props) => {
  const [shareRootDomain, setShareRootDomain] = useState(props.usPrivacyRegulation.shareRootDomain);
  const [writeFirstPartyCookiesFromServer, setWriteFirstPartyCookiesFromServer] = useState(props.usPrivacyRegulation.writeFirstPartyCookiesFromServer);
  const [cookieMaxAge, setCookieMaxAge] = useState(props.usPrivacyRegulation.cookieMaxAge)
  
  const handleRootDomainChange = (checked, e) => {
    setShareRootDomain(checked)
  }

  const handleFirstPartyCookiesChange = (checked, e) => {
    setWriteFirstPartyCookiesFromServer(checked)
  }

  const updateCookieMaxAge = ({target : {value}}) => {
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
      intValue = '';
    } else if (intValue < 1) {
      intValue = 1;
    } else if (intValue > 36135) {
      intValue = 36135;
    }
    setCookieMaxAge(intValue)
  };

  useEffect(()=>{
    if(props.readOnly) return;
    props.usPrivacyRegulation.shareRootDomain = shareRootDomain;
    props.usPrivacyRegulation.writeFirstPartyCookiesFromServer = writeFirstPartyCookiesFromServer;
    props.usPrivacyRegulation.cookieMaxAge = cookieMaxAge;
    props.resetValidationError();
  },[shareRootDomain,writeFirstPartyCookiesFromServer, cookieMaxAge]);

  return (
    <Row gutter={[0,16]}>
      {!props.readOnly ? (
      <Col className="step-header" span={24}>
        <h4>Advanced Settings</h4>
      </Col>
      ) : null}
      <Col>
        <Switch checked={shareRootDomain} disabled={props.readOnly} onChange={handleRootDomainChange} className="right-margin" /><span>Write 1st Party Cookies To Root Domain</span>  
      </Col>
      <Col className="step-section sharing-section">Toggling this setting ensures that the signal will be stored/persist across both the property's root domain and subdomains. In practice, this will ensure that users won't see the same message when moving from root to subdomain or vice versa.
      </Col>
      <Col>
        <Switch checked={writeFirstPartyCookiesFromServer} disabled={props.readOnly} onChange={handleFirstPartyCookiesChange} className="right-margin" /><span>Write 1st party cookies from the server to the property</span>  
      </Col>
      <Col className="step-section sharing-section">
        Toggling this setting on means that the server will set a 1st party cookie on your property, as opposed to the code on page setting the cookie which is the default state.
      </Col>
      {props.isStateFlow ? (
        <Col>
          <Space direction="vertical" size={4}>
            <Text strong>Privacy Choice Expiration {renderInfoTip(COOKIE_EXPIRATION_TOOLTIP)}</Text>
            <Text>Use the box below to determine the length of time you want to the cookie which stores the state of user's privacy choices (opt ins / opt outs) to last before it expires</Text>
            <div>
              <Input
                value={cookieMaxAge}
                onChange={updateCookieMaxAge}
                style={{width: 100}}
                error={cookieMaxAge === ''}
              />
            <label>&nbsp;<b>Days</b>&nbsp;(up to 36135 Days)</label>
            <div>
              {cookieMaxAge === '' ? <Text type="danger">Please enter a value greater than 0</Text> : null}
            </div>
          </div>
          </Space>
        </Col>
      ) : null}
    </Row>
  )
}

export default SignalSharing;