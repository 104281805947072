import React, { useRef } from "react";
import { Input, Tooltip, Typography, Button  } from 'antd'
import { Select} from "../index";
import { useState } from "react";
import { useEffect } from "react";
import { CheckSquareFilled, CloseOutlined, SearchOutlined } from "@ant-design/icons";
import classNames from "classnames";

const flattenGroupedOptions = (groupedOptions, flattened = []) => {
  return groupedOptions.reduce((flattened, opt) => {
    return opt.options
      ? [...flattened, ...flattenGroupedOptions(opt.options)]
      : [...flattened, opt]
  }, flattened)
}

const SelectDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.value);
  const [searchValue, onSearchChange] = useState("");
  const [optionLabelProp, setOptionLabelProp] = useState(props.optionLabelProp)
  const searchRef = useRef(null);

  const originalOptions = React.useMemo(
    () => (props.groupOptions ? flattenGroupedOptions(props.options) : props.options),
    []
  );

  const options = React.useMemo(
    () => (props.groupOptions? flattenGroupedOptions(props.options) : props.options).map((option) => option.value),
    [props.options]
  );

  useEffect(() => {
    if(props.value.length === 0){
      setValue([])
    } else if (props.value.length === options.length) {
      setValue(["all", ...options]);
    } else {
      setValue(props.value);
    }
  }, [props.value, options]);


  const onSelect = (val) => {
    if (props.multiple) {
      const updatedValue = [val, ...value];
      if (val === "all") {
        setValue(["all", ...options]);
      } else {
        if (updatedValue.length === options.length) {
          setValue(["all", ...updatedValue]);
          return;
        }
        setValue(updatedValue);
      }
    } else {
      props.onChange([val]);
      setOpen(false);
    }
  };

  const onDeselect = (val) => {
    if (!props.multiple) {
      setOpen(false);
      return;
    }
    if (val === "all") {
      const disabledValues = props.options.filter(value => value.disabled).map(o => o.value)
      setValue(disabledValues);
      if(props.groupOptions) props.onChange([]) 
    } else {
      setValue(value.filter((e) => e !== val && e !== "all"));
    }
  };

  const handleOnClear = () => {
    props.onChange([]);
    setValue([]);
    setOpen(false);
  };

  const handleOnSave = () => {
    props.onChange(value.filter((v) => v !== "all"));
    setOpen(false);
    onSearchChange("");
  };

  const handleCancel = () => {
    handleDropdownVisibleChange(false);
  };

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      if (props.directUpdate) {
        props.onChange(value.filter((v) => v !== "all"));
      } else {
        if (props.value.length === options.length) {
          setValue(["all", ...options]);
        } else {
          setValue(props.value);
        }
      }
      onSearchChange("");
    } else {
      setTimeout(() => {
        if (searchRef.current) {
          searchRef.current.focus();
        }
      }, 0);
    }
    setOpen(visible);
  };

  const maxTagPlaceholder = (ommitedValues) => {
    
    return value.length - 1 === options.length
      ? `All`
      : (
        <Tooltip
          title={
            ommitedValues.length > 10
              ? ommitedValues.map(({ title }) => title).join(", ")
              : ommitedValues.map(({ title }) => (
                  <>
                    <span>{title}</span>
                    <br />
                  </>
                ))
          }
        >
          + {ommitedValues.length}
        </Tooltip>
      );
  };

  const tagRender = (temp) => {
    const { label, value, closable, onClose } = temp;
    const selectedOption = originalOptions.find((option) => option.value === value);
    const customlabel = optionLabelProp && selectedOption ? selectedOption[optionLabelProp] : label;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <span
        className="ant-select-selection-item"
        onMouseDown={onPreventMouseDown}
      >
        {customlabel}
        {props.closeTags && value !== "DEFAULT"
          ? <CloseOutlined
              size={12}
              style={{marginLeft: "5px"}}
              className="pointer"
              onClick={()=>{props.onChange(props.value.filter((v) => v !== value))}}
            />
          : null}
      </span>
    );
  };
  const className = classNames("spsg-select-multiple", props.className, {"spsg-error": props.error});
  const dropdownClassName = classNames("spsg-select-multiple-drodown", props.dropdownClassName);

  const addLabelToOption = (option) => ({
    label: (
      <>
        <span className="spsg-select-dropdown-label" title={option.label}>
          {option.label}
        </span>
        {props.multiple && <CheckSquareFilled />}
      </>
    ),
    value: option.value,
    title: option.label,
    onMouseEnter:props.highlight ? (e) => props.highlight(e,option):() =>{},
    disabled: option?.disabled? option?.disabled: false
  });

  const populateOptions = (options) =>{
    let optArray = [];
    options.forEach((opt)=>{
      if(opt.options){
        optArray.push({
          label: opt.label,
          options: populateOptions(opt.options)
        })
      } else {
        optArray.push(addLabelToOption(opt))
      }
    })
    return optArray;
  }
  const filteredOptions = React.useMemo(
    () =>
      props.selectAll && props.multiple
        ? [
          addLabelToOption({
              label: `Select All ${props.entityName ? props.entityName : ""}`,
              value: "all",
            }),
            ...populateOptions(props.options),
          ]
        : populateOptions(props.options),
    [props.options]
  );

  const getOptionLabel = (option) => {
    // Custom styling for option labels
    const label = `${<b>{option.title}</b>} - ${<div dangerouslySetInnerHTML={{ __html: option.value }} />}`
    return (
      <span>
        {label}
      </span>
    );
  };

  return (
    <div>
    <Select
      virtual={true}
      optionLabelProp={props.optionLabelProp}
      maxTagPlaceholder={maxTagPlaceholder}
      menuItemSelectedIcon={null}
      open={open}
      showArrow
      placeholder={"Select"}
      onDropdownVisibleChange={handleDropdownVisibleChange}
      mode="multiple"
      optionFilterProp="title"
      searchValue={searchValue}
      value={value}
      maxTagCount={value.length - 1 === options.length ? 0 : "responsive"}
      className={className}
      popupClassName={dropdownClassName}
      onSelect={onSelect}
      onDeselect={onDeselect}
      options={filteredOptions}
      tagRender={tagRender}
      getOptionLabel={getOptionLabel}
      dropdownRender={(menu) => {
        return (
          <>
            {props.showSearch && (
              <div className="list-search">
                <Input
                  placeholder="Search"
                  onChange={({ target: { value } }) => onSearchChange(value)}
                  value={searchValue}
                  ref={searchRef}
                  type="search"
                  suffix={<SearchOutlined/>}
                />
              </div>
            )}
            {menu}
            {props.directUpdate ? null : (
              <div className="filter-button-group">
                {props.cancelButton ? (
                  <Button onClick={handleCancel}>
                    Cancel
                  </Button>
                ) : (
                  <Button onClick={handleOnClear}>
                    Clear
                  </Button>
                )}
                {props.multiple && (
                  <Button onClick={handleOnSave} type="primary">
                    Save
                  </Button>
                )}
              </div>
            )}
          </>
        );
      }}
      status={props.error ? 'error' : null}
      disabled={props.disabled}
    />
      { props.error && <Typography.Text type='danger'>{props.error}</Typography.Text> }
    </div>
  );
};

export default SelectDropdown;
