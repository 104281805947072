import React, { useEffect, useState, useContext } from "react";
import { Dropdown, Spin, Menu, Alert, Button, Input, Typography, Flex, Divider, Modal, } from "antd";
import { message } from "../../../../../../styleguide";
import { SearchOutlined, DeleteFilled, EditFilled, DownloadOutlined, InfoCircleFilled, DeleteOutlined } from "@ant-design/icons";
import AddVendorsModal  from "./AddVendorsModal";
import VendorsTable from "./VendorsTable";
import { generateVendorsHashMap, getVendorType, wrapInInfotip } from "../../../helper";
import { useDebouncedSearch, usePrevious } from "../../../../../../hooks";
import { connect } from "react-redux";
import { usPrivacyRegulationContext } from "../../../contexts";
import classNames from "classnames";
import BulkVendorsEditModal from "./BulkVendorsEditModal";
import { convertToCSV } from "../../../../../utils";
import _ from "lodash";
import Loading from "../../../../../common/Loading.js";
import CSVLink from "../../../../../common/CSVLink.js.jsx";
import { OrderedSet } from "immutable";

const VendorManagement = (props) => {
  const [showAddVendorsModal, setShowAddVendorsModal] = useState(false);
  const [selectedVendorsHashMap, setSelectedVendorsHashMap] = useState(generateVendorsHashMap(props.usPrivacyRegulation.categories))
  const [searchValue, setSearchValue] = useState('');
  const [selectedVendorsIdsForBulkAction, setSelectedVendorIdsForBulkAction] = useState([]);
  const [openBulkEditModal, setOpenBulkEditModal] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [exportCSVdropdownVisible, setExportCSVdropdownVisible] = useState(false);
  const [vendorTableFilters, setVendorTableFilters] = useState({});
  const { vendorsIdsMap } = useContext(usPrivacyRegulationContext);

  const previousCats = usePrevious(props.usPrivacyRegulation.categories);

  const [modal, contextHolder] = Modal.useModal();

  useEffect( ()=>{
    //because category ids change after every PUT call
    const didCatIdsUpdateAfterSave = previousCats?.every((cat, idx)=> {
      const newCat = props.usPrivacyRegulation.categories.find(c => c.privacyChoice === cat.privacyChoice)
      return cat.id !== newCat.id
    })

    if(didCatIdsUpdateAfterSave){
      const updateVHashMapPromise = () => new Promise((resolve, reject) => {
        resolve(generateVendorsHashMap(props.usPrivacyRegulation.categories))
      }) 
      const asyncUpdateVHashMap = async () => {
        setTableLoading(true);
        const vHashMap = await updateVHashMapPromise();
        setSelectedVendorsHashMap(vHashMap);
        setTableLoading(false);
      }
      asyncUpdateVHashMap();
    }
  },[props.usPrivacyRegulation.categories]);

  const updateCategories = async (categories) => {
    setTableLoading(true);
    await asyncUpdateCategories(categories);
    setTableLoading(false);
  }

  const asyncUpdateCategories = (categories) => new Promise((resolve, reject) => {
    props.usPrivacyRegulation.categories = categories;
    const vHashMap = generateVendorsHashMap(categories);
    setSelectedVendorsHashMap(_.cloneDeep(vHashMap));
    setSelectedVendorIdsForBulkAction([])
    resolve();
  }) 

  const updateVendorsWrappers = (vendorsWrappers) => {
    props.usPrivacyRegulation.vendorsWrappers = vendorsWrappers;
  }


  const removeVendors = async () => {
    const confirmation = await modal.confirm({
      title:"Remove Vendors",
      content: "Are you sure you want to remove selected vendors?",
      okText: "Remove",
      width: 500,
    })
    if(confirmation) {
      try{
        setTableLoading(true);
        const {vhashMap, updatedVendorsWrappers, updatedCategories } = await removeVendorsPromise();
        props.usPrivacyRegulation.categories = updatedCategories;
        props.usPrivacyRegulation.vendorsWrappers = updatedVendorsWrappers;
        setSelectedVendorsHashMap(vhashMap)
        setTableLoading(false);
        message.success(<><b>{selectedVendorsIdsForBulkAction.length}</b> vendors removed successfully</>)
        setSelectedVendorIdsForBulkAction([]);
      } catch(err) {
        console.log(err);
        message.error(`Failed to remove vendors`)
      }
    }
  }

  const updateVendorCategories = (vendorId, categories) => {
    let vhashMap = _.cloneDeep(selectedVendorsHashMap)
    let updatedVendorsWrappers = _.cloneDeep(props.usPrivacyRegulation.vendorsWrappers);
    if(categories === null){
      //remove vendor
      updatedVendorsWrappers = updatedVendorsWrappers.filter(vw => vw.vendorId !== vendorId)
      delete vhashMap[vendorId]
    }
    let updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
    updatedCategories = updatedCategories.map(cat => {
      if(categories === null){
        vhashMap[vendorId] = vhashMap[vendorId]?.filter(c => c !== (cat.id || cat.privacyChoice))
        delete cat.vendorCategorization[vendorId];
        return cat;
      }
      if(categories.includes(cat.id ?? cat.privacyChoice)){
        if(!cat.vendorCategorization[vendorId]){
          cat.vendorCategorization[vendorId] = {
            type: cat.defaultLegalBasis,
            vendorId: vendorId
          }
        }
      } else {
        if(cat.vendorCategorization[vendorId]){
          vhashMap[vendorId] = vhashMap[vendorId]?.filter(c => c !== (cat.id || cat.privacyChoice))
          delete cat.vendorCategorization[vendorId];
        }
      }
      return cat;
    })
    props.usPrivacyRegulation.categories = updatedCategories;
    props.usPrivacyRegulation.vendorsWrappers = updatedVendorsWrappers;
    setSelectedVendorsHashMap(vhashMap)
    setSelectedVendorIdsForBulkAction([])
  }

  const removeVendorsPromise = () =>
    new Promise((resolve, reject) => {
      setTimeout(() => {
        let vhashMap = _.cloneDeep(selectedVendorsHashMap);
        let updatedVendorsWrappers = _.cloneDeep( props.usPrivacyRegulation.vendorsWrappers);
        let updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories);
        updatedVendorsWrappers = updatedVendorsWrappers.filter((vw) => !selectedVendorsIdsForBulkAction.includes(vw.vendorId));
        selectedVendorsIdsForBulkAction.forEach((vendorId) => {
          delete vhashMap[vendorId];
          updatedCategories = updatedCategories.map((cat) => {
            delete cat.vendorCategorization[vendorId];
            return cat;
          });
        });
        resolve({vhashMap, updatedVendorsWrappers, updatedCategories});
      }, 1);
    });

  // useEffect(()=>{
  //     let updatedCategories = props.usPrivacyRegulation.categories.map(cat => {
  //       for (const [vendorId, categories] of Object.entries(selectedVendorsHashMap)){
  //         if(categories?.includes(cat.id ?? cat.privacyChoice)){
  //           if(!cat.vendorCategorization[vendorId]){
  //             cat.vendorCategorization[vendorId] = {
  //               type: cat.defaultLegalBasis,
  //               vendorId: vendorId
  //             }
  //           }
  //         } else {
  //           if(vendorId && cat.vendorCategorization[vendorId]){
  //             delete cat.vendorCategorization[vendorId];
  //           }
  //         }
  //       }
  //       return cat;
  //     })
  //     props.usPrivacyRegulation.categories = updatedCategories;
  // },[selectedVendorsHashMap]);



  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  let dataSource = null;
  dataSource = props.usPrivacyRegulation.vendorsWrappers?.map(vw => ({...vw, ids: vendorsIdsMap[vw.vendorId]})) ?? []
  dataSource = searchValue.trim().length ? _.cloneDeep(dataSource)?.filter(vendor => vendor.vendorId.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || vendor.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : _.cloneDeep(dataSource)

  const bulkUpdateCat = (selectedCategories) => new Promise((resolve, reject)=>{
    let updatedCategories = _.cloneDeep(props.usPrivacyRegulation.categories)
    if(selectedCategories?.length){
      updatedCategories =  updatedCategories.map( cat => {
        if(selectedCategories.includes(cat.id ?? cat.privacyChoice)){
          props.usPrivacyRegulation.vendorsWrappers.filter(vw => selectedVendorsIdsForBulkAction.includes(vw.vendorId)).forEach(vw => {
            cat.vendorCategorization[vw.vendorId] = {
              type: cat.defaultLegalBasis,
              vendorId: vw.vendorId
            }
          })
        } else {
          props.usPrivacyRegulation.vendorsWrappers.filter(vw => selectedVendorsIdsForBulkAction.includes(vw.vendorId)).forEach(vw => {
            // cat.vendorCategorization[vw.vendorId] = {}
            delete cat.vendorCategorization[vw.vendorId]
          })
        }
        return cat;
      })
    }
    resolve(updatedCategories); 
  })
 
  const handleBulkUpdate = async (selectedCategories) => {
    try{
      setTableLoading(true)
      const updatedCategories = await bulkUpdateCat(selectedCategories);
      updateCategories(updatedCategories);
      setTableLoading(false);
      setOpenBulkEditModal(false);
      message.success(`Privacy choices updated successfully `)
      setSelectedVendorIdsForBulkAction([])
    }catch(err){
      console.log(err)
    }
  }

  const applyVendorTypeFilter = (record, vendorTypeFilter) => {
    if (vendorTypeFilter.length === 0) return true;
    const vendorType = getVendorType(record);
    return vendorTypeFilter[0] === vendorType;
  };

  const exportVendorsAndChoices = (exportFiltered) => {
    let data = []
    let vendorTableData = _.cloneDeep(props.usPrivacyRegulation.vendorsWrappers?.map(vw => ({...vw, ids: vendorsIdsMap[vw.vendorId]})));
    if(exportFiltered) {
      vendorTableData = vendorTableFilters.name ? vendorTableData.filter(record => applyVendorTypeFilter(record, vendorTableFilters.name)) : vendorTableData;
      vendorTableData = vendorTableFilters.categories ? vendorTableData.filter(vw => selectedVendorsHashMap[vw.vendorId]?.some( cat => vendorTableFilters.categories.includes(cat))) : vendorTableData;
    }
    vendorTableData.forEach(vw => {
      data.push({
        vendorId: vw.vendorId,
        name: vw.name,
        type: getVendorType(vw),
        privacyChoices: props.usPrivacyRegulation.categories.filter(cat => selectedVendorsHashMap[vw.vendorId]?.includes(cat.id ?? cat.privacyChoice))?.map(cat => cat.privacyChoice ?? cat.privacyChoice)?.join(', ')
      })
    })
    const csvContent = (data.length > 0) ? convertToCSV(data) : "";
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csvContent], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = props.usPrivacyRegulation.name + 'Vendors&privacyChoices_UsPrivacyRegulation.csv';
    a.click();
    URL.revokeObjectURL(url);
  }

  const emptyTableTextCheck = vendorTableFilters.name || vendorTableFilters.categories || searchValue.trim().length;

  let changesAlert = props.changesDetectedWarning;

  if(props.usPrivacyRegulation?.deletedVendors?.length){
    const deletedVendors = props.usPrivacyRegulation.deletedVendors.map(v => v.name);
    changesAlert = (
      <Alert
        showIcon
        icon={<InfoCircleFilled className="info-icon" />}
        message={
          <div>
            <CSVLink
              data={deletedVendors.map((v) => [v])}
              enclosingCharacter={``}
              filename={"removed vendors_" + props?.usPrivacyRegulation?.name + ".csv"}
              onClick={(e) => e.stopPropagation()}
              isSanitized={true}
            >
             <b>{props.usPrivacyRegulation.deletedVendors.length} vendor(s)</b>
            </CSVLink>{" "}
            were removed from the vendor list due to a change with the vendor IDs. You can add them back in by clicking <b>Add Vendors</b> and finding the vendor(s) within <b>System Vendors.</b>
          </div>
        }
        type="warning"
      />
    );
  }

  return (
    <>
      {changesAlert}
      {!props.readOnly ? (
        <div className="step-header">
          <Typography.Title level={4}>Vendor Management</Typography.Title>
        </div>
      ) : null}
      <div className="step-breif">
        Choose the vendors that you use and map them to the privacy choices they process data for.
      </div>
      <div className="vendor-management-table-header-bar">
        <Input.Search
          placeholder="Search vendor name or ID"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
          style={{width: 360}}
        />
        {!props.readOnly ? (
          <Flex align='center'>
            {props.usPrivacyRegulation.vendorsWrappers?.length ? (
              <>
              <Typography.Text disabled={selectedVendorsIdsForBulkAction.length == 0} style={{marginRight: `var(--ant-margin-xs)`}}>Bulk actions:</Typography.Text>
              {wrapInInfotip(<Button type='link' icon={<EditFilled/>} disabled={selectedVendorsIdsForBulkAction.length == 0} onClick={() => setOpenBulkEditModal(true)}/>, "Edit")}
              {wrapInInfotip(<Button type='link' icon={<DeleteOutlined/>} disabled={selectedVendorsIdsForBulkAction.length == 0} onClick={() => removeVendors()}/>, "Remove Vendors")}
              <Divider type="vertical" orientation="center" style={{margin:"0 8px"}}/>
              <Dropdown
                overlay={
                  <Menu className="buttons-group">
                    <Menu.Item onClick={() => exportVendorsAndChoices(false)}>
                      Download all vendors (csv)
                    </Menu.Item>
                    <Menu.Item onClick={() => exportVendorsAndChoices(true)} disabled={!(vendorTableFilters.name || vendorTableFilters.categories)}>
                      Download filtered vendors (csv)
                    </Menu.Item>
                  </Menu>
                }
                trigger={['click']}
                placement="left"
                open={exportCSVdropdownVisible}
                onOpenChange={(open) => setExportCSVdropdownVisible(open)}
              >
                {wrapInInfotip(<Button type='link' icon={<DownloadOutlined/>} disabled={props.usPrivacyRegulation.vendorsWrappers.length === 0} />, "Export")}
              </Dropdown>
              </>
            ): null}
            <Button type="primary" onClick={() => setShowAddVendorsModal(true)} style={{marginLeft: 12}}> + Add Vendors </Button>
          </Flex>
         ) : null}
      </div>
      {/* <div className="step-section no-border"> */}
        {/* <div className="section-content"> */}
          <div style={{width: "100%"}}>
            {tableLoading ? <Loading/> : (
            <VendorsTable
              dataSource={dataSource}
              selectedVendors={selectedVendorsIdsForBulkAction}
              setSelectedVendors={setSelectedVendorIdsForBulkAction}
              isLoading={false}
              readOnly={props.readOnly}
              categoryOptions={props.usPrivacyRegulation.categories.map(
                (cat) => ({ value: cat.id ?? cat.privacyChoice, label: cat.privacyChoice ?? cat.name })
              )}
              vendorsHashMap={selectedVendorsHashMap}
              updateInstantly={updateVendorCategories}
              setVendorTableFilters={setVendorTableFilters}
              emptyTableTextCheck={emptyTableTextCheck}
            />)}
          </div>
        {/* </div>
      </div> */}
      {showAddVendorsModal && (
        <AddVendorsModal
          showAddVendorsModal={showAddVendorsModal}
          setShowAddVendorsModal={setShowAddVendorsModal}
          usPrivacyRegulation={props.usPrivacyRegulation}
          updateCategories={updateCategories}
          updateVendorsWrappers={updateVendorsWrappers}
        />
      )}
      {openBulkEditModal && (
        <BulkVendorsEditModal
          openBulkEditModal={openBulkEditModal}
          setOpenBulkEditModal={setOpenBulkEditModal}
          categories={props.usPrivacyRegulation.categories}
          selectedVendorsWrappers={props.usPrivacyRegulation.vendorsWrappers.filter(vw => selectedVendorsIdsForBulkAction.includes(vw.vendorId))}
          // updateCategories={updateCategories}
          selectedVendorsHashMap={selectedVendorsHashMap}
          handleBulkUpdate={handleBulkUpdate}
        />
      )}
      {contextHolder}
    </>
  );
}
const mapStateToProps = function (store) {
  return {
    globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'value']) ?? OrderedSet([]),
  };
};

export default connect(mapStateToProps)(VendorManagement);