import React, { useEffect, useState } from 'react';
import { Popover, Menu, Button, Spin, Checkbox } from 'antd';
import VendorStatusFilters from './VendorStatusFilter';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getVendorManagementActivitiesList,
  getVendorManagementActivitiesListClear,
  setIsVendorStatusMultiselectOperationActive,
  setVendorStatusSelectedPropertyList,
  vendorManagementActivitiesListPending,
} from '../../../../actions/diagnose_dashboard_actions';
import { getVendorWebsiteActivitiesListData } from '../../../../api/diagnose_dashboard/diagnose_dashboard';
import SVG from 'react-inlinesvg';
import multiselectIcon from '../../../../assets/icons/multi_select_icon.svg';
import multiselectActiveIcon from '../../../../assets/icons/multi _select_active_icon.svg';
import filterIcon from '../../../../assets/icons/vendor_management_filter_icon.svg';
import filterWithSelectedOptionsIcon from '../../../../assets/icons/vendor_management_filter_active_icon.svg';

import unreviewedStatusIcon from '../../../../assets/icons/unreviwed-status.svg';
import underReviewStatusIcon from '../../../../assets/icons/under-review-status.svg';
import allowedStatusIcon from '../../../../assets/icons/allowed-status.svg';
import actionRequiredStatusIcon from '../../../../assets/icons/action-required.svg';
import allowedRiskStatusIcon from '../../../../assets/icons/allowed-risk-status.svg';
import useSearchParams from '../shared/useSearchParams';

const VendorStatusPropertySelection = ({
  data,
  type,
  selectedMetric,
  setVendorStatusSelectedPropertyList,
  vendorStatusSelectedPropertyList,
  vendorManagementActivities,
  addVendorManagementActivitiesPending,
  vendorManagementStatusesPending,
  isVendorStatusMultiselectOperationActive,
  setIsVendorStatusMultiselectOperationActive,
  getVendorManagementActivitiesList,
  vendorManagementActivitiesListPending,
  getVendorManagementActivitiesListClear,
  vendorStatusFilterList,
}) => {
  const [indeterminate, setIndeterminate] = useState(false);
  const [isAllPropertiesChecked, setIsAllPropertiesChecked] = useState(false);
  const [isVendorStatusFilterVisible, setIsVendorStatusFilterVisible] = useState(false);
  const searchParams = useSearchParams();

  useEffect(() => {
    setVendorStatusSelectedPropertyList([]);
  }, [isVendorStatusMultiselectOperationActive]);

  useEffect(() => {
    getVendorManagementActivitiesListClear();
    setVendorStatusSelectedPropertyList([]);
    setIsVendorStatusFilterVisible(false);
  }, [vendorStatusFilterList]);

  useEffect(() => {
    if (searchParams.property) {
      setVendorStatusSelectedPropertyList([searchParams.property]);
      getVendorWebsiteActivitiesListData1([searchParams.property]);
    }
  }, [searchParams.property]);

  useEffect(() => {
    setIndeterminate(
      !!vendorStatusSelectedPropertyList.length && vendorStatusSelectedPropertyList.length < data?.vendor_status.length,
    );
    if (vendorStatusSelectedPropertyList.length === data?.vendor_status.length) {
      setIsAllPropertiesChecked(true);
    }
    if (!vendorStatusSelectedPropertyList.length) {
      getVendorManagementActivitiesListClear();
      setIsAllPropertiesChecked(false);
    }
  }, [vendorStatusSelectedPropertyList]);

  const getVendorWebsiteActivitiesListData1 = async (selectedProperties) => {
    vendorManagementActivitiesListPending(true);
    getVendorManagementActivitiesListClear();
    let arr = [];
    for (let i = 0; i <= selectedProperties.length - 1; i++) {
      let response = await getVendorWebsiteActivitiesListData({
        vendorId: type === "app"? data.diagnose_vendor_id: data?.vendor_id,
        website: selectedProperties[i],
        metric: selectedMetric,
      });
      arr = arr.concat(response);
    }
    getVendorManagementActivitiesList(arr);
    vendorManagementActivitiesListPending(false);
  };

  useEffect(() => {
    if (vendorManagementActivities && vendorStatusSelectedPropertyList?.length) {
      getVendorWebsiteActivitiesListData1(vendorStatusSelectedPropertyList);
    }
  }, [vendorManagementActivities]);

  const handleSelectWebsite = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    setVendorStatusSelectedPropertyList(selectedKeys);
    getVendorWebsiteActivitiesListData1(selectedKeys);
  };

  const onChangeCheckAllPropertiesCheckbox = (e) => {
    if (e.target.checked) {
      const allPropertyKeys = data?.vendor_status.map((item) => item.website);
      setVendorStatusSelectedPropertyList(allPropertyKeys);
      getVendorWebsiteActivitiesListData1(allPropertyKeys);
    } else {
      setVendorStatusSelectedPropertyList([]);
    }
    setIndeterminate(false);
    setIsAllPropertiesChecked(e.target.checked);
  };
  return (
    <Spin
      spinning={addVendorManagementActivitiesPending || vendorManagementStatusesPending}
      size="small"
      wrapperClassName={'detailed-info-filters'}
    >
      <div className={'detailed-info-property-filter'}>
        <div className={'detailed-info-property-filter-header'}>
          <div className={'detailed-info-property-filter-title'}>
            {isVendorStatusMultiselectOperationActive && (
              <Checkbox
                indeterminate={indeterminate}
                checked={isAllPropertiesChecked}
                className={'check-all-property-checkbox'}
                onChange={onChangeCheckAllPropertiesCheckbox}
              />
            )}
            <div>Property ({data?.vendor_status.length})</div>
          </div>
          <div className={'detailed-info-property-filter-btn-container'}>
            <Popover
              content={<VendorStatusFilters />}
              trigger="click"
              placement={'bottom'}
              open={isVendorStatusFilterVisible}
              onOpenChange={(newOpen) => setIsVendorStatusFilterVisible(newOpen)}
            >
              <div className={'detailed-info-property-filter-btn-block'}>
                {vendorStatusFilterList.length < 6 ? (
                  <SVG src={filterWithSelectedOptionsIcon} />
                ) : (
                  <SVG src={filterIcon} />
                )}
              </div>
            </Popover>
            <Button
              icon={<SVG src={isVendorStatusMultiselectOperationActive ? multiselectActiveIcon : multiselectIcon} />}
              className={`detailed-info-property-filter-btn-block ${isVendorStatusMultiselectOperationActive && 'multiple-active'}`}
              onClick={() => setIsVendorStatusMultiselectOperationActive(!isVendorStatusMultiselectOperationActive)}
            />
          </div>
        </div>
        <div className={'detailed-info-property-filter-items'}>
          {data?.vendor_status && (
            <Menu
              multiple={isVendorStatusMultiselectOperationActive}
              onSelect={handleSelectWebsite}
              onDeselect={handleSelectWebsite}
              selectedKeys={vendorStatusSelectedPropertyList}
            >
              {data?.vendor_status.map((vendorStatus, index) => {
                const statusName = vendorStatus.status;
                const tagName = vendorStatus.tag;
                if (
                  vendorStatusFilterList.includes(vendorStatus.status) ||
                  vendorStatusFilterList.includes(vendorStatus.tag)
                ) {
                  return (
                    <Menu.Item key={vendorStatus.website} title={vendorStatus.website_name}>
                      {isVendorStatusMultiselectOperationActive && <div className={'property-filter-checkbox'} />}
                      {statusName === 'Unreviewed' ? (
                        <SVG src={unreviewedStatusIcon} className={'status-icon'} />
                      ) : null}
                      {statusName === 'Approved' ? <SVG src={allowedStatusIcon} className={'status-icon'} /> : null}
                      {statusName === 'Action Required' ? (
                        <SVG src={actionRequiredStatusIcon} className={'status-icon'} />
                      ) : null}
                      {statusName === 'Under Review' ? (
                        <SVG src={underReviewStatusIcon} className={'status-icon'} />
                      ) : null}
                      {statusName === 'Approved with risk' ? (
                        <SVG src={allowedRiskStatusIcon} className={'status-icon'} />
                      ) : null}
                      <div
                        className={`website-name ${isVendorStatusMultiselectOperationActive ? 'property-filter-active' : ''}`}
                      >
                        {vendorStatus.website_name}
                      </div>
                      {tagName === 'New' ? <div className={'vendor-status-tag blue-tag'} /> : null}
                      {tagName === 'Change Detected' ? <div className={'vendor-status-tag yellow-tag'} /> : null}
                    </Menu.Item>
                  );
                }
                return null;
              })}
            </Menu>
          )}
        </div>
      </div>
    </Spin>
  );
};

const mapStateToProps = function (store) {
  return {
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    vendorStatusSelectedPropertyList: store.diagnoseDashboardState.getIn(['vendorStatusSelectedPropertyList', 'value']),
    addVendorManagementActivitiesPending: store.diagnoseDashboardState.get('addVendorManagementActivitiesPending'),
    vendorManagementStatusesPending: store.diagnoseDashboardState.get('vendorManagementStatusesPending'),
    isVendorStatusMultiselectOperationActive: store.diagnoseDashboardState.getIn([
      'isVendorStatusMultiselectOperationActive',
      'value',
    ]),
    vendorManagementActivitiesList: store.diagnoseDashboardState.getIn(['vendorManagementActivitiesList', 'value']),
    vendorStatusFilterList: store.diagnoseDashboardState.getIn(['vendorStatusFilterList', 'value']),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setVendorStatusSelectedPropertyList,
      setIsVendorStatusMultiselectOperationActive,
      getVendorManagementActivitiesList,
      vendorManagementActivitiesListPending,
      getVendorManagementActivitiesListClear,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorStatusPropertySelection);
