import { Button, Flex, Input, List, message, Modal, Table, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { mapSelectedFilters } from '../helpers';
import useTableDisplayConfig from '../../shared/useTableDispayConfig';
import { connect } from 'react-redux';
import { getChatbotHarFileData, getCookielessHarFileData, getParamsPixelsTechDetectedList, getPixelsHarFileData, getSessionReplayHarFileData } from '../../../../../actions/diagnose_dashboard_actions';
import { bindActionCreators } from 'redux';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';

function InfoModal({
  modalOpenType,
  data,
  onClose,
  selectedFilters,
  userDetails,
  pixelsParamsTechDetectedList,
  pixelsParamsTechDetectedListPending,
  getParamsPixelsTechDetectedList,
  getPixelsHarFileData,
  getCookielessHarFileData,
  getChatbotHarFileData,
  getSessionReplayHarFileData,
}) {
  const [tableFilters, setTableFilters] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState('');
  const filters = useMemo(() => mapSelectedFilters(selectedFilters), [selectedFilters]);
  const { limit, page, orderBy, handleLimit, handlePageChange, handleOrder } = useTableDisplayConfig(10, 1, 'name_asc');
  const accountId = userDetails.accountId;
  const vendorId = data?.vendorId || '';
  const pixelId = data?.record?.pixel_id || '';
  const techId = data.techId || "";
  
  const getPixelParamsTechDetected = async (tableFiltersData = {}, vendorId = []) => {
    const res = await getParamsPixelsTechDetectedList({
      ...filters,
      accountId,
      page,
      limit,
      orderBy,
      tableFiltersData,
      vendorId,
      pixelId,
    });
    return res;
  };

  const handleHarFileDownload = (scanUrl) => {
    const harFileDataFunctions = {
      pixelsScanUrl: getPixelsHarFileData,
      cookielessScanUrl: getCookielessHarFileData,
      chatbotsScanUrl: getChatbotHarFileData,
      sessionReplayScanUrl: getSessionReplayHarFileData,
    };
  
    const fetchHarFileData = harFileDataFunctions[modalOpenType];
    if (fetchHarFileData) {
      fetchHarFileDataWithParams(fetchHarFileData, filters, vendorId, scanUrl);
    }
  };
  
  const fetchHarFileDataWithParams = async (fetchFn, filters, vendorId, scanUrl) => {
    try {
      const response = await fetchFn({
        ...filters,
        accountId,
        page,
        limit,
        orderBy,
        vendorId,
        techId,
        scanUrl,
      });
      return response;
    } catch (error) {
      console.error("Error fetching HAR file data:", error);
    }
  };
  
  

  useEffect(() => {
    if (modalOpenType === 'parameterModal') {
      getPixelParamsTechDetected(filters, vendorId);
    }
  }, [filters, page, limit, orderBy, accountId, vendorId]);

  const onTableChange = (pagination, filters, sorter, extra) => {
    handleLimit(pagination.pageSize);
    handleOrder(sorter);
  };

  const onPaginationChange = (pageNumber) => {
    handlePageChange(pageNumber);
  };

  useEffect(() => {
    setFilteredData(pixelsParamsTechDetectedList?.results || []);
  }, [pixelsParamsTechDetectedList]);

  const onSearch = (e) => {
    const searchValue = e.target.value ? e.target.value.toLowerCase() : '';
    const filteredResults = pixelsParamsTechDetectedList?.results.filter(
      (item) => item.name.toLowerCase().includes(searchValue) || item.value.toLowerCase().includes(searchValue),
    );

    setFilteredData(filteredResults);
    setSearch(e.target.value);
  };
  

  const pixelParamsColumns = [
    {
      title: 'Key',
      dataIndex: 'name',
      key: 'name',
      width: 200,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      render: (text) => {
        const handleClick = (evt) => {
          evt.stopPropagation();
          evt.preventDefault();
          navigator.clipboard.writeText(text);
          message.success('Value copied');
        };

        return (
          <>
            <Flex justify="space-between" align="center">
              <Tooltip
                overlayClassName="diagnose--tooltip"
                title={
                  <div onClick={(evt) => evt.stopPropagation()} className="information-storage-tooltip">
                    {text}
                  </div>
                }
              >
                <div
                  style={{
                    maxWidth: '500px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {text}
                </div>
              </Tooltip>
              <Tooltip
                onClick={(evt) => evt.stopPropagation()}
                overlayClassName="diagnose--tooltip"
                title={<div onClick={(evt) => evt.stopPropagation()}>Copy key Value</div>}
              >
                <Button type="link" onClick={handleClick}>
                  <CopyOutlined />
                </Button>
              </Tooltip>
            </Flex>
          </>
        );
      },
    },
  ];

  return (
    <Modal
      title={
        <div>
          {(modalOpenType === 'pixelsScanUrl' ||
            modalOpenType === 'chatbotsScanUrl' ||
            modalOpenType === 'cookielessScanUrl' ||
            modalOpenType === 'sessionReplayScanUrl') &&
            'Scan URLs'}
          {modalOpenType === 'parameterModal' && 'Parameters Passed'}
        </div>
      }
      open={!!modalOpenType}
      destroyOnClose={true}
      onCancel={onClose}
      okButtonProps={{ style: { display: 'none' } }}
      width={800}
    >
      {modalOpenType === 'pixelsScanUrl' ||
      modalOpenType === 'chatbotScanUrl' ||
      modalOpenType === 'cookielessScanUrl' ||
      modalOpenType === 'sessionReplayScanUrl' ? (
        <List
          size="small"
          dataSource={data.record}
          renderItem={(item) => (
            <List.Item>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                <Tooltip
                  overlayClassName="diagnose--tooltip"
                  title={
                    <div className="information-storage-tooltip">
                      {item}
                    </div>
                  }
                >
                  <div
                    style={{
                      maxWidth: '700px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item}
                  </div>
                </Tooltip>
                <div>
                  <Button type="default" icon={<DownloadOutlined />} onClick={() => handleHarFileDownload(item)}>
                    HAR file
                  </Button>
                </div>
              </div>
            </List.Item>
          )}
        />
      ) : modalOpenType === 'parameterModal' ? (
        <div>
          <div>
            <Input.Search
              placeholder="Search Parameter Key"
              onChange={onSearch}
              style={{ marginBottom: 16, width: 300 }}
            />
            <Table
              columns={pixelParamsColumns}
              dataSource={filteredData}
              pagination={
                filteredData?.length > 0
                  ? {
                      current: page,
                      pageSize: limit,
                      total: pixelsParamsTechDetectedList.total_count,
                      position: ['bottomCenter'],
                      onChange: onPaginationChange,
                      defaultPageSize: 10,
                      showSizeChanger: true,
                      pageSizeOptions: ['10', '50', '100', '200'],
                    }
                  : false
              }
              onChange={onTableChange}
              loading={{
                className: 'diagnose-spinning',
                spinning: pixelsParamsTechDetectedListPending,
                tip: pixelsParamsTechDetectedList?.results?.length
                  ? ''
                  : 'Processing a large query; please wait or leave it open and check later.',
                size: 'large',
              }}
            />
          </div>
        </div>
      ) : null}
    </Modal>
  );
}

const mapStateToProps = (store) => ({
  userDetails: store.accountState.getIn(['userDetails', 'value']),
  selectedFilters: store.diagnoseDashboardState.getIn(['selectedFilters', 'value']),
  pixelsParamsTechDetectedList: store.diagnoseDashboardState.getIn(['pixelsParamsTechDetectedList', 'value']),
  pixelsParamsTechDetectedListPending: store.diagnoseDashboardState.get('pixelsParamsTechDetectedListPending'),
  pixelsHarFileData: store.diagnoseDashboardState.getIn(['pixelsHarFileData', 'value']),
  pixelsHarFileDataPending: store.diagnoseDashboardState.get('pixelsHarFileDataPending'),
  cookielessHarFileData: store.diagnoseDashboardState.getIn(['cookielessHarFileData', 'value']),
  cookielessHarFileDataPending: store.diagnoseDashboardState.get('cookielessHarFileDataPending'),
  chatbotHarFileData: store.diagnoseDashboardState.getIn(['chatbotHarFileData', 'value']),
  chatbotHarFileDataPending: store.diagnoseDashboardState.get('chatbotHarFileDataPending'),
  sessionReplayHarFileData: store.diagnoseDashboardState.getIn(['sessionReplayHarFileData', 'value']),
  sessionReplayHarFileDataPending: store.diagnoseDashboardState.get('sessionReplayHarFileDataPending'),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getParamsPixelsTechDetectedList,
      getPixelsHarFileData,
      getPixelsHarFileData,
      getCookielessHarFileData,
      getChatbotHarFileData,
      getSessionReplayHarFileData,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(InfoModal);
