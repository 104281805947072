import { Collapse, Empty, Image, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import DetailedBreakdown from './DetailedBreakdown';
import isSameSummaryData from '../../details_pages/isSameSummaryData';

const description = {
  all: 'The graphs below demonstrate how your selected properties compare to the selected benchmark properties in relation to how many vendors are firing after opt out from the share and/or sell of personal information. For more details please see the “Detailed Breakdown” section below.',
  average:
    "The average count shows the number of times on average per scan that we have observed the compliance metric you're viewing on your selected properties (average count my properties) and on your benchmark properties (average count benchmark)",
  relative:
    'The relative score is a percentile rank that looks at the specified compliance metric and compares the average performance across your properties with the average performance of your benchmark properties. The higher the relative score the better your privacy and data ethics.',
};

const VendorsAfterTargetedAdvertisingOptOut = ({
  summaryDiagnose,
  summaryPending,
  vendorsAfterOptOutPending,
  selectedFilters,
}) => {
  const isUSRegionSelected = !!selectedFilters?.regions?.filter((r) => r.match(/(US:)/gm))?.length;

  return (
    <div>
      <Spin spinning={summaryPending} size="large">
      {Object.keys(summaryDiagnose).length > 0 ?(
          <DetailedBreakdown />
        ):(
          <Empty />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.appDashboardState.getIn(['summaryAppDiagnose', 'value']),
    summaryPending: store.appDashboardState.get('summaryAppPending'),
  };
};

export default connect(mapStateToProps, null)(VendorsAfterTargetedAdvertisingOptOut);
