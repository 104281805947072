import * as types from './action_types';
import store from '../store.js';
import {
  getUserDetailsAjax,
  changeUserAccessAjax,
  inviteUserAjax,
  deleteUserAjax,
  updateUserAjax,
  getUserDomainsAjax,
  addDomainAjax,
  deleteDomainAjax,
  createAccountAjax,
  getAccountOwnersAjax,
  getAccountPermissionsAjax,
  getPermissionsListAjax,
  updateAccountPermissionsAjax,
  getAllAccountsAjax,
  freeScanDetailsAjax,
  getDiagnosePropertiesAjax,
  getALLSDKKeysAjax,
  generateSDKKeyAjax
} from '../api/account';

import { emailCustomReportAjax } from '../api/custom_report';
import { actionCreator } from './helpers';
import { DatabaseFilled } from '@ant-design/icons';

export const getAccountOwners = actionCreator(
  getAccountOwnersAjax,
  accountOwnersPending,
  accountOwnersLoaded,
  accountOwnersError,
);

export const getAllSDKKeys = actionCreator(
  getALLSDKKeysAjax,
  getAllSDKKeyPending,
  getAllSDKKeyLoaded,
  getAllSDKKeyError,
);

export const generateSDKKey = actionCreator(
  generateSDKKeyAjax,
  getAllSDKKeyPending,
  generateSDKKeyLoaded,
  generateSDKKeyError,
);

export const getAccountPermissions = actionCreator(
  getAccountPermissionsAjax,
  accountPermissionsPending,
  accountPermissionsLoaded,
  accountPermissionsError,
);

export const getPermissionsList = actionCreator(
  getPermissionsListAjax,
  permissionsListPending,
  permissionsListLoaded,
  permissionsListError,
);

export const getDiagnoseProperties = actionCreator(
  getDiagnosePropertiesAjax,
  diagnosePropertiesPending,
  diagnosePropertiesLoaded,
  diagnosePropertiesError,
);

export const updateAccountPermissions = actionCreator(
  updateAccountPermissionsAjax,
  accountPermissionsPending,
  accountPermissionsLoaded,
  accountPermissionsError,
);

export const getAllAccounts = actionCreator(
    getAllAccountsAjax,
    accountsPending,
    accountsLoaded,
    accountsError,
);

export const getUserDetails = actionCreator(
    getUserDetailsAjax,
    userDetailsPending,
    userDetailsLoaded,
    userDetailsLoadError,
    userDetailsCached,
);

export const updateUser = actionCreator(
    updateUserAjax,
    updateUserPending,
    userDetailsLoaded,
    userDetailsLoadError,
);

export const emailCustomReport = (url, params) => {
    return (dispatch) => {
        dispatch(emailReportPending());
        emailCustomReportAjax(url, params).then(resp => {
            return dispatch(emailReportSuccess(resp));
        }).catch(resp => {
            return dispatch(emailReportError(resp.message));
        })
    }
};

export const changeUserAccess = actionCreator(
  changeUserAccessAjax,
  usersPending,
  userAccessChanged,
  usersError,
);

export const inviteUser = actionCreator(
  inviteUserAjax,
  invitedUserPending,
  userInvited,
  invitedUserError,
);

export const deleteUser = actionCreator(
  deleteUserAjax,
  usersPending,
  userDeleted,
  usersError,
);

export const getUserDomains = actionCreator(
  getUserDomainsAjax,
  domainsPending,
  domainsLoaded,
  domainsError,
);

export const addDomain = actionCreator(
  addDomainAjax,
  domainsPending,
  domainAdded,
  domainsError,
);

export const deleteDomain = actionCreator(
  deleteDomainAjax,
  domainsPending,
  domainDeleted,
  domainsError,
);

export const createAccount = actionCreator(
  createAccountAjax,
  createAccountPending,
  accountCreated,
  createAccountError,
);

//for Google Ad Manager free scan form
export const freeScanDetails = actionCreator(
  freeScanDetailsAjax,
  freeScanDetailsPending,
  freeScanDetailsAdded,
  freeScanDetailsError
)
//End 

function accountPermissionsPending() {
  return ({
    type: types.ACCOUNT_PERMISSIONS_PENDING,
  });
}

function accountPermissionsLoaded(permissions) {
  return ({
    type: types.ACCOUNT_PERMISSIONS_LOADED,
    permissions,
  });
}

function accountPermissionsError(error) {
  return ({
    type: types.ACCOUNT_PERMISSIONS_ERROR,
    error,
  });
}

function permissionsListPending() {
  return ({
    type: types.PERMISSIONS_LIST_PENDING,
  });
}

function permissionsListLoaded(permissions) {
  return ({
    type: types.PERMISSIONS_LIST_LOADED,
    permissions,
  });
}

function permissionsListError(error) {
  return ({
    type: types.PERMISSIONS_LIST_ERROR,
    error,
  });
}

function createAccountPending() {
  return ({
    type: types.CREATE_ACCOUNT_PENDING,
  });
}

function accountCreated(createdUserCreds) {
  return ({
    type: types.ACCOUNT_CREATED,
    createdUserCreds,
  });
}

function createAccountError(errors) {
  return ({
    type: types.CREATE_ACCOUNT_ERROR,
    errorMessage: errors.first().toString(),
  });
}

function userDetailsPending() {
  return ({
    type: types.USER_DETAILS_PENDING,
  });
}

function updateUserPending() {
  return ({
    type: types.UPDATE_USER_PENDING,
  });
}

function userDetailsLoaded(userDetails) {
  return ({
    type: types.USER_DETAILS_LOADED,
    userDetails,
  });
}

function userDetailsCached() {
    return {
      type: types.USER_DETAILS_CACHED,
    }
  }

function userDetailsLoadError(errors) {
  return ({
    type: types.USER_DETAILS_ERROR,
    errors,
  });
}

export function clearCurrentUser() {
  return ({
    type: types.CLEAR_CURRENT_USER,
  });
}

export function clearUserDetailsError() {
  return {
    type: types.CLEAR_USER_DETAILS_ERROR,
  };
}

const emailReportPending = () => ({
    type: types.EMAIL_REPORT_PENDING
})

const emailReportSuccess = status => ({
    type: types.EMAIL_REPORT_SUCCESS,
    status
})

const emailReportError = errorMessage => ({
    type: types.EMAIL_REPORT_ERROR,
    errorMessage
})

export function addToFetchedStore(url) {
    return {
        type: types.ADD_TO_FETCHED,
        url,
    }
}

export function usersPending() {
    return {
        type: types.USERS_PENDING,
    }
}

export function usersError(errorMessage) {
    return {
        type: types.USERS_ERROR,
        errorMessage,
    }
}

export function userAccessChanged(user) {
    return {
        type: types.USER_ACCESS_CHANGED,
        user,
    }
}

export function invitedUserPending() {
    return {
        type: types.INVITED_USER_PENDING,
    }
}

export function invitedUserError(errorMessage) {
    return {
        type: types.INVITED_USER_ERROR,
        errorMessage,
    }
}

export function userInvited(user) {
    return {
      type: types.USER_INVITED,
      user,
    }
}

export function userDeleted(id) {
    return {
      type: types.USER_DELETED,
      id,
    }
}

export function domainsPending() {
    return {
        type: types.DOMAINS_PENDING,
    }
}

export function domainsError(errorMessage) {
    return {
        type: types.DOMAINS_ERROR,
        errorMessage,
    }
}

export function domainsLoaded(domains) {
    return {
        type: types.DOMAINS_LOADED,
        domains,
    }
}

export function domainAdded(domain) {
    return {
        type: types.DOMAIN_ADDED,
        domain,
    }
}

export function domainDeleted(id) {
    return {
        type: types.DOMAIN_DELETED,
        id,
    }
}

export function setMasqUserName(name) {
  return store.dispatch({ type: types.SET_MASQ_USER_NAME, name });
}

export function updateAccountFeatures(updatedAccount){
  return store.dispatch({type: types.ACCOUNT_FEATURES_UPDATED, updatedAccount}) 
}

function  accountOwnersPending() {
    return {
        type: types.ACCOUNT_OWNERS_PENDING,
    }
}

function accountOwnersError(errorMessage) {
    return {
        type: types.ACCOUNT_OWNERS_ERROR,
        errorMessage,
    }
}

function accountOwnersLoaded(users) {
    return {
        type: types.ACCOUNT_OWNERS_LOADED,
        users,
    }
}

function  accountsPending() {
    return {
        type: types.ACCOUNTS_PENDING,
    }
}

function accountsError(errorMessage) {
    return {
        type: types.ACCOUNTS_ERROR,
        errorMessage,
    }
}

function accountsLoaded(users) {
    return {
        type: types.ACCOUNTS_LOADED,
        users,
    }
}

//for Google Ad Manager Free Site Scan
function freeScanDetailsPending() {
  return {
    type: types.FREE_SCAN_DETAILS_PENDING
  }
}
function freeScanDetailsAdded(scanDetails){
  return {
    type: types.FREE_SCAN_DETAILS_ADDED,
    scanDetails
  }
}
function freeScanDetailsError(errorMessage){
  return {
    type: types.FREE_SCAN_DETAILS_ERROR,
    errorMessage
  }
}

function diagnosePropertiesPending() {
  return ({
    type: types.DIAGNOSE_LIST_PENDING,
  });
}

function diagnosePropertiesLoaded(diagnoseProperties) {
  return ({
    type: types.DIAGNOSE_LIST_LOADED,
    diagnoseProperties,
  });
}

function diagnosePropertiesError(error) {
  return ({
    type: types.DIAGNOSE_LIST_ERROR,
    error,
  });
}

function  getAllSDKKeyPending() {
  return {
      type: types.GET_ALL_SDK_PENDING,
  }
}

function getAllSDKKeyError(errorMessage) {
  return {
      type: types.GET_ALL_SDK_ERROR,
      errorMessage,
  }
}

function getAllSDKKeyLoaded(keys) {
  return {
      type: types.GET_ALL_SDK__LOADED,
      keys,
  }
}


function generateSDKKeyError(errorMessage) {
  return {
      type: types.GENERATE_SDK_KEY_ERROR,
      errorMessage,
  }
}

function generateSDKKeyLoaded(data) {
  return {
      type: types.GENERATE_SDK_KEY_LOADED,
      data,
  }
}
//End
