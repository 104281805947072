import React, { useState } from "react";
import {RenderLimitedChips, SelectDropdown } from "../index.js";
import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined, EditFilled } from "@ant-design/icons";

import { wrapInInfotip } from "../../components/consent/us_privacy_regulation/helper.js";
import classNames from "classnames";
import { Button, Input, Space, Tag, Typography } from "antd";
import { ConfirmationWrapper } from "../../components/consent/helper.jsx";

const AddEditDelete = (props) => {
  const [editMode, setEditMode] = useState(props.openInEditMode);
  const [value, setValue] = useState(props.value ?? "");
  const [error, setError] = useState(null);

  const handleCancel = () => {
    setValue(props.value);
    setEditMode(false);
    if(props.handleCancelEdit) {
      props.handleCancelEdit();
    }
  };

  const handleDelete = () => {
    props.handleSave(null);
    setValue(null);
    setEditMode(false);
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  const hanldeAdd = () => {
    setEditMode(true);
  };

  const handleSave = () => {
    const error = props.validate(value);
    if (!Boolean(error)) {
      props.handleSave(value);
      setError(null);
      setEditMode(false);
    } else {
      setError(error);
    }
  };

  let btnGroupClasses = {
    editMode: false,
    savedValueMode: false,
    addValueMode: false
  }
  if (editMode) {
    btnGroupClasses.editMode = true;
  } else if (value) {
    btnGroupClasses.savedValueMode = true;
  } else {
    btnGroupClasses.addValueMode = true;
  }

  const buttonGroup = (
    <>
      <div className={classNames("btn-group", btnGroupClasses.editMode ? null : "hide")}>
        <Button icon={<CheckOutlined/>} type="link" onClick={handleSave}/>
        <Button icon={<CloseOutlined/>} type="link" onClick={handleCancel} />
      </div>
      <div className={classNames("btn-group", btnGroupClasses.savedValueMode ? null : "hide")}>
        {wrapInInfotip(<Button type="link" icon={<EditFilled/>} onClick={handleEdit}/>, "Edit")}
        <ConfirmationWrapper
          actionDescription={`delete ${props.deleteEntityName ?? props.entityName}`}
          deleteHandler={handleDelete}
          deleteBtn={<Button icon={<DeleteOutlined/>} type="link"/>}
        />
      </div>
      <div className={classNames("btn-group", btnGroupClasses.addValueMode ? null : "hide")}>
        <Button type="primary" onClick={hanldeAdd}>
          Add +
        </Button>
      </div>
    </>
  );

  const suffixNode = !(editMode || value) ? props.suffixNode : null;

  const inputControl = props.dropdownInput ? (
    editMode ?
    <SelectDropdown 
      // className={"opt-in-select"}
      // dropdownClassName={"opt-in-dropdown"}
      value={value}
      disabled={!editMode}
      options={props.dropdownOptions}
      onChange={(value) => setValue(value)}
      selectAll
      multiple
      entityName={props.entityName}
      error={error}
      directUpdate
    />
    : <RenderLimitedChips chips={value?.map(v => <Tag title={props.dropdownOptions.find(option => option.value == v)?.label} style={{maxWidth: 200,  overflow: 'clip', textOverflow: "ellipsis" }}>{props.dropdownOptions.find(option => option.value == v)?.label}</Tag>)} cutOff={2}/> 
  ) : (
    !editMode && props.plainText ? <div className="saved-value-div">{value}</div> :
    <Space direction="vertical" size={4} style={{width: "70%"}}>
      <Input
        status={error ? "error" : null}
        value={value}
        disabled={!editMode}
        onChange={({ target: { value } }) => setValue(value)}
      />
      {error ?  <Typography.Text type="danger">{error}</Typography.Text> : null}
    </Space>
  );

  return (
    <>
      <div className={classNames("add-edit-delete-component", props.spaceBetween ? 'space-between' : null )}>
        {editMode || value ? inputControl : null}
        {buttonGroup}
      </div>
      {suffixNode}
    </>
  );
};

export default AddEditDelete;
