import { Record, List, fromJS } from 'immutable';
import { PurposeV2, Translations, Action, VendorCookie } from "./vendor_list_records";

export class SystemPurpose extends Record({
  id: null,
  systemId: null, // iabId: null,
  systemStackRef: null,// iabStackRef: null,
  purposeRef: null, // iabPurposeRef: null,
  name: '',
  negativeName: '',
  description: '',
  negativeDescription: '',
  descriptionLegal: '',
  //vendorIds: OrderedSet([]),
  consentActions: List([]),
  rejectActions: List([]),
  consentActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  rejectActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  systemPurposes: List([]), //iabPurposeRefs: List([]),
  // customVendorsRelyingOnLegInt: OrderedSet([]),
  vendorCategorization: {},
  configByGeo: {},
  type: 'SYSTEM_PURPOSE',
  categories: List([]),
  defaultLegalBasis: null,
  defaultCustomLegalBasis: null,
  disclosureOnly: false,
  translations: null,
  negativeTranslations: null,
  requireConsent: null,
  respectGPC: null,//valid value: array
  isNegative: false,
  privacyChoice: null, //label 
  privacyPolicySectionText: null,
  identificationList: null,
  privacyPolicySectionLink: null
}) {
  constructor(purpose) {
    const updatedPurpose = Object.assign({}, purpose);
    if (purpose._id) updatedPurpose.id = purpose._id;
    // if (
    //   purpose &&
    //   Object.keys(purpose).length &&
    //   purpose.vendors &&
    //   purpose.vendors.length
    // ) {
    //   updatedPurpose.vendorIds = OrderedSet(
    //     purpose.vendors.map(v => (typeof v === 'string' ? v : v._id))
    //   );
    // }
    // if (purpose.vendorIds && !purpose.vendorIds.toJS) {
    //   updatedPurpose.vendorIds = OrderedSet(purpose.vendorIds);
    // }
    
    if(purpose.purposeRef && purpose.purposeRef._id) {
      updatedPurpose.purposeRef.id = purpose.purposeRef._id;
      updatedPurpose.privacyChoice = purpose.purposeRef.privacyChoice ?? purpose?.purposeRef?.name;
    }
    if(purpose.systemStackRef && purpose.systemStackRef._id) {
      updatedPurpose.systemStackRef.id = purpose.systemStackRef._id
      updatedPurpose.privacyChoice = purpose.systemStackRef.privacyChoice ?? purpose?.purposeRef?.name;
    }
    if (!Boolean(updatedPurpose.privacyChoice)){
      updatedPurpose.privacyChoice = purpose.privacyChoice || purpose.name;
    }
    if (purpose.consentActions) {
      updatedPurpose.consentActions = List(
        purpose.consentActions.map(ca => new Action(ca))
      );
    }
    if (purpose.rejectActions) {
      updatedPurpose.rejectActions = List(
        purpose.rejectActions.map(ca => new Action(ca))
      );
    }
    if (purpose.categories) {
      let categories = purpose.categories;
      if (categories.toJS) {
        categories = categories.toJS();
      }
      updatedPurpose.categories = List(categories.map(ca => new SystemPurpose(ca)));
    }
    if (purpose.vendorCategorization) {
      if(Array.isArray(purpose.vendorCategorization) || List.isList(purpose.vendorCategorization)){
        let vendorCategorization = {};
        purpose.vendorCategorization.forEach(vCat => {
          if(vCat.vendorId) vendorCategorization[vCat.vendorId] = vCat;
        })
        updatedPurpose.vendorCategorization = vendorCategorization;
      } else if(typeof purpose.vendorCategorization === 'object'){
        updatedPurpose.vendorCategorization = purpose.vendorCategorization;
      }
    }
    if (purpose.translations) {
      updatedPurpose.translations = new Translations(purpose.translations);
    }
    if (purpose.negativeTranslations) {
      updatedPurpose.negativeTranslations = new Translations(purpose.negativeTranslations);
    }

    if (purpose.privacyPolicySectionLink) {
      updatedPurpose.privacyPolicySectionLink = purpose.privacyPolicySectionLink;
    }
    
    //respestGPC sanitization because the backend used to return Boolean value or an array of boolean for existing regulations
    if(_.isEqual(purpose.respectGPC, ['true']) || purpose.respectGPC === true) {
      updatedPurpose.respectGPC = []
    } else if(_.isEqual(purpose.respectGPC, ['false']) || purpose.respectGPC === false) {
      updatedPurpose.respectGPC = null;
    } else {
      updatedPurpose.respectGPC = purpose.respectGPC;
    }

    // XXX put back when not mocked
    // if (purpose.categories) {
    //   updatedPurpose.categories = List(purpose.categories.map(c => new Purpose(c)));
    // }

    //remove from v2
    if (purpose.systemPurposes) {
      updatedPurpose.systemPurposes = List(purpose.systemPurposes);
    }

    updatedPurpose.rejectActionExtension = new ActionExtension(
      purpose.rejectActionExtension
    ).toJS();
    updatedPurpose.consentActionExtension = new ActionExtension(
      purpose.consentActionExtension
    ).toJS();

    if(purpose.configByGeo) {
      updatedPurpose.configByGeo = purpose.configByGeo
    } else {
      // I donot understand why this required here but it gets buggy without it
      updatedPurpose.configByGeo = {}
    }
    // updatedPurpose.customVendorsRelyingOnLegInt = OrderedSet(
    //   purpose.customVendorsRelyingOnLegInt
    // );

    super(updatedPurpose);
  }

  equals(purposeRecord) {
    return this.id === purposeRecord.id;
  }

  googleConsentModeCategory() {
    let gcmConsentAction = this.consentActions.find((a) => ( a.type === GCM_ACTION_TYPE ))

    return gcmConsentAction ? gcmConsentAction.getIn(['tagManager', 'key']) : null;
  }

  hashCode() {
    return this.id;
  }
}

export class SystemVendor extends Record({
  id: null,
  name: '',
  description: '',
  vendorType: '',
  redbudId: null,
  purposes: List([]),
  siteUrl: '',
  policyUrl: '',
  googleId: null,
  isGoogleAtp: false,
  dateCreated: null,
  dateUpdated: null,
  ids: {}
}) {
  constructor(vendor) {
    const updatedVendor = Object.assign({}, vendor);
    // if(vendor.purposes) {
    //   updatedVendor.purposes = List(
    //     vendor.purposes.map(p => 
    //       typeof p === 'string' ? p : new SystemPurpose(p))
    //   )
    // }
    if (vendor.purposes) {
      updatedVendor.purposes = List(vendor.purposes);
    }
    if(vendor.ids) {
      updatedVendor.ids = {...vendor.ids}
    }
    updatedVendor.id = vendor._id;

    super(updatedVendor);
  }
  equals(vendor) {
    return this.id === vendor.id;
  }

  hashCode() {
    // Convert id to 32bit integer to avoid "maximum call stack size exceeded"
    // error caused by OrderedSet comparison
    return convertToHashCode(this.id);
  }
}

export class VendorWrapper extends Record({
  id: null,
  vendorId: null, 
  name: null,
  consentActions: List([]),
  rejectActions: List([]),
  consentActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  rejectActionExtension: {
    geolocation: { ruleBoolean: true, geoList: [] },
    onStatusChangeOnly: false,
    cookies: []
  },
  cookies: List([]),
  description: null,
  urlMappingRules: [],
  policyUrl: '',
  siteUrl: '',
  isApple: false,
  isCustomVendorLabel1: false,
  isCustomVendorLabel2: false,
  isCustomVendorLabel3: false,
  excludeFromCount: false,
}) {
  constructor(vendorWrapper) {
    const updatedVendorWrapper = Object.assign({}, vendorWrapper);

    if (vendorWrapper._id) updatedVendorWrapper.id = vendorWrapper._id;

    // vendor: Types.ObjectId | IVendorSchema; (backend)
    if (vendorWrapper.vendor && typeof vendorWrapper.vendor === 'object'){ 
      updatedVendorWrapper.vendorId = vendorWrapper.vendor._id;
      updatedVendorWrapper.name = vendorWrapper.vendor.name;
    };

    if (vendorWrapper.vendorId && typeof vendorWrapper.vendorId === 'string') updatedVendorWrapper.vendorId = vendorWrapper.vendorId; //just in case 
    if (vendorWrapper.name) updatedVendorWrapper.name = vendorWrapper.name;
  
    if (vendorWrapper.consentActions) {
      updatedVendorWrapper.consentActions = List(
        vendorWrapper.consentActions.map(ca => new Action(ca))
      );
    }
    if (vendorWrapper.rejectActions) {
      updatedVendorWrapper.rejectActions = List(
        vendorWrapper.rejectActions.map(ca => new Action(ca))
      );
    }
    if (vendorWrapper.cookies) {
      updatedVendorWrapper.cookies = List(
        vendorWrapper.cookies.map(cookie => new VendorCookie(cookie))
      );
    }
    if (vendorWrapper.urlMappingRules) {
      updatedVendorWrapper.urlMappingRules = vendorWrapper.urlMappingRules.map(
        ({ patterns }) => patterns
      );
    }

    updatedVendorWrapper.rejectActionExtension = new ActionExtension(vendorWrapper.rejectActionExtension).toJS();
    updatedVendorWrapper.consentActionExtension = new ActionExtension(vendorWrapper.consentActionExtension).toJS();

    super(updatedVendorWrapper);
  }
}

export class USPrivacyRegulation extends Record({
  id: null,
  name: '',
  categories: List([]),
  siteIds: List([]),
  consentScope: 'SINGLE',
  isActive: false,
  vendorsWrappers: List([]),
  deletedVendors: List([]),
  cookieMaxAge: 36135,
  appliesGeos: [],
  exclusionGeos: [],
  respectGPC: null,
  showIdentificationList: false,
  dateUpdated: '',
  dateCreated: '',
  accountId:null,
  intersection: List([]),
  shareRootDomain: true,
  writeFirstPartyCookiesFromServer: false,
  legislation: null,
  nonIab: false,
  signatoryId: null,
  signatoryName: null,
  metaData: {
    MspaCoveredTransaction: false,
    MspaOptOutOptionMode: false,
    MspaServiceProviderMode: false,
  }
}) {
  constructor(regulation) {
    const regulationObj = Object.assign({}, regulation);
    if (regulation._id) regulationObj.id = regulation._id;

    if (regulation.name) regulationObj.name = regulation.name;

    if (regulation.siteIds) {
      regulationObj.siteIds = List(regulation.siteIds);
    }
    if (regulation.isActive) {
      regulationObj.isActive = regulation.isActive;
    }
    if (regulation.categories) {
      regulationObj.categories = List(regulation.categories).map(p => {
        const systemPurpose = new SystemPurpose(p);
        return systemPurpose;
      });
    }

    if (regulation?.showIdentificationList === false) {
      regulationObj.showIdentificationList = false;
    } else if (regulation?.showIdentificationList === true) {
      regulationObj.showIdentificationList = true;
    }
    // Check if regulation.vendorsWrappers is null
    if (regulation.vendorsWrappers === null) {
      regulationObj.vendorsWrappers = List([]);
    } else {
      // Filter out elements with either vendor or vendorId and create new VendorWrapper instances
      regulationObj.vendorsWrappers = List(regulation.vendorsWrappers).filter(vw => vw.vendor || vw.vendorId).map(vw => new VendorWrapper(vw));
      
      regulationObj.deletedVendors = List(regulation.vendorsWrappers).filter(vw => !vw.vendor && !vw.vendorId).map(vw => new VendorWrapper(vw));
    }

        
    if (regulation.appliesGeos && regulation.appliesGeos.length) {
      regulationObj.appliesGeos = regulation.appliesGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }
    if (regulation.exclusionGeos && regulation.exclusionGeos.length) {
      regulationObj.exclusionGeos = regulation.exclusionGeos.map(geo => geo === 'CA' ? 'CAN' : geo);
    }
    super(regulationObj);
  }
}

export class RegulationListError extends Record({
  errorType: '',
  msg: '',
  data: null
}) {
  constructor(err) {
    const updatedErr = Object.assign({}, err);

    if (err.errorType === 'SITE_VALIDATION_FAILED') {
      updatedErr.data = List(err.data).map(vl => {
        const vendorList = Object.assign({}, vl.vendorList);
        vendorList.intersection = vl.intersection;
        return new USPrivacyRegulation(vendorList);
      });
    }
    super(updatedErr);
  }
}


export class ActionExtension extends Record({
  geoTargeting: { ruleBoolean: true, geoList: [] },
  cookies: [],
  onStatusChangeOnly: false,
  onNewUser: false
}) {
  constructor(ext) {
    super(ext);
  }
}

function convertToHashCode(string) {
  // Convert string to 32bit integer
  var hash = 0, i, chr;
  if (string.length === 0) return hash;
  for (i = 0; i < string.length; i++) {
    chr   = string.charCodeAt(i);
    hash  = ((hash << 5) - hash) + chr;
    hash |= 0;
  }
  return hash;
}
