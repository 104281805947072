import { Alert, Flex, Modal, Select, Space, Table, Tag, Typography } from "antd";
import React, { useState } from "react";
import { LEGALBASIS_OPTIONS, validateAndGetStateStringIcon } from "../../../helper";

const { Text, Paragraph } = Typography;

const BulkRegionMappingModal = (props) => {
  const [categories, setCategories] = useState(props.categories);

  function handleCateogyLegalBasisChagne(categoryId, bulkLegalBasis) {
    setCategories(categories.map((cat) => {
      if(cat.id === categoryId) {
        props.selectedRegions.forEach(({region}) => {
          const disabledIabChoice = !props.isNoFrameworkFlow && cat.type === "SYSTEM_PURPOSE" && !Boolean(cat.configByGeo[region])
          const isRespectGPC = cat.respectGPC && cat.respectGPC.includes(region.replace("US_", "STATE_"));
          if(!disabledIabChoice && !isRespectGPC) {
            cat.configByGeo[region] = {defaultLegalBasis: bulkLegalBasis}
          }
        })
      }
      return cat;
    }))
  }

  function handleSaveChange() {
    props.setCategories(categories);
    props.closeModal();
  }

  let dataSource = props.selectedRegions;
  categories.forEach((cat) => {
    if(cat.configByGeo) {
      dataSource = dataSource.map((data) => {
        const defLegBasisRecord = cat.configByGeo[data.region]
        if(defLegBasisRecord) {
          data["legalBasis"][cat.id] = defLegBasisRecord.defaultLegalBasis
        }
        // else {
        //   data["legalBasis"][cat.id] = "NOT_APPLICABLE"
        // }
        return data;
      })
    } 
  })

  const columns = [
    {
      title: "Region",
      fixed: "left",
      dataIndex: "region",
      width: 200,
      render: (stateCode, record) => {
        return (
          <Flex gap={12} align="center">
            <Text>{record.title}</Text>
            {props.isNoFrameworkFlow ? null : validateAndGetStateStringIcon(stateCode)}
          </Flex>
        );
      },
    },
  ];

  categories.forEach((cat) => {
    const isIabCat = cat.type === "SYSTEM_PURPOSE"
    columns.push({
      title: ({ sortOrder, sortColumn, filters }) => {
        return (
        <Flex vertical gap={12}>
          <Space direction="vertical">
            <Paragraph style={{maxWidth: 150}} ellipsis={{tooltip:{title: cat.privacyChoice ?? cat.name}, rows: 2}}>{cat.privacyChoice ?? cat.name}</Paragraph>
            <Tag color={isIabCat ? "orange" : "magenta"}> {isIabCat ? props.isNoFrameworkFlow ? "Standard" : "IAB" : "Custom"}</Tag>
          </Space>
          <Select
            onChange={(value) => handleCateogyLegalBasisChagne(cat.id, value)}
            options={LEGALBASIS_OPTIONS}
            style={{minWidth: 150}}
          />
        </Flex>
      )},
      width: 200,
      dataIndex: ["legalBasis", cat.id],
      render: (value, record) => {
        return (
          <Select
            value={value}  
            defaultValue={!Boolean(value) ? "NOT_APPLICABLE" : null}
            options={LEGALBASIS_OPTIONS}
            placeholder="Select"
            disabled={true}
            style={{minWidth: 150}}
          />
        )
      }
    })
  })

  return (
    <Modal
      open={props.openModal}
      title="Bulk Edit"
      okText="Save"
      onOk={handleSaveChange}
      cancelButtonProps={{
        type: "link"
      }}
      onCancel={props.closeModal}
      width={"80vw"}
    >
      <Flex vertical gap={16}>
        <Alert type="info" showIcon closable message="Note: Bulk operations will not affect the states where the privacy choice configuration is not editable."/>
        <Table
          columns={columns}
          dataSource={dataSource}
          scroll={{ x: 1000}}
          pagination={{
            position: ['bottomCenter'],
            showTotal: (total) => <div>Total Regions :{' '}{total}</div>,
            size: 'small',
            defaultPageSize: 7,
            showSizeChanger: true,
            pageSizeOptions: ['7', '10', '15', '20'],
          }}
        />
      </Flex>
    </Modal>
  )
}

export default BulkRegionMappingModal;