import React, { useState, useEffect } from "react";
import { Flex, Select, Space, Tag, Tooltip, Typography } from "antd";
import { CheckOutlined } from "@ant-design/icons";

const { Option } = Select;

const SelectWithSelectAll = ({ options, value, onChange, width, ...props }) => {
  const [internalSelectedValues, setInternalSelectedValues] = useState(
    value || []
  );

  useEffect(() => {
    setInternalSelectedValues(value);
  }, [value]);

  const handleChange = (value) => {
    if (value.includes("selectAll")) {
      const disabledOption = options.filter(opt => opt.disabled).map(opt => opt.value);
      const selectedDisabledOption = value.filter(opt => disabledOption.includes(opt))
      if (internalSelectedValues.length === options.length) {
        // Deselect all
        setInternalSelectedValues(selectedDisabledOption);
        onChange(selectedDisabledOption);
      } else {
        // Select all options
        const allSelectableValues = options.filter(opt => !opt.disabled).map((opt) => opt.value);
        setInternalSelectedValues(allSelectableValues.concat(selectedDisabledOption));
        onChange(allSelectableValues.concat(selectedDisabledOption));
      }
    } else {
      setInternalSelectedValues(value);
      onChange(value);
    }
  };

  // Determine if all options are selected
  const isAllSelected = internalSelectedValues.length === options.length;

  useEffect(() => {}, [isAllSelected]);
  return (
    <Select
      mode="multiple"
      value={internalSelectedValues}
      onChange={handleChange}
      placeholder="Select options"
      style={{ width: width ?? "100%" }}
      maxTagCount={"responsive"}
      maxTagPlaceholder={(ommitedValues) => ommitedValues.length === options.length ? "All" : (
        <Tooltip
          title={
            ommitedValues.length > 10
              ? ommitedValues.map(({ title }) => title).join(", ")
              : ommitedValues.map(({ title }) => (
                  <>
                    <span>{title}</span>
                    <br />
                  </>
                ))
          }
        >
          + {ommitedValues.length}
        </Tooltip>
      )}
      {...props}
    >
      <Option
        key="selectAll"
        value="selectAll"
        style={{
          backgroundColor: isAllSelected
            ? `var(--ant-select-option-selected-bg)`
            : undefined,
        }}
      >
        <Flex align="center" justify="space-between">
          <Typography.Text strong={isAllSelected}>Select All</Typography.Text>
          {isAllSelected ? (
            <CheckOutlined style={{ color: `var(--ant-color-primary)` }} />
          ) : null}
        </Flex>
      </Option>
      {options.map((option) => (
        <Option
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          title={option.label}
        >
          {option.label}{" "}{option.icon}
        </Option>
      ))}
    </Select>
  );
};

export default SelectWithSelectAll;
