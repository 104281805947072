import React, { useEffect, useState, useRef } from "react";
import moment from 'moment';
import { connect, useSelector } from 'react-redux';
import { getSiteGroups, getAllSites } from "../../../../actions/site_actions";
import { Map, List, OrderedSet, fromJS} from "immutable";
import { message } from "../../../../styleguide";
import { stepsData as sd, evaluateRequriedFieldsFromStep, updateCategoryCache, navigationValidationMethod, validateTranslation, scrollToTop, valideAllStepsBeforeSave, steps_data_state_specific } from "../helper";
import SequentialLeftNav, { getOrderedStepsKeys, updateStepsDataFunction, getStepsDataFieldMap } from "../../../common/SequentialNavigationWizard/SequentialLeftNav.jsx";
import {
  AdvancedSettings,
  OptInAndOptOut,
  IndustryFramework,
  LanguageTranslations,
  NameAndProperty,
  SelectPurposes,
  Summary,
  VendorManagement,
  ChoiceSelection,
  RegionMapping
} from "./Steps";
import { CloseOutlined, InfoCircleFilled, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { USPrivacyRegulation } from "../../../../records/us_privacy_regulation_records";
import {
  getSystemPurposes,
  getSystemStacks,
  createRegulation,
  getAccountList,
  getGlobalVendors,
} from "../../../../actions/us_privacy_regulation_actions";
import { useActions, usePrevious } from "../../../../hooks";
import { browserHistory } from "react-router";
import DismissErrorModal from "../../../common/DismissErrorModal";
import { renderTranslationWarningError } from "../helper";
import { usPrivacyRegulationContext } from "../contexts";
import Loading from "../../../common/Loading.js";
import { Button, Breadcrumb, Typography, Alert } from "antd";
import { capitalizeFirstLetter, getParameterByName } from "../../../utils.js";


const NewPrivacyRegulation = (props) => {
  const REGULATION_FRAMEWORK = getParameterByName('framework', window.location);
  const STEPS_FOR_FRAMEKWORK = REGULATION_FRAMEWORK === "state" || REGULATION_FRAMEWORK === "standard"
    ? steps_data_state_specific
    : sd;
  let initVlObj = {
    legislation: REGULATION_FRAMEWORK === "standard" || REGULATION_FRAMEWORK === "state" ? "state" : "usnat"
  };
  
  if (REGULATION_FRAMEWORK === "standard") {
    initVlObj.nonIab = true;
  }

  const actualComponents = getOrderedStepsKeys(fromJS(STEPS_FOR_FRAMEKWORK));
  const [intermediateUSPRegulation, setIntermediateUSPRegulation] = useState(
    new USPrivacyRegulation(initVlObj).toJS()
  );
  const [current, setCurrent] = useState(actualComponents[0]);
  const [currentRelay, setCurrentRelay] = useState(null);
  const [dontFinishCurrentYet, setDontFinishCurrentYet] = useState(false);
  const [stepsData, setStepsData] = useState(fromJS(STEPS_FOR_FRAMEKWORK));
  const [openExitWizardModal, setOpenExitWizardModal] = useState(false);
  const [showTranslationModal, setShowTranslationModal] = useState(false)
  const [translationErrorContent, setTranslationErrorContent] = useState(null)

  const stepsNamesMap = getStepsDataFieldMap(stepsData, 'title');
  const stepsErrorMap = getStepsDataFieldMap(stepsData, 'error')
  const stepsWarningMap = getStepsDataFieldMap(stepsData,'warning');
  //for vendors context
  const [vendorsIdsMap, setVendorIdsMap] = useState(props.globalVendors?.reduce((map, item) => {
    map[item.id] = item.ids;
    return map;
  }) ?? {});
  useEffect(()=>{
    if(props.globalVendors.size){
      setVendorIdsMap(props.globalVendors.reduce((map, item) => {
        map[item.id] = item.ids;
        return map;
      }, {}) ?? {})
    }
  },[props.globalVendors])
  
  //global categories cache
  const [regulationCategoryOptionsCache, setRegulationCategoryOptionsCache] = useState(null)

  //data for summary
  const [summaryPageData, setSummaryData] = useState({
    geoTerritories: []
  })

  //actions and state
  const getPurposes = useActions(getSystemPurposes);
  const getStacks = useActions(getSystemStacks);
  const createRegulationAction = useActions(createRegulation);
  const getUSPrivacyAccountsList = useActions(getAccountList);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']))?.map((cat) => {
    let updatedCat = cat;
    if(initVlObj.legislation === "state") {
      updatedCat = cat.delete("defaultLegalBasis");
      if(cat.systemId === 3) {
        updatedCat = cat.set("isNegative", true);
      }
    }
    return updatedCat;
  });
  const systemStacks = useSelector(state => state.usPrivacyReducerState.getIn(['stacks', 'value']));
  const regulationsList = useSelector(state => state.usPrivacyReducerState.getIn(['vendorList', 'value']));
  const regulationSavePending = useSelector(state => state.usPrivacyReducerState.get('savePending'));

  const errorContentRegulation = renderTranslationWarningError(translationErrorContent);
  //consts for every rerender
  const isLastStep = actualComponents.indexOf(current) === actualComponents.length - 1;

  const prevCurrent = usePrevious(current);
  useEffect(() => {
    let updatedStepsData = updateStepsDataFunction(stepsData, prevCurrent, "status", dontFinishCurrentYet ? "wait" : "finish");
    updatedStepsData = updateStepsDataFunction(updatedStepsData, current, "status", "process");
    setStepsData(updatedStepsData);
  }, [current]);

  useEffect(() => {
    (async () => {
      // filter systemIds 1 and 2 if state workflow DIA-4646
      const systemPurposes = await getPurposes(REGULATION_FRAMEWORK === "state" ? [1,2] : null);
      getStacks(systemPurposes);
    })()
    props.getSiteGroups(props.currentUser);
    props.getAllSites();
    getUSPrivacyAccountsList();
    props.getGlobalVendors();
  }, []);

  useEffect(()=>{
    let categoriesCache = List([])
    if(intermediateUSPRegulation){
      if(REGULATION_FRAMEWORK === "national") {
        const onlyPurposesNotInStack = systemPurposes.filter( purpose => !systemStacks.some( stack => stack.categories.map( c => c.purposeRef.id).includes(purpose.purposeRef.id)))

        onlyPurposesNotInStack.forEach((purpose) => {
          categoriesCache = categoriesCache.push(purpose);
        });
        systemStacks.forEach((stack)=>{
          categoriesCache = categoriesCache.push(stack);
        })
      } else {
        categoriesCache = systemPurposes;
      }
      categoriesCache = updateCategoryCache(categoriesCache, intermediateUSPRegulation, true)
      setRegulationCategoryOptionsCache(categoriesCache)
    }
  },[systemStacks, intermediateUSPRegulation]);

  const shouldStopFlowForTranslationError = () =>{
    const translationError = validateTranslation(intermediateUSPRegulation)
    if(translationError) {
      setTranslationErrorContent(translationError)
      setShowTranslationModal(true)
      return true;
    }
    return false
  }

  const onChange = (value, skipTranslationErrorCheck = true) => {
    setDontFinishCurrentYet(false);
    const isValidNavigation = navigationValidationMethod(intermediateUSPRegulation, stepsData, current, value, regulationsList);

    if (!isValidNavigation) {
      updateShowError(current, false);
    
      if (skipTranslationErrorCheck && (current === 'language_translations' && shouldStopFlowForTranslationError())) {
        setCurrentRelay(value);
        return;
      }
    
      if (current === 'language_translations' || current === 'opt_in_opt_out' || current === 'vendor_management' || current === "region_mapping") {
        setRegulationCategoryOptionsCache(updateCategoryCache(regulationCategoryOptionsCache, intermediateUSPRegulation, false));
        setStepsData(updateStepsDataFunction(stepsData, current, 'warning', false));
      }
    
      if (!evaluateRequriedFieldsFromStep(current, intermediateUSPRegulation, regulationsList)) {
        setDontFinishCurrentYet(true);
      }
    
      setCurrent(value);
      scrollToTop();
    } else {
      if(actualComponents.includes(isValidNavigation)){
        setCurrent(isValidNavigation);
      } else {
        updateShowError(current, true);
      }
    }
  };

  const onNext = async () => {
    setDontFinishCurrentYet(false);
    const nextStepIndex = actualComponents.findIndex((a) => a === current) + 1;
  
    if (isLastStep) {
    const stepsWithError = valideAllStepsBeforeSave(actualComponents, intermediateUSPRegulation, regulationsList, REGULATION_FRAMEWORK === "standard" || REGULATION_FRAMEWORK === "state")
      if(stepsWithError.length == 0){
        try {
          const resp = await createRegulationAction(props.currentUser.accountId, new USPrivacyRegulation(intermediateUSPRegulation));
    
          if (resp?.name) {
            message.success(
              <>
                Regulation <b>{resp.name}</b> has been successfully created
              </>
            );
            browserHistory.push("/us_privacy/regulations");
          }
        } catch (error) {
          // Handle error if needed
        }
      }else {
        setCurrent(stepsWithError[0]);
        stepsWithError.forEach(step => updateShowError(step, true))
      }

      return null;
    }
  
    if (current === 'language_translations' && shouldStopFlowForTranslationError()) {
      setCurrentRelay(actualComponents[nextStepIndex]);
      return;
    }
  
    const nextComponent = actualComponents[nextStepIndex];

    if (!navigationValidationMethod(intermediateUSPRegulation, stepsData, current, nextComponent, regulationsList)) {
      updateShowError(current, false);
  
      if (current === 'language_translations' || current === 'opt_in_opt_out'  || current === 'vendor_management' || current === "region_mapping") {
        setRegulationCategoryOptionsCache(updateCategoryCache(regulationCategoryOptionsCache, intermediateUSPRegulation, false));
        setStepsData(updateStepsDataFunction(stepsData, current, 'warning', false));
      }
  
      setCurrent(nextComponent);
      scrollToTop();
    } else {
      updateShowError(current, true);
    }
  };

  const onPrevious = () => {
    setDontFinishCurrentYet(false);
    const nextStepIndex = actualComponents.findIndex((a) => a == current) - 1;
    // if (current === 'language_translations' && shouldStopFlowForTranslationError()) {
    //   setCurrentRelay(actualComponents[nextStepIndex]);
    //   return;
    // }
    if(current == 'language_translations' || current == 'opt_in_opt_out' || current === 'vendor_management') {
      setRegulationCategoryOptionsCache(updateCategoryCache(regulationCategoryOptionsCache,intermediateUSPRegulation, false));
      setStepsData( updateStepsDataFunction(stepsData, current, 'warning', false));
    }
    if(!evaluateRequriedFieldsFromStep(current,intermediateUSPRegulation)){
      setDontFinishCurrentYet(true)
    }
    setCurrent(actualComponents[nextStepIndex]);
    scrollToTop();
  };

  const updateShowError = (step, isIncomplete) => {
    setStepsData(updateStepsDataFunction(stepsData, step, 'error', isIncomplete));
  };

  const resetValidationError = (step) => {
    if(evaluateRequriedFieldsFromStep(step, intermediateUSPRegulation, regulationsList)){
      updateShowError(step, false)
    }
  }

  const changesDetectedAlert = (
    <Alert showIcon icon={<InfoCircleFilled/>} style={{marginBottom: 16}} message={<div>Updates made in the <b>Privacy Choice Selection</b> step have resulted in new modifications in the current step. <b>Please review the recent updates before proceeding.</b></div>} type="warning"/>
  )

  let readOnly = false;
  if (props.currentUser.featureAccess &&
    !props.currentUser.featureAccess.includes('Vendor List - U.S. Multi-State')) {
    readOnly = true;
  }

  const renderWizardStepComponent = () => {
    let component;
    switch (current) {
      case "name_property_selection":
        component = (
          <NameAndProperty
            usPrivacyRegulation={intermediateUSPRegulation}
            setUSPrivacyRegulation={setIntermediateUSPRegulation}
            showError={stepsErrorMap.get("name_property_selection")}
            resetValidationError={() => resetValidationError("name_property_selection")}
            sites={props.sites}
            siteGroups={props.siteGroups}
            currentUser={props.currentUser}
            regulationsList={regulationsList}
            readOnly={readOnly}
          />
        );
        break;
      case "industry_framework":
        component = (
          <IndustryFramework
            usPrivacyRegulation={intermediateUSPRegulation}
            setUSPrivacyRegulation={setIntermediateUSPRegulation}
            showError={stepsErrorMap.get("industry_framework")}
            resetValidationError={() => resetValidationError("industry_framework")}
            summaryPageData={summaryPageData}
            readOnly={readOnly}
            onlyUsStates={REGULATION_FRAMEWORK === "state" || REGULATION_FRAMEWORK === "standard"}
            isNoFrameworkFlow={REGULATION_FRAMEWORK === "standard"}
            isNational={REGULATION_FRAMEWORK == "national"}
          />
        );
        break;
      case "select_puropses":
        component = (
          <SelectPurposes
            usPrivacyRegulation={intermediateUSPRegulation}
            showError={stepsErrorMap.get("select_puropses")}
            resetValidationError={() => resetValidationError("select_puropses")}
            goToSignatoryIdStep={()=>onChange('industry_framework')}
            regulationCategoryOptionsCache={regulationCategoryOptionsCache}
            stepsData={stepsData}
            setStepsData={setStepsData}
            current={current}
            readOnly={readOnly}
            isNational={REGULATION_FRAMEWORK == "national"}
          />
        );
        break;
      case "choice_selection":
        component = (
          <ChoiceSelection
            usPrivacyRegulation={intermediateUSPRegulation}
            showError={stepsErrorMap.get("choice_selection")}
            resetValidationError={() => resetValidationError("choice_selection")}
            regulationCategoryOptionsCache={regulationCategoryOptionsCache}
            stepsData={stepsData}
            setStepsData={setStepsData}
            current={current}
            readOnly={readOnly}
            isNoFrameworkFlow={REGULATION_FRAMEWORK === "standard"}
          />
        )
        break;
      case "region_mapping":
        component = (
          <RegionMapping
            usPrivacyRegulation={intermediateUSPRegulation}
            isNoFrameworkFlow={REGULATION_FRAMEWORK === "standard"}
            changesDetectedWarning={stepsWarningMap.get("region_mapping") ? changesDetectedAlert : null}
          />
        )
        break;
      case "language_translations":
        component = (
          <LanguageTranslations
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("language_translations") ? changesDetectedAlert : null}
            showError={stepsErrorMap.get("language_translations")}
            resetValidationError={() => resetValidationError("language_translations")}
            readOnly={readOnly}
            isStateFlow={REGULATION_FRAMEWORK === "standard" || REGULATION_FRAMEWORK === "state"}
          />
        );
        break;
      case "vendor_management":
        component = (
          <VendorManagement
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("vendor_management") ? changesDetectedAlert : null}
            readOnly={readOnly}
          />
        );
        break;
      case "opt_in_opt_out":
        component = (
          <OptInAndOptOut
            usPrivacyRegulation={intermediateUSPRegulation}
            changesDetectedWarning={stepsWarningMap.get("opt_in_opt_out") ? changesDetectedAlert : null}
            readOnly={readOnly}
          />
        );
        break;
      case "signal_settings":
        component = (
          <AdvancedSettings
            usPrivacyRegulation={intermediateUSPRegulation}
            readOnly={readOnly}
            isStateFlow={REGULATION_FRAMEWORK === "standard" || REGULATION_FRAMEWORK === "state"}
            showError={stepsErrorMap.get("signal_settings")}
            resetValidationError={() => resetValidationError("signal_settings")}
          />
        );
        break;
      case "summary":
        component = (
          <Summary
            stepsData={STEPS_FOR_FRAMEKWORK}
            stepArray={actualComponents}
            goToStep={onChange}
            usPrivacyRegulation={intermediateUSPRegulation}
            sites={props.sites}
            summaryPageData={summaryPageData}
            readOnly={readOnly}
            isStateFlow={REGULATION_FRAMEWORK === "standard" || REGULATION_FRAMEWORK === "state"}
            isNoFrameworkFlow={REGULATION_FRAMEWORK === "standard"}
          />
        );
        break;
    }
    return regulationSavePending ? <Loading/> : component;
  };

  const handleResumeWizardFlow = (e) => {
    e.preventDefault();
    setShowTranslationModal(false);
    onChange(currentRelay, false)
  }
  return (
    <>
      <div className="new-us-privacy-regulations-wizard">
        <div className="header">
          <Breadcrumb 
            items={[
              {
                title: 'US Multi-State Privacy',
              },
              {
                title: capitalizeFirstLetter(REGULATION_FRAMEWORK),
              },
              {
                title: 'New Regulation',
              }
            ]}
          />
          <Button className='close-wizard-btn' icon={<CloseOutlined/>} onClick={()=>setOpenExitWizardModal(true)}/>
        </div>
        <div className="content">
          <div className="wizard-left-nav">
            <SequentialLeftNav
              stepsData={stepsData}
              onChange={onChange}
              entityState={intermediateUSPRegulation}
            />
          </div>
          <div className="wizard-layout">
            <usPrivacyRegulationContext.Provider value={{vendorsIdsMap}}>
            <div id="wizard-step-component">
              {renderWizardStepComponent()}
            </div>
            </usPrivacyRegulationContext.Provider>

            <div className="bottom-nav">
              {actualComponents.indexOf(current) !== 0 ?  <Button
                icon={<LeftOutlined/>}
                type="default"
                onClick={() => onPrevious()}
                disabled={actualComponents.indexOf(current) === 0}
                style={{boxShadow: "none"}}
              >
                {stepsNamesMap.get(actualComponents[actualComponents.indexOf(current) - 1])}
              </Button> : null}
              <Button
                type="primary"
                icon={isLastStep ? null : <RightOutlined/>}
                iconPosition="end"
                onClick={() => onNext()}
                disabled={stepsErrorMap.get(current) || props.pendingRequestsMap.get("savePending")}
              >
                {isLastStep
                  ? "Save"
                  : `${stepsNamesMap.get(actualComponents[actualComponents.indexOf(current) + 1])}`}
              </Button>
            </div>
          </div>
        </div>
      </div>
      { openExitWizardModal ? 
      <DismissErrorModal
        modalWrapperClass="activate-confirmation"
        title={"Exit Wizard"}
        error={<><div>All the data will be lost if you exit the wizard without saving the Regulation.</div> <div>Are you sure you want to exit the wizard?</div></>}
        isModalVisible={openExitWizardModal}
        handleCancel={()=>setOpenExitWizardModal(false)}
        renderOk
        okText={"Exit"}
        cancelText={"Cancel"}
        handleOk={()=>{browserHistory.push("/us_privacy/regulations"); setOpenExitWizardModal(false)}}
        primaryAction="submit"
        /> : null}
      { showTranslationModal ?
      <DismissErrorModal
        modalWrapperClass="operation-confirmation"
        title={"Missing Translations"}
        error={renderTranslationWarningError(translationErrorContent)}
        isModalVisible={showTranslationModal}
        handleCancel={() => setShowTranslationModal(false)}
        renderOk={true}
        okText={"Continue"}
        cancelText={"Keep Editing"}
        handleOk={handleResumeWizardFlow}
        primaryAction="submit"
      /> : null}
    </>

  );
};

const mapStateToProps = function (store) {
  return {
    currentUser: store.accountState.getIn(['userDetails', 'value']) ?? {},
    sites: store.siteState.getIn(['sites', 'value'])?.filter((s) => s.type !== 'property_group' && s.domain && !s.domain.includes('-group-')),
    siteGroups: store.siteState
      .getIn(['siteGroups', 'value'])
      .sort(
        (a, b) => moment(a.createdAt).valueOf() - moment(b.createdAt).valueOf()
      ),
    globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'value']) ?? OrderedSet([]),
    pendingRequestsMap: Map({
      sites: store.siteState.getIn(['sites', 'pending']),
      siteGroups: store.siteState.getIn(['siteGroups', 'pending']),
      globalVendors: store.usPrivacyReducerState.getIn(['mspsSystemVendors', 'pending']),
    }),
  };
};

export default connect(mapStateToProps, {
  getSiteGroups,
  getAllSites,
  getGlobalVendors
})(NewPrivacyRegulation);

