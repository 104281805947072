import * as types from '../actions/action_types';

import { Map, List } from 'immutable';
import { StoreRecord } from '../records/store_records.js';
import { Account } from '../records/account_records.js';
import { setValue, setError, setPending, stopPending } from './helpers.js';

export const initialState = Map({
    accountId: new StoreRecord({ value: -1 }),
    isSPUser: false,
    userDetails: new StoreRecord(),
    masquerading: new StoreRecord(),
    fetched: new Set(),
    emailReport: new StoreRecord(),
    allUsers: new StoreRecord(),
    invitedUser: new StoreRecord(),
    domains: new StoreRecord(),
    createdUserCreds: new StoreRecord(),
    masqUserName: null,
    accountOwners: new StoreRecord({ value: new List([])}),
    allAccounts: new StoreRecord({ value: new List([])}),
    permissionsList: new StoreRecord({ value: new List([])}),
    diagnosePropertyList: new StoreRecord({ value: new List([])}),
    freeScanDetails: new StoreRecord(), //for Google Ad Manager scan form
    sdkKeyList: new StoreRecord({ value: new List([])}),
});

export default (state = initialState, action) => {

    let allUsers = state.getIn(['allUsers', 'value']);
    const domains = state.getIn(['domains', 'value']);
    let accountOwners = state.getIn(["accountOwners","value"]);
    const sdkKeyList = state.getIn(["sdkKeyList", "value"])

    switch(action.type) {

        case types.ADD_TO_FETCHED:
            return state.update('fetched', f => f.add(action.url));

        case types.USER_DETAILS_PENDING:
            return setPending(state, ['userDetails']);

        case types.USER_DETAILS_LOADED:
            return setValue(state, ['userDetails'], action.userDetails).set('updateUserPending', false);

        case types.USER_DETAILS_CACHED:
            return stopPending(state, ['userDetails']);

        case types.USER_DETAILS_ERROR:
            return setError(state, ['userDetails'], action.errors);

        case types.CLEAR_CURRENT_USER:
            return setValue(state, ['userDetails'], null);
            
        case types.UPDATE_USER_PENDING:
            return state.set('updateUserPending', true);

        case types.CLEAR_USER_DETAILS_ERROR:
          return state.setIn(['userDetails', 'error'], null);

        case types.ACCOUNT_PERMISSIONS_PENDING:
            return setPending(state, ['allUsers']);

        case types.ACCOUNT_PERMISSIONS_LOADED:
            return setValue(state, ['allUsers'], action.permissions);

        case types.ACCOUNT_PERMISSIONS_ERROR:
            return setError(state, ['allUsers'], action.errorMessage);

        case types.PERMISSIONS_LIST_PENDING:
            return setPending(state, ['permissionsList']);

        case types.PERMISSIONS_LIST_LOADED:
            return setValue(state, ['permissionsList'], action.permissions);

        case types.PERMISSIONS_LIST_ERROR:
            return setError(state, ['permissionsList'], action.errorMessage);

        case types.DIAGNOSE_LIST_PENDING:
            return setPending(state, ['diagnoseProperties']);
    
        case types.DIAGNOSE_LIST_LOADED:
            return setValue(state, ['diagnoseProperties'], action.diagnoseProperties);
    
        case types.DIAGNOSE_LIST_ERROR:
                return setError(state, ['diagnoseProperties'], action.errorMessage);

        case types.EMAIL_REPORT_PENDING:
            return setPending(state, ['emailReport']);

        case types.EMAIL_REPORT_SUCCESS:
            return setValue(state, ['emailReport'], action.status);

        case types.EMAIL_REPORT_ERROR:
            return setError(state, ['emailReport'], action.errorMessage);

        case types.USERS_PENDING:
            return setPending(state, ['allUsers']);

        case types.USERS_ERROR:
            return setError(state, ['allUsers'], action.errorMessage);

        case types.USER_ACCESS_CHANGED:
            const idx = allUsers.findIndex(u => u.id === action.user.id);
            allUsers = allUsers.update(idx, (u) => action.user);
            return setValue(state, ['allUsers'], allUsers);

        case types.INVITED_USER_PENDING:
            return setPending(state, ['invitedUser']);

        case types.INVITED_USER_ERROR:
            return setError(state, ['invitedUser'], action.errorMessage);

        case types.USER_INVITED:
            return setValue(state, ['invitedUser'], action.user)
                   .setIn(['allUsers', 'value'], state.getIn(['allUsers', 'value']).set(1, action.user))

        case types.USER_DELETED:
            return setValue(state, ['allUsers'], allUsers.filterNot(u => u.id === action.id));

        case types.DOMAINS_LOADED:
            return setValue(state, ['domains'], action.domains);

        case types.DOMAINS_PENDING:
            return setPending(state, ['domains']);

        case types.DOMAINS_ERROR:
            return setError(state, ['domains'], action.errorMessage);

        case types.DOMAIN_ADDED:
            return setValue(state, ['domains'], domains.push(action.domain));

        case types.DOMAIN_DELETED:
            return setValue(state, ['domains'], domains.filterNot(d => d.id === action.id));

        case types.ACCOUNT_CREATED:
            const updatedAccountOwners = accountOwners.push(new Account(action.createdUserCreds));
            state = setValue(state, ['accountOwners'], updatedAccountOwners);
            state = setValue(state, ['createdUserCreds'], new StoreRecord(action.createdUserCreds));
            return state;

        case types.CREATE_ACCOUNT_ERROR:
            return setError(state, ['createdUserCreds'], action.errorMessage)

        case types.CREATE_ACCOUNT_PENDING:
            return setPending(state, ['createdUserCreds']);

        case types.SET_MASQ_USER_NAME:
            return state.set('masqUserName', action.name);

        case types.ACCOUNT_OWNERS_PENDING:
            return setPending(state, ['accountOwners']);

        case types.ACCOUNT_OWNERS_LOADED:
            return setValue(state, ['accountOwners'], action.users);

        case types.GET_ALL_SDK_PENDING:
            return setPending(state, ['sdkKeyList']);

        case types.GET_ALL_SDK__LOADED:
            return setValue(state, ['sdkKeyList'], action.keys?.data);

        case types.GENERATE_SDK_KEY_LOADED:
             sdkKeyList.push(action.data)
            return setValue(state, ['sdkKeyList'],sdkKeyList);

        case types.ACCOUNT_FEATURES_UPDATED:
            const index = accountOwners.findIndex(acc => acc.accountId === action.updatedAccount.id);
            accountOwners = accountOwners.update(index, (acc)=> acc.set("accountFeatures",new List(action.updatedAccount.features)))
            return setValue(state, ['accountOwners'], accountOwners);

        case types.ACCOUNT_OWNERS_ERROR:
            return setError(state, ['accountOwners'], action.errorMessage);

        case types.ACCOUNTS_PENDING:
            return setPending(state, ['allAccounts']);

        case types.ACCOUNTS_LOADED:
            return setValue(state, ['allAccounts'], action.users);

        case types.ACCOUNTS_ERROR:
            return setError(state, ['allAccounts'], action.errorMessage);
        //for Google Ad Manager scan form
        case types.FREE_SCAN_DETAILS_PENDING:
            return setPending(state,['freeScanDetails']);
        case types.FREE_SCAN_DETAILS_ERROR:
            return setError(state, ['freeScanDetails'], action.errorMessage);
        case types.FREE_SCAN_DETAILS_ADDED:
            return setValue(state,['freeScanDetails'],action.scanDetails);
        //End
    }

    return state;
};
