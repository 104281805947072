/* eslint-disable import/prefer-default-export */

export function accountFeaturesToAvailableDiagnoseMetrics(features = [], userFeatures) {
  const metricsList = [
    { feature: 'diagnose_metric_disclosed_vendors', userFeature: 'Diagnose - Disclosed Vendors', name: 'Disclosed Vendors' },
    { feature: 'diagnose_metric_non_disclosed_vendors', userFeature: 'Diagnose - Non disclosed vendors observed', name: 'Non disclosed vendors observed' },
    { feature: 'diagnose_metric_vendors_prior_to_consent', userFeature: 'Diagnose - Vendors triggered prior to consent', name: 'Vendors triggered prior to consent' },
    { feature: 'diagnose_metric_fingerprinting', userFeature: 'Diagnose - Possible Fingerprinting', name: 'Possible Fingerprinting' },
    { feature: 'diagnose_metric_data_leaving_eea', userFeature: 'Diagnose - Data leaving the EEA', name: 'Data leaving the EEA' },
    { feature: 'diagnose_metric_all_cookies_found', userFeature: 'Diagnose - All Cookies Found', name: 'All Cookies Found' },
    { feature: 'diagnose_metric_cookies_with_long_lifespans', userFeature: 'Diagnose - Cookies with long lifespans', name: 'Cookies with long lifespans' },
    { feature: 'diagnose_metric_vendors_after_opt_out', userFeature: 'Diagnose - Vendors after opt out', name: 'Vendors after opt out' },
    { feature: 'diagnose_metric_tracking_tech_detected', userFeature: 'Diagnose - Tracking tech detected', name: 'Tracking Tech' },
  ];

  if (!features.includes('diagnose_dashboard')) {
    return [];
  }

  return metricsList.reduce((availableMetrics, metric) => {
    return features.includes(metric.feature) && (userFeatures === true || userFeatures?.includes(metric.userFeature)) ? [...availableMetrics, metric.name] : availableMetrics;
  }, []);
}

export function accountFeaturesToAvailableAppDiagnoseMetrics(features = [], userFeatures) {
  const metricsList = [
    { feature: 'app_metric_vendors_prior_to_consent', userFeature: 'Diagnose - Vendors triggered prior to consent', name: 'Vendors triggered prior to consent' },
    { feature: 'app_metric_vendors_after_opt_out', userFeature: 'Diagnose - Vendors after opt out', name: 'Vendors after opt out' },
    { feature: 'app_metric_vendors_after_reject_all', userFeature: 'Diagnose - Vendors after reject all', name: 'Vendors after reject all' },
    { feature: 'app_metric_all_vendors_found', userFeature: 'Diagnose - All vendors found', name: 'All vendors found' },
  ];

  if (!features.includes('app_dashboard')) {
    return [];
  }

  return metricsList.reduce((availableMetrics, metric) => {
    return features.includes(metric.feature) && (userFeatures === true || userFeatures?.includes(metric.userFeature)) ? [...availableMetrics, metric.name] : availableMetrics;
  }, []);
}
