import React, { useState, useEffect } from 'react';
import { Table, Form, Button, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, RedoOutlined } from "@ant-design/icons"
import { Input, Modal, Popconfirm } from "../../../../../../styleguide";
import { renderInfoTip } from "../../../helper";

const { TextArea } = Input;

const WARNING_TEXT = "For empty translation fields, Default Language(English) Translation will be used."


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  type,
  activeTab,
  ...restProps
}) => {

  const inputNode = record?.translation?.length < 50 ? <Input /> : <TextArea rows={3} />;
  const validationCondition = (activeTab === "1" && type === "CUSTOM")
  const rules = !validationCondition && record?.name === "English" ? [
    {
      validator: async (_, value) => {
        if (!value || value.trim() === '') {
          return Promise.reject(`Please Input ${title}!`);
        }
      },
    },
  ] : []
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={rules}
        >
          {inputNode}
        </Form.Item>
      ) : (
          children
        )}
    </td>
  );
};



const TranslationTable = (props) => {
  const {
    dataSource,
    handleTranslationChanges,
    resetData,
    showResetStatus,
    toggleReset,
    resetKey,
    selectedPurpose,
    editable,
    activeTab,
    showMessageTone
  } = props;
  const [editingKey, setEditingKey] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentKey, setCurrentKey] = useState(null)
  const [currentRecord, setCurrentRecord] = useState({});
  const [currentData, setCurrentData] = useState(dataSource)
  const [previousData, setPreviousData] = useState(dataSource)
  const [showReset, setShowReset] = useState({})
  const [showWarningModal, setShowWarningModal] = useState(false)
  const [keepTranslation, setkeepTranslation] = useState(true)
  const [visible, setVisible] = React.useState(false)
  const [form] = Form.useForm();
  const NO_TRANSLATION_TEXT = "No Translations found"

  const isEditing = (record) => record.key === editingKey;

  const reset = () => {
    if (showResetStatus[resetKey]) {
      setPreviousData(dataSource)
      setCurrentData(dataSource)
      resetData()
      setVisible(false)
    }
    if (JSON.stringify(currentData) !== JSON.stringify(previousData) && !showResetStatus[resetKey]) {
      setPreviousData(currentData)
      // handleTranslationChanges(previousData);
      resetData()
      setVisible(false)
    }
  }


  useEffect(() => {
    cancel();
  }, [dataSource]);

  const handleVisibleChange = (visible) => {
    setVisible(visible)
  }
  const handleRecordSave = async (currentRecord) => {
    const row = await form.getFieldValue();
    setPreviousData(currentData)
    if (currentData.length === 1 || currentRecord.name !== 'English') {
      setShowModal(false)
      save(currentRecord, true)
    } else if (row.name === "English" && row.translation.trim() === "" && props.selectedPurpose.type !== "CUSTOM") {
      setShowModal(false)
    } else if(props.selectedPurpose.type === "CUSTOM") {
      setShowModal(false)
      save(currentRecord, true)
    } else {
      setShowModal(true)
    }
  }

  const columns = [
    {
      title: 'Language',
      dataIndex: 'name',
      key: 'name',
      width: 200,
      className: 'language-name',
      render: (value, record) => <><span >{value}</span>{record.name === 'English' ? <i className="default">(Default)</i> : ''} {(record.translation === '' || record.translation === NO_TRANSLATION_TEXT) ? (activeTab === "1" || value !== "English") ? renderInfoTip(WARNING_TEXT, "warning") : renderInfoTip(WARNING_TEXT, "error-icon") : null}</>
    },
    {
      title: 'Translation',
      dataIndex: 'translation',
      key: 'translation',
      editable: true,
      width: '70%',
      render: (value) => {
        let fieldValue = value?.trim();
        if (fieldValue === '' || fieldValue === NO_TRANSLATION_TEXT) {
          fieldValue = NO_TRANSLATION_TEXT;
          return <span className="no-translation">{fieldValue}</span>
        } else {
          return <span>{fieldValue?.replace(/\[|\]/g, "")}</span>
        }
      }
    },
  ]

  if (props.readOnly === false) {
    columns.push({
      title: showResetStatus[resetKey] ?(
        <Popconfirm
          title={"Are you sure you want to reset the text to default message for current choice?"}
          overlayClassName={"usp-regulation-popconfirm"}
          open={visible}
          trigger="click"
          icon={null}
          onConfirm={reset}
          onCancel={() => handleVisibleChange(false)}
          onOpenChange={(visible) => handleVisibleChange(visible)}
          okText="Yes"
          cancelText="No"
        >
          <Button size='small' type='link' iconPosition='end' icon={<RedoOutlined className='redo'/>}>Reset</Button>
        </Popconfirm>
      ): null,
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="icon-container">
            <Button icon={<CheckOutlined/>} type="link" onClick={() => handleRecordSave(record)}/>
            <Button icon={<CloseOutlined/>} type="link" onClick={cancel} />
          </div>
        ) : (
            <Tooltip title="Edit"><Button icon={<EditOutlined/>} type="link" onClick={() => edit(record)}/></Tooltip>
          )
      },
    })
  }

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        type: selectedPurpose?.type,
        activeTab: activeTab
      }),
    };
  });



  const edit = (record) => {
    setCurrentRecord(record)
    form.setFieldsValue({
      name: '',
      translation: '',
      ...record,
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const handleOnOk = (currentRecord) => {
    setShowModal(false)
    setkeepTranslation(false)
    save(currentRecord, false)
  }

  const footer = [
    <Button key="1" onClick={() => {
      setShowModal(false)
      save(currentRecord, true)
    }}>
      Keep translations
        </Button>,
    <Button key="2" onClick={() => handleOnOk(currentRecord)} type="primary">
      Clear
        </Button>,
  ];

  const footer_other = [
    <Button key="1" onClick={() => {
      setShowWarningModal(false)
      const modifiedNewdata = currentData.map(d => {
          return { ...d, translation: '' }
      })
      handleTranslationChanges(modifiedNewdata);
    }}>
     Confirm
        </Button>,
    <Button key="2" onClick={() => setShowWarningModal(false)} type="primary">
      Cancel
        </Button>,
  ];

  const checkEnglishTranslation = (data) => {
    const englishTranslation = data?.find(d => d.name === "English")?.translation?.trim();
    const otherLanguageTranslation = data?.filter(d => d?.name !== "English" && d?.translation !== "");
    if (englishTranslation === "" && otherLanguageTranslation?.length > 0) {
      return true
    } else {
      return false
    }
  }

  const save = async (record, keepTranslation) => {
    const { key } = record
    try {
      const row = await form.validateFields();
      const newData = [...dataSource]
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
      } else {
        newData.push(row);
      }

      let modifiedNewdata = newData
      if (!keepTranslation) {
        modifiedNewdata = newData.map(d => {
          if (d.name !== 'English') {
            return { ...d, translation: '' }
          } else {
            return { ...d }
          }
        })
      }else{
        modifiedNewdata = newData.map(d => {
          if (d.translation === '') {
            return { ...d, translation: '' }
          } else {
            return { ...d }
          }
        })
      }
      setEditingKey('');
      setkeepTranslation(true)
      setShowModal(false)
      setCurrentData(modifiedNewdata)
      const isEnglishTranslation = checkEnglishTranslation(modifiedNewdata)
      if (isEnglishTranslation) {
        setShowWarningModal(isEnglishTranslation)
      } else {
        handleTranslationChanges(modifiedNewdata)
      }    
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  return (
    <>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={false}
          scroll={{
            y: !props.isStateFlow && (showMessageTone || selectedPurpose.type === "CUSTOM") ? 'calc(50vh - 110px)' : 'calc(50vh - 70px)',
          }}
        />
      </Form>
      <Modal
        open={showModal}
        footer={footer}
        visible={showModal}
        onCancel={() => setShowModal(false)}
      >
        <p className="title">Updating default text</p>
        <p>The translations for all languages are only available for the default text. If you choose to update this text, you will have to either manually update translations for all languages or use the bulk upload option.</p>
        <p>If you still want to go ahead with updating the default text, would you like to clear the text for all the supported languages?</p>
      </Modal>
      <Modal
        open={showWarningModal}
        footer={footer_other}
        visible={showWarningModal}
        onCancel={() => setShowWarningModal(false)}
      >
        <div className="marginTop">Deleting the text for the default English Language will also delete the translations
         for the other languages. Are you sure you want to delete all translations for this privacy choice?
        </div>
      </Modal>
    </>
  )
}

export default TranslationTable