import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import {   SelectDropdown, Toggle, AddEditDelete, Table, Button } from "../../../../../../styleguide";
import { ExportOutlined, InfoCircleFilled } from "@ant-design/icons";
import { Tabs, Checkbox, Image, Alert, Input, Switch, Typography, Row, Space, Col} from "antd";
import { renderInfoTip, PROCESSING_SENSITIVE_DATA_TOOLTIP, COOKIE_EXPIRATION_TOOLTIP, SALE_PERONSAL_DATA_TOOLTIP, SHARING_PERSONAL_DATA_TOOLTIP, SALE_AND_SHARING_TOOLTIP, PRIVACY_POLICY_SECTION_LINK_TOOLTIP, BASED_ON_COLORADO_REGION_TOOLTIP, evaluateRequriedFieldsFromStep, BASED_ON_CALIFORNIA_REGION_TOOLTIP, IDENTIFICATION_TOOLTIP } from "../../../helper";
import { updateStepsDataFunction, getStepsDataFieldMap } from "../../../../../common/SequentialNavigationWizard/SequentialLeftNav";
import RegionSelectorTreeSelect from "../../../../../common/RegionSelector/RegionSelectorTreeSelect";
import { GEO_LIST_TREE_STRUCTURE } from "../../../../../../constants";
import { validateURL, capitalizeFirstLetter } from "../../../../../utils";
import classNames from "classnames";
import CustomChoicesTable from "./CustomChoicesTable";

const systemIdForOptOutChoices = {
  sharing : 1,
  sale: 2,
  saleAndSharing: 3
}
const REF_SENSITIVE_DATA_SYSTEM_ID = 16;

const { Title, Text, Paragraph }=  Typography;

const SelectPurposes = (props) => {
  const [cookieMaxAge, setCookieMaxAge] = useState(props.usPrivacyRegulation.cookieMaxAge)
  const [isProcessSensitiveData, setIsProcessSensitiveData] = useState(props.usPrivacyRegulation.categories.find(c => c.type === 'SYSTEM_STACK' && c.systemStackRef?.systemId === REF_SENSITIVE_DATA_SYSTEM_ID) ? true : false);
  const [optOutChoices, setOptOutChoices] = useState({
    sale: false,
    sharing: false,
    saleAndSharing: false,
  })
  const [respectGPCEnabled, setRespectGPCEnabled] = useState(false);
  const [respectGPCChecked, setRespectGPCChecked] = useState(props.usPrivacyRegulation.categories.filter(c => c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE").some( c => c.respectGPC));

  const [regulationCategories, setRegulationCategories] = useState(props.usPrivacyRegulation.categories);
  //-->
  const [respectGpcInCodes, setRespectGpcInCodes] = useState(props.usPrivacyRegulation.categories.find(c => c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE")?.respectGPC ?? null);
  const respectGpcInOptions = useMemo(()=>_.cloneDeep(GEO_LIST_TREE_STRUCTURE).map((option) => {
    if (props.usPrivacyRegulation.appliesGeos.includes(option.key)) {
      const filteredChildren = option.children.filter(obj => !props.usPrivacyRegulation.exclusionGeos.includes(obj.code));
      option.children = filteredChildren;
    } else {
      const filteredChildren = option.children.filter(obj => props.usPrivacyRegulation.appliesGeos.includes(obj.code) && !props.usPrivacyRegulation.exclusionGeos.includes(obj.code));
      option.children = filteredChildren;
    }
    if(option.children?.length) return option;
  }).filter(option => option),[props.usPrivacyRegulation.appliesGeos, props.usPrivacyRegulation.exclusionGeos]);
  
  const allOptionsCodes = respectGpcInOptions.flatMap(option => option.children.map(child => child.code))
  //<--

  //error states
  const [categoryError, setCategoryError] = useState(false);
  const [cookieAgeError, setCookieAgeError] = useState(false);
  const [emptyStack, setEmptyStack] = useState(false);
  const [showIdentificationList, setShowIdentificationList] = useState(props.usPrivacyRegulation.categories.find(c => c.defaultLegalBasis === "OPT-OUT" && c?.translations?.identificationList))

  useEffect(() => {
    const hasCategoryError = props.showError && regulationCategories.length === 0;
    setCategoryError(hasCategoryError);
  
    const hasCookieAgeError = props.showError && cookieMaxAge === '';
    setCookieAgeError(hasCookieAgeError);
  
    const hasEmptyStack = props.showError && !regulationCategories.filter(c => c.type === "SYSTEM_STACK").every(c => c.categories?.length);
    setEmptyStack(hasEmptyStack);
  
  }, [props.showError, cookieMaxAge, regulationCategories]);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']));
  const systemStack = useSelector(state => state.usPrivacyReducerState.getIn(['stacks', 'value']));

  const systemDefaultPurposesJS = systemPurposes.toJS()
  const systemDefaultStackJS = systemStack.toJS()

  const systemStacksJS = props.regulationCategoryOptionsCache?.filter(cat => cat.type == 'SYSTEM_STACK').toJS() ?? []
  const systemPurposesJS = props.regulationCategoryOptionsCache?.filter(cat => cat.type == 'SYSTEM_PURPOSE').toJS() ?? []
  const stepsStatusMap = getStepsDataFieldMap(props.stepsData, 'status')

  useEffect(()=>{
    props.usPrivacyRegulation.cookieMaxAge = cookieMaxAge;
    props.usPrivacyRegulation.showIdentificationList = showIdentificationList;
    props.usPrivacyRegulation.categories = regulationCategories
    props.resetValidationError();
  }, [cookieMaxAge, showIdentificationList, regulationCategories])

  useEffect(() => {
    let updatedRegulationCategories = _.cloneDeep(regulationCategories);
    const updatedData = updatedRegulationCategories?.map(category => {
      if (category.type === "SYSTEM_STACK" || category.type === "SYSTEM_PURPOSE") {
        const systemPurpose = (category?.type === "SYSTEM_STACK") ? systemDefaultStackJS.find(sys => sys?.systemStackRef?.id === category?.systemStackRef?.id) : systemDefaultPurposesJS.find(sys => sys?.purposeRef?.id === category?.purposeRef?.id)
        // Check if translations exist and replace identificationList with null
        if (category?.translations) {
          category.translations.identificationList = (showIdentificationList) ? systemPurpose?.translations?.identificationList : null
        } else {
          category.translations = {}
          category.translations.identificationList = (showIdentificationList) ? systemPurpose?.translations?.identificationList : null
        }
        if (category?.negativeTranslations) {
          category.negativeTranslations.identificationList = (showIdentificationList) ? systemPurpose?.translations?.identificationList : null
        } else {
          category.negativeTranslations = {}
          category.negativeTranslations.identificationList = (showIdentificationList) ? systemPurpose?.translations?.identificationList : null
        }
     
        category.identificationList = (showIdentificationList) ? systemPurpose?.identificationList : ""
      }
      return category;
      });
      setShowIdentificationList(showIdentificationList)
      if (!_.isEqual(updatedData, regulationCategories)) {
        setRegulationCategories(updatedData);
      }
  }, [showIdentificationList,regulationCategories])

  useEffect(()=>{
    setOptOutChoices({
      sale: props.usPrivacyRegulation.categories.find( c => c.type === 'SYSTEM_PURPOSE' && (c.purposeRef?.systemId === systemIdForOptOutChoices.sale)) ? true : false,
      sharing: props.usPrivacyRegulation.categories.find( c => c.type === 'SYSTEM_PURPOSE' && (c.purposeRef?.systemId === systemIdForOptOutChoices.sharing)) ? true : false,
      saleAndSharing: props.usPrivacyRegulation.categories.find( c => c.type === 'SYSTEM_PURPOSE' && (c.purposeRef?.systemId === systemIdForOptOutChoices.saleAndSharing)) ? true : false,
    })

    const errorInLanguageTranslations = getStepsDataFieldMap(props.stepsData, 'error').get('language_translations');
    if(errorInLanguageTranslations && evaluateRequriedFieldsFromStep('language_translations', props.usPrivacyRegulation, [])){
      let updatedStepsData = updateStepsDataFunction(props.stepsData, 'language_translations', 'error', false)
      props.setStepsData(updateStepsDataFunction(updatedStepsData, 'language_translations', 'status', 'finish')) 
    }
  },[props.usPrivacyRegulation.categories])

  useEffect(()=>{
    let updatedRegulationCategories = _.cloneDeep(regulationCategories);
    const stackAlreadyPresent = Boolean(updatedRegulationCategories.find(stack => stack.type === "SYSTEM_STACK" && stack.systemStackRef?.systemId === REF_SENSITIVE_DATA_SYSTEM_ID))
      if(isProcessSensitiveData){
       if(!stackAlreadyPresent) updatedRegulationCategories.push(systemStacksJS.find(stack => stack.systemStackRef?.systemId === REF_SENSITIVE_DATA_SYSTEM_ID));
      } else {
        updatedRegulationCategories = updatedRegulationCategories.filter(c => !(c.type === "SYSTEM_STACK" && c.systemStackRef?.systemId === REF_SENSITIVE_DATA_SYSTEM_ID));
      }
    setRegulationCategories(updatedRegulationCategories)
  },[isProcessSensitiveData]);

  const checkForChangesDetectedErrorState = () => {
    let updatedStepsData = props.stepsData;
    if(stepsStatusMap.get('language_translations') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'language_translations', 'warning', true)
    }
    if(stepsStatusMap.get('opt_in_opt_out') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'opt_in_opt_out', 'warning', true)
    }
    if(stepsStatusMap.get('vendor_management') === 'finish') {
      updatedStepsData = updateStepsDataFunction(updatedStepsData, 'vendor_management', 'warning', true)
    }
    props.setStepsData(updatedStepsData);
  }

  const onChangeProcessData = (e) => {
    setIsProcessSensitiveData(e.target.checked)
    checkForChangesDetectedErrorState();
  }

  const handlePurposeInStackSelection = (stackRef, purposeRefs) => {
    checkForChangesDetectedErrorState();
    setRegulationCategories(regulationCategories.map( stack => {
      if(stack.type === "SYSTEM_STACK" && stack.systemStackRef?.id == stackRef) {
        const allStackCategories = systemStacksJS.find( s => s.systemStackRef?.id === stackRef)?.categories
        stack.categories = allStackCategories?.filter( purpose => purposeRefs.includes(purpose.purposeRef?.id))
      }
      return stack;
    }))
  }

  const handleRespectGPCToggleChange = (value) =>{
    setRespectGPCChecked(value);
    if(value){
      setRespectGpcInCodes(respectGpcInCodes ?? allOptionsCodes)
    }else {
      setRespectGpcInCodes(null);
    }
  }

  const handleIdentificationListVisible = (value) => {
    setShowIdentificationList(value)
  }

  useEffect(()=>{
    const isAnyOPtOutChoiceSelected = optOutChoices.sale || optOutChoices.sharing || optOutChoices.saleAndSharing
    setRespectGPCEnabled(isAnyOPtOutChoiceSelected)
    if(!isAnyOPtOutChoiceSelected) {
      setRespectGPCChecked(false)
      setRespectGpcInCodes(null)
    } else {
      setRespectGPCChecked(props.usPrivacyRegulation.categories.filter(c => c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE").some( c => c.respectGPC))
    };
  },[optOutChoices]);

  useEffect(()=>{
    setRegulationCategories((rc)=> rc.map( c => {
      if(c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE") {
        c.respectGPC = respectGPCChecked ? respectGpcInCodes : null;
      }
      return c;
    }))
  },[respectGPCChecked, respectGpcInCodes]);

  useEffect(()=>{
    const newCodes = respectGpcInCodes?.filter(code => allOptionsCodes.includes(code)) ?? null;
    setRespectGpcInCodes(newCodes)
  },[respectGpcInOptions]);


  const toggleSaleAndShare = (e, setting) => {
    checkForChangesDetectedErrorState();
    const value = e.target.checked;
    if(value) {
      if(setting == 'sale' || setting == 'sharing') {
        setOptOutChoices((prevState) => (
          {
          ...prevState,
          [setting]: true,
          saleAndSharing: false
          }
        ))
        const updatedRegulationCategories = regulationCategories.filter( c =>(c.type === 'SYSTEM_STACK' || c.type === 'CUSTOM' || c.purposeRef?.systemId != systemIdForOptOutChoices.saleAndSharing));
        const newCat = systemPurposesJS.find( p => p.purposeRef?.systemId === systemIdForOptOutChoices[setting])
        newCat.isNegative = true;
        newCat.privacyPolicySectionLink = regulationCategories.find(cat => cat.type === 'SYSTEM_PURPOSE' && Object.values(systemIdForOptOutChoices).includes(cat.purposeRef?.systemId))?.privacyPolicySectionLink;
        setRegulationCategories(updatedRegulationCategories.concat(newCat))
      }else {
        setOptOutChoices({
          sale : false,
          sharing: false,
          saleAndSharing: true
        });
        const updatedRegulationCategories = regulationCategories.filter( c => c.type === 'SYSTEM_STACK' || c.type === 'CUSTOM' ||  !(c.purposeRef?.systemId == systemIdForOptOutChoices.sale || c.purposeRef?.systemId == systemIdForOptOutChoices.sharing));
        const newCat = systemPurposesJS.find( p => p.purposeRef?.systemId === systemIdForOptOutChoices[setting])
        newCat.privacyPolicySectionLink = regulationCategories.find(cat => cat.type === 'SYSTEM_PURPOSE' && Object.values(systemIdForOptOutChoices).includes(cat.purposeRef?.systemId))?.privacyPolicySectionLink;
        newCat.isNegative = true;
        setRegulationCategories(updatedRegulationCategories.concat(newCat))
      }
    } else {
      setRegulationCategories((rc) => rc.filter( c => c.type === 'SYSTEM_STACK' || c.type === 'CUSTOM' || c.purposeRef?.systemId !== systemIdForOptOutChoices[setting]))
      setOptOutChoices((prevState)=>({...prevState, [setting]: false}))
    }
  }

  const updateCookieMaxAge = ({target : {value}}) => {
    let intValue = parseInt(value);
    if (isNaN(intValue)) {
      intValue = '';
    } else if (intValue < 1) {
      intValue = 1;
    } else if (intValue > 36135) {
      intValue = 36135;
    }
    setCookieMaxAge(intValue)
  };

  const updatePrivacyPolicySectionLink = (value, sysId, catType) => {
    setRegulationCategories(regulationCategories.map( cat => {
      if(cat.type == catType && (sysId.includes(cat.systemStackRef?.systemId) || sysId.includes(cat.purposeRef?.systemId))){
        cat.privacyPolicySectionLink = value;
      }
      return cat;
    }))
  }
  const validatePrivacyPolicyLink = (value) => {
    //returns truthy if invalid
    if(value.trim() === ""){
      return "You cannot save this as empty";
    } else if(!validateURL(value)){
      return "Please provide a valid URL starting with ‘http://’ or ‘https://’"
    }
    return false;
  }

  const stacks = systemStacksJS.map(stack => {
    const selectedStack = regulationCategories.filter( c => c.type === 'SYSTEM_STACK').find( s => s.systemStackRef?.id === stack.systemStackRef?.id);
    const stackCategories = selectedStack?.categories.map(c => c.purposeRef?.id);
    return (
      <React.Fragment>
        <div className="box-container">
        <Checkbox onChange={(e) => onChangeProcessData(e)} checked={isProcessSensitiveData}>{stack.privacyChoice ?? stack.name}{renderInfoTip(PROCESSING_SENSITIVE_DATA_TOOLTIP)}</Checkbox>
        </div>
        <div className="sensitive-purposes">
          <span><i>Opt In to process the consumer's sensitive data consisting of</i></span>
          <SelectDropdown
            className={"opt-in-select"}
            dropdownClassName={"opt-in-dropdown"}
            value={stackCategories ?? stack.categories.map(c => c.purposeRef?.id)}
            options={stack.categories.map(c => ({value: c.purposeRef?.id, label: capitalizeFirstLetter(c.name.replace("Opt In to process the consumer's sensitive data consisting of", ""))}))}
            onChange={(purposeRefs) => handlePurposeInStackSelection(stack.systemStackRef?.id, purposeRefs)}
            selectAll
            multiple
            entityName="purposes"
            showSearch 
            directUpdate
            disabled={!isProcessSensitiveData}
            error={emptyStack ? 'Select at least one Privacy Choice' : false}
          />
        </div>
      </React.Fragment>
    )
  })

  const disableOptOut = props.usPrivacyRegulation.metaData.MspaCoveredTransaction && props.usPrivacyRegulation.metaData.MspaServiceProviderMode;

  const PrivacyLinkInfoTipOptOut = <Alert type="warning" showIcon message={<>Based on California Regulations {renderInfoTip(BASED_ON_CALIFORNIA_REGION_TOOLTIP)}</>}/>
  const PrivacyLinkInfoTipOptIn = <Alert type="warning" showIcon message={<>Based on Colorado Regulations {renderInfoTip(BASED_ON_COLORADO_REGION_TOOLTIP)}</>}/>

  return (
    <div className="privacy-choice">
    <Row gutter={[0,16]}>
      <Col span={24}>
        <Title level={4}>Privacy Choices</Title>
      </Col>
      <Col span={24}>
        <Alert showIcon message={<div><b>Note:</b> When you select a privacy choice, you are indicating that the required notice for that data processing activity will be given to all users. As such we will update the fields related to that privacy choice in the IAB string to indicate notice has been given.</div>} type="info"/>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={4}>
          <Text strong>Privacy Choice Selection</Text>
          <Text>Select Privacy Choices for data processing activities</Text>
        </Space>
        {categoryError ? <Text type="danger">Select at least one Privacy Choice</Text> : null}
        <Tabs
          defaultActiveKey="2"
          className={classNames({'validation-error' : categoryError || emptyStack})}
          destroyInactiveTabPane={true}
        >
          <Tabs.TabPane
            tab={"Opt In Choice"}
            icon={renderInfoTip('"Opt in choices" are choices presented to the user which the user has to consent to/opt into prior to the data processing taking place.')}
            key={"1"}
          >
            <div className="opt-container">
              <div className="heading">
                IAB Choices
              </div>
              <div className="opt-in-sub-container">
                <div className="info-message">
                  If you process any of the sensitive data categories listed please check the box below and select the relevant categories on the right. Each sensitive data category will be presented in a bullet point list on the message and the user will have the choice to opt in to all or none of the data categories.
                </div>
                <div>
                  {stacks}
                </div>
                { isProcessSensitiveData ? <hr className="partition-line" /> : null}
                { isProcessSensitiveData ? (
                  <div className="privacy-choice-link">
                      <h5>Privacy Policy Section Link {renderInfoTip(PRIVACY_POLICY_SECTION_LINK_TOOLTIP)} :</h5>
                      <AddEditDelete validate={validatePrivacyPolicyLink} value={regulationCategories.find(cat => cat.type === 'SYSTEM_STACK' &&  REF_SENSITIVE_DATA_SYSTEM_ID == cat.systemStackRef?.systemId)?.privacyPolicySectionLink} handleSave={(value) => updatePrivacyPolicySectionLink(value, [REF_SENSITIVE_DATA_SYSTEM_ID], 'SYSTEM_STACK')} entityName="Privacy Policy Link" suffixNode={PrivacyLinkInfoTipOptIn}/>
                  </div>
                  ) : null
                }
              </div>
            </div>
            <CustomChoicesTable
              regulationCategories={regulationCategories}
              setRegulationCategories={setRegulationCategories}
              defaultLegalBasis={"CONSENT"}
              showAlertIconForNextSteps={checkForChangesDetectedErrorState}
            />
          </Tabs.TabPane>
          <Tabs.TabPane 
            tab={"Opt Out Choice"}
            icon={renderInfoTip( "“Opt-out choices” are choices presented to the user which the user has to opt out of prior to the data processing taking place.")}
            key={"2"}
          >
          <div className={`opt-container ${disableOptOut? 'disabled' : ''}`} >
            <div className="heading">
              IAB Choices
              {disableOptOut ? <div>Unavailable in Service Provider Mode selected in <a onClick={props.goToSignatoryIdStep}>Add Identification ID</a></div> : null}
            </div>
            <div className="opt-out-sub-container">
              <div className="opt-out-sub-sub-container">
                <div className="info-message">
                  If you "sell" and "share" personal data on your website you can choose to present the user with one choice "do not sell or share my personal data" (or words to that effect) or two choices "do not sell my personal data" and "do not share my personal data" (or words to that effect). Use the tick boxes below to select your preference.
                </div>
                <div>
                  <div className="box-parent">
                    <div className="box-container">
                    <Checkbox checked={optOutChoices.sharing} onChange={(e) => toggleSaleAndShare(e,'sharing')}>{systemPurposesJS.find(p => p.purposeRef?.systemId === systemIdForOptOutChoices.sharing)?.privacyChoice}{renderInfoTip(SHARING_PERSONAL_DATA_TOOLTIP)}</Checkbox>
                    </div>
                    <div className="box-container" style={{marginTop:"10px"}}>
                    <Checkbox checked={optOutChoices.sale}  onChange={(e) => toggleSaleAndShare(e,'sale')}>{systemPurposesJS.find(p => p.purposeRef?.systemId === systemIdForOptOutChoices.sale)?.privacyChoice}{renderInfoTip(SALE_PERONSAL_DATA_TOOLTIP)}</Checkbox>
                    </div>
                  </div>
                  <div className="mid-text-container">
                    <div class="vertical-line"></div>
                    <div className="mid-text">OR</div>
                    <div class="vertical-line"></div>
                  </div>
                  <div style={{flex:0.5}}>
                    <div className="box-container">
                    <Checkbox checked={optOutChoices.saleAndSharing}  onChange={(e) => toggleSaleAndShare(e,'saleAndSharing')}>{systemPurposesJS.find(p => p.purposeRef?.systemId === systemIdForOptOutChoices.saleAndSharing)?.privacyChoice}{renderInfoTip(SALE_AND_SHARING_TOOLTIP)}</Checkbox>
                    </div>
                  </div>
                </div>
                { respectGPCEnabled ? <hr className="partition-line" /> : null}
                { respectGPCEnabled ? (
                  <div className="privacy-choice-link">
                      <h5>Privacy Policy Section Link {renderInfoTip(PRIVACY_POLICY_SECTION_LINK_TOOLTIP)} :</h5>
                      <AddEditDelete validate={validatePrivacyPolicyLink} value={regulationCategories.find(cat => cat.type === 'SYSTEM_PURPOSE' &&   Object.values(systemIdForOptOutChoices).includes(cat.purposeRef?.systemId))?.privacyPolicySectionLink} handleSave={(value) => updatePrivacyPolicySectionLink(value, Object.values(systemIdForOptOutChoices), 'SYSTEM_PURPOSE')} entityName="Privacy Policy Link" suffixNode={PrivacyLinkInfoTipOptOut}/>
                  </div>
                  ) : null
                  }
                  {respectGPCEnabled && (
                    <>
                      <hr className="partition-line" />
                      <div className="privacy-choice-link">
                        <h5>Show Identification List {renderInfoTip(IDENTIFICATION_TOOLTIP)}: </h5>
                        <Switch checked={showIdentificationList} onChange={handleIdentificationListVisible} style={{ marginLeft: "10px" }} />
                      </div>
                    </>
                  )
                  }
              </div>
                  <hr className="partition-line" />
                  {!respectGPCEnabled ?
              <div className="info-text">This setting is only available if you indicate that you either sell or share personal data.</div> : null}
              <div className={`gpc-container ${respectGPCEnabled ? '' : 'disabled'}`}>
              <div>
                <Switch
                  checked={respectGPCChecked}
                  onChange={handleRespectGPCToggleChange}
                  disabled={!respectGPCEnabled}
                  style={{ marginRight: "10px" }}
                />{" "}
                Respect &nbsp;
                <a
                  href="https://docs.sourcepoint.com/hc/en-us/articles/8357018608019-Global-Privacy-Control-GPC-"
                  target="_blank"
                >
                  Global Privacy Control
                </a>{" "}
                <ExportOutlined />
              </div>
                {respectGPCChecked ? (
                  <div>
                    <Text strong>Respect GPC in :</Text> 
                    <RegionSelectorTreeSelect
                      selectedCodes={respectGpcInCodes}
                      setSelectedCodes={setRespectGpcInCodes}
                      treeOptions={respectGpcInOptions}
                      filterEEA={props.isNational}
                      width={360}
                    />
                  </div>
                ) : null}
              </div>

            </div>
            <CustomChoicesTable
              regulationCategories={regulationCategories}
              setRegulationCategories={setRegulationCategories}
              defaultLegalBasis={"OPT-OUT"}
              showAlertIconForNextSteps={checkForChangesDetectedErrorState}
            />
          </div>
          </Tabs.TabPane>
        </Tabs>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={4}>
          <Text strong>Privacy Choice Expiration {renderInfoTip(COOKIE_EXPIRATION_TOOLTIP)}</Text>
          <Text>Use the box below to determine the length of time you want to the cookie which stores the state of user's privacy choices (opt ins / opt outs) to last before it expires</Text>
          <div>
            <Input
              value={cookieMaxAge}
              onChange={updateCookieMaxAge}
              style={{width: 100}}
              error={cookieAgeError}
            />
          <label>&nbsp;<b>Days</b>&nbsp;(up to 36135 Days)</label>
          <div>
            {cookieAgeError ? <Text type="danger">Please enter a value greater than 0</Text> : null}
          </div>
        </div>
        </Space>
      </Col>
    </Row>
    </div>
  )
}

export default SelectPurposes;
