import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Typography, Input, Form,theme, Select } from 'antd';
const { Title, Text, Paragraph } = Typography;

const AddExistingDialougeProperty = (props) => {
  const {
    showAddDialogePropertyModal,
    handleCancel,
  } = props;

  const { token } = theme.useToken()
  const [form] = Form.useForm();

  const handleAddDialougeProperty = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Form Values:', values); // This will log the form values
        // Perform additional actions like saving or sending data to the server
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    handleCancel()
  }

  return (
    <Modal
      title={"Import a Dialouge Property"}
      open={showAddDialogePropertyModal}
      width={"720px"}
      closable={true}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={handleAddDialougeProperty}
      okText={"Save"}
      cancelButtonProps={{ type: "link" }}
    >

      <Form
        layout={"vertical"}
        form={form}
        initialValues={{ name: null, type: 'App' }}
      >
        <Form.Item label="Name" name="name" >
        <Select mode="multiple">
          {props.sites.toJS().map(s => {
            return (<Select.Option value={s.id} key={s.id}>{s.domain}</Select.Option>)
          })}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Input disabled value="App" placeholder="input placeholder" />
        </Form.Item>
      </Form>
    </Modal>
  )
}

const mapStateToProps = function (store) {
  return {
    sites: store.siteState.getIn(['sites', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps, null)(AddExistingDialougeProperty);