import { InfoCircleFilled } from '@ant-design/icons';
import { Divider, Table, Tag, Tooltip, Typography } from 'antd';
import React from 'react';
import { browserHistory } from 'react-router';
import { Area, ComposedChart, Line, ResponsiveContainer } from 'recharts';

const { Title } = Typography

const ComplianceMetrics = ({ metricsList, resetSummary,type = "diagnose" }) => {
  const tags = [
    { name: 'Very Low', color: '#cb483a' },
    { name: 'Low', color: '#fe6600' },
    { name: 'Medium', color: '#ffc62c' },
    { name: 'High', color: '#a2df53' },
    { name: 'Very high', color: '#05a233' },
  ];

  const renderTag = (val) => {
    if (val < 21) {
      return tags[0];
    } else if (val >= 21 && val < 41) {
      return tags[1];
    } else if (val >= 41 && val < 61) {
      return tags[2];
    } else if (val >= 61 && val < 81) {
      return tags[3];
    } else if (val >= 81) {
      return tags[4];
    }
    return {};
  };

  const renderAverageCountEvolutionChart = (data, data2) => {
    const chartData = [...data];

    if (data2) {
      for (let i = 0; i < chartData.length; i++) {
        chartData[i].score2 = data2[i].score;
      }
    }

    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={100} height={500} data={chartData}>
          {data2 ? (
            <Area type="monotone" isAnimationActive={false} dataKey="score2" stroke="#8884d8" fill="#8884d8" />
          ) : null}
          <Line type="monotone" isAnimationActive={false} dataKey="score" stroke="#000000" fill="#000000" />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  const CustomizedDot = (props) => {
    const { cx, cy, stroke, payload, value } = props;
    if (value < 21) {
      return <circle r="3" stroke="#cb483a" fill="#cb483a" strokeWidth="1" width="10" height="10" cx={cx} cy={cy} />;
    } else if (value >= 21 && value < 41) {
      return <circle r="3" stroke="#fe6600" fill="#fe6600" strokeWidth="1" width="10" height="10" cx={cx} cy={cy} />;
    } else if (value >= 41 && value < 61) {
      return <circle r="3" stroke="#ffc62c" fill="#ffc62c" strokeWidth="1" width="10" height="10" cx={cx} cy={cy} />;
    } else if (value >= 61 && value < 81) {
      return <circle r="3" stroke="#a2df53" fill="#a2df53" strokeWidth="1" width="10" height="10" cx={cx} cy={cy} />;
    } else if (value >= 81) {
      return <circle r="3" stroke="#05a233" fill="#05a233" strokeWidth="1" width="10" height="10" cx={cx} cy={cy} />;
    }
    return false;
  };

  const renderRSEvolutionChart = (data) => {
    const chartData = [...data];

    return (
      <ResponsiveContainer width="100%" height="100%">
        <ComposedChart width={100} height={500} data={chartData}>
          <Line
            dot={<CustomizedDot />}
            type="monotone"
            isAnimationActive={false}
            dataKey="score"
            stroke="#000000"
            fill="#000000"
          />
        </ComposedChart>
      </ResponsiveContainer>
    );
  };

  const criteriaTooltips = {
    'Disclosed Vendors': 'Number of vendors disclosed in the CMP on the properties',
    'Non disclosed vendors observed':
      'Number of vendors that are found per scan that are not disclosed in the vendor list on the property',
    'Vendors triggered prior to consent':
      'Vendors that we have observed on the selected properties ahead of the user making a choice on the CMP',
    'Cookies with long lifespans':
      'Cookies with long lifespans are cookies that are being dropped on the selected properties with lifespans over 13 months',
    'Possible Fingerprinting': 'Number of observed vendors that are using data that could be used for fingerprinting',
    'Data leaving the EEA': 'Number of observed vendors that have servers outside of the EEA',
    'All Cookies Found': 'Number of distinct cookie names we have observed on your properties',
    'Vendors after opt out':
      'Vendors that we have observed on the selected properties after a user has opted out of the sharing and/or selling of personal information',
    'Tracking Tech':
      'Number of vendors using tracking tech technologies on your properties. Tracking Technology includes: pixels, cookiless IDs, chatbots and session replay.',
  };
  const renderCriteriaTooltip = (criteria) => {
    if (Object.keys(criteriaTooltips).includes(criteria)) {
      return criteriaTooltips[criteria];
    }
  };

  const columnsDiagnose = [
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
      sorter: (a, b) => a.criteria.localeCompare(b.criteria),
      render: (text, record) => (
        <div>
          <div>
            {text === 'Tracking Tech' ? <b>Tracking Tech Detected</b> : <b>{text}</b>}
            <Tooltip overlayClassName="diagnose--tooltip" title={renderCriteriaTooltip(record.criteria)}>
              {' '}
              <InfoCircleFilled style={{ color: '#808080' }} />
            </Tooltip>
          </div>
          {/* {( record.criteria === 'Possible Fingerprinting') && <div>Coming soon...</div>} */}
        </div>
      ),
    },
    {
      title: () => {
        return (
          <div>
            Relative score{' '}
            <Tooltip
              title={
                'The Relative Score is a percentile rank that looks at the specified compliance metric and compares the average performance across your properties with the other properties in the benchmark. Example: If you have a relative score of 67% then you score better than 67% of properties in your benchmark'
              }
            >
              {' '}
              <InfoCircleFilled style={{ color: '#808080' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'relative_score',
      key: 'relative_score',
      sorter: (a, b) => a.relative_score - b.relative_score,
      render: (text) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <b style={{ marginRight: '5px' }}>
            <h4>{text}</h4>
          </b>
          <div>
            <Tag color={renderTag(Number(text)).color} key={renderTag(Number(text)).name}>
              {' '}
              {renderTag(Number(text)).name}
            </Tag>
          </div>
        </div>
      ),
      align: 'center',
    },
    {
      title: () => {
        return (
          <div>
            Relative score evolution{' '}
            <Tooltip
              overlayClassName="diagnose--tooltip"
              title={'The Relative Score Evolution shows the Relative Score over the time period selected'}
            >
              {' '}
              <InfoCircleFilled style={{ color: '#808080' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'relative_score_evolution',
      key: 'relative_score_evolution',
      render: (text) => (
        <div style={{ height: '50px', width: '100px', borderBottom: '0.5px dashed #707070', margin: 'auto' }}>
          {renderRSEvolutionChart(text)}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Average count',
      key: 'average_count',
      children: [
        {
          title: () => {
            return (
              <div>
                My properties{' '}
                <Tooltip
                  overlayClassName="diagnose--tooltip"
                  title={
                    'The average count is the total number of times on average per scan we have observed the compliance metric detailed to the left on your selected properties'
                  }
                >
                  {' '}
                  <InfoCircleFilled style={{ color: '#808080' }} />
                </Tooltip>
              </div>
            );
          },
          dataIndex: 'properties',
          key: 'properties',
          sorter: (a, b) => a.properties - b.properties,
          render: (text) => (
            <b>
              <h4>{text}</h4>
            </b>
          ),
          align: 'center',
        },
        {
          title: () => {
            return (
              <div>
                Benchmark Properties{' '}
                <Tooltip
                  overlayClassName="diagnose--tooltip"
                  title={
                    'The average count is the total number of times on average per scan we have observed the compliance metric detailed to the left on your selected benchmark properties'
                  }
                >
                  {' '}
                  <InfoCircleFilled style={{ color: '#808080' }} />
                </Tooltip>
              </div>
            );
          },
          dataIndex: 'benchmark',
          key: 'benchmark',
          sorter: (a, b) => a.benchmark - b.benchmark,
          render: (text) => (
            <b>
              <h4 style={{ color: '#8884d8' }}>{text}</h4>
            </b>
          ),
          align: 'center',
        },
      ],
    },
    {
      title: () => {
        return (
          <div>
            Average Count Evolution{' '}
            <Tooltip
              overlayClassName="diagnose--tooltip"
              title={
                'The average count evolution graph compares the average count on your properties (in black) with the average count of your benchmark properties (in purple) over time.'
              }
            >
              {' '}
              <InfoCircleFilled style={{ color: '#808080' }} />
            </Tooltip>
          </div>
        );
      },
      dataIndex: 'average_count_evolution',
      key: 'average_count_evolution',
      render: (text, record) => (
        <div style={{ height: '50px', width: '100px', borderBottom: '0.5px dashed #707070', margin: 'auto' }}>
          {renderAverageCountEvolutionChart(record.value_evolution, record.benchmark_evolution)}
        </div>
      ),
      align: 'center',
    },
  ];

  const columnsApp = [
    {
      title: 'Criteria',
      dataIndex: 'criteria',
      key: 'criteria',
      width: "350px",
      sorter: (a, b) => a.criteria.localeCompare(b.criteria),
      render: (text, record) => (
        <div>
          <div>
            <b>{text}</b>
            <Tooltip overlayClassName="diagnose--tooltip" title={renderCriteriaTooltip(record.criteria)}>
              {' '}
              <InfoCircleFilled style={{ color: '#808080' }} />
            </Tooltip>
          </div>
        </div>
      ),
    },
    {
      title: 'Vendor Count',
      dataIndex: 'vendorCount',
      key: 'count',
     // sorter: (a, b) => a.criteria.localeCompare(b.criteria),
      render: (text, record) => (
        <div>10</div>
      ),
    },
  ]

  const scrollToTop = () => {
    document.querySelector('.spt-content').scrollTo({ top: 0, behavior: 'smooth' });
  };

  const loadDetailsData = (metricName) => {
    resetSummary([]);
    const metricRoute = {
      'Disclosed Vendors': 'diagnose_dashboard/disclosed_vendors',
      'Non disclosed vendors observed': 'diagnose_dashboard/non_disclosed_vendors_observed',
      'Vendors triggered prior to consent': 'diagnose_dashboard/vendors_triggered_prior_to_consent',
      'Cookies with long lifespans': 'diagnose_dashboard/cookies_with_long_lifespans',
      'Possible Fingerprinting': 'diagnose_dashboard/possible_fingerprinting',
      'Data leaving the EEA': 'diagnose_dashboard/data_leaving_the_EEA',
      'All Cookies Found': 'diagnose_dashboard/all_cookies_found',
      'Vendors after opt out': 'diagnose_dashboard/vendors_after_opt_out',
      'Tracking Tech': '/diagnose_dashboard/tracking_tech_detected',
    }?.[metricName];
    const metricAppRoute = {
      'Vendors triggered prior to consent': 'app_metrics/vendors_triggered_prior_to_consent',
      'Vendors after opt out': 'app_metrics/vendors_after_opt_out',
    }?.[metricName]
    const route = (type === "app") ? metricAppRoute : metricRoute
    browserHistory.push(route);
    scrollToTop();
  };

  const columns = (type === "app")? columnsApp : columnsDiagnose

  return (
    <div className={'compliance_metrics_container'}>
      {type === "app" && <Title level={4}>Compliance Metrics</Title>}
      <div style={{padding:"10px"}}>
        The cards below summarise the performance of the selected properties across a set of key compliance criteria
        over the chosen time period. Click on a card to get detailed insights and recommendations.{' '}
      </div>
      <Divider />
      <Table
        id="compliance-metrics"
        getPopupContainer={(trigger) => document.getElementById('compliance-metrics')}
        pagination={false}
        dataSource={metricsList}
        columns={columns}
        rowKey={(record) => record.criteria}
        showSorterTooltip={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              loadDetailsData(record.criteria);
            },
          };
        }}
      />
    </div>
  );
};

export default ComplianceMetrics;
