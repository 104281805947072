import React, { useEffect, useState } from 'react';
import { Button, Input, Checkbox, Tooltip } from 'antd';
import { ArrowRightOutlined, DownOutlined, UpOutlined, CloseOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import {
  addVendorManagementActivitiesList,
  setVendorStatusSelectedPropertyList,
} from '../../../../actions/diagnose_dashboard_actions';
import SVG from 'react-inlinesvg';
import { Select } from '../../../../styleguide';

import unreviewedStatusIcon from '../../../../assets/icons/unreviwed-status.svg';
import underReviewStatusIcon from '../../../../assets/icons/under-review-status.svg';
import allowedStatusIcon from '../../../../assets/icons/allowed-status.svg';
import actionRequiredStatusIcon from '../../../../assets/icons/action-required.svg';
import allowedRiskStatusIcon from '../../../../assets/icons/allowed-risk-status.svg';
import removePropertyIcon from '../../../../assets/icons/close-icon-vm.svg';
import Logo from '../../../../assets/images/spt-logo-without-name.png';
import { formatClassName } from './helpers';
import useSearchParams from '../shared/useSearchParams';

const statusList = [
  { text: 'Unreviewed', value: 'Unreviewed', icon: <SVG src={unreviewedStatusIcon} /> },
  { text: 'Under Review', value: 'Under Review', icon: <SVG src={underReviewStatusIcon} /> },
  { text: 'Approved', value: 'Approved', icon: <SVG src={allowedStatusIcon} /> },
  { text: 'Action Required', value: 'Action Required', icon: <SVG src={actionRequiredStatusIcon} /> },
  { text: 'Approved with risk', value: 'Approved with risk', icon: <SVG src={allowedRiskStatusIcon} /> },
];

const VendorStatusActivity = ({
  data,
  type,
  websitePropertiesList,
  vendorStatusSelectedPropertyList,
  selectedMetric,
  addVendorManagementActivitiesList,
  vendorManagementActivities,
  isVendorStatusMultiselectOperationActive,
  vendorManagementActivitiesList,
  setVendorStatusSelectedPropertyList,
  userDetails,
}) => {
  const searchParams = useSearchParams();
  const [isCollapseAllActivities, setIsCollapseAllActivities] = useState(
    !!vendorManagementActivitiesList?.some((act) => searchParams?.activity && act.id === searchParams.activity),
  );
  const [isActive, setIsActive] = useState(false);
  const [isCommentForAllPropertiesChecked, setIsCommentForAllPropertiesChecked] = useState(true);
  const [statusNotes, setStatusNotes] = useState({});
  const [vendorStatus, setVendorStatus] = useState({});

  const { TextArea } = Input;
  const Option = Select.Option;

  useEffect(() => {
    if (vendorManagementActivities && vendorManagementActivities.length) {
      isVendorStatusMultiselectOperationActive
        ? setVendorStatus({ 0: null })
        : setVendorStatus({ 0: vendorManagementActivitiesList[0]?.status });
    } else {
      isVendorStatusMultiselectOperationActive
        ? setVendorStatus({ 0: null })
        : setVendorStatus({ 0: vendorManagementActivitiesList[0]?.status });
    }
  }, [vendorManagementActivities]);

  useEffect(() => {
    if (vendorManagementActivitiesList.length) {
      isVendorStatusMultiselectOperationActive
        ? setVendorStatus({ 0: null })
        : setVendorStatus({ 0: vendorManagementActivitiesList[0]?.status });
    }
  }, [vendorManagementActivitiesList]);

  useEffect(() => {
    isVendorStatusMultiselectOperationActive
      ? setVendorStatus({ 0: null })
      : setVendorStatus({ 0: vendorManagementActivitiesList[0]?.status });
    // setIsActive(false);
  }, [vendorStatusSelectedPropertyList]);

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'Unreviewed':
        return <SVG src={unreviewedStatusIcon} />;
      case 'Approved':
        return <SVG src={allowedStatusIcon} />;
      case 'Action Required':
        return <SVG src={actionRequiredStatusIcon} />;
      case 'Under Review':
        return <SVG src={underReviewStatusIcon} />;
      case 'Approved with risk':
        return <SVG src={allowedRiskStatusIcon} />;
      default:
        return null;
    }
  };

  const updateVendorManagementActivities = ({ ...updatedActivities }) => {
    let updatedData = [];
    vendorStatusSelectedPropertyList.map((property, index) => {
      const current = data.vendor_status.find(({ website }) => website === property);
      const formattedStatus = vendorStatus[index];
      if (formattedStatus === current.status && !updatedActivities?.tag) {
        // we don't update property if status & tag wasn't changed
        return;
      }
      updatedData.push({
        //required fields:
        metric: selectedMetric,
        vendor_id: type === "app" ? data.diagnose_vendor_id: data.vendor_id,
        website_id: property,
        user_dialogue_id: userDetails.name,
        note: statusNotes[index] ? statusNotes[index] : '',
        //optional fields:
        status: formattedStatus,
        tag: updatedActivities?.tag,
      });
    });
    addVendorManagementActivitiesList({ data: updatedData });
  };
  const onChangeStatus = (value) => {
    let statuses = [];
    vendorStatusSelectedPropertyList.map((property, index) => {
      statuses[index] = value;
    });
    setVendorStatus(statuses);
    if (!isVendorStatusMultiselectOperationActive && value !== vendorManagementActivitiesList) {
      setIsActive(true);
    } else {
      setIsActive(false);
    }
    if (isVendorStatusMultiselectOperationActive) {
      setIsActive(true);
    }
  };

  const onDismissTag = () => {
    updateVendorManagementActivities({ tag: 'Dismiss' });
  };

  const onChangeNotesForAll = (value) => {
    let notes = [];
    vendorStatusSelectedPropertyList.map((property, index) => {
      notes[index] = value;
    });
    setStatusNotes(notes);
  };
  const onChangeNoteForOne = (value, index) => {
    setStatusNotes((prevState) => ({ ...prevState, [index]: value }));
  };

  const onUpdateActivities = () => {
    updateVendorManagementActivities({});
    setIsActive(false);
    setStatusNotes({});
    //Todo show popup on success
  };

  const onCancelChanges = () => {
    setIsActive(false);
    isVendorStatusMultiselectOperationActive
      ? setVendorStatus({ 0: null })
      : setVendorStatus({ 0: vendorManagementActivitiesList[0]?.status });
    setStatusNotes({});
  };
  const onChangeCheckbox = (e) => {
    setIsCommentForAllPropertiesChecked(e.target.checked);
  };
  const removeSelectedProperty = (propertyId) => {
    const updatedPropertyList = vendorStatusSelectedPropertyList?.filter((property) => property !== propertyId);
    setVendorStatusSelectedPropertyList(updatedPropertyList);
  };

  return (
    <div>
      <div className={`detailed-info-status-block ${isActive ? 'active' : ''}`}>
        <div className={'detailed-info-status-block-property'}>
          <div className={'detailed-info-status-block-property-content'}>
            <div className={'detailed-info-status-title'}>Property:</div>
            {vendorStatusSelectedPropertyList?.map(
              (propertyId, index) =>
                index < 2 &&
                websitePropertiesList.map(
                  (website) =>
                    website.value === propertyId && (
                      <div
                        key={'key-website-' + propertyId}
                        className={`detailed-info-status-property-item ${searchParams.property === propertyId ? 'diagnose-notification-highlight background' : ''}`}
                        title={website.title}
                      >
                        <div>{website.title}</div>
                        {vendorStatusSelectedPropertyList?.length > 1 && (
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<SVG src={removePropertyIcon} />}
                            size={'small'}
                            onClick={() => removeSelectedProperty(propertyId)}
                          />
                        )}
                      </div>
                    ),
                ),
            )}
            {vendorStatusSelectedPropertyList?.length > 2 ? (
              <Tooltip
                overlayClassName="diagnose--tooltip"
                title={() => {
                  return (
                    <div className={'vendor-status-properties-tooltip'}>
                      {vendorStatusSelectedPropertyList?.map(
                        (propertyId, index) =>
                          index > 1 &&
                          websitePropertiesList.map(
                            (website) =>
                              website.value === propertyId && (
                                <div key={'key-property-list-item' + propertyId} className={'property-list-item'}>
                                  <div className={'vendor-status-website-name'}>{website.title}</div>
                                  <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<CloseOutlined />}
                                    size={'small'}
                                    onClick={() => removeSelectedProperty(propertyId)}
                                  />
                                </div>
                              ),
                          ),
                      )}
                    </div>
                  );
                }}
                placement="bottomLeft"
                overlayClassName="vendor-status-properties-tooltip-overlay"
              >
                <div className={'detailed-info-status-property-item'}>
                  +{vendorStatusSelectedPropertyList?.length - 2}
                </div>
              </Tooltip>
            ) : null}
            {!isVendorStatusMultiselectOperationActive &&
            vendorManagementActivitiesList[0]?.tag !== 'Dismiss' &&
            vendorManagementActivitiesList[0]?.tag ? (
              <div
                className={`detailed-info-status-item ${vendorManagementActivitiesList[0]?.tag === 'New' ? 'new' : ''} ${vendorManagementActivitiesList[0]?.tag === 'Change Detected' ? 'change' : ''}`}
              >
                <div className={'status-item-name'}>{vendorManagementActivitiesList[0]?.tag}</div>
                <div onClick={onDismissTag} role={'button'} className={'status-item-btn'}>
                  Dismiss
                </div>
                <div
                  className={`status-item-tooltip ${vendorManagementActivitiesList[0]?.tag === 'Change Detected' ? 'change' : ''}`}
                >
                  {vendorManagementActivitiesList[0]?.tag === 'New' ? (
                    <div>
                      This vendor is newly added to this property. You can update the current status to remove the{' '}
                      {<span className={'status-item-name'}>{vendorManagementActivitiesList[0]?.tag}</span>} tag. Or you
                      can remove it manually by selecting Dismiss.
                    </div>
                  ) : null}
                  {vendorManagementActivitiesList[0]?.tag === 'Change Detected' ? (
                    <div>
                      Changes were detected in the data for this vendor since the last time you updated the status. You
                      can update the current status to remove the{' '}
                      {<span className={'status-item-name'}>{vendorManagementActivitiesList[0]?.tag}</span>} tag. Or you
                      can remove it manually by selecting Dismiss.
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </div>
          <div className={'detailed-info-status-block-property-buttons'}>
            <Button onClick={onCancelChanges}>Cancel</Button>
            <Button onClick={onUpdateActivities} type="primary">
              Update
            </Button>
          </div>
        </div>
        <div className={'detailed-info-set-status-block'}>
          <div className={'detailed-info-status-title'}>Vendor status</div>
          <Select
            size="medium"
            getPopupContainer={(trigger) => trigger.parentElement}
            placeholder={'Status'}
            value={vendorStatus[0]}
            onChange={onChangeStatus}
            className={'diagnose-dashboard-select vendor-status-dropdown-select'}
          >
            {statusList?.map((status, index) => {
              return (
                <Option key={`period-${index}`} label={status.text} value={status.value}>
                  {status.icon}
                  {status.text}
                </Option>
              );
            })}
          </Select>
        </div>
        {!isVendorStatusMultiselectOperationActive ? (
          <div className={'detailed-info-set-comments-block'}>
            <div className={'detailed-info-status-title'}>Comments:</div>
            <TextArea
              autoSize
              placeholder={'Add comment ...'}
              value={statusNotes[0]}
              onChange={(e) => onChangeNotesForAll(e.target.value)}
            />
          </div>
        ) : null}
      </div>
      {isVendorStatusMultiselectOperationActive ? (
        <div className={'comment-for-all-properties-container'}>
          <Checkbox
            checked={isCommentForAllPropertiesChecked}
            className={'is-comment-for-all-properties-checkbox'}
            onChange={onChangeCheckbox}
          >
            Same comment for all properties
          </Checkbox>
          {isCommentForAllPropertiesChecked ? (
            <div className={'set-comments-for-all-properties-block'}>
              <div className={'set-comments-for-all-properties-title'}>Comments:</div>
              <TextArea
                autoSize
                placeholder={'Add comment for all selected properties...'}
                value={statusNotes[0]}
                onChange={(e) => onChangeNotesForAll(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      ) : null}
      {!isVendorStatusMultiselectOperationActive ? (
        <div className={'detailed-info-activities-block'}>
          <div className={'detailed-info-activities-block-title'}>Activity</div>
          {vendorManagementActivitiesList?.length ? (
            vendorManagementActivitiesList.map((activity, index) => {
              const prevStatusName = vendorManagementActivitiesList[index + 1]?.status;
              const currentStatusName = activity.status;
              const currentTagName = activity.tag;
              const prevTagName = vendorManagementActivitiesList[index + 1]?.tag;
              const formatClassName = (name) => {
                return name?.split(' ').join('-').toLowerCase();
              };
              if (isCollapseAllActivities || (!isCollapseAllActivities && index < 1)) {
                return (
                  <div
                    key={`activity-item-${index}`}
                    className={`detailed-info-activity-item ${activity.id === searchParams.activity ? 'diagnose-notification-highlight background' : ''}`}
                  >
                    <div className={'activity-row'}>
                      <div className={'activity-date'}>{`${moment(activity.created_at + 'Z').format('LLL')} |`}</div>
                      <div className={'updated-by'}>
                        <div className={'updated-by-title'}>Updated by</div>
                        {activity.user_dialogue_id === 'system' ? (
                          <div className={'updated-by-system'}>
                            <img src={Logo} alt="" />
                            SYSTEM
                          </div>
                        ) : (
                          <b>{activity.user_dialogue_id}</b>
                        )}
                      </div>
                    </div>
                    <div className={'activity-statuses-row'}>
                      {!prevStatusName ? (
                        <div className={`activity-current-tag ${formatClassName(currentTagName)}`}>
                          {currentTagName}
                        </div>
                      ) : null}

                      {prevStatusName !== currentStatusName ? (
                        <div className={`activity-prev-status ${formatClassName(prevStatusName)}-status`}>
                          {renderStatusIcon(prevStatusName)}
                          {prevStatusName}
                        </div>
                      ) : null}
                      {prevStatusName !== currentStatusName && prevStatusName ? <ArrowRightOutlined /> : null}
                      {prevStatusName !== currentStatusName && prevStatusName ? (
                        <div className={`activity-current-status ${formatClassName(currentStatusName)}-status`}>
                          {renderStatusIcon(currentStatusName)}
                          {currentStatusName}
                        </div>
                      ) : null}

                      {currentTagName === 'Change Detected' ? (
                        <div className={`activity-current-tag ${formatClassName(currentTagName)}`}>
                          {currentTagName}
                        </div>
                      ) : null}
                      {currentTagName === 'Dismiss' ? (
                        <div className={'activity-dismissed-tag-row'}>
                          <div className={`activity-prev-tag ${formatClassName(prevTagName)}`}>{prevTagName}</div>
                          <div className={`activity-current-tag ${formatClassName(currentTagName)}`}>
                            {currentTagName}
                          </div>
                        </div>
                      ) : null}
                    </div>
                    {activity.note ? (
                      <div className={'activity-comment-row'}>
                        <div className={'activity-comment-title'}>Comments:</div>
                        <div className={'activity-comment-block'}>
                          {activity.note.split('\\n').map((item) => (
                            <div key={item}>{item}</div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              } else if (!isCollapseAllActivities && index > 0) {
                return null;
              }
            })
          ) : (
            <div style={{ textAlign: 'center' }} className={'detailed-info-activity-item'}>
              There is no activity so far for this vendor
            </div>
          )}
        </div>
      ) : null}
      {!isCommentForAllPropertiesChecked && isVendorStatusMultiselectOperationActive ? (
        <div className={'detailed-info-activities-block multi-select-operation'}>
          {vendorStatusSelectedPropertyList?.map((propertyId, index) => {
            const lastActivitiesByPropertyId = vendorManagementActivitiesList
              .filter((activity) => activity?.website === propertyId)
              .slice(0, 2);
            const prevStatusName = () => {
              if (isActive && vendorStatus[0]) {
                return lastActivitiesByPropertyId[0]?.status;
              } else {
                return lastActivitiesByPropertyId[1]?.status;
              }
            };
            const currentStatusName = (type) => {
              if (isActive && vendorStatus[0]) {
                if (type === 'className') {
                  return vendorStatus[index];
                } else {
                  return vendorStatus[index];
                }
              } else {
                return lastActivitiesByPropertyId[0]?.status;
              }
            };
            if (lastActivitiesByPropertyId.length) {
              return (
                <div key={`${index}-detailed-info`} className={'detailed-info-activity-item'}>
                  <div className={'activity-row'}>
                    <div className={'property-title-block'}>
                      <div className={'property-title'}>Property:</div>
                      <div className={`property-name`}>{lastActivitiesByPropertyId[0]?.website_name}</div>
                    </div>
                    <div className={'activity-statuses-row'}>
                      {prevStatusName() && vendorStatus[0] && prevStatusName() !== currentStatusName('displayName') ? (
                        <div className={`activity-prev-status ${formatClassName(prevStatusName() || '')}-status`}>
                          {renderStatusIcon(prevStatusName())}
                          {prevStatusName()}
                        </div>
                      ) : null}
                      {prevStatusName() && vendorStatus[0] && prevStatusName() !== currentStatusName('displayName') ? (
                        <ArrowRightOutlined />
                      ) : null}
                      <div className={`activity-current-status ${formatClassName(prevStatusName() || '')}-status`}>
                        {renderStatusIcon(currentStatusName('displayName'))}
                        {currentStatusName('displayName')}
                      </div>
                    </div>
                  </div>
                  <div className={'activity-comment-row'}>
                    <div className={'activity-comment-title'}>Comments:</div>
                    <TextArea
                      className={'activity-comment-block'}
                      disabled={isCommentForAllPropertiesChecked}
                      autoSize
                      placeholder={'Add comment for all selected properties...'}
                      value={statusNotes[index]}
                      onChange={(e) => onChangeNoteForOne(e.target.value, index)}
                    />
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      ) : null}
      {vendorManagementActivitiesList?.length > 1 && !isVendorStatusMultiselectOperationActive ? (
        <div
          onClick={() => setIsCollapseAllActivities(!isCollapseAllActivities)}
          className={'detailed-info-activities-block-collapse-btn'}
        >
          {isCollapseAllActivities ? 'Hide all' : 'View all'}
          {isCollapseAllActivities ? <UpOutlined /> : <DownOutlined />}
        </div>
      ) : null}
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    websitePropertiesList: store.diagnoseDashboardState.getIn(['websitePropertiesList', 'value']),
    vendorStatusSelectedPropertyList: store.diagnoseDashboardState.getIn(['vendorStatusSelectedPropertyList', 'value']),
    vendorManagementActivities: store.diagnoseDashboardState.getIn(['vendorManagementActivities', 'value']),
    isVendorStatusMultiselectOperationActive: store.diagnoseDashboardState.getIn([
      'isVendorStatusMultiselectOperationActive',
      'value',
    ]),
    vendorManagementActivitiesList: store.diagnoseDashboardState.getIn(['vendorManagementActivitiesList', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addVendorManagementActivitiesList,
      setVendorStatusSelectedPropertyList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorStatusActivity);
