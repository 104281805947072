import React, { useEffect, useState } from 'react';
import { CopyOutlined, DeleteOutlined, ExclamationCircleOutlined, FileAddOutlined, PauseOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form, Tag, Table, Empty, Flex, Badge, Button, Tooltip, message, Modal, Typography, Space } from 'antd';
import { columnDiagnose } from '../AccountFeatures';
import DiagnoseScanSetupEditorModal from './ScanEditorModal';
import { getDiagnoseAccountScans, getDiagnoseAccountWebsites } from '../../../actions/diagnose_scan_setup';
import { deleteAccountScan, toggleAccountScan } from '../../../api/diagnose_dashboard/scan_setup';
import { text } from '@fortawesome/fontawesome-svg-core';


const { Option } = Select;

function scanSetup(status) {
  if (status) {
    return <span className="scan-status-successful">Successful</span>;
  }
  if (status === false) {
    return <span className="scan-status-failed">Failed</span>;
  }
  return <span className="scan-status-pending">Pending</span>;
}

function scanBadge(status) {
  if (status) {
    return "success";
  }
  if (status === false) {
    return "error";
  }
  return "warning";
}

function DiagnoseScanSetup() {
  const [modal, contextHolder] = Modal.useModal();
  const accountState = useSelector((store) => store.accountState);
  const scanSetupState = useSelector((store) => store.diagnoseScanSetupState);
  const accountOwners = accountState.getIn(["accountOwners", "value"]);
  const accountScans = scanSetupState.getIn(["accountScans", "value"]);
  const websitePages = scanSetupState.getIn(["websitePages", "value"]) || [];

  const isLoadingAccountScans = scanSetupState.getIn([
    "accountScans",
    "pending",
  ]);
  const errorMessage = scanSetupState.getIn(["accountScans", "error"]);
  const dispatch = useDispatch();
  const diagnoseAccount = accountOwners.filter((account) =>
    account.accountFeatures.some((feature) => columnDiagnose.includes(feature))
  );
  const [accountId, setAccountId] = useState(null);
  const [editScanId, setEditScanId] = useState(null);
  const [modalOpenType, setModalOpenType] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [tableFilter, setTableFilter] = useState({})
  const [paginationState, setPaginationState] = useState({
    page: 1, 
    pageSize: 10,
  });
  const [sortingState, setSortingState] = useState({});

  const handleRowClick = (record) => {
    const isExpanded = expandedRowKeys.includes(record.website_id);
    setExpandedRowKeys(isExpanded ? [] : [record.website_id]);
  };
  
  const [successfulScan, setSuccessfulScan] = useState(null);


  const refreshAccountScans = async (accountId, currentPage = paginationState.page, currentPageSize = paginationState.pageSize, sortOrder, sortField, filter = {}) => {
  
    let sort_order = sortOrder === "ascend" ? 1 : 0;
  
    const payload = {
      params: {
        website_names: filter.website_name || [],
      },
      pagination: {
        page: currentPage,
        page_size: currentPageSize,
        sort_order: sort_order,
        sort_field: sortField,
      },
    };
  
    try {
      const response = await dispatch(
        getDiagnoseAccountScans({ accId: accountId, accountId: accountId }, payload)
      );
    } catch (error) {
      console.error("Failed to load scans:", error);
      message.error("Failed to load scans for the selected account.");
    }
  };

  
  const handlePaginationChange = (pagination, filters, sorter) => {
    setTableFilter(filters)
    setPaginationState((prevState) => ({
      ...prevState,
      page: pagination.current,
      pageSize: pagination.pageSize,
    }));
    setSortingState(sorter);
    refreshAccountScans(accountId, pagination.current, pagination.pageSize, sorter.order, sorter.field, filters);
  };

  const handleAccountSelect = (value) => {
    setAccountId(value);
    websiteName(value);
    setTableFilter({});
    setPaginationState({ page: 1, pageSize: 10 }); 
    setSortingState({});
    refreshAccountScans(value, 1, 10);
  };

  const websiteName = (accountId) => {
      dispatch(getDiagnoseAccountWebsites({ accId: accountId }));
  }

  const websiteNameFilter = websitePages?.map((web) => ({label: web.url, value: web.name, text: web.name})) ?? [];

  
  const DiagnosePropertyScanColumns = [
    {
      title: "Property Name",
      dataIndex: "website_name",
      key: "website_name",
      width: 270,
      fixed: "left",
      sortDirections: ["descend", "ascend"],
      sortOrder: sortingState.field === "website_name" ? sortingState.order : null, 
      filters: websiteNameFilter,
      filterSearch: true,
      filteredValue: tableFilter.website_name || null,
      onFilter: (value, record) => value === record.website_name,
      sorter: (a, b) => a.website_name.localeCompare(b.website_name),
      render: (text, record) => {
        const failedScans = record.scans
          ? record.scans.filter((scan) => scan.successful == false).length
          : 0;
        return (
          <Space>
            <Typography.Paragraph style={{maxWidth: 270}} ellipsis={{tooltip:{title: record.website_name}, rows: 1}}>{record.website_name}</Typography.Paragraph>
            {failedScans > 0 && (
              <Tooltip title={`Failed Scans : ${failedScans}`}>
                <Button type="link" icon={<ExclamationCircleOutlined />}/>
              </Tooltip>
            )}
          </Space>
        );
      },
    },
    {
      title: "Total Scans",
      dataIndex: "total_scans",
      key: "total_scans",
      width: 270,
      sortDirections: ["ascend", "descend"],
      sorter: (a, b) => a.total_scans - b.total_scans,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
    },
  ];

  const DiagnoseScanSetupColumns = (recordLine) => {
    const zoneFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.zone)),
    ].map((zone) => ({ text: zone || "Not completed yet", value: zone }));

    const regionFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.country)),
    ].map((country) => ({
      text: country || "Not completed yet",
      value: country,
    }));

    const consentFilters = [
      ...new Set(recordLine.scans.map((scan) => scan.consent_category)),
    ].map((consentCategory) => ({
      text: consentCategory,
      value: consentCategory,
    }));

    const scanIdFilters = recordLine.scans.map((scan) => ({
      text: scan.id,
      value: scan.id,
    }));

    return [
      {
        title: "Region",
        dataIndex: "zone",
        filters: zoneFilters,
        onFilter: (value, record) => value === record.zone,
        key: "zone",
        width: 95,
        render: (text) => text || "Not completed yet",
      },
      {
        title: "Country / state",
        dataIndex: "country",
        key: "country",
        onFilter: (value, record) => value === record.country,
        filters: regionFilters,
        width: 125,
        render: (text) =>
          text ? (
            <Tag bordered key={text}>
              {text}
            </Tag>
          ) : (
            "Not completed yet"
          ),
      },
      {
        title: "Scan ID",
        filterSearch: true,
        dataIndex: "id",
        filters: scanIdFilters,
        onFilter: (value, record) => value === record.id,
        key: "id",
        width: 125,
        render: (text) => {
          const handleClick = (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            navigator.clipboard.writeText(text);
            message.success("Scan ID copied");
          };

          return (
            <>
              <Flex justify='space-between' align='center'>
                <Tooltip
                  overlayClassName="diagnose--tooltip"
                  title={
                    <div
                      onClick={(evt) => evt.stopPropagation()}
                      className="information-storage-tooltip"
                    >
                      {text}
                    </div>
                  }
                >
                  {text.split("-")[0]}...
                </Tooltip>
                <Tooltip
                  onClick={(evt) => evt.stopPropagation()}
                  overlayClassName="diagnose--tooltip"
                  title={
                    <div onClick={(evt) => evt.stopPropagation()}>
                      Copy Scan ID
                    </div>
                  }
                >
                  <Button type="link" onClick={handleClick}>
                    <CopyOutlined />
                  </Button>
                </Tooltip>
              </Flex>
            </>
          );
        },
      },
      {
        title: "Consent type",
        onFilter: (value, record) => value === record.consent_category,
        dataIndex: "consent_category",
        filters: consentFilters,
        key: "consent_category",
        width: 155,
      },
      {
        title: "Scan Frequency",
        dataIndex: "one_time",
        key: "one_time",
        width: 155,
        render: (text, record) => {
          return (
            <div key={record?.vendor_id}>{text ? "One Time" : "Recurring"}</div>
          );
        },
      },
      {
        title: "Last Scan",
        dataIndex: "successfull",
        key: "successfull",
        width: 125,
        filters: [
          { text: "Successfull", value: "successful" },
          { text: "Failed", value: "failed" },
          { text: "Pending", value: "pending" },
        ],
        onFilter: (value, record) => {
          if (value === "successful") {
            return record.successful === true;
          } else if (value === "failed") {
            return record.successful === false;
          } else if (value === "pending") {
            return record.pending === null;
          }
          return false;
        },
        render: (text, record) => {
          return (
            <div key={record?.vendor_id}>
              <Badge
                status={scanBadge(record.successful)}
                text={scanSetup(record.successful)}
              />
            </div>
          );
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
        width: 125,
        render: (_, record) => {
          const onDuplicate = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            await editScan(record.id, "duplicate");
          };

          const onToggleState = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            await toggleAccountScan(
              { accId: accountId },
              { scan_id: record.id, enabled: !record.enabled }
            );
            refreshAccountScans(accountId, paginationState.current, paginationState.pageSize, sortingState.order, sortingState.field, tableFilter);
          };

          const onDelete = async (evt) => {
            evt.stopPropagation();
            evt.preventDefault();
            const confirm = await modal.confirm({
              title: "Delete Scan",
              icon: <ExclamationCircleOutlined />,
              content: "Are you sure you want to delete the scan?",
              okText: "Delete",
              cancelText: "Cancel",
            });
            if (confirm) {
              await deleteAccountScan({ accId: accountId, scan_id: record.id });
              refreshAccountScans(accountId, paginationState.current, paginationState.pageSize, sortingState.order, sortingState.field, tableFilter);
            } else {
              return;
            }
          };

          return (
            <Flex justify={"space-between"}>
               <Tooltip title={record.enabled ? "Pause" : "Play"}>
                <Button
                  type="link"
                  onClick={onToggleState}
                  icon={
                    record.enabled ? <PauseOutlined /> : <PlayCircleOutlined />
                  }
                />
              </Tooltip>
              <Tooltip title="Clone">
                <Button
                  type="link"
                  onClick={onDuplicate}
                  icon={<FileAddOutlined />}
                />
              </Tooltip>
              <Tooltip title="Delete">
                <Button
                  type="link"
                  onClick={onDelete}
                  icon={<DeleteOutlined />}
                />
              </Tooltip>
            </Flex>
          );
        },
      },
    ];
  };

  async function editScan(scanId, type, successfulScan) {
    setEditScanId(scanId);
    setModalOpenType(type);
    setSuccessfulScan(successfulScan);
  }
  return (
    <div>
      <div className="admin-settings-page-new">
        <Flex align="center" justify="space-between">
          <Form layout="vertical" autoComplete="off">
            <Form.Item name="account" label="Account">
              <Select
                showSearch
                virtual
                allowClear={false}
                menuItemSelectedIcon={null}
                value={[accountId]}
                style={{ width: "215px" }}
                onChange={handleAccountSelect}
                className={"spsg-select-multiple"}
                popupClassName={"spsg-select-multiple-drodown"}
                placeholder="Search account"
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {diagnoseAccount.map((account) => (
                  <Option
                    key={`option-${account.accountId}`}
                    value={account.accountId}
                    title={account.accountName}
                    label={account.accountName}
                  >
                    <span
                      className="spsg-select-dropdown-label"
                      title={account.accountName}
                    >
                      {account.accountName}
                    </span>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Flex>
        <Flex vertical>
          {accountId && (
            <Flex
              align="center"
              gap="middle"
              justify="space-between"
              style={{ padding: "12px 0" }}
            >
              <span>
                The scan summary page provides an overview of all the scans
                created for the respective properties under the selected
                account.
              </span>
              <Button
                style={{ marginBottom: 0 }}
                onClick={() => setModalOpenType("create")}
                type="primary"
              >
                Create New Scan
              </Button>
            </Flex>
          )}
          {!accountId && <Empty description="Select account to view data" />}
          {accountId &&
            (accountScans?.results?.length || isLoadingAccountScans ? (
              <Table
                scroll={{ x: "max-content", y: 630 }}
                columns={DiagnosePropertyScanColumns}
                onRow={(record) => ({
                  onClick: () => handleRowClick(record),
                })}
                expandable={{
                  expandedRowRender: (record) => (
                    <Table
                      onRow={(scanRecord) => ({
                        onClick: () => editScan(scanRecord.id, "edit", scanRecord.successful),
                      })}
                      rowClassName={"pointer"}
                      scroll={{ x: "max-content", y: 630 }}
                      columns={DiagnoseScanSetupColumns(record)}
                      dataSource={record.scans}
                      showSorterTooltip={false}
                      pagination={false}
                      rowKey={(subrecord) => subrecord?.id}
                    />
                  ),
                  expandedRowKeys: expandedRowKeys,
                  onExpand: (expanded, record) => {
                    const keys = expanded ? [record.website_id] : [];
                    setExpandedRowKeys(keys);
                  },
                }}
                dataSource={accountScans?.results}
                showSorterTooltip={false}
                rowKey={(record) => record?.website_id}
                pagination={{
                  position: ["bottomCenter"],
                  showSizeChanger: true,
                  defaultPageSize: 10,
                  current: paginationState.page,   
                  pageSize: paginationState.pageSize,            
                  total:  tableFilter.website_name ?  accountScans?.results.length : accountScans?.count, 
                }}
                loading={{
                  className: "diagnose-spinning",
                  spinning: isLoadingAccountScans,
                  size: "large",
                }}
                onChange={handlePaginationChange}
              />
            ) : (
              <Empty
                description={
                  errorMessage?.message ? (
                    <Typography.Text type="danger">
                      {errorMessage?.message}
                    </Typography.Text>
                  ) : (
                    "No scans"
                  )
                }
              />
            ))}
        </Flex>
        {contextHolder}
      </div>
      {modalOpenType && (
        <DiagnoseScanSetupEditorModal
          accountId={accountId}
          modalOpenType={modalOpenType}
          editScanId={editScanId}
          onClose={() => {
            setEditScanId(null);
            setModalOpenType(null);
          }}
          refreshAccountScans={() => refreshAccountScans(accountId, paginationState.current, paginationState.pageSize, sortingState.order, sortingState.field, tableFilter)}
          successfulScan={successfulScan}
        />
      )}
    </div>
  );
}

export default DiagnoseScanSetup;