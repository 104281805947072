import React, {
  useState,
  useEffect,
  useContext
} from 'react';
import { Select } from "../../../../../../../styleguide";
import { ALL_COUNTRIES_AND_STATES } from "../../../../../../../constants";

import { SitesContext, VendorListsContext } from '../../../../../contexts';

const { Option } = Select;

const permissionsSvcBaseUrl = process.env.REACT_APP_PERMISSIONS_API_URL;

const VendorList = ({
  siteId,
  updateSetting,
  settingKey,
  value,
  messageCategory,
  allSettings,
}) => {
  const { sites, isSiteGroup } = useContext(SitesContext);
  const vLists = useContext(VendorListsContext);

  const [selectedSite, setSelectedSite] = useState(null);
  const [geosMap, setGeosMap] = useState({});

  const siteList = isNaN(siteId) ? [] : [+siteId]; 
  const siteIds = isSiteGroup ? sites.map(({ id }) => id).toJS() : siteList;
  const vendorLists = vLists ? vLists.filter(vl => vl.siteIds.some(id => siteIds.includes(id))) : [] //filtering VLs based on site ID [DIA-2571]

  useEffect(() => {
    if (vendorLists.findIndex(({ id }) => value === id) === -1) {
      vendorLists.length
        ? updateSetting(settingKey, vendorLists[0].id)
        : updateSetting(settingKey, value);
    }
  }, [value, vLists]);

  useEffect(() => {
    (async () => {
      const selectedVendorList = vendorLists.find(({ id }) => id === value) || vendorLists[0];
      setSelectedSite(isSiteGroup ? siteIds.find(id => selectedVendorList.siteIds.includes(id)) : siteId);
      updateSetting(settingKey, selectedVendorList ? selectedVendorList.id : null);
    })();
  }, [siteId, vLists]);

  const sitesToVlsMap = vendorLists.reduce((sitesToVlsMap, vl) => {
    vl.siteIds.forEach(id => {
      if (siteIds.includes(id)) {
        const site = sitesToVlsMap.find(site => site.id === id);
        if (site) {
          if (site.vls.findIndex(({ id }) => id === vl.id) === -1) {
            site.vls.push(vl);
          };
        } else {
          sitesToVlsMap.push({ id, vls: [vl] })
        };
      };
    })
    return sitesToVlsMap;
  }, []);

  const selectSite = (siteId) => {
    const vl = sitesToVlsMap.find(({ id }) => id == siteId)?.vls[0]?.id;
    setSelectedSite(siteId);
    updateSetting(settingKey, vl);
  };

  useEffect(() => {
    const selectedVendorList = vendorLists.find(({ id }) => id === value) || vendorLists[0];
    if (!selectedVendorList) return;

    const newGeosMap = {};

    (selectedVendorList?.appliesGeos || []).forEach(geo => {
      if (geo === 'US') {
        ALL_COUNTRIES_AND_STATES.filter(item => item.region === 'US').forEach((item) => {
          newGeosMap[`US-${item.code}`] = item;
        });
      } else if (geo.slice(0,6) === 'STATE_') {
        return ALL_COUNTRIES_AND_STATES.filter(item => item.region === 'US' && item.code === geo.slice(6)).forEach((item) => {
          newGeosMap[`US-${item.code}`] = item;  
        });
      } else if (geo.slice(0,3) === 'CA_') {
        return ALL_COUNTRIES_AND_STATES.filter(item => item.region === 'CA' && item.code === geo.slice(3)).forEach((item) => {
          newGeosMap[`CA-${item.code}`] = item;
        });
      } else if (geo === 'CA') {
        ALL_COUNTRIES_AND_STATES.filter(item => item.region === 'CA').forEach((item) => {
          newGeosMap[`CA-${item.code}`] = item;
        });
      } else if (geo === 'ALL_EEA') {
        return ALL_COUNTRIES_AND_STATES.filter(item => item.region === 'EEA').forEach((item) => {
          newGeosMap[item.code] = item;
        });
      } else if (geo === 'ALL_OTHER') {
        return ALL_COUNTRIES_AND_STATES.filter(item => !['US', 'CA', 'EEA'].includes(item.region)).forEach((item) => {
          newGeosMap[item.code] = item;
        });
      } else {
        return ALL_COUNTRIES_AND_STATES.filter(item => item.code === geo && item.type === 'country').forEach((item) => {
          newGeosMap[item.code] = item;
        });
      }
    });
    setGeosMap(newGeosMap);
  }, [value]);

  return (
    <div className="four-options">
      {isSiteGroup && (
        <React.Fragment>
          <h4>Site</h4>
          <div className="option-inputs">
            <Select
              style={{ width: '100%' }}
              value={selectedSite}
              onChange={selectSite}
              disabled={!vendorLists.length}
            >
              {(sites.map(({ id }) => (
                <Option key={id} value={id}>
                  {sites.find(site => site.id === id).domain}
                </Option>
              )))}
            </Select>
          </div>
        </React.Fragment>
      )}
      <h4>{messageCategory === 'usnat' ? 'Regulation Preview' : 'Vendor List Preview'}</h4>
      <div className="option-inputs">
        <Select
          style={{ width: '100%' }}
          value={value}
          onChange={(vl) => updateSetting(settingKey, vl)}
          disabled={!sitesToVlsMap.length || !sitesToVlsMap.find(({ id }) => id == selectedSite)?.vls?.length}
        >
          {sitesToVlsMap.length && sitesToVlsMap.find(({ id }) => id == selectedSite)?.vls?.map((vl) => (
            <Option key={vl.id} value={vl.id}>
              {vl.name}
            </Option>
          ))}
        </Select>
      </div>
      {messageCategory === 'usnat' && (
        <div>
          <br />
          <h4>Location Preview</h4>
          <div className="option-inputs">
            <Select
              style={{ width: '100%' }}
              value={allSettings.previewGeo || ''}
              onChange={(vl) => updateSetting('previewGeo', vl)}
              disabled={!sitesToVlsMap.length || !sitesToVlsMap.find(({ id }) => id == selectedSite)?.vls?.length}
            >
              <Option key='no override' value=''>Current Location</Option>
              {Object.entries(geosMap).map(([key, item]) => {
                return (
                  <Option key={key} value={key}>
                    {item.name}
                  </Option>
                );
              })}
            </Select>
          </div>
        </div>
      )}
    </div>
  );
};

export default VendorList;