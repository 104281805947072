import React, { useState } from "react";
import {AddEditDelete } from "../../../../../../styleguide";
import classNames from "classnames";
import { SystemPurpose } from "../../../../../../records/us_privacy_regulation_records";
import _ from "lodash";
import { Spin, Button, Table, Alert, Flex } from "antd";
import { findNthTermInAnEntityList } from "../../../../../utils";

const CustomChoicesTable = (props) => {
  const [tableRefresh, setTableRefresh] = useState(false);
  const [tempRegulationToBeAdded, setTempRegulationToBeAdded] = useState(null);
  const [showInfoNoteOnNewChoiceAdd, setShowInfoNoteOnNewChoiceAdd] = useState(false);

  const saveCustomChoice = (id, updatedLabel) => {
    let updatedRegulationCategories = _.cloneDeep(props.regulationCategories);
    if(updatedLabel === null){
      updatedRegulationCategories = updatedRegulationCategories.filter(cat => cat.id != id)
      props.showAlertIconForNextSteps();
    } else {
      if(tempRegulationToBeAdded){
        tempRegulationToBeAdded.privacyChoice = updatedLabel?.trim();
        updatedRegulationCategories.unshift(tempRegulationToBeAdded);
        setShowInfoNoteOnNewChoiceAdd(true)
        props.showAlertIconForNextSteps();
      } else {
        updatedRegulationCategories = updatedRegulationCategories.map( cat => {
          if(cat.id == id) {
            cat.privacyChoice = updatedLabel;
          }
          return cat;
        })
      }
    }
    props.setRegulationCategories(updatedRegulationCategories);
    setTempRegulationToBeAdded(null);
  };

  const validateCustomChoice = (newCustomChoiceName, oldCustomChoiceName) => {
    //returns truthy if invalid
    const trimmedValue = newCustomChoiceName.trim()
    const duplicateName = props.regulationCategories.map(cat => cat.privacyChoice).find(cat => cat === trimmedValue);
    if(trimmedValue == ""){
      return "You cannot save this as empty"
    } else if(duplicateName && duplicateName !== oldCustomChoiceName) {
      return "This Privacy choice name already exists in this Regulation"
    }
    return false;
  }

   const columns = [
    {
      title: <><div>Choice Name</div><div>Actions</div></>,
      dataIndex: 'privacyChoice',
      width:'100%',
      className: 'choice-name',
      render: (privacyChoice, record) => {
        return (
        <AddEditDelete 
          validate={(newCustomChoiceName) => validateCustomChoice(newCustomChoiceName, privacyChoice)}
          value={privacyChoice}
          handleSave={(updatedLabel) => saveCustomChoice(record.id, updatedLabel)}
          handleCancelEdit={() => setTempRegulationToBeAdded(null)}
          entityName="Privacy Choice"
          suffixNode={null}
          openInEditMode={tempRegulationToBeAdded?.id == record.id}
          spaceBetween
          plainText
        />
        )
      }
    },
  ]

  const customCategories = props.regulationCategories.filter(cat => cat.type === "CUSTOM" && cat.defaultLegalBasis == props.defaultLegalBasis);
  let dataSource = customCategories;
  if(tempRegulationToBeAdded){
    dataSource.unshift(tempRegulationToBeAdded)
  }

  function generateRandom10DigitNumber() {
    const min = 1000000000; // Minimum 10-digit number
    const max = 9999999999; // Maximum 10-digit number
    // Generate a random number between min and max (inclusive)
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNum;
  }

  const addNewCustomPrivacyChoice = (defaultLegalBasis) => {
    let updatedRegulationCategories = _.cloneDeep(props.regulationCategories);
    let updatedCategoryLabels = updatedRegulationCategories?.map(cat => cat.privacyChoice)
    if(tempRegulationToBeAdded){
      updatedCategoryLabels.unshift(tempRegulationToBeAdded.privacyChoice);
    }
    let NthTerm = findNthTermInAnEntityList(updatedCategoryLabels, "Custom Privacy Choice ");
    let newCustomCategory = new SystemPurpose({privacyChoice : NthTerm, _id: `custom_${generateRandom10DigitNumber()}`, defaultLegalBasis, type : "CUSTOM"})
    setTempRegulationToBeAdded(newCustomCategory?.toJS());
    setTableRefresh(true);
    setTimeout(()=>{setTableRefresh(false)}, 100)
  }

  
  return (
    <Flex vertical gap={8} className="opt-container">
      <div className="heading">
        <div>Custom Choices</div>
        <Button
          type="primary"
          onClick={() => addNewCustomPrivacyChoice(props.defaultLegalBasis)}
          disabled={Boolean(tempRegulationToBeAdded)}
        >
          + Add
        </Button>
      </div>
      {showInfoNoteOnNewChoiceAdd && customCategories?.length ? <Alert type="warning" showIcon message={<>It is necessary to add <b>Choice text</b> of <b>English (Default)</b> language in <b>Language & Translation</b> section for newly added custom choices.</>}/> : null}
      <div
        className={classNames(dataSource.length ? null : "empty-custom-choices")}
        style={{marginTop: '5px'}}
      >
        {tableRefresh ? <Spin/> : 
        <>
        {dataSource.length ? 
          <Table
            // className={"usnat-custom-choices-table"}
            pagination={false}
            rowKey={(record) => `${record.id}`}
            dataSource={dataSource}
            columns={columns}
            tableLayout="fixed"
          />
          : (
            <div>
              <div style={{fontWeight:'700'}}>No Custom Choices added</div>
              <div>To add choices, click on <b>+ Add</b> button</div>
            </div>
          )
        }
        </>}
      </div>
    </Flex>
  );
};

export default CustomChoicesTable;
