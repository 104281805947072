/* eslint-disable react/prop-types */
import { Collapse, Empty, Image, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { summaryAppLoaded,setMetricsList } from '../../../actions/app_dashboard_actions';
import ComplianceMetricsApp from './ComplianceMetricsApp';
import { mapAppSummaryToMetricsList } from './details_pages/mapSummaryToMetricsList';

const AppMetricsDashboard = ({
  summaryAppDiagnose,
  summaryAppPending,
  summaryAppLoaded,
}) => {
  return (
    <div>
      <Spin
        spinning={summaryAppPending}
        className="diagnose-spinning"
        tip="Processing a large query; please wait or leave it open and check later."
        size="large"
      >
        {Object.keys(summaryAppDiagnose).length>0 ? (
          <ComplianceMetricsApp resetSummary={summaryAppLoaded} metricsList={mapAppSummaryToMetricsList(summaryAppDiagnose)} />
        ) : (
            <Empty />
          )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryAppDiagnose: store.appDashboardState.getIn(['summaryAppDiagnose', 'value']),
    selectedMetrics: store.appDashboardState.getIn(['selectedMetrics', 'value']),
    metricsList: store.diagnoseDashboardState.getIn(['metricsList', 'value']),
    summaryAppPending: store.appDashboardState.get('summaryAppPending'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      summaryAppLoaded,
      setMetricsList,
    },
    dispatch,
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppMetricsDashboard);
