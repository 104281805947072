import {
  getAppSummaryData,
  getVendorsPriorToConsentListData,
  getVendorsOptOutData,
  getAllVendorsFoundData,
  getVendorAfterRejectAllData,
} from '../api/diagnose_dashboard/app_dashboard';

import * as types from './action_types';
import { actionCreator } from './helpers';


const summaryAppPending = () => ({
  type: types.SUMMARY_APP_PENDING,
});

export const summaryAppLoaded = (summaryDiagnose) => ({
  type: types.SUMMARY_APP_LOADED,
  summaryDiagnose: summaryDiagnose.metrics || {},
});

const summaryAppError = (errorMessage) => ({
  type: types.SUMMARY_APP_ERROR,
  errorMessage,
});


export const getAppSummary = actionCreator(
  getAppSummaryData,
  summaryAppPending,
  summaryAppLoaded,
  summaryAppError,
);

const vendorsPriorToConsentListPending = () => ({
  type: types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_PENDING,
});

const vendorsPriorToConsentListLoaded = (vendorsPriorToConsentList) => ({
  type: types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_LOADED,
  vendorsPriorToConsentList,
});

const vendorsPriorToConsentListError = (errorMessage) => ({
  type: types.APP_VENDORS_PRIOR_TO_CONSENT_LIST_ERROR,
  errorMessage,
});

const vendorsOptOutListPending = () => ({
  type: types.APP_VENDORS_OPT_OUT_LIST_PENDING,
});

const vendorsOptOutListLoaded = (vendorsOptOutList) => ({
  type: types.APP_VENDORS_OPT_OUT_LIST_LOADED,
  vendorsOptOutList: vendorsOptOutList?.vendors,
});

const vendorsOptOutListError = (errorMessage) => ({
  type: types.APP_VENDORS_OPT_OUT_LIST_ERROR,
  errorMessage,
});

const vendorsAllFoundListPending = () => ({
  type: types.APP_ALL_VENDORS_FOUND_PENDING,
});

const vendorsAllFoundListLoaded = (allVendorsFound) => ({
  type: types.APP_ALL_VENDORS_FOUND_LOADED,
  allVendorsFound,
});

const vendorsAllFoundListError = (errorMessage) => ({
  type: types.APP_ALL_VENDORS_FOUND_ERROR,
  errorMessage,
});

const vendorsAfterRejectAllPending = () => ({
  type: types.APP_VENDORS_REJECT_ALL_PENDING,
});

const vendorsAfterRejectAllLoaded = (vendorsAfterRejectAll) => ({
  type: types.APP_VENDORS_REJECT_ALL_LOADED,
  vendorsAfterRejectAll,
});

const vendorsAfterRejectAllError = (errorMessage) => ({
  type: types.APP_VENDORS_REJECT_ALL_ERROR,
  errorMessage,
});

export function setAvailableMetrics(metricsList) {
  return {
    type: types.SET_AVAILABLE_METRICS_APP,
    metricsList,
  };
}

export function setMetricsList(selectedMetrics) {
  return {
    type: types.SET_METRICS_LIST_APP,
    selectedMetrics,
  };
}

export const getVendorsPriorToConsentList = actionCreator(
  getVendorsPriorToConsentListData,
  vendorsPriorToConsentListPending,
  vendorsPriorToConsentListLoaded,
  vendorsPriorToConsentListError,
);

export const getVendorsAfterOptOut = actionCreator(
  getVendorsOptOutData,
  vendorsOptOutListPending,
  vendorsOptOutListLoaded,
  vendorsOptOutListError,
);

export const getAllVendorsFoundList = actionCreator(
  getAllVendorsFoundData,
  vendorsAllFoundListPending,
  vendorsAllFoundListLoaded,
  vendorsAllFoundListError,
);

export const getVendorsAfterRejectList = actionCreator(
  getVendorAfterRejectAllData,
  vendorsAfterRejectAllPending,
  vendorsAfterRejectAllLoaded,
  vendorsAfterRejectAllError,
);