import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tabs, Modal, Select, Input, Form,theme,message } from 'antd';
import { PlusOutlined, UploadOutlined} from '@ant-design/icons';
import { createDiagnoseProperty, importDiagnoseProperties } from '../../../actions/diagnose_property.js';

const { TabPane } = Tabs;

const AddNewDiagnoseProperty = (props) => {
  const {
    showAddDiagnosePropertyModal,
    handleCancel,
    refreshPropertyList,
  } = props;

  const { token } = theme.useToken()
  const [form] = Form.useForm();
  const [currentTab, setCurrentTab] = useState("1")

  const onChange = (key) => {
    setCurrentTab(key)
  };

  const handleAddDiagnoseProperty = async () => {
    form
      .validateFields()
      .then(async (values) => {
        try {
          const accountId = props?.userDetails.get('accountId')
          const resp = await props.createDiagnoseProperty(values?.name,accountId);
          if (resp?.data) {
            message.success(
              <>
                Property <b>{resp.data?.diagnose_website_name}</b> has been successfully created
              </>
            );
            refreshPropertyList()
          }
        } catch (error) {
          // Handle error if needed
        }
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
    handleCancel()
  }

  const handleImportDiagnoseProperty = async () => {
    form
    .validateFields()
    .then(async (values) => {
      const propertyIds = values?.name;
      const propertyList = props?.sites?.toJS().filter(site => propertyIds.includes(site.id))
      const payloadPropertyList = propertyList?.map(site => ({
        property_id: site.id,
        property_domain: site.domain
      }))
      try {
        const accountId = props?.userDetails.get('accountId')
        const resp = await props.importDiagnoseProperties(payloadPropertyList,accountId);
        if (resp?.diagnose) {
          message.success(
            <>
              <b>{resp.diagnose?.property_created} properties</b> have been successfully created
            </>
          );
          refreshPropertyList()
        }
      } catch (error) {
        // Handle error if needed
      }
      handleCancel()
    }

    )

  }

  return (
    <Modal
      title={"Add new property"}
      open={showAddDiagnosePropertyModal}
      width={"720px"}
      className='add-property-modal'
      closable={true}
      destroyOnClose={true}
      onCancel={handleCancel}
      onOk={() => currentTab === "1"? handleAddDiagnoseProperty(): handleImportDiagnoseProperty()}
      okText={"Save"}
      cancelButtonProps={{ type: "link" }}
    >

    <Tabs
      activeKey={currentTab}
      onChange={onChange}
      defaultActiveKey="1"
      destroyInactiveTabPane={true}
      >
        <TabPane icon={<PlusOutlined />} tab="Add new Diagnose property" key="1">
         <Form
          layout={"vertical"}
          form={form}
          initialValues={{ name: '', type: 'App' }}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter the name' }]} >
            <Input placeholder="Enter Property Name" />
          </Form.Item>
          <Form.Item name="type" label="Type">
            <Input disabled value="App" placeholder="input placeholder" />
          </Form.Item>
        </Form>
         </TabPane>
         <TabPane icon={<UploadOutlined/>} tab="Import a Dialogue property" key="2">
         <Form
        layout={"vertical"}
        form={form}
        initialValues={{ names: [], type: 'App' }}
      >
      <Form.Item label="Name" name="names" placeholder="Select Property" >
        <Select mode="multiple">
          {props.sites.filter(site => site.type === "app").toJS().map(s => {
            return (<Select.Option value={s.id} key={s.id}>{s.domain}</Select.Option>)
          })}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Type">
          <Input disabled value="App" placeholder="input placeholder" />
        </Form.Item>
      </Form>
         </TabPane>
      </Tabs>
    </Modal>
  )
}

const mapStateToProps = function (store) {
  return {
    sites: store.siteState.getIn(['sites', 'value']),
    userDetails: store.accountState.getIn(['userDetails', 'value']),
  };
};

export default connect(mapStateToProps,{
  createDiagnoseProperty,
  importDiagnoseProperties,
})(AddNewDiagnoseProperty);
