import React, { useState, useEffect } from 'react';
import { COUNTRY_LANGUAGES } from '../../../../../../constants';
import { Select, Space, Typography, Modal, Button } from 'antd';
import { Toggle } from '../../../../../../styleguide';

const PreviewModal = (props) => {
  const { closeModal, regulationCategories, selectedLang, isIdentificationListVisible, isStateFlow } = props;
  const [currentTab, setCurrentTab] = useState('descriptions');
  const [isActive, setIsActive] = useState(props.isFirstActive);
  const [selectedLanguage, setLanguage] = useState("EN")

  let languageOptions = COUNTRY_LANGUAGES.toJS().filter((c => selectedLang?.includes(c.code))).map((l, i) => ({
    label: l.language,
    value: l.code,
  }));

  const changeTab = (value) => {
    setCurrentTab(value)
    setIsActive(!isActive)
  }

  const handleSelectedLanguage = (value) => {
    setLanguage(value)
  }

  const getPrivacyChoiceText = (category,isSubCat) => {
    const sentiment = category.isNegative;
    let status = null;
    if (isStateFlow) {
      status = category?.isNegative;
    } else {
      status =
        category?.defaultLegalBasis === "CONSENT"
          ? category?.isNegative
          : !category?.isNegative;
    }
    const translations = (sentiment) ? category.negativeTranslations.names : category.translations.names;
    const language = selectedLanguage.toLowerCase()
    const translationLanguage = translations[language]?.trim();
    const englishTranslation = translations["en"]?.trim();
    const privacyChoiceText = (translationLanguage === "No Translations found" || translationLanguage === "") ? englishTranslation : translationLanguage
    if (privacyChoiceText) {
      return (
        <div className={`bullet-container ${currentTab === "names" ? 'selected' : 'not-selected'}`}>
          <div style={{ flex: 1 }}>{privacyChoiceText}</div>
          {!isSubCat && (<Toggle checked={status} />)}
        </div>
      )
    }
  }

  const convertTextToLink = (text,link) => {
    // Use a regular expression to find and replace text in square brackets with links
    const updatedText = text.replace(/\[(.*?)\]/g, (match, linkText) => {
      // Create the link using the matched text
      return `<a target="_blank" href="${link}">${linkText}</a>`;
    });

    return { __html: updatedText };
  }

  const getPrivacyChoiceLink = (category) => {
    if (category.privacyPolicySectionLink) {
      const privacyPolicyTranslations = category.translations?.privacyPolicySectionText;
      const language = selectedLanguage.toLowerCase()
      const englishTranslation = privacyPolicyTranslations["en"]?.trim();
      const privacyPolicyTranslation = (privacyPolicyTranslations[language] === "No Translations found" || privacyPolicyTranslations[language] === "") ? englishTranslation : privacyPolicyTranslations[language] 
      if (privacyPolicyTranslation) {
        const updatedText = convertTextToLink(privacyPolicyTranslation, category.privacyPolicySectionLink);
        return (
          <div dangerouslySetInnerHTML={updatedText}></div>
        )
      }
    }
  }

  const getIdentificationList = () => {
    const systemCategories = regulationCategories.filter(cat => (cat.defaultLegalBasis === "OPT-OUT" || cat.defaultLegalBasis === "OPT_OUT") && cat.type !== "CUSTOM");
    const language = selectedLanguage.toLowerCase()
    const isLastIndex = systemCategories.length - 1;
    const identificationList = systemCategories[isLastIndex]?.translations?.identificationList;
    const showIdentificationList = (identificationList) ? ((identificationList[language] === "No Translations found" || identificationList[language] === "") ? identificationList["en"] : identificationList[language]): (systemCategories[isLastIndex]?.identificationList)? systemCategories[isLastIndex]?.identificationList: null
    if (showIdentificationList && isIdentificationListVisible) {
      return (
        <div dangerouslySetInnerHTML={{ __html: showIdentificationList }}></div>
      )
    }
  }

  const getDescriptionText = (category) => {
    const sentiment = category.isNegative;
    const translations = (sentiment) ? category.negativeTranslations.descriptions : category.translations.descriptions;
    const language = selectedLanguage.toLowerCase()
    const translationLanguage = translations[language]?.trim();
    const englishTranslation = translations["en"]?.trim();
    const showDescription = (translationLanguage === "No Translations found" || translationLanguage === "") ? (englishTranslation !== "" ? englishTranslation : null) : translationLanguage
    if (showDescription) {
      return(<div>{showDescription}</div>)
    }
  }

  const regulationSortedData = regulationCategories.sort((a, b) => {
    // If defaultLegalBasis is the same, maintain the original order
    if (a.defaultLegalBasis === b.defaultLegalBasis) {
      return 0;
    }
    // 'OPT_In' comes before 'OPT_Out'
    if (a.defaultLegalBasis === 'CONSENT') {
      return -1;
    }
    // 'OPT_Out' comes after 'OPT_In'
    if (a.defaultLegalBasis === 'OPT-OUT') {
      return 1;
    }
  });

  return (
    <Modal
      wrapClassName={`preview-modal`}
      open={true}
      visible={true}
      footer={(
        <Space align='center'>
          <Typography.Text>Language:</Typography.Text>
          <Select
            options={languageOptions}
            onChange={(value) =>
              handleSelectedLanguage(value)
            }
            value={selectedLanguage}
          />
        </Space>
      )}
      onCancel={closeModal}
      title={"Preview"}
      width={"730px"}
    >
      <p className="sub-title">This is a preview of how your message will look for the configuration you have set up so far in the regulation setting. You can select one of the options below to see the corresponding highlighted information in the message preview and change the language in the dropdown field at the bottom to visualize the message in different languages.</p>
      <div className="section-tabs">
        <Button className={currentTab === "descriptions" ? 'active' : 'inactive'} onClick={() => changeTab("descriptions")} type="primary" >Description</Button>
        <Button className={currentTab === "names" ? 'active' : 'inactive'} onClick={() => changeTab("names")} type="primary" >Choice text</Button>
        {!isStateFlow ? (<Button className={currentTab === "privacyPolicySectionText" ? 'active' : 'inactive'} onClick={() => changeTab("privacyPolicySectionText")} >Privacy Policy Section Link</Button>): null}
        {isIdentificationListVisible ? (<Button className={currentTab === "identificationList" ? 'active' : 'inactive'} onClick={() => changeTab("identificationList")} >Identification list</Button>) : null}
      </div>
      <div className="msg-container">
        {
          regulationSortedData.map(reg => {
            const subCategories = reg.categories;
            const description = getDescriptionText(reg)
            return (
              <>
                {description && (<div className={`desc-container ${currentTab === "descriptions" ? 'selected' : 'not-selected'}`}>
                  {description}
                </div>)}
                <div className={`privacy-container`}>
                  {subCategories.length > 0 && (
                    <ul>
                      {reg.categories.map(c => {
                         return getPrivacyChoiceText(c,true)
                      })}
                    </ul>
                  )}
                  {getPrivacyChoiceText(reg,false)}
                </div>
                <div className={`privacy-link ${currentTab === "privacyPolicySectionText" ? 'selected' : 'not-selected'}`}>
                    {getPrivacyChoiceLink(reg)}
                </div>
              </>
            )
          })
        }
        <div className={`privacy-link ${currentTab === "identificationList" ? 'selected' : 'not-selected'}`}>
          {getIdentificationList()}
        </div>
      </div>
    </Modal>
  )
}

export default PreviewModal;