import { Map, List } from "immutable";
import PropTypes from "prop-types";
import ImmutablePropTypes from "react-immutable-proptypes";
import React, { useState, useEffect } from "react";
import { DeleteOutlined, PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";
import { Select, Table, Switch, Button, Checkbox, Radio } from "antd";
import {
  changeUserAccess as changeUserAccessAction,
  deleteUser as deleteUserAction,
} from "../../../actions/account_actions";
import { getSiteGroups as getSiteGroupsAction } from "../../../actions/site_actions";
import { useActions, usePrevious } from "../../../hooks";
import WarningMessage from "../../common/WarningMessage.js.jsx";
import { Account, User } from "../../../records/account_records";
import { Site, SiteGroup } from '../../../records/site_records';
import FeatureAccessDropdown from './FeatureAccessDropdownNew.jsx';
import { accountHasPrivacyLens } from '../../../util';
import SelectPropertiesPermissions from "./SelectPropertiesPermissions.jsx";

const privacyLensFeatures = ["Privacy Lens", "Privacy Lens - Advanced", "Privacy Lens - Basic", "Privacy Lens - Read Only"];

const ManageUsersTable = (props) => {
  const {
    userFilter,
    editingUserId,
    setEditingUserId,
    currentUser,
    sites,
    siteGroups,
    permissionsList,
    getAllAccounts,
    getAllSites,
    diagnoseProperties,
    allUsers,
    allAccounts,
  } = props;

  const [usersTableState, setUsersTableState] = useState({
    hoveredRowUserId: null,
    editingUserSiteAccess: null,
    editingUserDiagnoseAccess:null,
    editingUserFeatureAccess: null,
    editingUserAccountAccess: null,
    editingUserIsAdmin: null,
    warningMessagePresent: false,
  });

  const [privacyLensOpen, setPrivacyLensOpen] = useState(false);
  const [privacyLensFeature, setPrivacyLensFeature] = useState(null);


  const changeUserAccessDispatch = useActions(changeUserAccessAction);
  const deleteUser = useActions(deleteUserAction);
  const getSiteGroups = useActions(getSiteGroupsAction);

  const prevCurrentUser = usePrevious(currentUser);
  const hasDiagnoseDashboardPermission = currentUser.accountFeatures.includes("diagnose_dashboard")

  useEffect(() => {
    if (!prevCurrentUser && currentUser) {
      getAllSites({ allSites: true });
      getSiteGroups(currentUser.accountId);
      if (currentUser.accountId === 22 || currentUser.accountId === 375) {
        getAllAccounts();
      }
    }
  });

  const handleSelectSites = (siteIds) => {
    setUsersTableState((usersTableData)=>({
     ...usersTableData,
      editingUserSiteAccess: List(siteIds),
    }));
  };

  const siteAccessWithoutDeletedSites = (ids) => {
    return ids.filter((id) => sites.map((s) => s.id).includes(id));
  };

  const getSiteDomain = (id) => {
    return sites.find((s) => s.id === id).domain;
  };

  const setSelectedFeatures = (features) => {
    setUsersTableState((usersTableData)=>({
      ...usersTableData,
       editingUserFeatureAccess: features,
     }));
  };

  const handleDiagnoseSites = (sites) => {
    setUsersTableState((usersTableData)=>({
     ...usersTableData,
      editingUserDiagnoseAccess: List(sites),
    }));
  };

  const handleSelectAccounts = (accountIds) => {
    setUsersTableState((usersTableData)=>({
     ...usersTableData,
      editingUserAccountAccess: List(accountIds),
    }));
  };

  const handleDelete = (userId) => {
    setEditingUserId(null);
    setUsersTableState((usersTableData)=>({...usersTableData, warningMessagePresent: false }));
    deleteUser(userId);
  };

  const toggleWarningMessage = (e) => {
    e.stopPropagation();
    setUsersTableState((usersTableData)=>({
     ...usersTableData,
      warningMessagePresent: !usersTableState.warningMessagePresent,
    }));
  };

  const cancelEditing = () => {
    setEditingUserId(null);
  };

  const changeUserAccess = (u) => {
    if (usersTableState.editingUserSiteAccess.includes("all access")) {
      u = u.set("siteAccess", "all access");
    } else {
      u = u.set("siteAccess", usersTableState.editingUserSiteAccess);
    }

    if (usersTableState.editingUserFeatureAccess.includes("All access")) {
      u = u.set("featureAccess", "all access");
    } else {
      u = u.set(
        "featureAccess",
        usersTableState.editingUserFeatureAccess.map((pemissionName) => {
          return permissionsList.find((p) => p.name === pemissionName);
          //filtering out Privacy Lens
        }).filter(p => !!p),
      );
    }

    if (usersTableState.editingUserDiagnoseAccess?.includes("All access")) {
      u = u.set("diagnoseAccess", "all access");
    } else {
      u = u.set(
        "diagnoseAccess",
        usersTableState.editingUserDiagnoseAccess?.map((diaPropName) => {
          return diagnoseProperties.find((p) => p.name === diaPropName)?._id;
        })
      );
    }

    if (usersTableState.editingUserAccountAccess.includes("All access")) {
      u = u.set("accountAccess", "all access");
    } else {
      u = u.set("accountAccess", usersTableState.editingUserAccountAccess);
    }

    u = u.set("isAdmin", usersTableState.editingUserIsAdmin);
    changeUserAccessDispatch(u, currentUser.id).then((r) => {
      cancelEditing();
    });
  };

  const getDiagnoseSiteNames = (diagnoseAccess) => {
    return  diagnoseAccess?.map((id)=>diagnoseProperties?.find(p=>p._id === id)?.name)
  };

  const setEditing = (user) => {
    let editingUserSiteAccess = List(["all access"]);
    let editingUserDiagnoseAccess = List(["All access"]);
    let editingUserFeatureAccess = List(["All access"]);
    let editingUserAccountAccess = List(["All access"]);
    if (user.siteAccess) {
      editingUserSiteAccess = siteAccessWithoutDeletedSites(user.siteAccess);
    }
    if (user.featureAccess) {
      editingUserFeatureAccess = user.featureAccess;
    }
    if (user.accountAccess) {
      editingUserAccountAccess = user.accountAccess;
    }
    if(user.diagnoseAccess){
      editingUserDiagnoseAccess = getDiagnoseSiteNames(user.diagnoseAccess)
    }
    setUsersTableState((usersTableData)=>({
     ...usersTableData,
      editingUserSiteAccess,
      editingUserDiagnoseAccess,
      editingUserFeatureAccess,
      editingUserAccountAccess,
      editingUserIsAdmin: user.isAdmin,
    }));
    setEditingUserId(user.id);
  };
  
  const generateDataSource = () => {
    let users = allUsers.sort((u1, u2) => {
      return u1.id === currentUser.id ? -1 : u2.id === currentUser.id ? 1 : 0;
    });

    if (userFilter) {
      users = users.filter((u) => {
        return (
          u.name.toLowerCase().includes(userFilter.toLowerCase()) ||
          u.email.toLowerCase().includes(userFilter.toLowerCase())
        );
      });
    }
  
    const dataSource = users.map((u) => {
      let domains = "";
      if (editingUserId === u.id) {
        domains = (
          <SelectPropertiesPermissions
            value={usersTableState.editingUserSiteAccess?.toJS() || []}
            sites={sites}
            siteGroups={siteGroups}
            placeholder="Select sites this user will be granted acceess to..."
            onChange={handleSelectSites}
          />
        );
      } else {
        if (!u.siteAccess) {
          domains = <p className="all-access">Access to all properties</p>;
        } else {
          const accessWithoutDeletedSites = siteAccessWithoutDeletedSites(
            u.siteAccess
          );
          domains = (
            <div className="sites-container">
              {accessWithoutDeletedSites.slice(0, 2).map((id) => (
                <div className="site">{getSiteDomain(id)}</div>
              ))}
              {accessWithoutDeletedSites.size > 2 ? (
                <div className="more">{`+ ${
                  accessWithoutDeletedSites.size - 2
                } more`}</div>
              ) : null}
            </div>
          );
        }
      }
    
      let features = "";
      if (editingUserId === u.id) {
        features = <FeatureAccessDropdown 
          setSelectedFeatures={setSelectedFeatures}
          permissionsList={permissionsList}
          editing
          userFeatureAccess={usersTableState.editingUserFeatureAccess}
          hasPrivacyLens={accountHasPrivacyLens(currentUser.accountFeatures)}
        />
      } else {
        features = <FeatureAccessDropdown 
          setSelectedFeatures={setSelectedFeatures}
          permissionsList={permissionsList}
          userFeatureAccess={u.featureAccess}
        />
      }

      let diagnose = ""
      if(editingUserId === u.id){
        diagnose = (
          <Select
            value={
              (usersTableState.editingUserDiagnoseAccess &&
                usersTableState.editingUserDiagnoseAccess.toJS()) ||
              []
            }
            onChange={handleDiagnoseSites}
            mode="multiple"
            tokenSeparators={[","]}
            placeholder="Select diagnose sites this user will be granted acceess to..."
          >
            <Select.Option key="All access">All access</Select.Option>
            {diagnoseProperties && diagnoseProperties?.map((p) => {
              return <Select.Option key={p.name}>{p.name}</Select.Option>;
            })}
          </Select>
        );
      }else {
        if (!u.diagnoseAccess) {
          diagnose = <p className="all-access">All access</p>;
        } else {
          u = u.set("diagnoseAccess",u.diagnoseAccess)
          const diagnoseAcessNames = getDiagnoseSiteNames(u.diagnoseAccess)
          diagnoseAcessNames?.size > 0?diagnose = (
            <div className="sites-container">
              <div className="site">{diagnoseAcessNames?.slice(0, 1)}</div>
              {diagnoseAcessNames?.size > 1 ? (
                <div className="more">{`+ ${
                  diagnoseAcessNames?.size - 1
                } more`}</div>
              ) : null}
            </div>
          ):null;
        }
      }

      let accounts = "";
      if (editingUserId === u.id) {
        accounts = (
          <Select
            value={
              (usersTableState.editingUserAccountAccess &&
                usersTableState.editingUserAccountAccess
                  .toJS()
                  .map((val) => val.toString())) ||
              []
            }
            onChange={handleSelectAccounts}
            mode="multiple"
            tokenSeparators={[","]}
            placeholder="Select accounts this user will be able to masq into..."
            filterOption={(input, option) => {
              if (!option.children) return false;
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            <Select.Option key="All access">All access</Select.Option>
            {allAccounts.map((account) => {
              return (
                <Select.Option
                  key={account.accountId.toString()}
                  value={account.accountId.toString()}
                >
                  {account.accountName}
                </Select.Option>
              );
            })}
          </Select>
        );
      } else {
        if (!u.accountAccess) {
          accounts = <p className="all-access">All access</p>;
        } else {
          accounts = (
            <div className="sites-container">
              {u.accountAccess.slice(0, 1).map((accountId) => {
                const account = allAccounts.find((a) => {
                  return a.accountId === accountId;
                });
                if (account){
                  const accountName = account.accountName;
                  return <div className="site">{accountName}</div>;
                }
              })}
              {u.accountAccess.size > 1 ? (
                <div className="more">{`+ ${
                  u.accountAccess.size - 1
                } more`}</div>
              ) : null}
            </div>
          );
        }
      }

      const isEditing = editingUserId === u.id;

      let edit = "";
      if (isEditing) {
        let warningMessage;
        if (usersTableState.warningMessagePresent) {
          warningMessage = (
            <WarningMessage
              handleDelete={() => handleDelete(u.id)}
              name={u.name}
              handleCancel={toggleWarningMessage}
            />
          );
        }
        let deleteIcon;
        if (editingUserId !== currentUser.id) {
          deleteIcon = <DeleteOutlined onClick={toggleWarningMessage} />;
        }
        edit = (
          <div className="edit-buttons">
            {deleteIcon}
            <Button className="cancel" onClick={cancelEditing}>
              Cancel
            </Button>
            <Button
              className="save"
              onClick={() => changeUserAccess(u)}
              type="primary"
            >
              Save
            </Button>
            {warningMessage}
          </div>
        );
      } else if (
        usersTableState.hoveredRowUserId === u.id &&
        !editingUserId
      ) {
        edit = (
          <div className="edit-button-container">
            <Button onClick={() => setEditing(u)}>edit</Button>
          </div>
        );
      }

      const admin = (
        <Switch
          checked={isEditing ? usersTableState.editingUserIsAdmin : u.isAdmin}
          onChange={() =>
            setUsersTableState((usersTableData)=>({
             ...usersTableData,
              editingUserIsAdmin: !usersTableState.editingUserIsAdmin,
            }))
          }
          disabled={u.id !== editingUserId || u.id === currentUser.id}
        />
      );

      if (u.name === "Kate (Test) Bennett") {
        console.log("features: ", features);
      }

      return Map({
        name: (
          <div>
            <p className="user-name">{u.name}</p>
            <p className="user-email">{u.email}</p>
          </div>
        ),
        domains,
        diagnose,
        features,
        accounts,
        admin,
        edit,
        key: u.id,
      });
    });

    return dataSource;
  };

  const generateColumns = () => {
    let columns = List([
      Map({
        title: "Name",
        dataIndex: "name",
        key: "name",
        width: 250,
      }),
      Map({
        title: "Dialogue Properties",
        dataIndex: "domains",
        key: "domains",
        width: 300,
        render: (text) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>
            {text}
          </div>
        ),
      }),
      Map({
        title: "Diagnose Properties",
        dataIndex: "diagnose",
        key: "diagnose",
        width: 250,
      }),
      Map({
        title: "Permissions",
        dataIndex: "features",
        key: "features",
        width: 300,
      }),
    ]);

    if (currentUser.accountId === 22) {
      columns = columns.push(
        Map({
          title: "Account Access",
          dataIndex: "accounts",
          key: "accounts",
          width: 300,
        })
      );
    }

    columns = columns.concat(
      List([
        Map({
          title: "Admin access",
          dataIndex: "admin",
          key: "admin",
          sorter: (a, b) => {
            const aAdmin = a.admin.props.checked ? 1 : 0;
            const bAdmin = b.admin.props.checked ? 1 : 0;
            return aAdmin - bAdmin;
          },
        }),
        Map({
          title: "",
          dataIndex: "edit",
          key: "edit",
          className: "edit",
        }),
      ])
    );

    let filteredColumns = (hasDiagnoseDashboardPermission)?columns: columns.filter((col) => col.get('key') !=='diagnose');
    return filteredColumns;
  };

  const handleHoverRow = (record) => {
    setUsersTableState((usersTableData)=>({...usersTableData, hoveredRowUserId: record.key }));
  };

  return  (
    <Table
      className="menage-users-table"
      dataSource={generateDataSource().toJS()}
      columns={generateColumns().toJS()}
      pagination={false}
      onRow={(record) => ({
        onMouseEnter: () => handleHoverRow(record),
      })}
    />
  );
};

ManageUsersTable.propTypes = {
  userFilter: PropTypes.string,
  editingUserId: PropTypes.number,
  setEditingUserId: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(User).isRequired,
  sites: ImmutablePropTypes.listOf(Site).isRequired,
  siteGroups: ImmutablePropTypes.listOf(SiteGroup).isRequired,
  permissionsList: ImmutablePropTypes.listOf({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  allUsers: ImmutablePropTypes.listOf(User).isRequired,
  allAccounts: ImmutablePropTypes.listOf(Account).isRequired,
  getAllAccounts: PropTypes.func.isRequired,
  getAllSites: PropTypes.func.isRequired,
}

export default React.memo(ManageUsersTable);
