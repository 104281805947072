import PropTypes from 'prop-types';
import React from 'react';
import { DeleteOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip, Button, Select, notification } from 'antd';
import { Input } from '../../../styleguide';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { PurposeV2 as Purpose, Vendor, Translations } from '../../../records/vendor_list_records';
import { CategoryView } from '../../../records/privacy_manager_records';
import { COUNTRY_LANGUAGES, GCM_CATEGORIES } from '../../../constants.js';
import ReactQuill from 'react-quill';
import { addGcmActions } from './util';
import PurposeIllustrations from './PurposeIllustrations';

export default class ConfigurePurposeTab extends React.Component {
  static propTypes = {
    overrideNameAndDescriptionOnly: PropTypes.bool,
    iabPurposes: ImmutablePropTypes.listOf(PropTypes.instanceOf(Purpose)),
    purpose: PropTypes.instanceOf(Purpose).isRequired,
    categoryView: PropTypes.instanceOf(CategoryView),
    updatePurpose: PropTypes.func.isRequired,
    allListVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)),
    globalVendors: ImmutablePropTypes.orderedSetOf(PropTypes.instanceOf(Vendor)),
    readOnly: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    overrideNameAndDescriptionOnly: false,
    categoryView: null,
    iabPurposes: null,
    allListVendors: null,
    globalVendors: null,
    readOnly: false,
  }

  state = {
    customPurposeName: '',
    translateLanguage: 'default',
    error: null
  }

  formats = ['bold', 'italic', 'underline', 'strike', 'list', 'bullet', 'link', 'code-block']
  iabPurpose = this.props.iabPurposes.find(p => p.iabPurposeRef === this.props.purpose.iabPurposeRef);

  handleGcmChange = (value) => {
    this.props.updatePurpose(addGcmActions(this.props.purpose, value))
  }

  handlePurposeChange = (value, prop) => {
    let purpose = this.props.purpose;
    if (this.props.categoryView) {
      purpose = this.props.categoryView;
    }
    this.props.updatePurpose(purpose.set(prop, value));
  }

  findVendorById = (id) => {
    return this.props.globalVendors.find(v => v.id === id);
  }

  renderTitle = (title, name, isDisabled = false) => {
    let onChange = (e) => this.handlePurposeChange(e.target.value, 'name');
    if (this.state.translateLanguage !== 'default') {
      const translations = this.props.purpose.translations || new Translations({});
      name = translations.getIn(['names', this.state.translateLanguage]) || '';
      onChange = (e) => {
        const updatedTranslations = translations.setIn(['names', this.state.translateLanguage], e.target.value);
        this.props.updatePurpose(this.props.purpose.set('translations', updatedTranslations));
      };
    }
    return (
      <React.Fragment>
        <label className='title' >{title}</label>
        <Input
          value={name}
          disabled={isDisabled || this.props.readOnly}
          onChange={onChange}
          error={this.props.error}
        />
      </React.Fragment>
    );
  }

  handleDescriptionChange = (value) => {
    if (this.state.translateLanguage !== 'default') {
      const translations = this.props.purpose.translations || new Translations({});
      const updatedTranslations = translations
        .setIn(['descriptions', this.state.translateLanguage], value)
      this.props.updatePurpose(this.props.purpose.set('translations', updatedTranslations));
    } else {
      this.handlePurposeChange(value, 'description');
    }
  }

  componentDidMount = () =>{
    //rerendiring the component once after mounting because ReactQuill is only printing the correct description value on the rerender.(https://sourcepoint.atlassian.net/browse/DIA-2855) 
    setTimeout(()=>{
      this.forceUpdate();
    },1)
  }

  renderDescription = (title, description, isDisabled = false, iabStack = false) => {
    let iabStackMessage;
    if (iabStack && isDisabled) {
      iabStackMessage = <span style={{ fontSize: 12, fontWeight: 'normal' }}> (to change IAB stack description provide CMP publisher ID in advanced settings)</span>
    }

    if (this.state.translateLanguage !== 'default') {
      const translations = this.props.purpose.translations || new Translations({});
      description = translations.getIn(['descriptions', this.state.translateLanguage]) || '';
    }

    const readOnly = isDisabled || this.props.readOnly;
    return readOnly ? (
      <React.Fragment>
        <label className="title">
          {title}
          {iabStackMessage}
        </label>
        <div className='description'>
          {description}
        </div>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <label className="title">
          {title}
          {iabStackMessage}
        </label>
        <ReactQuill
          style={{ height: 140, marginBottom: 55, opacity: readOnly ? 0.5 : 1 }}
          readOnly={readOnly}
          onChange={(value, delta, source, editor)=>{
            if(source === "user") this.handleDescriptionChange(value)
          }}
          value={description}
          formats={this.formats}
        />
      </React.Fragment>
    );
  }

  createCustomPurpose = () => {
    const isValiadate = this.props.validateHandler(this.state.customPurposeName)
    if (!isValiadate) {
      const newPurpose = new Purpose({ id: `temp-${new Date().getTime()}`, name: this.state.customPurposeName, type: 'CUSTOM' });
      this.props.updatePurpose(this.props.purpose.update('categories', cs => cs.push(newPurpose)));
      this.setState({
        customPurposeName: '',
        error: null
      });
    } else this.setState({ error: isValiadate })
  }

  addPurposeToStack = (purpose) => {
    this.props.updatePurpose(this.props.purpose.update('categories', cs => cs.push(purpose)));
    // will delete purpose from vendor list; if purpose was before currently editing purpose - need to decrement current idx
    const idxOfPurposeToDelete = this.props.vendorList.categories.findIndex(p => p.name === purpose.name);
    if (idxOfPurposeToDelete < this.props.purposeIndex) {
      this.props.decrementIdx();
    }
    this.props.updateCategoriesInVl(this.props.vendorList.categories.filterNot(p => p.name === purpose.name));
  }

  deletePurposeFromStack = (purpose) => {
    if(this.props.purpose.categories?.size <= 1) {
      notification.error({
        message: 'A Stack should have at least ONE purpose',
        duration: 4,
      });
      return;
    }
    this.props.updatePurpose(this.props.purpose.update('categories', cs => cs.filterNot(p => p.name === purpose.name)));
    this.props.updateCategoriesInVl(this.props.vendorList.categories.push(purpose), purpose);
  }

  handleTranslationChange = (language) => {
    this.setState({ translateLanguage: language });
  }

  handleDisclosureOnlyChange = (checked) => {
    let updatedPurpose = this.props.purpose.set('disclosureOnly', checked);
    if (checked) {
      updatedPurpose = updatedPurpose.set('defaultCustomLegalBasis', null);
    }
    this.props.updatePurpose(updatedPurpose);
  }

  handleRequireConsentChange = ({ target: { checked } }) => {
    let updatedPurpose = this.props.purpose.set('requireConsent', checked)
    const updatedPurposesUnderStack = this.props.purpose.categories.map(c => c.set('requireConsent', checked))
    updatedPurpose = updatedPurpose.set('categories', updatedPurposesUnderStack)

    this.props.updatePurpose(updatedPurpose)
  }

  render() {
    const p = this.props.purpose;

    let nonChangableName;
    let changableName;
    let nonChangableDescription;
    let changableDescription;
    // let legalDescription;
    let Illustrations = null;
    let isDisabledName;
    let isDisabledDescription;

    // TODO - this list needs to be "global" to this vendor list so that multiple custom purposes can't use the same category
    const gcmCategory = p.googleConsentModeCategory()
    const gcmUnusedCategories = this.props.vendorList.getUnusedGcmCategories(p.get('id'))

    if (this.props.overrideNameAndDescriptionOnly) {

      nonChangableName = this.renderTitle('Vendor List Purpose Name', this.props.purpose.name, true);
      changableName = this.renderTitle('Override Purpose Name', this.props.categoryView.name);
      nonChangableDescription = this.renderDescription('Vendor List Purpose Description', this.props.purpose.description, true);
      changableDescription = this.renderDescription('Override Description Name', this.props.categoryView.description);
    } else {
      let title = (p.type === 'CUSTOM_STACK') ? 'Custom stack title' : 'Purpose title';
      let descriptionLabel = 'Description'
      if (p.type === 'IAB_STACK') {
        title = 'IAB stack title'
        isDisabledName = true;
        isDisabledDescription = !this.props.vendorList.publisherCmpId2;
        descriptionLabel = 'User-friendly text'
      }
      if (p.type === 'IAB_PURPOSE') {
        title = 'IAB purpose title'
        isDisabledName = true;
        isDisabledDescription = true;
        descriptionLabel = 'User-friendly text'
        // isDisabledDescription = !this.props.vendorList.publisherCmpId2;
        // const iabPurpose = this.props.iabPurposes.find(p => p.iabPurposeRef === this.props.purpose.iabPurposeRef);
        // if (iabPurpose.descriptionLegal) {
        //   legalDescription = this.renderDescription('Legal description', iabPurpose.descriptionLegal, true);
        // }
        Illustrations = <PurposeIllustrations
          purpose={this.props.purpose}
          iabPurpose={this.iabPurpose}
          iabPurposes={this.props.iabPurposes}
          translateLanguage={this.state.translateLanguage}
          updatePurpose={this.props.updatePurpose}
          formats={this.formats}
          readOnly={this.props.readOnly}
        />
      }
      ////
      
      changableName = this.renderTitle(title, this.props.purpose.name, isDisabledName);
      changableDescription = this.renderDescription(descriptionLabel, this.props.purpose.description, isDisabledDescription, p.type === 'IAB_STACK');
    }

    let purposes;
    let selectConsentType;

    if (p.type === 'IAB_STACK') {
      const iabStack = this.props.iabStacks.find(s => s.iabStackRef === p.iabStackRef);
      purposes = (
        <div className='purposes-container'>
          <label className='title'>IAB purposes:</label>
          {
            iabStack.categories.map(c => {
              return <p className='purpose iab'>{c.name}</p>
            })
          }

          <label className='title'>IAB Special Features:</label>
          {
            iabStack.iabSpecialFeatures && iabStack.iabSpecialFeatures.map(c => {
              return <p className='purpose iab'>{c.name}</p>
            })
          }
        </div>
      )
    } else if (p.type === 'CUSTOM_STACK') {
      const customPurposesNotInStack = this.props.vendorList.categories.filter(c => c.type === 'CUSTOM');
      let purposesAvailableToAdd;
      if (customPurposesNotInStack.size) {
        purposesAvailableToAdd = (
          <React.Fragment>
            <label className='title'>Custom purposes not in a stack:</label>
            {
              customPurposesNotInStack.map(purpose => (
                <div>
                  <div className='purpose-container'>
                    <p className='purpose'>{purpose.name}</p>
                    <PlusOutlined onClick={() => this.addPurposeToStack(purpose)} />
                  </div>
                </div>
              ))
            }
          </React.Fragment>
        )
      }

      purposes = (
        <div className='purposes-container'>
          <div className='purposes-in-stack'>
            <label className='title'>Purposes in stack:</label>
            {
              p.categories.map(purpose => {
                return (
                  <div className='purpose-container'>
                    <p className='purpose'>{purpose.name}</p>
                    <DeleteOutlined onClick={() => this.deletePurposeFromStack(purpose)} />
                  </div>
                );
              })
            }
          </div>
          {purposesAvailableToAdd}
          <div className='add-purpose'>
            <label className='title'>Create new custom purpose to include in stack:</label>
            <div className='custom-purpose'>
              <label>Custom purpose name:
                <Input
                  onChange={(e) => this.setState({ customPurposeName: e.target.value, error: e.target.value === '' && null })}
                  value={this.state.customPurposeName}
                  error={this.state.error}
                />
              </label>
              <Button
                onClick={this.createCustomPurpose}
                disabled={!this.state.customPurposeName.trim()}
              >
                Add custom purpose
              </Button>
            </div>
          </div>
        </div>
      )
    } else if (p.type === 'IAB_PURPOSE') {
      const defaultLegalBasisTooltipText = 'If a vendor declares both user consent, and legitimate interest; the legal basis would be defaulted to the selection chosen. This value would override the vendor list level default, set within the advanced setting'
      const isPurpose3456 = this.iabPurpose && [3,4,5,6].includes(this.iabPurpose.iabId);

      selectConsentType = (
        <div className='default-consent-type'>
          <label>Default IAB Vendor Consent Type</label>
          <Select
            value={p.defaultLegalBasis || 'Not Selected'}
            onChange={(type) => this.props.updatePurpose(p.set('defaultLegalBasis', type === 'Not Selected' ? null : type))}
            placeholder='not selected'
            disabled={this.props.readOnly}
          >
            <Select.Option key='Not Selected'>Not Selected</Select.Option>
            <Select.Option key='CONSENT'>Consent</Select.Option>
            {isPurpose3456 ? null : <Select.Option key='LEGITIMATE_INTEREST'>Legitimate Interest</Select.Option>}
          </Select>
          <Tooltip title={defaultLegalBasisTooltipText} placement="right">
            <InfoCircleOutlined />
          </Tooltip>
        </div>
      )
    }

    let purposeId;
    if (this.props.purpose.id) {
      purposeId = <p className='purpose-id'>ID: {this.props.purpose.id}</p>;
    };

    const translations = (
      <div className='translations'>
        <p>Language translation: </p>
        <Select
          value={this.state.translateLanguage}
          onChange={this.handleTranslationChange}
          placeholder='Select a language for translation...'
          disabled={this.props.readOnly}
        >
          <Select.Option key={'default'}>Default</Select.Option>
          {COUNTRY_LANGUAGES.map((o) => <Select.Option key={o.code.toLowerCase()}>{o.language}</Select.Option>)}
        </Select>
      </div>
    )

    return (
      <div className='edit-purpose-tab-container'>
        {purposeId}
        {nonChangableName}
        {changableName}
        {nonChangableDescription}
        {changableDescription}
        {/* {legalDescription} */}
        {Illustrations}
        {selectConsentType}
        {translations}
        {!this.props.isInStack && (
          <div>
            <label>
              Required for Consent
            </label>
            <Checkbox
              id="required-consent-checkbox"
              onChange={this.handleRequireConsentChange}
              checked={p.requireConsent}
            />
            <Tooltip title="Applies to Neutral Consent Privacy Manager only" placement="right">
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}
        {purposes}
        {p.type === 'CUSTOM' && (
          <React.Fragment>
            <div>
              <label>
                Disclosure Only
              </label>
              <Checkbox
                id="custom-special-purpose-checkbox"
                checked={p.disclosureOnly}
                onChange={({ target: { checked } }) => this.handleDisclosureOnlyChange(checked)}
              />
              <Tooltip
                title={disclosureOnlyToolTipText}
                placement="right"
              >
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div>
              <label>Google Consent Mode Category</label>
              <Select
                value={p.disclosureOnly ? 'None' : (gcmCategory || 'None')}
                onChange={(type) => this.handleGcmChange(type)}
                placeholder='None'
                disabled={p.disclosureOnly}
              >
                <Select.Option key='None'>None</Select.Option>
                {GCM_CATEGORIES.map((category) => (
                  <Select.Option key={category} disabled={gcmCategory !== category && gcmUnusedCategories.indexOf(category) < 0}>{category}</Select.Option>
                ))}
              </Select>
              <Tooltip title={"Tie this Custom Purpose to a Google Consent Mode category"} placement="right">
                <InfoCircleOutlined />
              </Tooltip>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const disclosureOnlyToolTipText = 'Making the category disclosure only will result in end users not being able to opt in/out of it; and will allow you to categorize vendors under it as disclosure only'
const defaultCustomLegalBasisTooltipText = 'Changes the legal basis of custom vendors under this purpose to the selection chosen. This value would override the vendor list level default, set within the advanced setting'