import React, { Component } from 'react';
import { Infotip, Button, Select } from "../../../../../../../../styleguide";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandPointUp } from '@fortawesome/free-solid-svg-icons';
import CustomJSComp from './Types/CustomJS';
import RedirectComp from './Types/Redirect';
import WhitelistComp from './Types/Whitelist';
import {
  SaveAndExit,
  Accept,
  Reject,
  ContinueWithAdblocker,
  ExecJS,
  Redirect,
  Whitelist,
  AcceptAll,
  RejectAll,
  Dismiss,
  Cancel,
} from '../../../../../../../../records/choice_config_records';
import { IsApp } from '../../../../../../contexts';

const { Option, OptGroup } = Select;

let scenarioConsentUrl;
let scenarioCcpaUrl;
let ScenarioUsnatUrl;
if (process.env.REACT_APP_PUB_PORTAL_ENV === 'prod') {
  scenarioConsentUrl = 'https://sourcepoint.mgr.consensu.org/tcfv2';
  scenarioCcpaUrl = 'https://ccpa-service.sp-prod.net';
  ScenarioUsnatUrl = 'https://cdn.privacy-mgmt.com/usnat';
} else {
  scenarioConsentUrl = 'https://cmpv2.sp-stage.net/tcfv2';
  scenarioCcpaUrl = 'https://ccpa.sp-stage.net';
  ScenarioUsnatUrl = 'https://usp.sp-stage.net';
}


export default class ChoiceOptions extends Component {
  state = {
    show: false,
    choice: { type: undefined, data: {} }
  }

  promiseInfo = {}

  show = async (buttonText) => {
    let choice = buttonText && this.props.choiceOptions && this.props.choiceOptions.find((o) => (o.data && o.data.button_text === buttonText))
    choice = choice || { type: null, data: {} };

    return new Promise((resolve, reject) => {
      this.promiseInfo = { resolve, reject };
      this.setState({ show: true, choice: choice });
    });
  }

  hide = () => this.setState({ show: false })

  selectValue = () => {
    const { resolve, reject } = this.promiseInfo;
    if (this.state.choice.type) {
      this.hide();
      this.props.updateActions(this.state.choice);
      resolve(this.state.choice);
    } else {
      reject();
    }
  }

  changeValue = (value, data) => {
    const { category, messageSubcategory } = this.props
    data = (data && data.button_text) ? data : {};

    if (category === 'gdpr') {
      data['consent_origin'] = scenarioConsentUrl;
    } else if (category === 'usnat') {
      data['consent_origin'] = ScenarioUsnatUrl;
    } else {
      data['consent_origin'] = scenarioCcpaUrl;
    }

    if (value === 'Accept') data = new Accept(data).toJS();
    if (value === 'Reject') data = new Reject(data).toJS();
    if (value === 'SE') data = new SaveAndExit(data).toJS();
    if (value === 'Cancel') data = new Cancel(data).toJS();
    if (value === 6) data = new ContinueWithAdblocker(data).toJS();
    if (value === 9) data = new ExecJS(data).toJS();
    if (value === 5) data = new Redirect(data).toJS();
    if (value === 1) data = new Whitelist(data).toJS();
    if (value === 11) data = new AcceptAll(data).toJS();
    if (value === 13) data = new RejectAll(data).toJS();
    if (value === 15) data = new Dismiss(data).toJS();
    if (value === 2) data = new Cancel(data).toJS();
    if (data.hasOwnProperty('enabled')) delete data.enabled;

    data.button_text = JSON.stringify(Date.now());
    const dataValue = value === null ? {} : data;
    this.setState({
      choice: {
        type: value,
        data: dataValue,
        usePmV2Opt: [2, 11, 13].includes(value) && ['privacy-manager', 'privacy_manager_non_iab'].includes(this.props.messageSubcategory)
      }
    });
  }
  hasFeature = (feature) => {
    const { accountId, features } = this.props;
    return features && (features.includes(feature))
  }
  render() {
    const { reject } = this.promiseInfo;
    const { show, choice } = this.state;
    const isPM = ['privacy-manager', 'privacy_manager_non_iab'].includes(this.props.messageSubcategory);

    const isFromCustom = this.props.category === 'custom' && this.props.messageSubcategory === 'custom';

    const menu = (
      <IsApp.Consumer>
        {(isApp) => (
          <div style={{ padding: '8px 0', width: '280px' }}>
            <h5>Choice Options</h5>
            <div className="option-inputs">
              <Select placeholder="Select a choice option..." value={choice.type} style={{ width: '100%' }} onChange={this.changeValue}>
                {this.hasFeature('adblock_v2') && !isFromCustom && (
                  <OptGroup label="Adblock Options">
                    <Option value={1}>White list</Option>
                    <Option value={6}>Continue width Adblocker</Option>
                  </OptGroup>
                )}
                {this.hasFeature('custom_choices') && (
                  <OptGroup label="Choice Options">
                    <Option value={9}>Custom {!isApp ? 'Javascript' : 'Action'}</Option>
                    <Option value={5}>Redirect</Option>
                    {this.props.category === 'gdpr' && (isPM
                      ? <Option value={'ACCEPT_LI_ONLY'}>Accept Legitimate Interest Only</Option>
                    : <Option value={'SE'}>Accept Legitimate Interest Only</Option>)}
                    {!this.props.isFromAdblock && !isFromCustom && <Option value={11}>Accept All</Option>}
                    {!this.props.isFromAdblock && !isFromCustom && <Option value={13}>Reject All</Option>}
                    {this.props.messageSubcategory === 'us_pm' && <Option value={2}>Cancel</Option>}
                    {this.props.category !== 'usnat' && (isPM
                      ? <Option value={2}>Cancel</Option>
                    : <Option value={15}>Dismiss</Option>)}
                  </OptGroup>
                )}
              </Select>
            </div>
            <div className="option-details" style={{ marginTop: '10px' }}>
              {(choice.type === 9) && <CustomJSComp changeValue={this.changeValue} value={choice} isApp={isApp}/>}
              {choice.type === 5 && <RedirectComp changeValue={this.changeValue} value={choice} />}
              {choice.type === 1 && <WhitelistComp changeValue={this.changeValue} value={choice} />}
            </div>
            <div style={{ textAlign: 'right', marginTop: '10px' }}>
              <Button onClick={() => { this.hide(); reject(); }}>Cancel</Button>
              <Button disabled={!choice.type} style={{ marginLeft: '5px' }} type="primary" onClick={this.selectValue}>Save</Button>
            </div>
          </div>
        )}
      </IsApp.Consumer>
    );

    return (
      <button style={{ width: 'auto' }} className="ql-linkChoiceOptions">
        <Infotip open={show} placement="bottom" title="Select Choice Options:" content={menu} trigger="click">
          <span><FontAwesomeIcon icon={faHandPointUp} /></span>
        </Infotip>
      </button>
    );
  }
}