import React, { useState, useEffect,useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { Select, message,Modal,Button } from '../../../../styleguide'
import { useDispatch } from "react-redux";
import CampaignsList from "./CampaignsList";
import CampaignDetails from "./CampaignDetails";
import EndedCampaigns from "./EndedCampaigns";
import { environment, maxMsgs, getCampaignType, getCampaignNameLatestEnv } from "../helper";
import ConfirmationModal from "./ConfirmationModal";
import DismissErrorModal from '../../../common/DismissErrorModal';
import CampaignCloneModal from "./CampaignCloneModal";
import { getParameterByName, capitalizeFirstLetter } from '../../../utils.js';

const { Option } = Select;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const move = (source, destination, droppableSource, droppableDestination) => {
  let updatedDestination = destination;

  const sourceIndex = droppableSource.index;
  let destinationIndex = droppableDestination.index;

  const campaignTobeMoved = source[sourceIndex];
  const movingCampaignTypeId = Number(campaignTobeMoved.campaign_type_id);
  const rmDupCampaign = updatedDestination.find(dc => Number(dc.campaign_type_id) === movingCampaignTypeId);
  if(rmDupCampaign) {
    const dupCampaignIndex = updatedDestination.findIndex(dc => Number(dc.campaign_type_id) === movingCampaignTypeId);
    updatedDestination.splice(dupCampaignIndex, 1);
    if(dupCampaignIndex < destinationIndex) {
      destinationIndex -= 1; 
    }
  }  

  const sourceClone = Array.from(source);
  const destClone = Array.from(updatedDestination);
  // const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(destinationIndex, 0, sourceClone[droppableSource.index]);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

function CampaignsLists(props) {

  const [ items, setItems ] = useState(props.stageCampaign);
  const [ selected, setSelected ] = useState(props.publicCampaign);
  const [ campaignDetails, updateCampaignDetails ] = useState(null);
  const [ endedCampaignsList, updateEndedCampaigns ] = useState([]);
  const [ showEndedCampaigns, toggleEndedCampaigns ] = useState(false);
  const [ showEndedCampaign, toggleEndedCampaign ] = useState(false);
  const [ shouldMoveCampaignVisible, updateMoveCampaign ] = useState(false);
  const [ intermediateCampaignState, updateIntermediateCampaign ] = useState(null);
  const [ endCampaignDetails, updateEndedCampaignsDetails ] = useState([]);
  const [ endCampaignsType, updateEndCampaignsType ] = useState('stage');
  const [ changeEnvironmentError, setChangeEnvironmentError ] = useState({show:false, env: ""});
  const [showCloneModal, setShowCloneModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [homeIndex, setHomeIndex] = useState(null)
  const [resultObj,setResultObj] = useState({})
  const [priorityChange, updatePriorityChange] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    setItems(props.stageCampaign);
    setSelected(props.publicCampaign);
  },[props.stageCampaign, props.publicCampaign]);


  const confirmCampaignEndAction = (deletStateCampaign) => {
    updateEndedCampaignsDetails(deletStateCampaign);
    toggleEndedCampaign(prevState => !prevState);
  };

  const onCancelCampaignPriorityChange = () => {
    updateIntermediateCampaign(null)
    updatePriorityChange(false)
  };

  const endCampaignConfirmed = () => {
      toggleEndedCampaign(false);
      endCampaign(endCampaignDetails);
  };

  const endCampaign = (endCampaignDetails) => {
    const isMultiCampaignEnabled = props.isMultiCampaignEnabled;
    const campaignType = Number(endCampaignDetails.campaign_type_id);
    const environment = endCampaignDetails.environment;

    let fieldTobeUpdated;
    let priorityList;
    let deletePriorityIndex;
    let options;
    const siteGroupId = getParameterByName('site_group_id', window.location);
    const siteId = getParameterByName('site_id', window.location);
    if (siteGroupId) {
      fieldTobeUpdated = environment === 2 ? 'stageCampaignPriority' : 'publicCampaignPriority';
      priorityList = environment === 2 ? props.site.stageCampaignTypePriority : props.site.publicCampaignTypePriority;
      deletePriorityIndex = priorityList.indexOf(campaignType);
      priorityList.splice( deletePriorityIndex, 1);
      props.stopSiteGroupLiveCampaign({
        siteId,
        siteGroupId,
        accountId: props.accountId,
        campaign: endCampaignDetails,
        isMultiCampaignEnabled: isMultiCampaignEnabled,
        siteGroup: { siteGroupId, siteId, [fieldTobeUpdated] : priorityList }
      }).then(() => message.success(`'${getCampaignNameLatestEnv(endCampaignDetails.description)}' is ended successfully`));
    } else {
      fieldTobeUpdated = environment === 2 ? 'stage_campaign_type_priority' : 'public_campaign_type_priority';
      priorityList = environment === 2 ? props.site.stageCampaignTypePriority : props.site.publicCampaignTypePriority;
      deletePriorityIndex = priorityList.indexOf(campaignType);
      if(deletePriorityIndex !== -1){
        priorityList.splice( deletePriorityIndex, 1);
      }
      options = { 
        isMultiCampaignEnabled: isMultiCampaignEnabled,
        priorityList: { [fieldTobeUpdated] : priorityList }
      };
     props.stopLiveCampaign(endCampaignDetails, options).then(() => message.success(`'${getCampaignNameLatestEnv(endCampaignDetails.description)}' is ended successfully`))
    }
  }

  const onCampaignRefresh = () => {
    endAndRefresh(endCampaignDetails)
  }

  const endAndRefresh = (camp) => {
      const allPartitions = props.partitions.toJS();
      let partitionIds
      if(props.isMultiCampaignEnabled){
        const campaignType_partitions = allPartitions.filter(part => part.campaign_type_id === camp.campaign_type_id)
        partitionIds = campaignType_partitions.find(partition => partition?.description === camp?.partition_set.get('description'))?.id
        if(!partitionIds){
          partitionIds = campaignType_partitions[0].id;
        }
      }else{
        partitionIds = allPartitions.find(par => par?.description === camp?.partition_set.get('description'))?.id
      }
      const env = camp.environment === 1?"public":"stage"
      let priorityList = camp.environment === 2 ? props.site.stageCampaignTypePriority : props.site.publicCampaignTypePriority;
      const priority = priorityList.indexOf(camp.campaign_type_id) + 1;
      props.handleSaveAndActivate(env, camp.description, partitionIds, camp.campaign_type_id, priority,true,true)
      setRefresh(false)
  }

  const onCancelMoveCampaign = () => {
    updateMoveCampaign(false);
    updateIntermediateCampaign(null);
  };

  const onCancelRefresh = () => {
    setRefresh(false);
    updateEndedCampaignsDetails(null);
  };

  const id2List = {
    stage: "items",
    production: "selected",
  };

  const getList = (id) => {
    return id2List[id] === "items" ? items : selected;
  };

  const preventPrioritizingAdblockOverGdpr = (cardMoveDetails) => {
    let gdprCampaignIndex;
    let adblockCampaignIndex;
    let showError = false;
    let orderList = [];
    const { source, destination } = cardMoveDetails;
    const sourceList = Array.from(getList(source.droppableId));
    if (source?.droppableId === destination?.droppableId) {
      const campaigns = reorder(sourceList, source.index, destination.index);
      orderList = campaigns.map(c => parseInt(c.campaign_type_id));
    }
    gdprCampaignIndex = orderList.indexOf(1);
    adblockCampaignIndex = orderList.indexOf(3);
    if(gdprCampaignIndex > adblockCampaignIndex && gdprCampaignIndex != -1 && adblockCampaignIndex != -1) {
      showError = true;
    }
    return showError;
  }

  const onDragStart = (start) => {
    const homeIndex = lists.map(l=> l.id).indexOf(start.source.droppableId);
    setHomeIndex(homeIndex)
  }

  const onDragEnd = (result) => {
    setHomeIndex(null)
    const { source, destination } = result;
    if(!props.hasStageCampaignAccess && destination.droppableId === 'stage'){
      setChangeEnvironmentError({show: true, env: "stage"});
      return;
    } else if(!props.hasPublicCampaignAccess && destination.droppableId === 'production') {
      setChangeEnvironmentError({show: true, env: "public"});
      return;
    }
    if(source?.index === destination?.index && source?.droppableId === destination?.droppableId) {
      return;
    }

    if(source?.droppableId === destination?.droppableId){
      updatePriorityChange(true)
    }

    if(props.isMultiCampaignEnabled) {
      const prioritizeErrorMsg = preventPrioritizingAdblockOverGdpr(result);
      if(prioritizeErrorMsg && props.isMultiCampaignEnabled) {
        message.warning(`An Anti-Adblock campaign can not be prioritised over a GDPR message, the Anti-Adblock script will not trigger without the consent of the user.`);
        return;
  	  }  
    }

    const _v = props.isMultiCampaignEnabled ? 2 : 1;
    const sourceDroppabbleId = result.source.droppableId;
    const sourceIndex = result.source.index;
    const newPriorityIndex = result.destination?.index;
    const campaignDetails = lists.find(l => l.id === sourceDroppabbleId).campaigns[sourceIndex];
    const diffEnv = environment.find(e => e.value != campaignDetails.environment).label;
    const updatedResult = { ...result, description: campaignDetails.description, campaign_type_id: Number(campaignDetails.campaign_type_id), diffEnv, _v, newPriorityIndex};
    updateIntermediateCampaign(updatedResult);
  };

  const moveOrReorderCampaign = () => {
    const result = intermediateCampaignState;
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const sourceList = getList(source.droppableId);
    if (source.droppableId === destination.droppableId) {
      let env;
      const items = reorder(sourceList, source.index, destination.index);
      if (source.droppableId === "production") {
        env = 'public';
        setSelected(items);
      } else {
        env = 'stage';
        setItems(items);
      }
      const priorityList = items.map(c => Number(c.campaign_type_id));
      if(props.isMultiCampaignEnabled) {
        props.updatePriorityList(priorityList, env);
      }
    } else {
      const destinationList = getList(destination.droppableId);
      const updatedLists = move(sourceList, destinationList, source, destination);
      if(props.isMultiCampaignEnabled) {
        const stagePriorityList = updatedLists.stage.map(c => Number(c.campaign_type_id));
        const publicPriorityList = updatedLists.production.map(c => Number(c.campaign_type_id));

        const sourceDroppabbleId = source.droppableId;
        const sourceIndex = result.source.index;
        const copyAndEndCampaignDetails = lists.find(l => l.id === sourceDroppabbleId).campaigns[sourceIndex];
        props.copyAndEndCampaign(copyAndEndCampaignDetails, stagePriorityList, publicPriorityList);
      } else {
        const copyAndEndCampaignDetails = lists.find(l => l.id === source.droppableId).campaigns[result.source.index];
        props.copyAndActivateInEnv(source.droppableId,copyAndEndCampaignDetails);
      }
      setItems(updatedLists.stage);
      setSelected(updatedLists.production);
    }
    onCancelMoveCampaign();
    onCancelCampaignPriorityChange();
    };
  
  const handleCampaignDetails = (campaign = null, priority = null) => {
    let updatedCampaign = campaign;
    if(priority) updatedCampaign.priority = priority + 1;
    updateCampaignDetails(updatedCampaign);
  };

  const header = (env) => <div className="campaign-header">
    <span key={env} className="campaign-type">{capitalizeFirstLetter(env)} Campaigns</span>
    <Button type="secondary" size='small' onClick={() => getEndedCampaigns(env)}>Show Ended Campaigns</Button>
  </div>;

  // edit campaign and show ended campaigns 

  const subHeader = (env) => <div>
    <Button type="secondary">Edit</Button>
    <span>Show Ended campaigns</span>
  </div>

  const stageSortOrder = props.site.stageCampaignTypePriority;
  const publicSortOrder = props.site.publicCampaignTypePriority;
 
  const lists = [
    {
      id: 'stage',
      campaigns: items,
      campaignType: 'stage',
      header: header('stage'),
      scenarios: props.stageScenarios,
      messages:props.stageMessages,
      sortOrder: stageSortOrder,
    },
    {
      id: 'production',
      campaigns: selected,
      campaignType: 'public',
      header: header('public'),
      scenarios: props.publicScenarios,
      messages:props.publicMessages,
      sortOrder: publicSortOrder
    }
  ];
  
  const scenarios = props.stageScenarios.concat(props.publicScenarios, props.draftScenarios);
  let priority = 1;
  if(campaignDetails) {
   const campaignSortOrder = campaignDetails.environment === 1 ? publicSortOrder : stageSortOrder;
    priority = campaignSortOrder.indexOf(Number(campaignDetails.campaign_type_id)) + 1;
  }
  const campaignDetailsModal = !!campaignDetails ? <CampaignDetails currentUser={props.currentUser} priority={priority} isMultiCampaignEnabled={props.isMultiCampaignEnabled} scenarios={scenarios} campaign={campaignDetails} onCancel={handleCampaignDetails} vendorListMetaData={props.vendorListMetaData}/> : null;

  const getEndedCampaigns = (type) => {
    const campaigns = type === 'stage' ? props.oldStageCampaigns : props.oldPublicCampaigns;
    updateEndCampaignsType(type);
    updateEndedCampaigns(campaigns.toJS());
    toggleEndedCampaigns(prevState => !prevState);
  };

  const onCancelEndedCampaigns = () => {
    toggleEndedCampaigns(prevState => !prevState);
  }

  const cloneCampaigns = (campaign,env) => {
    const campaignList = getList(env);
    const sourceIndex = campaignList.findIndex(camp =>campaign ==camp.id);
    const resultObj = {
      "draggableId": campaign,
      "source": {
        "index": sourceIndex,
        "droppableId": env
      },
      "destination": {
        "droppableId": env === "production"?"stage":"production",
        "index": 0,
      },
    }
    onDragEnd(resultObj);
    updateMoveCampaign(true);
  }

  const onRefreshCampaign = (selectedCampaign) => {
    setRefresh(true);
    updateEndedCampaignsDetails(selectedCampaign)
  }

  const campaignsLists = <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
         {lists.map((list, index) => {
                                      const isDropDisabled = index !== homeIndex
                                      return(<CampaignsList
                                        isMultiCampaignEnabled={props.isMultiCampaignEnabled} 
                                        key={index} 
                                        list={list}
                                        vendorListMetaData={props.vendorListMetaData}
                                        confirmCampaignEndAction={confirmCampaignEndAction} 
                                        fetchEndedCampaigns={getEndedCampaigns} 
                                        onClickCampaign={handleCampaignDetails}
                                        cloneCampaigns={cloneCampaigns}
                                        onRefresh={onRefreshCampaign}
                                        isDropDisabled={isDropDisabled}
                                        />)}
          )}
       </DragDropContext>;
  
  const endedCampaigns = showEndedCampaigns && <EndedCampaigns
                            site={props.site}
                            currentUser={props.currentUser}
                            scenarios={scenarios}
                            isMultiCampaignEnabled={props.isMultiCampaignEnabled} 
                            showEndedCampaigns={showEndedCampaigns}
                            //statsData={props.statsData.toJS()}
                            campaigns={endedCampaignsList}
                            endCampaignsType={endCampaignsType}
                            //statsLoading={props.statsLoading}
                            onCancel={onCancelEndedCampaigns}/>

  const confirmationModal = showEndedCampaign && <ConfirmationModal 
                              campaign={endCampaignDetails} 
                              isEndedCampaign={true} 
                              isModalVisible={showEndedCampaign} 
                              handleOk={endCampaignConfirmed} 
                              handleCancel={confirmCampaignEndAction}/>
                        
const confirmationPriorityModal = priorityChange && <ConfirmationModal 
                              campaign={intermediateCampaignState} 
                              isEndedCampaign={false} 
                              isPriorityChange={priorityChange}
                              isModalVisible={priorityChange} 
                              handleOk={moveOrReorderCampaign} 
                              handleCancel={onCancelCampaignPriorityChange}/>

  const cantChangeEnvironmentModal = changeEnvironmentError.show &&
                                      <DismissErrorModal
                                      title="Cannot move campaign"
                                      error= {<h4>Cannot move Campaign to {changeEnvironmentError.env} as you donot have access to  <b>{changeEnvironmentError.env} Campaigns</b></h4>}
                                      isModalVisible={changeEnvironmentError.show}
                                      cancelText="Back"
                                      handleCancel={() => setChangeEnvironmentError({show: false, env : ""})}/>

  let isReplaceCampaign;
    if(intermediateCampaignState && intermediateCampaignState?.destination?.droppableId) {
      const diffEnv = intermediateCampaignState?.destination?.droppableId != intermediateCampaignState?.source?.droppableId
      const envCampaigns = lists.find(l => l.id === intermediateCampaignState.destination.droppableId);
      const previouslyActiveCampaign = envCampaigns.campaigns.find(c => Number(c.campaign_type_id) === Number(intermediateCampaignState.campaign_type_id));
      if(previouslyActiveCampaign && diffEnv) {
        const priorityList = envCampaigns.sortOrder.length === 0 ? [ 0 ] : envCampaigns.sortOrder;
        const previousCampaignPriority = priorityList.indexOf(Number(previouslyActiveCampaign.campaign_type_id));
        isReplaceCampaign = {"type":getCampaignType(Number(intermediateCampaignState.campaign_type_id)),"destination":intermediateCampaignState.destination.droppableId};
        const newPriority = intermediateCampaignState.newPriorityIndex <= previousCampaignPriority ? intermediateCampaignState.newPriorityIndex + 1 : (intermediateCampaignState.newPriorityIndex);
        const priorityText = props.isMultiCampaignEnabled ? `with priority ${newPriority}`: '';
       // replaceCampaignMsg = `Note: US Privacy compliance type campaign already exists in Public campaign. This clone will override the current Public US Privacy compliance campaign.?`;
      }
    }
  
  const campaignCloneModal = shouldMoveCampaignVisible && 
  <CampaignCloneModal
    resultObj={resultObj}
    isModalVisible={shouldMoveCampaignVisible} 
    campaign={intermediateCampaignState}
    closeModal={onCancelMoveCampaign}
    confirmCloneCampaigns={moveOrReorderCampaign}
    isReplaceCampaign={isReplaceCampaign}
  />

  const campaignRefreshModal = refresh && <Modal
    visible={refresh}
    closeModal={onCancelRefresh}
    className='clone-modal'
    onCancel={onCancelRefresh}
    footer={[
      <Button key="1" onClick={onCancelRefresh} className="cancel">
        Cancel
      </Button>,
      <Button key="2" onClick={onCampaignRefresh} type="primary">
        Refresh Campaign
      </Button>,
    ]}
  >
     <p className="title">Refresh the campaign</p>
     <div className="refresh-camp-header">
      Refreshing the campaign will end the current campaign and create a new campaign 
      reflecting all the changes that have been detected
      </div>
      <div className="refresh-camp-header">Are you sure you want to <b>REFRESH THE CAMPAIGN ?</b></div>
  </Modal>

  return (
    <div className="campaigns-wrapper">
      { campaignsLists }
      { campaignDetailsModal }
      { endedCampaigns }
      { confirmationModal }
      {/* { moveCampaignModal } */}
      {/* { maxMessagePageViewModal } */}
      { cantChangeEnvironmentModal }
      { campaignCloneModal}
      { campaignRefreshModal}
      {confirmationPriorityModal}
    </div>
  );
}

export default CampaignsLists;
