import PropTypes from 'prop-types';
import React from 'react';
import { List } from 'immutable';
import { InfoCircleOutlined, SettingOutlined, WarningFilled } from '@ant-design/icons';
import { Modal, Input, Select, Tooltip, Button } from 'antd';
import AutoAddNewVendorsCheckbox from './AutoAddNewVendorsCheckbox';
import ServerCookieCheckbox from './ServerCookieCheckbox';
import AllIabCheckbox from './AllIabCheckbox';
import { VendorList } from '../../../records/vendor_list_records';
import { GEO_FILTER_LIST_DETAILS } from '../../../constants';
import { hasFeature } from '../../utils';
import { Alert, Checkbox } from '../../../styleguide';

const Option = Select.Option;
const defaultLegalBasisTooltipText = 'When a IAB vendor declares both consent and legitimate interest for a particular purpose, the vendor list will default it to this selection when adding the vendor to the vendor list (also applies to auto-update if applicable). Can be set for each purpose individually that will override this setting.'
const baseActionsGrantTooltipText = 'Emit a vendor consent action if the vendor is opted into all purposes under the legal basis defined for the vendor. Otherwise fire the vendor reject action if present';
const donotStoreUUIDsTooltipText = 'Only Andriod app is supported by this feature';
const reportingPurposeIdentifierText = 'In order to report on unique users, we send a unique identifier for each user to our reporting pipeline in order to attribute page views and actions that have been made by the same user. Depending on the jurisdiction you are in, the DPA cookie guidance on this use case may vary and therefore you have the option to restrict this use case if you wish. Please note that ticking this setting will mean that any reporting metrics that relate to unique users will show the exact same numbers as page views for GDPR reporting. If you are also running campaigns with our US privacy solution on the same properties, unique user numbers in the US privacy reporting may be impacted as well.'
const googleConsentModeV2Text = <>This setting will add an additional flag to the TCData object, signaling to Google that 'Consent Mode 2.0.' is enabled. To leverage this setting, you must add the IAB vendor <b>'Google Advertising Products'</b> to your vendor list. Please note that this setting will only set ad_storage, ad_personalization, and ad_user_data Consent Mode checks. Please refer to the Help Center for instructions on how to implement additional Google Consent Mode checks. </>

export default class AdvancedSettingsModal extends React.Component {
  static propTypes = {
    vendorList: PropTypes.instanceOf(VendorList).isRequired,
    visible: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    readOnly: PropTypes.bool.isRequired,
    vendorScanningEnabled: PropTypes.bool.isRequired,
    getVendorsAndCategoriesForToggleIab: PropTypes.func.isRequired,
    updateParentState: PropTypes.func.isRequired,
    allIabAndAutoUpdate: PropTypes.bool.isRequired,
  }

  state = {
    vl: this.props.vendorList,
    allIabAndAutoUpdate: this.props.allIabAndAutoUpdate,
    showWarning: false,
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ vl: nextProps.vendorList })
  }

  toggleShareRootDomain = (e) => {
    this.setState({
      vl: this.state.vl.set('shareRootDomain', e.target.checked),
    });
  }

  toggleAutoScan = () => {
    this.setState({
      vl: this.state.vl.set('autoUpdateVendorScan', !this.state.vl.autoUpdateVendorScan),
    });
  }

  changeDefaultLegalBasis = (type) => {
    this.setState({
      vl: this.state.vl.set('defaultLegalBasis', type),
    });
  }

  handleCancelModalChanges = () => {
    this.setState({ vl: this.props.vendorList });
    this.props.closeModal();
  }

  validateMMSDomain = (url) => {
    return /^(ftp|http|https):\/\/[^ "]+$/.test(url);
  }

  handleOnOk = () => {
    let vl = this.state.vl;
    if (this.state.allIabAndAutoUpdate && !this.props.allIabAndAutoUpdate) {
      const vendorsAndCategories = this.props.getVendorsAndCategoriesForToggleIab(this.state.vl, this.state.vl.defaultLegalBasis);

      vl = this.state.vl
        .set('vendors', vendorsAndCategories.get('vendors'))
        .set('categories', vendorsAndCategories.get('categories'));
    }

    this.props.updateParentState({
      vl,
      allIabAndAutoUpdate: this.state.allIabAndAutoUpdate,
    });
    this.props.closeModal();
  }

  toggleAllIabAndUpdate = () => {
    this.setState({
      allIabAndAutoUpdate: !this.state.allIabAndAutoUpdate,
    });
  }

  setPublisherCmpId = (e) => {
    this.setState({
      vl: this.state.vl.set('publisherCmpId', e.target.value),
    });
  }

  setCookieMaxAge = (e) => {
    let value = parseInt(e.target.value);
    if (isNaN(value)) {
      value = '';
    } else if (value < 0) {
      value = 0;
    } else if (value > 365) {
      value = 365;
    }
    if (value > 365) value = 365;
    this.setState({
      vl: this.state.vl.set('cookieMaxAge', value),
    });
  }

  toggleWriteFirstPartyCookiesFromServer = (e) => {
    this.setState({
      vl: this.state.vl.set('writeFirstPartyCookiesFromServer', e.target.checked),
      showWarning: e.target.checked
    });
  }

  toggleHasConsentCompensation = (e) => {
    this.setState({
      vl: this.state.vl.set('hasConsentCompensation', e.target.checked),
    });
  }

  toggleUseSpecialTreatmentP1 = (e) => {
    this.setState({
      vl: this.state.vl.set('useSpecialTreatmentP1', e.target.checked),
    });
  }

  togglePublisherPurposeModal = () => {
    this.props.togglePublisherPurposeModal()
  }

  setP1Geos = (selected) => {
    this.setState({
      vl: this.state.vl.set('specialTreatmentP1Geo', List(selected)),
    });
  }


  toggleSpecPurpFeat = (e) => {
    this.setState({
      vl: this.state.vl.set('displaySpecialPurposesNFeatures', e.target.checked),
    });
  }

  renderFooter = () => (
    <div className="footer-container">
      {this.props.filterElement(
        <div className="settings-container" onClick={this.togglePublisherPurposeModal}>
          <SettingOutlined type="setting" />
          <span>Manage Publisher Purposes</span>
        </div>
      )}
      <div className="footer-btn-container">
        <Button onClick={this.handleCancelModalChanges}>{this.props.readOnly? "Close" : "Cancel"}</Button>{" "}
       {!this.props.readOnly ? <Button type="primary" onClick={this.handleOnOk}>Apply Changes</Button> : null}
      </div>
    </div>
  );

  toggleCustomElementsFirst = (e) => {
    this.setState({
      vl: this.state.vl.set('customElementsFirst', e.target.checked),
    });
  }

  toggleUseVendorActionsV2 = (e) => {
    this.setState({
      vl: this.state.vl.set('useVendorActionsV2', e.target.checked),
    });
  }

  toggleSetEuconsentCookie = (e) => {
    this.setState({ vl: this.state.vl.set('setEuconsentCookie', e.target.checked) });
  }

  toggleDisclosedVendors = (e) => {
    this.setState({ vl: this.state.vl.set('includeDisclosedVendors', e.target.checked) });
  }
  toggleServerStorage = (e) => {
    this.setState({ vl: this.state.vl.set('noServerStorage', e.target.checked) });
  }

  toggleReportingPurpose = (e) => {
    this.setState({ vl: this.state.vl.set('noUniqueIdReporting', e.target.checked) });
  }

  toggleGoogleConsentModeV2 = (e) => {
    this.setState({ vl: this.state.vl.set('googleConsentModeV2', e.target.checked)});
  }

  render() {
    const { filterElement } = this.props;
    const geoList = GEO_FILTER_LIST_DETAILS.filterNot(geo => geo.code === 'NA' || geo.code === 'CA' || geo.type == 'province');
    return (
      <Modal
        visible={this.props.visible}
        className='vl-advanced-settings'
        closable={false}
        footer={this.renderFooter()}
        destroyOnClose
      >

        <p className='title'>Advanced Settings</p>

          <div className='cookies-expiration'>
            <span>Cmp publisher id</span>
            <Input
              value={this.state.vl.publisherCmpId2}
              onChange={this.setPublisherCmpId}
              disabled='true'
            />
          </div>

        {this.props.vendorScanningEnabled && filterElement(
          <AutoAddNewVendorsCheckbox
            onChange={this.toggleAutoScan}
            checked={this.state.vl.autoUpdateVendorScan}
            tooltipPlacement='right'
            readOnly={this.props.readOnly}
          />
        )}

        {/* {filterElement(
          <AllIabCheckbox
            toggleAllIabAndUpdate={this.toggleAllIabAndUpdate}
            allIabAndAutoUpdate={this.state.allIabAndAutoUpdate}
            readOnly={this.props.readOnly}
            tooltipPlacement='right'
          />
        )} */}

        {filterElement(
          <div ClassName='default-consent-type'>
            <label>Default IAB Vendor Consent Type</label>
            <Select
              value={this.state.vl.defaultLegalBasis}
              onChange={this.changeDefaultLegalBasis}
              disabled={this.props.readOnly}
            >
              <Select.Option key='CONSENT'>Consent</Select.Option>
              <Select.Option key='LEGITIMATE_INTEREST'>Legitimate Interest</Select.Option>
            </Select>
            <Tooltip title={defaultLegalBasisTooltipText} placement='right'>
              <InfoCircleOutlined />
            </Tooltip>
          </div>
        )}

        <div className='share-root-domain-checkbox'>
          <Checkbox
            className='auto-scan'
            checked={this.state.vl.shareRootDomain}
            onChange={this.toggleShareRootDomain}
            disabled={this.props.readOnly}
          >
            Write 1st Party Cookies To Root Domain
          </Checkbox>
        </div>

        <ServerCookieCheckbox
          toggleWriteFirstPartyCookiesFromServer={this.toggleWriteFirstPartyCookiesFromServer}
          writeFirstPartyCookiesFromServer={this.state.vl.writeFirstPartyCookiesFromServer}
          showWarning={false}
          tooltipPlacement='right'
          readOnly={this.props.readOnly}
        />

        <div className='cookies-expiration'>
          <span>Consent cookies expiration</span>
          <Input
            value={this.state.vl.cookieMaxAge}
            onChange={this.setCookieMaxAge}
            disabled={this.props.readOnly}
          />
          <span>days</span>
        </div>

        {filterElement(
          <React.Fragment>
            <div className='special-treatment-p1'>
              <div className='share-root-domain-checkbox'>
                <Checkbox
                  className='auto-scan'
                  checked={this.state.vl.useSpecialTreatmentP1}
                  onChange={this.toggleUseSpecialTreatmentP1}
                  disabled={this.props.readOnly}
                >
                  Use Special Treatment for Purpose One
                </Checkbox>
              </div>

              <div className='flex-row'>
                <p>in Countries</p>
                <Select
                  className='country-select'
                  mode='multiple'
                  onChange={this.setP1Geos}
                  tokenSeparators={[',']}
                  value={this.state.vl.useSpecialTreatmentP1 ? this.state.vl.specialTreatmentP1Geo.toJS() : []}
                  disabled={!this.state.vl.useSpecialTreatmentP1 || this.props.readOnly}
                  filterOption
                  optionFilterProp="searchValue"
                >
                  {
                    geoList.map((country) => {
                      return <Option key={country.code} searchValue={country.name}>{country.name}</Option>;
                    })
                  }
                </Select>
              </div>
            </div>
            <div className='share-root-domain-checkbox'>
              <Checkbox
                className='auto-scan'
                checked={this.state.vl.displaySpecialPurposesNFeatures}
                onChange={this.toggleSpecPurpFeat}
                disabled={this.props.readOnly}
              >
                Display Special Purposes, Features, and Discloure Only Vendors in Message
              </Checkbox>
            </div>
            <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
              <Checkbox
                className='auto-scan'
                checked={this.state.vl.customElementsFirst}
                onChange={this.toggleCustomElementsFirst}
                disabled={this.props.readOnly}
              >
                List IAB Purpose 1 and Custom Elements first in the message
              </Checkbox>
            </div>
          </React.Fragment>
        )}
        <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
          <Checkbox
            className='auto-scan'
            checked={this.state.vl.useVendorActionsV2}
            onChange={this.toggleUseVendorActionsV2}
            disabled={this.props.readOnly}
          >
            Base vendor consent and reject actions on vendor grants
            {(
              <Tooltip title={baseActionsGrantTooltipText} placement='right'>
                <InfoCircleOutlined />
              </Tooltip>
            )}
          </Checkbox>
        </div>
        {filterElement(
          <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
            <Checkbox
              className='auto-scan'
              checked={this.state.vl.setEuconsentCookie}
              onChange={this.toggleSetEuconsentCookie}
              disabled={this.props.readOnly}
            >
              Store euconsent-v2 first party cookie
          </Checkbox>
          </div>
        )}
        <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
          <Checkbox
            className='auto-scan'
            checked={this.state.vl.includeDisclosedVendors}
            onChange={this.toggleDisclosedVendors}
            disabled={this.props.readOnly}
          >
            Disclosed Vendors in the TCString
          </Checkbox>
        </div>
        {hasFeature(this.props.currentUser, "no_server_storage") ? (
          <div style={{ marginTop: 10 }} className="share-root-domain-checkbox">
            <Checkbox
              className="auto-scan"
              checked={this.state.vl.noServerStorage}
              onChange={this.toggleServerStorage}
              disabled={this.props.readOnly}
            >
              Do not store UUIDs server side.
              <Tooltip title={donotStoreUUIDsTooltipText} placement="right">
                <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
            {this.state.vl.noServerStorage ? (
              <Alert
                type="warning"
                icon={<WarningFilled />}
                showIcon={true}
                message="Selecting this option will remove all user related metrics from Sourcepoint reporting. You will only be able to report on page view data."
              />
            ) : null}
          </div>
        ) : null}
          <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
            <Checkbox
              className='auto-scan'
              checked={this.state.vl.noUniqueIdReporting}
              onChange={this.toggleReportingPurpose}
              disabled={this.props.readOnly}
            >
              Do not use unique user identifiers for reporting purposes
              <Tooltip title={reportingPurposeIdentifierText} placement="right">
                  <InfoCircleOutlined />
              </Tooltip>
            </Checkbox>
          </div>
          {filterElement(
            <div style={{ marginTop: 10 }} className='share-root-domain-checkbox'>
              <Checkbox
                className='auto-scan'
                checked={this.state.vl.googleConsentModeV2}
                onChange={this.toggleGoogleConsentModeV2}
                disabled={this.props.readOnly}
              >
                Use TCF to Enable Google Consent Mode 2.0
                <Tooltip title={googleConsentModeV2Text} placement="right">
                  <InfoCircleOutlined />
                </Tooltip>
              </Checkbox>
            </div>
          )}
      </Modal>
    );
  }
}