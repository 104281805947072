import React, { useState } from "react";
import { Space, Modal, Typography, Row, Col, Button, Table, Flex, Alert, theme,Tag, Tooltip, Divider } from 'antd';
import { RenderLimitedChips } from "../../../../../../styleguide"
import { region_title_index_map } from "../../../../../../constants";
const { Title, Text, Paragraph } = Typography;

const AddIabChoicesModal = (props) => {

  const {
    cancelBtnTextAndOpenIabModal,
    handleCancel,
    addCategory,
    systemPurposes
  } = props;

  const { token } = theme.useToken()
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const handleAddIabChoices = () => {
    addCategory(selectedRowKeys)
  }

  let footer = (
    <Flex vertical gap={16} align="flex-start">
      {props.isNoFrameworkFlow ? null : (
        <>
          <Text>Access detailed <a target="_blank" href="https://github.com/InteractiveAdvertisingBureau/Global-Privacy-Platform/tree/main/Sections/US-States">technical specifications</a> of IAB's state-level string requirements.</Text>
          <Divider style={{margin: 0}}/>
        </>
      )}
      <Flex justify="flex-end" style={{width: "100%"}}>
        <Button type='link' onClick={() => handleCancel('iab')}>{cancelBtnTextAndOpenIabModal}</Button>
        <Button disabled={systemPurposes.length === 0} type='primary' onClick={handleAddIabChoices} >Add selected choices {selectedRowKeys.length ? `(${selectedRowKeys.length})` : null}</Button>
      </Flex>
    </Flex>
  )



  let iabChoices = systemPurposes.map((item, index) => ({
    ...item,
    key: item.id || index,
  }));

  let regionFilterOptions = props.systemPurposes.reduce((regionCodes, currPurpose) => {
    let usCodes = Object.keys(currPurpose.configByGeo);
    regionCodes = [...new Set(regionCodes.concat(usCodes))]
    return regionCodes;
  },[]);

  regionFilterOptions = regionFilterOptions.map((usCode) => { return (
    {
      text: (<><Tag>{usCode.split("_")[1]}</Tag> - {region_title_index_map[usCode.replace("US_","STATE_")]}</>),
      value: usCode
    }
  )}) 

  const columns = [
    {
      title: "Privacy Choice",
      fixed: "left",
      dataIndex: "privacyChoice",
      width: 250,
      sorter: (a, b) => a.privacyChoice.localeCompare(b.privacyChoice),
      render: (privacyChoice, record) => <Paragraph ellipsis={{tooltip:{title: privacyChoice}, rows: 2}}>{privacyChoice}</Paragraph>
    }
  ]

  if(!props.isNoFrameworkFlow) {
    columns.push(
      {
        title: "Applicable in (Region)",
        fixed: "left",
        dataIndex: "configByGeo",
        width: 350,
        filters: regionFilterOptions,
        onFilter: (value, record) => {
          return Object.keys(record.configByGeo).includes(value)
        },
        filterSearch: true,
        render: (configByGeo, record) => {
          const states = configByGeo ? Object.keys(configByGeo) : []
          const stateTags = states.map(s => {
            const stateCode = s.split("_")[1]
            return (
            <Tooltip
              title={region_title_index_map[s.replace("US_", "STATE_")]}
            >
            <Tag>{stateCode}</Tag>
            </Tooltip>
          )
          })
          return (
            <RenderLimitedChips chips={stateTags} cutOff={5} />
          )
        }
      }
    )
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys)
    }
  };

    return (
      <Modal
        title={props.isNoFrameworkFlow ? "Add Standard Choice" : "Add IAB Choices"}
        open={cancelBtnTextAndOpenIabModal}
        width={"720px"}
        closable={true}
        destroyOnClose={true}
        onCancel={() => handleCancel('iab')}
     //   confirmLoading={vendorConnectionsSavePending}
        footer={footer}
        style={{
          top: 20
        }}
      >
        <Row gutter={[0, 16]} className='row-gutter'>
          <Col span={24}>
            <Space direction="vertical" size={token.margin}>
            <Text type="secondary">
                {props.isNoFrameworkFlow
                  ? "Select from these commonly configured US state privacy choices."
                  : "Select the IAB categories you want to add to your list:"}
              </Text>
            </Space>
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              // style={{ height: "500px", overflow:'auto'}}
              scroll={{y: 400}}
              dataSource={iabChoices}
              rowSelection={rowSelection}
              pagination={false}
            />
            </Col>
        </Row>
      </Modal>
    )
  
}

export default AddIabChoicesModal