require('es6-promise').polyfill();
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch, { retries: 2, retryDelay: 500 });

import { checkStatus, buildUrlWithVersion, DIAGNOSE_SDK_BASEURL, exportCSVResponse, getVersion } from '../helper.js';
import { fetchRetryWrapper } from './helpers.js';


export function getAppSummaryData({
  metrics,
  websites,
  start,
  end,
  regions,
  accountId
} = {}) {
  return fetch(
    buildUrlWithVersion(
      `${DIAGNOSE_SDK_BASEURL}/metrics/summary/`,
      {},
      'summary',
    ),
    {
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify({
        _version: getVersion('details'),
        metrics,
        websites,
        start,
        end,
        geo: regions,
        account_id:accountId
      }),
    },
  )
    //.then(checkStatus)
    .then((resp) => resp)
    .then((resp) => resp.json())
    .catch((err) => {
      console.log(err);
      alert(err.response ? (`Error on get 'Summary' data: status ${err.response.status}`) : err);
    });
}

export function getVendorsPriorToConsentListData({
  dateFrom,
  dateTo,
  page,
  limit,
  orderBy,
  benchmarks,
  properties,
  region,
  tableFiltersData,
  vendorId,
  accountId
} = {}) {
  return fetchRetryWrapper(buildUrlWithVersion(
      `${DIAGNOSE_SDK_BASEURL}/metrics/vendorsPriorToConsent/`,
      {
          async_: true,
      },
      'details',
  ),
  {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _version: getVersion('details'),
        start: dateFrom,
        end: dateTo,
        page,
        limit,
        order_by: orderBy,
        websites: properties.split(';'),
        geo: region.split(';'),
        async_: true,
        account_id: accountId,
        metrics: [
         "Vendors Prior to Consent"
        ],
      }),
  })
  .then((resp) => resp?.json())
  .catch((err) => {
      console.log(err);
      alert(err.response ? `Error on get 'Vendors triggered prior to consent' data: status ${err.response.status}` : err);
  });
}

export function getVendorsOptOutData({
  dateFrom,
  dateTo,
  page,
  limit,
  orderBy,
  benchmarks,
  properties,
  region,
  tableFiltersData,
  vendorId,
  accountId
} = {}) {
  return fetchRetryWrapper(buildUrlWithVersion(
      `${DIAGNOSE_SDK_BASEURL}/metrics/vendorsAfterOptOut/`,
      {
          async_: true,
      },
      'details',
  ),
  {
      method: 'post',
      credentials: 'include',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        _version: getVersion('details'),
        start: dateFrom,
        end: dateTo,
        page,
        limit,
        order_by: orderBy,
        websites: properties.split(';'),
        geo: region.split(';'),
        async_: true,
        account_id: accountId,
        metrics: [
          "Vendors after opt out"
        ],
      }),
  })
  .then((resp) => resp.json())
  .catch((err) => {
      console.log(err);
      alert(err.response ? `Error on get 'Vendors triggered prior to consent' data: status ${err.response.status}` : err);
  });
}

export function getAllVendorsFoundData({
  dateFrom,
  dateTo,
  page,
  limit,
  orderBy,
  benchmarks,
  properties,
  region,
  tableFiltersData,
  vendorId,
  accountId
} = {}) {
 // return Promise.resolve(vendorsPriorToConsent)
 return fetchRetryWrapper(buildUrlWithVersion(
  `${DIAGNOSE_SDK_BASEURL}/metrics/allVendorsFound/`,
  {
      async_: true,
  },
  'details',
),
{
  method: 'post',
  credentials: 'include',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    _version: getVersion('details'),
    start: dateFrom,
    end: dateTo,
    page,
    limit,
    order_by: orderBy,
    websites: properties.split(';'),
    geo: region.split(';'),
    async_: true,
    account_id: accountId,
    metrics: [
     "All vendors found"
    ],
  }),
})
  .then((resp) => resp.json())
  .catch((err) => {
      console.log(err);
      alert(err.response ? `Error on get 'All vendors found' data: status ${err.response.status}` : err);
  });
}

export function getVendorAfterRejectAllData({
  dateFrom,
  dateTo,
  page,
  limit,
  orderBy,
  benchmarks,
  properties,
  region,
  tableFiltersData,
  vendorId,
  accountId
} = {}) {
 // return Promise.resolve(vendorsPriorToConsent)
 return fetchRetryWrapper(buildUrlWithVersion(
  `${DIAGNOSE_SDK_BASEURL}/metrics/vendorsAfterRejectAll/`,
  {
      async_: true,
  },
  'details',
),
{
  method: 'post',
  credentials: 'include',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
  },
  body: JSON.stringify({
    _version: getVersion('details'),
    start: dateFrom,
    end: dateTo,
    page,
    limit,
    order_by: orderBy,
    websites: properties.split(';'),
    geo: region.split(';'),
    async_: true,
    account_id: accountId,
    metrics: [
     "Vendors after reject all"
    ],
  }),
})
  .then((resp) => resp.json())
  .catch((err) => {
      console.log(err);
      alert(err.response ? `Error on get 'Vendors after reject all' data: status ${err.response.status}` : err);
  });
}