import React, { useState } from "react";
import { Infotip, Button, Toggle, Chip } from "../../../styleguide";
import { InfoCircleFilled, ExclamationCircleFilled, InfoCircleOutlined } from "@ant-design/icons";
import { ActionExtension } from "../../../records/us_privacy_regulation_records";
import { List } from "immutable";
import { COUNTRY_LANGUAGES } from '../../../constants';
import { Tooltip, theme } from "antd";



export const renderTranslationWarningError = (transObj) => {
  if(transObj) {
    return (
      <React.Fragment>
        <div className="note"><b>Translations are missing for following Privacy Choices/Languages </b></div>
        <div className="note-container">
        {
            Object.keys(transObj).map(cat => {
              return (
                <div className="error-block">
                  <div className="grid-box">
                    <span className="reg-title">Choice: </span>
                    <p><b>{cat}</b></p>
                  </div>
                  <div className="grid-box">
                    <span className="reg-title">Language: </span>
                    <p>{transObj[cat].join(", ")}</p>
                  </div>
                </div>
              )
            })
        }
        </div>
        <div className="bottom-note">For empty translation fields, Default Language(English) Translation will be used.</div>
      </React.Fragment>
    );
  } 
  return null;
}

export const validateTranslation = (regulation) => {
  const errorContent = {}
  regulation.categories.map(cat => {
    const languages = []
    if (cat.type === 'SYSTEM_STACK') {
      let translations = cat.isNegative ? cat.negativeTranslations?.names : cat.translations?.names
      Object.keys(translations).map(key => {
        if (translations[key] === "No Translations found") {
          const langName = COUNTRY_LANGUAGES.find(l => l.code === key.toUpperCase())?.language
          const cName = cat?.name || cat?.privacyChoice
          languages.push(langName)
          errorContent[cName] = languages
        }
      })
      cat.categories.map(c => {
        const languages = []
        let translations = c.isNegative ? c.negativeTranslations?.names : c.translations?.names
        Object.keys(translations).map(key => {
          if (translations[key] === "No Translations found") {
            const langName = COUNTRY_LANGUAGES.find(l => l.code === key.toUpperCase())?.language
            const cName = cat?.name || cat?.privacyChoice
            languages.push(langName)
            errorContent[cName] = languages
          }
        })
      })
    } else {
      const languages = []
      let translations = cat.isNegative ? cat.negativeTranslations?.names : cat.translations?.names
      Object.keys(translations).map(key => {
        if (translations["en"] === "No Translations found") {
          const langName = COUNTRY_LANGUAGES.find(l => l.code === key.toUpperCase())?.language
          const cName = cat?.name || cat?.privacyChoice
          languages.push(langName)
          errorContent[cName] = languages
        }
      })
    }
  })
  return (Object.keys(errorContent).length > 0) ? errorContent: null
}

export const  groupPropertiesByGroups = ( siteGroups, siteIds) => {
  let arr = [{groupId: 'no_group', groupName: 'Not in any property group', siteIds: []}];
  outterLoop: for (let i = 0; i < siteIds?.length; i++) {
    for( let group of siteGroups.toJS()) {
      if(group.siteIds.includes(siteIds[i])) {
        let g = arr.find( a => a.groupId === String(group.siteId))
        // let gIdx = arr.findIndex( a => a.groupId === group.siteId)
        if(g) {
          g.siteIds.push(siteIds[i])
          // arr.splice(gIdx, 1);
        } else {
          arr.push({
            groupId : String(group.siteId),
            groupName: group.name,
            siteIds : [siteIds[i]]
          })
        }
        continue outterLoop;
      }
    }
    arr[0].siteIds.push(siteIds[i])
  }
  arr = arr.filter( group => group.siteIds.length)
  return arr;
}

export const renderInfoTip = (content, additionalClass, marginLeft = "4px") => {
  const className = `usp-infotip ${additionalClass ?? ''}`
  const iconStyles = { marginLeft}
  return (
    <Tooltip
      className={className}
      trigger="hover"
      title={content}
      overlayStyle={{ maxWidth: 400 }}
      // content={content}
    >
      {additionalClass ? (
        <InfoCircleFilled style={iconStyles} />
      ) : (
        <InfoCircleOutlined style={iconStyles} />
      )}
    </Tooltip>
  );
}

export const wrapInInfotip = (children, content) => {
  const className = `usp-infotip`
  return (
    <Infotip
      className={className}
      trigger="hover"
      title={null}
      content={content}
    >
      {children}
    </Infotip>
  )
}

export const renderTruncatedTags = (tags) => {
  if (tags.length > 0) {
    return (
      <div>
        <Chip style={{ borderRadius: "5px" }}>{tags[0]}</Chip>{" "}
        {tags.length > 1 ? (
          <Infotip
            className="usp-infotip"
            trigger="hover"
            title={null}
            content={<ul>{tags.map((a,i) => i != 0 ? (<li>{a}</li>) : null)}</ul>}
          >
            <Chip style={{ borderRadius: "5px", background: '#411F90', color: '#fff' }}>
              <b>+{tags.length - 1}</b>
            </Chip>
          </Infotip>
          
        ) : null}
      </div>
    );
  } else {
    return "-";
  }
}
export const updateStatusForAllSteps = (data, udpatedStatus) => {
  return data.map(item => {
    if (item.steps) {
      return {
        ...item,
        steps: updateStatusForAllSteps(item.steps, udpatedStatus),
      };
    }
    return {
      ...item,
      status: udpatedStatus,
    };
  });
};

export const stepsData = [
  {
    label: "Name and Properties",
    title: "Name and Properties",
    status: "process",
    key: "name_property_selection",
    warning: false,
    error: false,
  },
  {
    label: "Framework Territories",
    title: "Framework Territories",
    status: "wait",
    key: "industry_framework",
    warning: false,
    error: false,
  },
  {
    label: "Privacy Choices",
    steps: [
      {
        title: "Privacy Choices Selection",
        status: "wait",
        key: "select_puropses",
        warning: false,
        error: false,
      },
      {
        title: "Language & Translations",
        status: "wait",
        key: "language_translations",
        warning: false,
        error: false,
      }
    ],
  },
  {
    label: "Additional Settings",
    steps: [
      {
        title: "Vendor Management",
        status: "wait",
        key: "vendor_management",
        warning: false,
        error: false,
      },
      {
        title: "Opt In/Opt Out Hooks",
        status: "wait",
        key: "opt_in_opt_out",
        warning: false,
        error: false,
      },
      {
        title: "Advanced Settings",
        status: "wait",
        key: "signal_settings",
        warning: false,
        error: false,
      },
    ],
  },
  {
    label: "Summary",
    title: "Summary",
    status: "wait",
    key: "summary",
    warning: false,
    error: false,
  },
]

export const steps_data_state_specific = _.cloneDeep(stepsData).map(
  (step, idx) => {
    if (idx == 2) {
      step.steps.splice(0, 1, {
        title: "Choices Selection",
        status: "wait",
        key: "choice_selection",
        warning: false,
        error: false,
      });
      step.steps.splice(2, 0, {
        title: "Region Mapping",
        status: "wait",
        key: "region_mapping",
        warning: false,
        error: false,
      });
    }
    return step;
  }
);

export const STATE_SPECIFIC_STRING_STATE_CODES = ["STATE_CA", "STATE_VA", "STATE_CO", "STATE_UT", "STATE_CT"];

export const validateAndGetStateStringIcon = (stateCode) => {
  const stateSpecificString = STATE_SPECIFIC_STRING_STATE_CODES.includes(
    stateCode
  ) ? (
    <Tooltip title="Active State Law">
      <img src="/svg_icons/trademark-s.svg"/>
    </Tooltip>
  ) : null;
  return stateSpecificString;
}

export const navigationValidationMethod = (intermediateUSPRegulation, stepsData, current, jumpTo, regulationList) => {

  const stepsDataFlat = stepsData.map(main => {
    if (main.get('steps')) {
      return main.get('steps').map(step => ({ key: step.get('key'), status: step.get('status') }));
    } else {
      return [{ key: main.get('key'), status: main.get('status') }];
    }
  }).flatMap(entry => entry).toArray();

  const currentStepIndex = stepsDataFlat.findIndex((step) => step.key === current);
  const jumpToStepIndex = stepsDataFlat.findIndex((step) => step.key === jumpTo);

  if (jumpToStepIndex > currentStepIndex) {
    if(!evaluateRequriedFieldsFromStep(current,intermediateUSPRegulation, regulationList)){
      return true
    } else {
      for (let i = currentStepIndex + 1; i < jumpToStepIndex; i++) {
        if (stepsDataFlat[i].status !== 'finish') {
          return stepsDataFlat[i].key;
        }
      }
    }
    return !evaluateRequriedFieldsFromStep(current,intermediateUSPRegulation, regulationList);
  } else {
    let furthestFinish = -1;
    stepsDataFlat.forEach( (step, idx) => {
      if(step.status == 'finish'){
        furthestFinish = idx;
      }
    })
    if(currentStepIndex === furthestFinish + 1) {
      return false;
    } else {
      return !evaluateRequriedFieldsFromStep(current,intermediateUSPRegulation, regulationList);
    }
  }
}

export const GPPMSPS_FEATURES = [
  {
    title: "National String",
    description:
      "Sourcepoint's solution for MSPS supports the national string. This means that the underlying string that is signaled on your properties is the same for all territories included below, rather than being tailored on a state by state regulation basis.",
  },
];

export const OPERATE_MODE_OPTIONS = [
  {
    value: "MspaOptOutOptionMode",
    label: (
      <>
        Opt-out option Mode
        {renderInfoTip("Opt-out option mode is a signal that is sent in the MSPS that indicates that you sell, share or target advertising to users on your properties.")}
      </>
    ),
  },
  {
    value: "MspaServiceProviderMode",
    label: (
      <>
        Service Provider Mode
        {renderInfoTip(<>Service Provider Mode means that you do not sell or share the consumer's personal information or process it for the purposes of targeted advertising. You may however process and disclose such information to partners that are also acting as Service Providers, solely for California Digital Advertising Activities.<br/> Please note that by enacting Service Provider Mode, you will not be able to offer your users any privacy choices related to the sale or sharing of personal data or targeted advertising.</>)}
      </>
    ),
  },
];

export const evaluateRequriedFieldsFromStep = (
  current,
  intermediateUSPRegulation,
  regulationList,
  isStateLegislation = true
) => {
  let isStepcomplete = true;
  switch (current) {
    case "name_property_selection":
      if (
        intermediateUSPRegulation.name.trim().length == 0 ||
        regulationList.map(al => al.name).find(vl => vl === intermediateUSPRegulation.name.trim()) ||
        intermediateUSPRegulation.siteIds.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "industry_framework":
      if (
        intermediateUSPRegulation.appliesGeos.length == 0 &&
        intermediateUSPRegulation.exclusionGeos.length == 0
      ) {
        isStepcomplete = false;
      }
      break;
    case "select_puropses":
      if (intermediateUSPRegulation.categories.length == 0 || intermediateUSPRegulation.cookieMaxAge == '' || !intermediateUSPRegulation.categories.filter(c => c.type === "SYSTEM_STACK")?.every(c => c.categories?.length)) {
        isStepcomplete = false;
      }
      break;
    case "choice_selection":
      if(intermediateUSPRegulation.categories.length == 0) {
        isStepcomplete = false;
      }
      break;
    case "language_translations":
      const categories = intermediateUSPRegulation.categories.filter(cat => cat.type === "CUSTOM");
      let emptyTranslations = categories.length > 0 ? categories.filter(cat => {
        let translations = cat.isNegative ?  cat?.negativeTranslations : cat?.translations;
        return translations === null || translations?.names["en"] === "No Translations found" || translations?.names["en"] === ""
      }): [];
      if (emptyTranslations.length > 0) {
        isStepcomplete = false;
      }
      break;
    case "signal_settings":
      if(intermediateUSPRegulation.cookieMaxAge == '' && isStateLegislation) {
        isStepcomplete = false;
      }
      break;
    case "summary":
      break;
  }
  return isStepcomplete;
};

export const valideAllStepsBeforeSave = (steps, intermediateUSPRegulation, regulationsList, isStateLegislation) => {
  let stepsWithError = [];
  steps.forEach(step => {
    if(!evaluateRequriedFieldsFromStep(step, intermediateUSPRegulation, regulationsList, isStateLegislation)){
      stepsWithError.push(step)
    }
  })
  return stepsWithError;
}


const copyRegulationPurposeData = (copyTo, copyFrom) => {

 return copyTo
        .set("translations", copyFrom ? copyFrom.translations : null)
        .set("negativeTranslations", copyFrom ?  copyFrom.negativeTranslations : null)
        .set("consentActions", copyFrom ?  copyFrom.consentActions : List([]))
        .set(
          "consentActionExtension",
          copyFrom ?  copyFrom.consentActionExtension : new ActionExtension({})
        )
        .set("rejectActions", copyFrom ?  copyFrom.rejectActions : List([]))
        .set(
          "rejectActionExtension",
          copyFrom ?  copyFrom.rejectActionExtension : new ActionExtension({})
        )
        .set("vendorCategorization", copyFrom ? copyFrom.vendorCategorization : {})
        .set("configByGeo", copyFrom && Object.keys(copyFrom).length ? copyFrom.configByGeo : copyTo.configByGeo)
}


export const updateCategoryCache = (regulationCategoryOptionsCache, intermediateUSPRegulation, newFlow) => {
  let updatedCategoryCache = regulationCategoryOptionsCache.map( cat => {
    if(cat.type == 'SYSTEM_STACK') {
      let updatedStack = cat;
      const stackFromRegulation = intermediateUSPRegulation.categories.find(
        (purpose) => purpose.type === 'SYSTEM_STACK' && purpose.systemStackRef.id == cat.systemStackRef.id
      );
      if(Boolean(stackFromRegulation) || newFlow){
        updatedStack = copyRegulationPurposeData(cat, stackFromRegulation);
      }
      if(Boolean(stackFromRegulation)) {
        updatedStack = updatedStack.set('categories', updatedStack.categories.map( cat => {
          const catFromRegulation = stackFromRegulation.categories.find((pur) => pur.purposeRef.id == cat.purposeRef.id)
          return copyRegulationPurposeData(cat, catFromRegulation);
        }))
      } else if(newFlow) {
        updatedStack = updatedStack.set('categories', updatedStack.categories.map( cat => {
          return copyRegulationPurposeData(cat, null);
        }))
      }
      return updatedStack;
    } else {
      let updatedPurpose = cat;
      const purposeFromRegulation = intermediateUSPRegulation.categories.find(
        (purpose) => purpose.type === 'SYSTEM_PURPOSE' && purpose.purposeRef.id == cat.purposeRef.id
      );
      if(purposeFromRegulation || newFlow) {
        updatedPurpose = copyRegulationPurposeData(cat, purposeFromRegulation)
      }
      return updatedPurpose;
    }
  })
  return updatedCategoryCache;
}

// export const generateVendorTableDataSource = (categories, vendors) => {
//   let dataSource = [];
//   if(vendors) {
//     const presentCategoriesArray = categories.map(cat => ({id: cat.id, name: cat.name}));
//     dataSource = vendors.map(vendor => {
//       return ({
//       ...vendor,
//       categories: Math.random(),
//     })})
//   } 
//   return dataSource;
// }

export const generateVendorsHashMap = (categories) => {
  let vendorsHashMap = {}
    categories?.forEach(cat => {
      for (const [vendorId, vCat] of Object.entries(cat.vendorCategorization)){
        if(vendorsHashMap[vendorId]){
          vendorsHashMap[vendorId] = [...new Set(vendorsHashMap[vendorId].concat(cat.id ?? cat.privacyChoice))]
        } else {
          vendorsHashMap[vendorId] = [cat.id ?? cat.privacyChoice];
        }
      }
    })
  return vendorsHashMap;
}

export const getVendorIcon = (vendor) => {
  if(vendor.ids?.mspaId){
    return <i className="fas fa-circle" style={{color:'#FF5D89',width: 10, height: 10,  marginRight: 10}} />
  }else if(vendor.ids?.iabId){
    return <i className="fas fa-circle" style={{color:'#FCA015',width: 10, height: 10,  marginRight: 10}} />
  } else {
    return <i className="fas fa-circle" style={{color:'#C7C7C7',width: 10, height: 10,  marginRight: 10}} />
  }
}

export const getVendorType = (record) => {
  if (record.ids?.mspaId) return "MSPA";
  if (record.ids?.iabId) return "IAB";
  return "CUSTOM";
};

export const getPrivacyChoiceLabelWithSystemId = (category) => {
  let privacyChoiceLabel = category.privacyChoice ?? category.name;
  if(category.systemStackRef?.systemId) {
    privacyChoiceLabel = `${category.systemStackRef.systemId}. ${privacyChoiceLabel}` 
  } else if(category.purposeRef?.systemId) {
    privacyChoiceLabel = `${category.purposeRef.systemId}. ${privacyChoiceLabel}`
  }

  return privacyChoiceLabel;
}

// Scroll to the top of the page
export const scrollToTop = () => {
  const wizardContainer = document.getElementById("wizard-step-component")
  wizardContainer.scrollTo({
      top: 0,
      behavior: 'auto' // Use 'auto' for instant scrolling
  });
}

//tooltip content
export const GPP_MSPS_TOOLTIP = <div>The GPP's MSPS framework provides a mechanism to notify downstream partners that participating publishers have provided users with specific notice and choice over data processing activities on their properties.<br/><br/> Coupled with the MSPS (the technical signal) there is a complementary legal framework called the Multi-State Privacy Agreement (MSPA). The MSPA is a joint legal agreement across industry participants that dictates obligations of partners on how the framework should be used in practice so that all participants have confidence that the framework is being used appropriately and in the same way.<br/><br/> There is no obligation to sign the MSPA, however if you do sign the MSPA a signal will be sent in the MSPS which indicates that the transaction is covered by the MSPA.</div>
export const FRAMEWORK_TERRITORIES_TOOLTIP = <div>The US Multi-State Regulation settings provide a common privacy framework for users across all states or territories. The same MSPS signal is set across the territories you select below.</div>
export const PROCESSING_SENSITIVE_DATA_TOOLTIP =  <div>When you select this option, you are indicating that you will present all users with notice and choice over these data processing activities. As such we will update the following fields in the MSPS:<br/> - <b>SensitiveDataLimitUseNotice</b> will indicate Yes, notice was provided<br/> - <b>SensitiveDataProcessingOptOutNotice</b> will indicate Yes, notice was provided<br/> - For the categories you have chosen <b>SensitiveDataProcessing</b> will indicate No Consent (default) or Consent, depending on the users choice.</div>
export const SHARING_PERSONAL_DATA_TOOLTIP = <div>When you select this option, you are indicating that you will present all users with notice and choice over this data processing activity. As such we will update the following fields in the MSPS:<br/> - <b>SharingNotice</b> will indicate Yes, notice was provided<br/> - <b>SharingOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>TargetedAdvertisingOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>SharingOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice<br/> - <b>TargetedAdvertisingOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice</div>
export const SIGNATORY_ID_TOOLTIP = <div>If you are a signatory of the Multi-State Privacy Agreement (MSPA) you can add your ID in the field below in order to set the field "MspaCoveredTransaction" to "Yes" in the Multi-State Privacy String, thereby signalling to your partners that you the transaction is covered by the MSPA.</div>  
export const SALE_PERONSAL_DATA_TOOLTIP = <div>When you select this option, you are indicating that you will present all users with notice and choice over this data processing activity. As such we will update the following fields in the MSPS:<br/> - <b>SaleOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>SaleOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice</div>
export const SALE_AND_SHARING_TOOLTIP = <div>When you select this option, you are indicating that you will present all users with notice and choice over this data processing activity. As such we will update the following fields in the MSPS:<br/> - <b>SharingNotice</b> will indicate Yes, notice was provided<br/> - <b>SharingOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>TargetedAdvertisingOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>SaleOptOutNotice</b> will indicate Yes, notice was provided<br/> - <b>SaleOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice<br/> - <b>SharingOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice<br/> - <b>TargetedAdvertisingOptOut</b> will indicate Did Not Opt Out (default) or Opted Out, depending on the users choice</div>
export const COOKIE_EXPIRATION_TOOLTIP = <div>When a user visits one of your properties the state (e.g. opted in, opted out) of their privacy choices is stored within a cookie for reference on future visits. If the user clears their cookies or if the cookie expires and the user makes a subsequent visit to the property, they will be deemed a new user, and therefore, their privacy choices will return to the default state. We have set the default to 99 years in order to allow you to honor user choices without expiration. However, you can change the lifetime based on your legal requirements.</div>
export const MESSAGE_SENTIMENT_TOOLTIP = (isNegative, toggle, label) => (
  <div>
    <div>
      Message Sentiment defines the default state and behaviour of selection
      control for end user.
    </div><br/>
    <div><b>{isNegative ? "Negative" : "Affirmative"}:</b> Default Toggle {toggle ? "ON" : "OFF"}</div><br/>
    <div>
      {" "}
      <Toggle checked={toggle} style={{cursor: 'not-allowed'}} />
      {" "}
      <span>{label}</span>
    </div>
  </div>
);
export const IDENTIFICATION_TOOLTIP = <div>MSPA Section 4.2(a)(ii) states "(2) that the Consumer has the right to Opt Out of the Re-Sale or Re-Sharing of the Consumer's Personal Information by a Downstream Participant or Certified Partner by clicking on the link to the Identification List and Opting Out on such Downstream Participant's or Certified Partner's Digital Property (such link to the Identification List shall be made available by the First Party as part of this disclosure)." Messages that display sale or share choices will contain a link to the Identification List indicating that the user can opt out of re-sale by downstream participants there</div>
export const PRIVACY_POLICY_SECTION_LINK_TOOLTIP = <div>Add a link to the applicable section of your privacy policy that contains the relevant disclosures needed for sensitive categories. We will display this link in messages that display sensitive category data choices. You can amend the wording associated with this link in the next step.</div>
export const BASED_ON_CALIFORNIA_REGION_TOOLTIP = <div>California regulation 7012(f) states: "If a business collects personal information from a consumer online, the Notice at Collection may be given to the consumer by providing a link that takes the consumer directly to the specific section of the business's privacy policy that contains the information required in subsection (e)(1) through (6). Directing the consumer to the beginning of the privacy policy, or to another section of the privacy policy that does not contain the required information, so that the consumer is required to scroll through other information in order to determine the categories of personal information to be collected and/or whether the business sells or shares the personal information collected, does not satisfy this standard." <br/><b>By proceeding without adding a link your messaging may not be considered in alignment with California regulations.</b></div>
export const BASED_ON_COLORADO_REGION_TOOLTIP = <div>Colorado regulation (Rule 7.04D) states "Any Consent request by a Controller must contain the disclosures required by 4 CCR 904-3, Rule 7.03(E)(1) either directly or through a link. Where possible, the request interface itself should contain the disclosures required by 4 CCR 904-3, Rule 7.03(E)(1)(a)-(d). Alternatively, the Controller may provide the Consumer with a link to a webpage containing the required Consent disclosures, provided the request clearly states the title and heading of the webpage section containing the relevant disclosures. If technically feasible, the request method must also link the Consumer directly to the relevant section of the disclosure.". <br/><b>By proceeding without adding a link your messaging may not be considered in alignment with Colorado regulations.</b></div>
export const IMPORT_HOOKS_FROM_EXISTIN_VL_TOOLTIP = <div>Hooks configuration from the GDPR vendor lists will be translated in the following manner - <br/> <div><b>Consent Actions</b> will be imported as <b>Opt-in hooks</b><br/><b>Reject Actions</b> will be treated as <b>Opt-out hooks</b></div></div>
export const CONSENT_SCOPE_TOOLTIP = (
  <div>
    <b>Single property</b>
    <div>Consent will only be applied to the same property a user has consented on.</div>
    <b>Shared property</b>
    <div>Consent will be shared across all properties on the vendor list that implemented the authenticated consent feature and website properties that share the same top level domain.</div>
  </div>
);

export const REG_TYPE_TITLE_MAP = {
  "usnat" : "National",
  "state" : "State"
}

export const LEGALBASIS_OPTIONS = [
  {label: "Opt-In", value: "CONSENT"},
  {label: "Opt-Out", value: "OPT-OUT"},
  {label: "Not Applicable", value: "NOT_APPLICABLE"}
];

export const GPC_ENABLED_STATES = ["STATE_CA", "STATE_CO", "STATE_CT", "STATE_MT", "STATE_OR", "STATE_TX", "STATE_DE", "STATE_IA", "STATE_NE", "STATE_NH", "STATE_NJ", "STATE_TN", "STATE_MN", "STATE_MD"];
