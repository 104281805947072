import * as types from './action_types';
import { actionCreator } from './helpers';
import { createDiagnosePropertyAjax,importDiagnosePropertyAjax,getAllPropertiesListAjax } from '../api/diagnose_dashboard/diagnose_properties'


function createDiagnosePropertyPending(){
    return {
      type: types.DIAGNOSE_PROPERTY_SAVE_PENDING
    }
}

function createDiagnoseCreated(property){
    return {
      type: types.DIAGNOSE_PROPERTY_CREATED,
      property: property
    }
  }

function createDiagnoseCreatedError(error) {
    return {
      type: types.DIAGNOSE_PROPERTY_ERROR,
      error: error
    }
}

function importDiagnosePropertyPending(){
  return {
    type: types.DIAGNOSE_PROPERTY_IMPORTED_PENDING
  }
}

function importDiagnoseProperty(property){
  return {
    type: types.DIAGNOSE_PROPERTY_CREATED,
    property: property
  }
}

function importDiagnosePropertyError(error) {
  return {
    type: types.DIAGNOSE_PROPERTY_IMPORTED_ERROR,
    error: error
  }
}

function getAllPropertiesListPending(){
  return {
    type: types.GET_ALL_DIAGNOSE_PROPERTY_PENDING
  }
}

function getAllPropertiesListLoaded(propertyList){
  return {
    type: types.GET_ALL_DIAGNOSE_PROPERTY_LOADED,
    propertyList: propertyList?.data
  }
}

function getAllPropertiesListError(error){
  return {
    type: types.GET_ALL_DIAGNOSE_PROPERTY_ERROR,
    error:error
  }
}

export const getAllProperties = actionCreator(
  getAllPropertiesListAjax,
  getAllPropertiesListPending,
  getAllPropertiesListLoaded,
  getAllPropertiesListError
)

export const createDiagnoseProperty = actionCreator(
    createDiagnosePropertyAjax,
    createDiagnosePropertyPending,
    createDiagnoseCreated,
    createDiagnoseCreatedError,
    null,
  )

  export const importDiagnoseProperties = actionCreator(
    importDiagnosePropertyAjax,
    importDiagnosePropertyPending,
    importDiagnoseProperty,
    importDiagnosePropertyError,
    null,
  )