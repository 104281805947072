import React, { useState, useContext } from 'react';
import { Modal, Button } from 'antd';
import VendorsTable from './VendorsTable';
import { usPrivacyRegulationContext } from "../../../contexts";

const BulkVendorsEditModal = (props) =>{
  const [selectedCategories, setSelectedCategories] = useState([])
  const [vendorTableFilters, setVendorTableFilters] = useState({});
  const [error, setError] = useState(false);

  const { vendorsIdsMap } = useContext(usPrivacyRegulationContext);

  const handleBulkUpdate = (categories) => {
    if(categories.length == 0) {
      setError("Select at least one Privacy Choice")
      return;
    }
    props.handleBulkUpdate(categories);
    setError(false)
  }

  const handleCancel = () => {
    props.setOpenBulkEditModal  (false);
  }

  const renderFooter = () => (
    [
      <Button key="back" onClick={handleCancel}>Cancel</Button>,
      <Button key="submit" type="primary" onClick={() => handleBulkUpdate(selectedCategories)} /*disabled={selectedCategories.length == 0} */>Update</Button>,
    ]
  )

  const dataSource = props.selectedVendorsWrappers.map(vw => ({...vw, ids: vendorsIdsMap[vw.vendorId]}))
  const emptyTableTextCheck = vendorTableFilters.name || vendorTableFilters.categories;

  return (
    <Modal
      open={props.openBulkEditModal}
      title={"Bulk edit"}
      onOk={() => handleBulkUpdate(selectedCategories)}
      onCancel={handleCancel}
      closable={true}
      className="usnat-vendor-management-modal"
      destroyOnClose
      footer={renderFooter()}
      width={'70vw'}
    >
      <p>Privacy choices will be updated for the list of selected vendors.</p>
      <VendorsTable
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        dataSource={dataSource}
        // setSelectedVendors={setSelectedVendorIdsForBulkAction}
        isBulkEdit={true}
        triggerBulkEditError={error}
        isLoading={false}
        readOnly={true}
        categoryOptions={props.categories.map(
          (cat) => ({ value: cat.id ?? cat.privacyChoice, label: cat.privacyChoice ?? cat.name })
        )}
        vendorsHashMap={props.selectedVendorsHashMap}
        setVendorTableFilters={setVendorTableFilters}
        emptyTableTextCheck={emptyTableTextCheck}
        // updateInstantly={updateVendorCategories}
      />
    </Modal>
  );
}

export default BulkVendorsEditModal;