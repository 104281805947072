import React, { useEffect, useState } from 'react';
import { Modal, Tabs, Button, Radio, Select, Space, Typography } from 'antd';
import ActionsTab from '../../../../../common/ActionsTab';
import { SystemPurpose, VendorWrapper, ActionExtension } from '../../../../../../records/us_privacy_regulation_records';
import { List } from 'immutable';
import { getVendorIcon } from '../../../helper';

const ConsentAndRejectModal = (props) => {
  const [currentTab, setCurrentTab] = useState(props.purpose?.defaultLegalBasis === 'CONSENT' ? 'OPT-OUT' : 'CONSENT');
  const [purpose, setPurpose] = useState(!props.isAddNew ? props.purpose : {})
  const [purposeId, setPurposeId] = useState(!props.isAddNew ? props.purposeId : null);

  useEffect(()=>{
    if(purpose.defaultLegalBasis) setCurrentTab(purpose?.defaultLegalBasis === 'CONSENT' ? 'OPT-OUT' : 'CONSENT')
  },[purpose?.defaultLegalBasis])

  const updateActions = (actions, actionType) => {
    const updatedPurpose = purpose.set(actionType, actions);
    setPurpose(updatedPurpose);
  }

  const updateActionExtension = (type, key) => (value) => {
    const updatedPurpose = purpose?.update(`${type}ActionExtension`, (ext) => ({ ...ext, [key]: value }));
    setPurpose(updatedPurpose);
  }

  const handleCancelModalChanges = () => {
    setPurpose(props.purpose)
    props.closeModal();
  }

  const handleApply = () => {
    props.handlePurposeSave(purpose, purposeId);
  }

  const handleClearHooks = () => {
    let record = purpose;
    if(currentTab === "CONSENT"){
      record = record.set('consentActions', List([]));
      record = record.set('consentActionExtension', new ActionExtension({})?.toJS())
    } else if(currentTab === "OPT-OUT"){
      record = record.set('rejectActions', List([]));
      record = record.set('rejectActionExtension', new ActionExtension({})?.toJS())
    }
    setPurpose(record);
  }

  const onChange = (e) => {
    setCurrentTab(e.target.value)
  };

  const handlePurposeChange = (value) => {
    setPurposeId(value)
    setPurpose(props.label == 'Vendor' ? new VendorWrapper(props.records.find(vw => vw.vendorId == value) ?? {}) : new SystemPurpose(props.records.find(cat => (cat.id ?? cat.privacyChoice) == value) ?? {}))
  };

  const renderFooter = () => {
    return (
      [
        <Button key="clear" type='link' onClick={handleClearHooks} disabled={!Boolean(purposeId)}>Clear Hooks</Button>,
        <Space>
          <Button key="back" onClick={handleCancelModalChanges}>Cancel</Button>
          <Button key="submit" type="primary" onClick={handleApply} disabled={!Boolean(purposeId)}>Apply changes</Button>
        </Space>
      ]
    );
  }
 
  const defaultTitle = {
    'OPT-OUT': purpose.defaultLegalBasis === 'CONSENT',
    CONSENT: purpose.defaultLegalBasis === 'OPT-OUT',
  }

  return (
    <Modal
      title="Opt In/Opt Out Hooks Configuration"
      onOk={handleApply}
      visible={props.visible}
      onCancel={handleCancelModalChanges}
      closable={true}
      className="us-privacy-edit-purpose"
      destroyOnClose
      footer={renderFooter()}
      width={'700px'}
      style={{
        top: "5%"
      }}
    >
      <Space direction='vertical' size={4}>
        <Typography.Text>{props.label}</Typography.Text>
        <Select
          value={purposeId}
          options={props.records.map(record => props.label == 'Vendor' ? ({value: record.vendorId, label: <>{getVendorIcon(record)}{record.name}</>, searchLabel: record.name}) :  ({value: record.id ?? record.privacyChoice, label: record.privacyChoice ?? record.name, searchLabel: record.privacyChoice ?? record.name}))}
          disabled={!props.isAddNew}
          placeholder={"Select " + props.label}
          onChange={handlePurposeChange}
          showSearch
          optionFilterProp={'searchLabel'}
        />
      </Space>
        <hr/>
      {purposeId ? (
      <>  
        <Radio.Group
          defaultValue={purpose.defaultLegalBasis}
          value={currentTab}
          buttonStyle="solid"
          className="radio-tabs"
          onChange={(e)=> onChange(e)}
        >
        <Radio.Button value="CONSENT">Opt In Hooks {defaultTitle.CONSENT ? "(Default)" : ''}</Radio.Button>
        <Radio.Button value="OPT-OUT">Opt Out Hooks {defaultTitle['OPT-OUT'] ? "(Default)" : ''}</Radio.Button>
        </Radio.Group>
        <div className="condition-title">Conditions</div>

        {currentTab === 'CONSENT' && (
          <ActionsTab
            isCcpa
            readOnly={props.readOnly}
            actions={purpose.consentActions}
            geoTargeting={purpose.consentActionExtension.geoTargeting}
            onStatusChangeOnly={purpose.consentActionExtension.onStatusChangeOnly}
            actionCookies={purpose.consentActionExtension.cookies}
            updateGeoTargeting={updateActionExtension('consent', 'geoTargeting')}
            updateActions={ (actions) => updateActions(actions, 'consentActions') }
            updateOnStatusChangeOnly={updateActionExtension('consent', 'onStatusChangeOnly')}
            updateActionCookies={updateActionExtension('consent', 'cookies')}
            isUSPRegulation
          />
        )}
        {currentTab === 'OPT-OUT' && (
          <ActionsTab
            readOnly={props.readOnly}
            isRejectActions
            isCcpa
            actionsType='rejectActions'
            actions={purpose.rejectActions}
            geoTargeting={purpose.rejectActionExtension.geoTargeting}
            onStatusChangeOnly={purpose.rejectActionExtension.onStatusChangeOnly}
            actionCookies={purpose.rejectActionExtension.cookies}
            updateActions={ (actions) => updateActions(actions, 'rejectActions') }
            updateGeoTargeting={updateActionExtension('reject', 'geoTargeting')}
            updateOnStatusChangeOnly={updateActionExtension('reject', 'onStatusChangeOnly')}
            updateActionCookies={updateActionExtension('reject', 'cookies')}
            isUSPRegulation
          />
        )}
      </>) : null}
     
    </Modal>
  );
}

export default ConsentAndRejectModal;