import { Collapse, Empty, Image, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import DetailedBreakdown from './DetailedBreakdown';
import isSameSummaryData from '../../details_pages/isSameSummaryData';

const VendorsAfterRejectAll = ({ summaryDiagnose, summaryPending }) => {
  return (
    <div>
      <Spin spinning={summaryPending} size="large">
        {Object.keys(summaryDiagnose).length > 0 ?(
          <DetailedBreakdown />
        ):(
          <Empty />
        )}
      </Spin>
    </div>
  );
};

const mapStateToProps = function (store) {
  return {
    summaryDiagnose: store.appDashboardState.getIn(['summaryAppDiagnose', 'value']),
    summaryPending: store.appDashboardState.get('summaryAppPending'),
  };
};

export default connect(mapStateToProps, null)(VendorsAfterRejectAll);
