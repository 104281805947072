import { Alert, Flex, Modal,  Space, Switch, Typography } from "antd";
import React, { useState } from "react";
import { validateAndGetStateStringIcon } from "../../../helper";
import { region_title_index_map } from "../../../../../../constants";
import SelectWithSelectAll from "../../../../../../styleguide/components/SelectWithSelectAll";
import { useSelector } from "react-redux";

const { Text } = Typography;

const RespectGpcModal = (props) => {
  const [respectGPC, setRespectGPC] = useState(props.respectGpcCategory.respectGPC);
  const systemPurposes = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']))

  const availableGpcStates =  props.regionsInVendorList?.map((usPrefix) => usPrefix.replace("US_", "STATE_")); // GPC_ENABLED_STATES is STATE_ prefixed as we have been saving category[].respecGPC with STATE_ prefix for national (usnat) solution. 

  function handleSwitchChange(checked) {
    if(checked){
      setRespectGPC(availableGpcStates)
    } else {
      setRespectGPC(null)
    }
  }

  function handleSaveChange() {
    props.setCategories((categories) => categories.map(cat => {
      if(cat.purposeRef?.systemId === 3) {
        respectGPC?.forEach(statePrefixCode => {
          const usPrefixCode = statePrefixCode.replace("STATE_", "US_");
          const iabPurposeOriginal = systemPurposes.find((purpose) => purpose.purposeRef.id === cat.purposeRef?.id)
          const disabledIabChoice = iabPurposeOriginal && !Object.keys(iabPurposeOriginal.configByGeo).includes(usPrefixCode);
          if(!disabledIabChoice) {
            cat.configByGeo[usPrefixCode] = {defaultLegalBasis: "OPT-OUT"}
          }
        })
        cat.respectGPC = respectGPC; // This must stay STATE_ prefixed
      }
      return cat;
    }));
    props.closeModal();
  }

  const gpcDropdownOption = availableGpcStates
    .map((code) => ({
      label: region_title_index_map[code],
      value: code,
      icon: props.isNoFrameworkFlow ? null : validateAndGetStateStringIcon(code)
    }));

  const isRespectGpcEmpty = Boolean(respectGPC) && !Boolean(respectGPC.length);

  return (
    <Modal
      open={props.openModal}
      title="Global Privacy Control"
      okText="Save"
      onOk={handleSaveChange}
      cancelButtonProps={{
        type: "link"
      }}
      okButtonProps={{
        disabled: isRespectGpcEmpty || props.readOnly,
        title: isRespectGpcEmpty ? "Please select at least one region" : null
      }}
      onCancel={props.closeModal}
      width={"50vw"}
    >
      <Flex vertical gap={32}>
        <Space><Switch disabled={props.readOnly} checked={Boolean(respectGPC)} onChange={handleSwitchChange}/><Text>Respect <a href="https://docs.sourcepoint.com/hc/en-us/articles/8357018608019-Global-Privacy-Control-GPC-" target="_blank"><u>Global Privacy Control</u></a></Text></Space>
        <Space direction="vertical" size={4}>
          <Text>Select regions GPC should apply to</Text>
          <SelectWithSelectAll
            options={gpcDropdownOption ?? []}
            value={respectGPC ?? []}
            onChange={setRespectGPC}
            width={320}
            disabled={!Boolean(respectGPC)}
          />
        </Space>
        <Alert type="info" showIcon closable message="Note: Enabling this setting only opts you out of the Sell/Share privacy choice."/>
      </Flex>
    </Modal>
  )
}

export default RespectGpcModal;
