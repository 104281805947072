import React, { useEffect, useState } from "react";
import PropertySelection from "../../../../../common/PropertySelection";
import { CONSENT_SCOPE_TOOLTIP, renderInfoTip } from "../../../helper";
import { findNthTermInAnEntityList } from "../../../../../utils";
import { Space, Typography, Input, Alert, Select, theme, Flex, Row, Col } from "antd";

const { useToken } = theme;

const NameAndProperty = (props) => {
  
  const [ regulationName, setRegulationName ] = useState(props.usPrivacyRegulation.name ?? "");
  const [ selectedPropertiesKeys, setSelectedPropertiesKeys ] = useState(props.usPrivacyRegulation.siteIds);
  const [ consentScope, setConsentScope ] = useState(props.usPrivacyRegulation.consentScope);
  const [ regulationNameError, setRegulationNamenError ] = useState(null);
  const [ siteIdsError, setSiteIdsError ] = useState(false);

  useEffect(()=>{
    props.usPrivacyRegulation.name = regulationName;
    props.usPrivacyRegulation.siteIds =  selectedPropertiesKeys;
    props.usPrivacyRegulation.consentScope = consentScope;
    props.resetValidationError();
  },[regulationName, selectedPropertiesKeys, consentScope])


  useEffect(() => {
    if (props.showError) {
      const isRegulationNameEmpty = regulationName.trim().length === 0;
      const isRegulationNameExists = props.regulationsList?.some(al => al.name === regulationName.trim());
      setRegulationNamenError(
        isRegulationNameEmpty
          ? "Please enter Regulation Name"
          : isRegulationNameExists
          ? <>Regulation with name <b>{regulationName}</b> already exists</>
          : null
      );
  
      setSiteIdsError(
        selectedPropertiesKeys.length === 0
          ? "Select at least one property"
          : false
      );
    } else {
      setRegulationNamenError(null);
      setSiteIdsError(false);
    }
  }, [props.showError, regulationName, selectedPropertiesKeys]);

  useEffect(() => {
    if(!props.isEditFlow && props.regulationsList.size && !Boolean(props.usPrivacyRegulation.name)){
      setRegulationName(findNthTermInAnEntityList(props.regulationsList.map(reg => reg.name), "New Regulation"))
    }
  },[props.regulationsList]);

  const { token } = useToken();

  return (
    <Row gutter={[0, token.margin]}>
      {props.readOnly ? (
        <Col span={24}>
          <Alert type="error" message={<><b>View Only:</b> User does not has access to U.S. Multi State Regulations</>}/> 
        </Col>
      ) : null}
      <Col span={24}>
        {!props.isEditFlow ? <Typography.Text>This wizard will guide you through settings that are required to set up a privacy framework for users on your properties that fall under <b>US state privacy regulations.</b></Typography.Text> : null}
      </Col>
      <Col span={24}>
       <Space size={token.marginXXL}>
        <Space block direction="vertical" size={token.marginXXS}>
          <Typography.Text>Regulation Name</Typography.Text>
          <Input
            style={{width: 387}}
            placeholder="Enter Name"
            value={regulationName}
            onChange={({target: {value}}) => {
              setRegulationName(value);
            }}
            status={regulationNameError ? "error" : null}
            maxLength={60}
            showCount={true}
            disabled={props.readOnly}
          />
          {regulationNameError ? <Typography.Text type="danger">{regulationNameError}</Typography.Text> : null}
        </Space>
        <Flex vertical gap={token.marginXXS}>
          <Space size={token.marginXXS}>
            <Typography.Text>Consent Scope</Typography.Text>
            {renderInfoTip(CONSENT_SCOPE_TOOLTIP)}
          </Space>
          <Select
            value={consentScope}
            onChange={(value) => {setConsentScope(value)}}
            options={[{label: 'Single site', value: 'SINGLE'}, {label: 'Shared site', value: 'GROUP'}]}
            disabled={props.readOnly}
            style={{width: 387}}
          />
        </Flex>
       </Space>
      </Col>
      <Col span={24}>
        <Space direction="vertical" size={token.marginXXS}>
          <Typography.Title level={4}>Property Selection</Typography.Title>
          <Typography.Text>Select the properties or property groups you wish these regulation settings to apply to.</Typography.Text>
          {siteIdsError ? <Typography.Text type="danger">{siteIdsError}</Typography.Text> : null}
        </Space>
      </Col>
      <Col span={24}>
        <div className="step-section">
          <div className={`section-content ${siteIdsError ? 'validation-error' : null}`}>
            <PropertySelection
              selectedPropertiesKeys={selectedPropertiesKeys}
              setSelectedPropertiesKeys={setSelectedPropertiesKeys}
              sites={props.sites}
              siteGroups={props.siteGroups}
            />
          </div>
        </div>
      </Col>     
    </Row>
  )
}

export default NameAndProperty;
