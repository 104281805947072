import React, { useEffect, useMemo, useState } from "react";
import { EditOutlined, InfoCircleFilled, PlusOutlined } from "@ant-design/icons"
import {  GPP_MSPS_TOOLTIP, FRAMEWORK_TERRITORIES_TOOLTIP, renderInfoTip, STATE_SPECIFIC_STRING_STATE_CODES } from "../../../helper";
import { GEO_LIST_TREE_STRUCTURE, LEGACY_EU_COUNTRY_CODES } from "../../../../../../constants";
import _ from "lodash";
import SignatoryIdModal from "./SignatoryIdModal";
import RegionSelector from "../../../../../common/RegionSelector/RegionSelector";
import { Button, Tag, Typography, Alert, Row, Col, Space, Flex, theme } from "antd";
import { useSelector } from "react-redux";
import { usePrevious } from "../../../../../../hooks";

const IndustryFramework = (props) => {
  
  const [ selectedTerritories, setSelectedTerritories ] = useState();
  const [ showRespectGpcAlert, setShowRespectGpcAlert ] = useState(false);
  const [ showRegionsMappingAlert, setShowRegionsMappingAlert ] = useState(false);
  const [ selectedCodes, setSelectedCodes] = useState(null)
  const [ geoScopes, setGeoScope ] = useState({
    appliesGeos : props.usPrivacyRegulation.appliesGeos ?? [],
    exclusionGeos : props.usPrivacyRegulation.exclusionGeos ?? []
  })
  const [ geoScopesError, setGeoScopeError ] = useState(false);
  const [ isSignatoryModalVisible, setIsSignatoryModalVisible ] = useState(false);
  const [ signatoryId, setSignatoryId ] = useState(props.usPrivacyRegulation.signatoryId);
  const systemPurposesJS = useSelector(state => state.usPrivacyReducerState.getIn(['purposes', 'value']))?.toJS()

  useEffect(()=>{
    if(props.showError) {
      setGeoScopeError( geoScopes.appliesGeos.length == 0 && geoScopes.exclusionGeos.length == 0 ? "Select at least one Territory" : false)
    } else {
      setGeoScopeError(false);
    }
  },[props.showError])

  useEffect(()=>{
    props.usPrivacyRegulation.appliesGeos = geoScopes.appliesGeos;
    props.usPrivacyRegulation.exclusionGeos = geoScopes.exclusionGeos;
    props.resetValidationError();
  },[geoScopes])

  useEffect(()=>{
    if(props.summaryPageData){
      props.summaryPageData.geoTerritories = selectedTerritories;
    }
  },[selectedTerritories])

  const prevCodes = usePrevious(selectedCodes);
  useEffect(()=>{
    if(selectedCodes && selectedCodes.length && prevCodes && prevCodes.length && !_.isEqual(selectedCodes, prevCodes)){
      let updatedCategories = _.clone(props.usPrivacyRegulation.categories);
      let usPrefixSelectedCodes = selectedCodes.map(c => c.replace("STATE_", "US_"));
      if(props.onlyUsStates) {
        updatedCategories = updatedCategories.map(cat => {
          if(cat.purposeRef?.systemId === 3 && cat?.respectGPC) {
            setShowRespectGpcAlert(true);
            cat.respectGPC = selectedCodes;
            usPrefixSelectedCodes?.forEach(usPrefixCode => {
              const disabledIabChoice = cat.type === "SYSTEM_PURPOSE" && !Boolean(cat.configByGeo[usPrefixCode])
              if(!disabledIabChoice) {
                cat.configByGeo[usPrefixCode] = {defaultLegalBasis: "OPT-OUT"}
              }
            })
          }
          // udpate configByGeo when appliesGeos and exclusionGeos change.
          if(cat.configByGeo) {
            Object.keys(cat.configByGeo).forEach((usPrefixCode) => {
              if(!usPrefixSelectedCodes.includes(usPrefixCode)) {
                delete cat.configByGeo[usPrefixCode];
              }
            })
            const defaultSysPurpose = systemPurposesJS.find(sysPur => sysPur.id === cat.purposeRef?.id)
            usPrefixSelectedCodes?.forEach(usPrefixCode => {
              if(!Boolean(cat.configByGeo[usPrefixCode]) && defaultSysPurpose?.configByGeo[usPrefixCode]) {
                cat.configByGeo[usPrefixCode] = defaultSysPurpose.configByGeo[usPrefixCode];
                setShowRegionsMappingAlert(true);
              }
            })
          }
          return cat;
        })
      } else if(props.usPrivacyRegulation.categories.filter(c => c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE").some( c => c.respectGPC)){
        setShowRespectGpcAlert(true);
        updatedCategories = updatedCategories.map(c => {
          if(c.defaultLegalBasis == "OPT-OUT" && c.type == "SYSTEM_PURPOSE" && selectedTerritories) {
            c.respectGPC = selectedCodes;
          }
          return c;
        })
      }
      props.usPrivacyRegulation.categories = updatedCategories;
    }
  },[selectedCodes]);

  useEffect(()=>{
    if(props.usPrivacyRegulation.metaData.MspaCoveredTransaction && props.usPrivacyRegulation.metaData.MspaServiceProviderMode) {
      props.usPrivacyRegulation.categories = props.usPrivacyRegulation.categories.filter( cat => !(cat.defaultLegalBasis === 'OPT-OUT' && cat.type !== 'CUSTOM'));
    }
  },[props.usPrivacyRegulation.metaData]);

  const saveSignatoryId = (id, metaData, name,) => {
    setSignatoryId(id);
    props.usPrivacyRegulation.signatoryId = id;
    props.usPrivacyRegulation.signatoryName = name;
    props.usPrivacyRegulation.metaData = metaData;
  }

  const signatoryIdModal =  (
    <SignatoryIdModal
      open={isSignatoryModalVisible}
      signatoryId={signatoryId}
      signatoryName={props.usPrivacyRegulation.signatoryName}
      metaData={props.usPrivacyRegulation.metaData}
      onClose={()=>{
        setIsSignatoryModalVisible(false)
      }}
      handleSave={saveSignatoryId}
    />
  )
  //Commenting out as per https://sourcepoint.atlassian.net/browse/DIA-2945
  // const operationMode = OPERATE_MODE_OPTIONS.find( o => o.value === (props.usPrivacyRegulation.metaData.MspaOptOutOptionMode ? 'MspaOptOutOptionMode' : 'MspaServiceProviderMode'))?.label

  // let regionOptionsWithStateStringStates = GEO_LIST_TREE_STRUCTURE.map(region => {
  //   if(region.key == 'US'){
  //     region.children = region.children.map( state => {
  //       if(STATE_SPECIFIC_STRING_STATE_CODES.includes(state.code)){
  //         state.icon = (<i  className="fas fa-circle" style={{color:'#411F90',width: 10, height: 10,  marginRight: 10}} />)
  //       }
  //       return state;
  //     })
  //   }
  //   return region;
  // })

  let copyBreifForRegionSelection = "For all territories selected the IAB GPP US National String will be sent."
  if(props.isNoFrameworkFlow) {
    copyBreifForRegionSelection = null;
  } else if(props.onlyUsStates) {
    copyBreifForRegionSelection = "For states where an IAB state-string is available, the relevant state-string will be used."
  }

  const { token } = theme.useToken();
  return (
    <Row gutter={[0, 16]}>
      {props.isNoFrameworkFlow ? null : (
        <>
        <Col span={24}>
          <Space direction="vertical" size={8}>
            <Typography.Title level={4}>Industry Framework Signals</Typography.Title>
            <Typography.Text>The US state regulation settings support the IAB Tech Lab's National Multi-State Privacy String. If you are a signatory of the Multi-State Privacy Agreement you can add your ID below.</Typography.Text>
          </Space>
        </Col>
        <Col span={24} className="step-section no-margin-bottom">
          <Flex justify="space-between"  style={{padding: "8px 16px"}}>
            <Space size={8}>
              <Typography.Text>MSPA Identification ID <Typography.Text type="secondary">(optional)</Typography.Text> : </Typography.Text>
              <Button iconPosition="start" icon={signatoryId ? <EditOutlined/> : <PlusOutlined/>} onClick={()=>setIsSignatoryModalVisible(true)}>{signatoryId ??  'Add'}</Button>
              {props.usPrivacyRegulation.signatoryName ? <Typography.Text >{props.usPrivacyRegulation.signatoryName}</Typography.Text> : null}
            </Space>
            <Space>
              <Typography.Text>Global Privacy Platform's Multi-State Privacy String (GPP MSPS)</Typography.Text>{renderInfoTip(GPP_MSPS_TOOLTIP,"",0)}
            </Space>
          </Flex>
        </Col>
        </>
      )}
      <Col span={24}>
        <Space direction="vertical" size={token.marginXXS}>
          <Typography.Title level={4}>Framework Territories</Typography.Title>
          <Typography.Text>Choose the territories where these framework settings should apply. {copyBreifForRegionSelection}</Typography.Text>
          {showRespectGpcAlert ? <Alert style={{marginTop: 10}} showIcon icon={<InfoCircleFilled className="info-icon" />} message={<div>Modifying the Framework Territories will reset the GPC region selection to <i>All</i> regions. You can update the selection under GPC settings.</div>} type="warning"/> : null}
          {showRegionsMappingAlert ? <Alert style={{marginTop: 10}} showIcon icon={<InfoCircleFilled className="info-icon" />} message={<div>Modifying the Framework Territories will update the consent options for those states to reset to default values. You can review and update this on the <i>Region Mapping</i> step.</div>} type="warning"/> : null}
          {geoScopesError ? <Typography.Text type="danger">{geoScopesError}</Typography.Text> : null}
        </Space>
      </Col>
      <Col span={24} className="step-section">
        <div className={`section-content ${geoScopesError ? 'validation-error' : null}`}>
          <RegionSelector
            isIncludeExcludeStructure={true}
            setGeoScope={setGeoScope}
            appliesGeos={geoScopes.appliesGeos}
            exclusionGeos={geoScopes.exclusionGeos}
            updatedCodes={() => {}}
            codes={[]}
            setSelectedTerritoriesData={setSelectedTerritories}
            showStateString={!props.isNoFrameworkFlow}
            selectedRegionsLegend={props.isNoFrameworkFlow ? null : (<Typography.Text><img src="/svg_icons/trademark-s.svg" style={{marginBottom: 2}}/> : Active State Law</Typography.Text>)}
            setSelectedCodes={setSelectedCodes}
            onlyUsStates={props.onlyUsStates}
            filterEEA={props.isNational}
          />
        </div>
      </Col>
      {signatoryIdModal}
    </Row>
  );
}
export default IndustryFramework;
