import React, { useState, useMemo, useEffect } from "react";
import { Tree as AntTree, Divider, Input, Checkbox } from "antd";
import _ from "lodash";
import { useDebouncedSearch } from "../../hooks";

const Tree = (props) => {
  const { selectedCodes, setSelectedCodes } = props;
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filterTreeData, setFilteredTreeData] = useState(props.treeOptions);

  //debouncing search for performance 
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);


  useEffect(() => {
    setFilteredTreeData(filterTreeNodes1(props.treeOptions, searchValue));
    if(props.isNested){
      const expandedKeys = searchValue.trim() ? getExpandedKeys(props.treeOptions, searchValue) : [];
      setExpandedKeys(expandedKeys);
    }
  }, [searchValue, props.treeOptions]);

  const filterTreeNodes1 = (data, searchValue) => {
    if (!searchValue.trim()) return data;
    let copyData = _.cloneDeep(data);
    if(props.isNested){
      copyData = copyData.map((region) => {
        let newRegion = region;
        newRegion.children = region.children.filter((item) =>
          item.title.toLowerCase().includes(searchValue.toLowerCase())
        );
        return newRegion;
      }).filter((r) => r.children?.length);
    } else {
      copyData = copyData.filter( d => d.title.toLowerCase().includes(searchValue.toLowerCase()));
    }
    return copyData
  };
  const getExpandedKeys = (data, searchValue) => {
    return data.reduce((keys, item) => {
      if (
        item.title.toLowerCase().includes(searchValue.toLowerCase()) ||
        (item.children &&
          item.children.length > 0 &&
          item.children.some((child) =>
            child.title.toLowerCase().includes(searchValue.toLowerCase())
          ))
      ) {
        keys.push(item.key);
      }

      if (item.children && item.children.length > 0) {
        const childKeys = getExpandedKeys(item.children, searchValue);
        keys.push(...childKeys);
      }

      return keys;
    }, []);
  };

  const onExpand = (expandedKeys, e) => {
    if (e.expanded) {
      setExpandedKeys([...new Set([...expandedKeys, e.node.key])]);
    } else {
      setExpandedKeys(expandedKeys.filter((key) => key !== e.node.key));
    }
  };

  function handleSelectTerritory(selectedkeys, e){
    if(e.checked) {
      if(e.node.children) {
        setSelectedCodes([...new Set([...selectedCodes, ...e.node.children.map(c=> c.code)])])
      } else {
        setSelectedCodes([...new Set([...selectedCodes, e.node.code])])
      }
    } else {
      if(e.node.children){
        const filteredOutCodes = selectedCodes.filter( code => !e.node.children.map(c => c.code).includes(code))
        setSelectedCodes(filteredOutCodes)    
      } else {
        setSelectedCodes(selectedCodes.filter(code => e.node.code !== code))
      }
    }
  }

  function handleSelectAll({target: {checked}}, e) {
    if(checked) {
      setSelectedCodes(Object.keys(objectMap))
    } else {
      setSelectedCodes([]);
    }
  }
  
  const TREE_PROPS = {
    treeData: filterTreeData,
    checkable: true,
    checkedKeys: props.selectedKeys,
    multiple: true,
    onCheck: handleSelectTerritory,
    showLine: true,
    expandedKeys: expandedKeys,
    showSearch: true,
    searchValue: searchValue,
    selectable: false,
    onExpand: onExpand,
    showIcon: true
  };

  const objectMap = useMemo(
    () => {
      if(props.isNested) {
        return props.treeOptions.reduce((map, item) => {
          item.children.forEach((child) => {
            map[child.code] = child.title;
          });
          return map;
        }, {})
      } else {
        return props.treeOptions.reduce((result, item) => {result[item.code] = item.title;return result;}, {});
      }
    },
    [props.treeOptions]
  );

  const rootClass = props.rootClass ?? "";
  const indeterminate = selectedCodes.length ?  selectedCodes.length < Object.keys(objectMap).length : false;
  const checkAll = Object.keys(objectMap).length === selectedCodes.length;
  return (
    <div className="spsg-tree">
      <div className="search-select-all-container">
        <Input.Search
          placeholder="Search"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
        />
        <Divider style={{margin: "8px 0"}}/>
        {props.all && searchValue.trim().length == 0 ? (
          <>
            <Checkbox onChange={handleSelectAll} indeterminate={indeterminate} checked={checkAll}>{props.all}</Checkbox>
            <Divider style={{margin: "8px 0"}}/>
          </>
        ) : null}
      </div>
      <AntTree rootClassName={rootClass}{...TREE_PROPS} />
    </div>
  );
};

export default React.memo(Tree);
