import React, { useEffect, useState, useMemo } from "react";
import { Popconfirm } from "../../styleguide"
import { Input, Divider, Tree, Typography, Tabs, Checkbox, Tag, Button, Tooltip } from "antd";
import { SearchOutlined, CloseOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDebouncedSearch } from "../../hooks";

const { TabPane } = Tabs;

const groupPropertiesByGroups = (siteGroups, siteIds) => {
  const groupsMap = new Map([
    ['no_group', { groupId: 'no_group', groupName: 'Not in any property group', siteIds: new Set() }]
  ]);

  for (let i = 0; i < siteIds?.length; i++) {
    let foundGroup = false;

    for (let group of siteGroups) {
      if (group.siteIds.has(siteIds[i])) {
        let groupId = String(group.siteId);
        let currentGroup = groupsMap.get(groupId);

        if (currentGroup) {
          currentGroup.siteIds.add(siteIds[i]);
        } else {
          groupsMap.set(groupId, {
            groupId,
            groupName: group.name,
            siteIds: new Set([siteIds[i]])
          });
        }

        foundGroup = true;
        break;
      }
    }

    if (!foundGroup) {
      groupsMap.get('no_group').siteIds.add(siteIds[i]);
    }
  }

  // Convert sets back to arrays
  const result = Array.from(groupsMap.values()).map(group => ({
    ...group,
    siteIds: Array.from(group.siteIds)
  }));

  return result.filter(group => group.siteIds.length > 0);
};

const PropertySelection = (props) => {
  const {
    selectedPropertiesKeys,
    setSelectedPropertiesKeys,
  } = props;
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filteredGroups, setFilteredGroups] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedPropertiesGrouped, setSelectedPropertiesGrouped] = useState([]);

  const indexSiteNameMap = useMemo(() => props.sites.toJS().reduce((map, obj) => (map[obj.id] = obj.domain, map), {}), [props.sites]);
  const siteGroupsCached = useMemo(() => props.siteGroups.sort((a,b) => a.siteId - b.siteId).map((group) => group.set('siteIds', new Set(group.siteIds))),[props.siteGroups])

  useEffect(() => {
    setFilteredGroups(
      props.siteGroups
        .filter((sg) => sg.siteIds && sg.siteIds.size)
        .toJS()
        .map((sg) => ({
          label: sg.name,
          value: sg.siteId,
          siteIds: sg.siteIds,
        }))
    );
  }, [props.siteGroups]);

  useEffect(() => {
    let arr = groupPropertiesByGroups(siteGroupsCached, selectedPropertiesKeys);
    setSelectedPropertiesGrouped(arr);
  }, [selectedPropertiesKeys]);

  const handlePropertiesSelection = (siteIds, e) => {
    if (e.checked) {
      setSelectedPropertiesKeys([...selectedPropertiesKeys, e.node.value]);
    } else {
      setSelectedPropertiesKeys([
        ...selectedPropertiesKeys.filter((key) => key !== e.node.value),
      ]);
    }
  };

  const handleSelectAll = ({ target: { checked } }) => {
    if (checked) {
      setSelectedPropertiesKeys(props.sites.map((site) => site.id).toJS());
    } else {
      setSelectedPropertiesKeys([]);
    }
  };
  useEffect(() => {
    if (!searchValue.trim()) {
      setFilteredProperties(
        props.sites.map((s) => ({ label: s.domain, value: s.id })).toJS()
      );
    } else {
      setFilteredProperties(
        props.sites
          .map((s) => ({ label: s.domain, value: s.id }))
          .filter((s) =>
            s.label.toLowerCase().includes(searchValue.trim().toLowerCase())
          )
          .toJS()
      );
    }
  }, [searchValue, props.sites]);

  const handleSearchGroups = (value) => {
    if (!value.trim())
      setFilteredGroups(
        props.siteGroups
          .toJS()
          .map((sg) => ({
            label: sg.name,
            value: sg.siteId,
            siteIds: sg.siteIds,
          }))
      );
    setFilteredGroups(
      props.siteGroups
        .map((sg) => ({
          label: sg.name,
          value: sg.siteId,
          siteIds: sg.siteIds,
        }))
        .filter((s) =>
          s.label.toLowerCase().includes(value.trim().toLowerCase())
        )
        .toJS()
    );
  };
  const addGroup = (sg) => {
    setSelectedPropertiesKeys([
      ...new Set([...selectedPropertiesKeys, ...sg.siteIds]),
    ]);
  };

  const removeGroup = (sg) => {
    setSelectedPropertiesKeys(
      selectedPropertiesKeys.filter((siteId) => !sg.siteIds.includes(siteId))
    );
  };

  const groupOptions = filteredGroups.map((sg) => {
    const isPlusDisabled = sg.siteIds.every((site) =>
      selectedPropertiesKeys.includes(site)
    );
    const isMinusDisabled = !sg.siteIds.some((site) =>
      selectedPropertiesKeys.includes(site)
    );
    return (
      <div className="property-group">
        <div className="button-group">
          <Button
            className="property-group-btn"
            type="primary"
            disabled={isMinusDisabled}
            onClick={() => removeGroup(sg)}
            size="small"
            icon={isMinusDisabled ? null : <MinusOutlined />}
          />
          <Button
            type="primary"
            className="property-group-btn"
            disabled={isPlusDisabled}
            onClick={() => addGroup(sg)}
            size="small"
            icon={isPlusDisabled ? null : <PlusOutlined />}
          />
        </div>
        <Typography.Text>{sg.label}</Typography.Text>
      </div>
    );
  });

  const indeterminate = selectedPropertiesKeys.length
    ? selectedPropertiesKeys.length < Object.keys(indexSiteNameMap).length
    : false;
  const checkAll =
    Object.keys(indexSiteNameMap).length ===
    selectedPropertiesKeys.length;

  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);

  
  const renderSelectedPropertyGroups = (group) => {
    const chips = group.siteIds.map((id) => (
      <Tag closable onClose={(e) =>{removeSiteId(id)}} key={`site-${id}`}>
        {indexSiteNameMap[id]}
      </Tag>
    ));

    return (
      <div className="property-group">
        <div className="group-name">
          {group.groupName}
          <Tooltip
            title="Remove property group"
          >
            <Button size="small" icon={<CloseOutlined/>} type="link" onClick={() => removeSiteGroup(group)}/>
          </Tooltip>
        </div>
        <div className="tags-container">
          {chips}
        </div>
      </div>
    );
  };

  const removeSiteId = (siteId) => {
    setSelectedPropertiesKeys(
      selectedPropertiesKeys.filter((id) => id !== siteId)
    );
  };

  const removeSiteGroup = (group) => {
    setSelectedPropertiesKeys(
      selectedPropertiesKeys.filter((id) => !group.siteIds.includes(id))
    );
  };

  const stepSection = document.getElementsByClassName("step-section")[0];

  if(stepSection) {
    console.log(stepSection.offsetTop);
  }

  return (
    <div className="properties-section">
      <div className="properties-checkgroup-section">
        <Tabs defaultActiveKey="1" centered>
          <TabPane tab="Properties" key="1">
            <div>
              <Input.Search
                placeholder="Search"
                type="search"
                onChange={({ target: { value } }) => {
                  debouncedChangeHandler(value);
                }}
              />
              <Divider style={{margin: "8px 0"}}/>
              {searchValue.trim().length ? null : (
                <>
                  <Checkbox
                    onChange={handleSelectAll}
                    indeterminate={indeterminate}
                    checked={checkAll}
                    className="select-all-properties-checkbox"
                  >
                    Select All Properties
                  </Checkbox>
                  <Divider style={{margin: "8px 0"}}/>
                </>
              )}
              <div className="properties-checkgroup-options">
                <Tree
                  treeData={filteredProperties}
                  multiple
                  checkable
                  selectable={false}
                  onCheck={handlePropertiesSelection}
                  checkedKeys={selectedPropertiesKeys}
                  fieldNames={{
                    title: "label",
                    key: "value",
                    children: "children",
                  }}
                />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Groups" key="2">
            <div>
              <Input.Search
                placeholder="Search"
                type="search"
                onChange={({ target: { value } }) => {
                  handleSearchGroups(value);
                }}
              />
              <Divider style={{margin: "8px 0"}}/>
              <div className="property-group-options">{groupOptions}</div>
            </div>
          </TabPane>
        </Tabs>
      </div>
      <div className="selected-properties">
        <div className="heading">
          <Typography.Text strong>Selected</Typography.Text>
          {selectedPropertiesKeys.length ? (
            <Popconfirm
              overlayClassName="exit-wizard-confirmation"
              title={
                <div>
                  Are you sure you want to remove all selected properties?
                </div>
              }
              // visible={exitStatus}
              placement="topRight"
              trigger="click"
              icon={null}
              onConfirm={() => setSelectedPropertiesKeys([])}
              // onCancel={() => updateExitStatus(false)}
              // onOpenChange={(visible) => updateExitStatus(visible)}
              okText="Yes"
              cancelText="No"
            >
              <Button type="link" icon={<CloseOutlined/>} iconPosition="start">Remove all</Button>
            </Popconfirm>
          ) : null}
        </div>
        <Divider style={{margin: 0}}/>
        <div
          className={`property-groups-container ${
            selectedPropertiesKeys.length ? "" : "centered"
          }`}
        >
          {selectedPropertiesKeys.length ? (
            selectedPropertiesGrouped.map((group) => {
              return renderSelectedPropertyGroups(group);
            })
          ) : (
            <div>Selected Properties will appear here.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PropertySelection, (prevProps, nextProps) => _.isEqual(prevProps.selectedPropertiesKeys, nextProps.selectedPropertiesKeys) && _.isEqual(prevProps.sites, nextProps.sites))
