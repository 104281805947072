import PropTypes from 'prop-types';
import { List } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes';
import React, { useMemo, useEffect, useState } from 'react';
import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons"
import { TreeSelect } from 'antd';
import { includes } from 'lodash';

const privacyLensFeatures = [
  'Privacy Lens - Advanced',
  'Privacy Lens - Basic',
  'Privacy Lens - Read Only',
];
const diagnoseDashboardMetricsFeatures = [
  'Diagnose - Non disclosed vendors observed',
  'Diagnose - Vendors triggered prior to consent',
  'Diagnose - Disclosed Vendors',
  'Diagnose - Possible Fingerprinting',
  'Diagnose - Data leaving the EEA',
  'Diagnose - All Cookies Found',
  'Diagnose - Cookies with long lifespans',
  'Diagnose - Vendors after opt out',
  'Diagnose - Tracking Tech Detected',
];

const FeatureAccessTreeSelect = (props) => {
  const {
    permissionsList,
    editing,
    userFeatureAccess = List(['All access']),
    setSelectedFeatures,
    hasPrivacyLens,
  } = props;

  const [selectedFeatures, setSelectedFeaturesData] = useState(userFeatureAccess)

  const treeData = useMemo(() => {
    const groupedPermissionsList = permissionsList.reduce(
      (acc, permission) => {
        if (privacyLensFeatures.includes(permission.name)) {
          acc.privacyLensList.push({
            title: permission.name,
            value: permission.name,
            key: permission.name,
            disabled: true,
            disableCheckbox: true,
          });
        } else if (diagnoseDashboardMetricsFeatures.includes(permission.name)) {
          acc.diagnoseMetricsList.push({
            title: permission.name,
            value: permission.name,
            key: permission.name,
          });
        } else if (permission.name !== 'Diagnose Dashboard') {
          acc.otherPermissionsList.push({
            title: permission.name,
            value: permission.name,
            key: permission.name,
          });
        }
        return acc;
      },
      {
        privacyLensList: [],
        diagnoseMetricsList: [],
        otherPermissionsList: [],
      }
    );

    const sortedPrivacyLensList = groupedPermissionsList.privacyLensList.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    const sortedDiagnoseMetricsList = groupedPermissionsList.diagnoseMetricsList.sort((a, b) =>
      a.title.localeCompare(b.title)
    );
    const sortedOtherPermissionsList = groupedPermissionsList.otherPermissionsList.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    return [
      {
        title: 'All access',
        value: 'All access',
        key: 'All access',
      },
      {
        title: 'Privacy Lens',
        value: 'Privacy Lens - Group',
        key: 'Privacy Lens - Group',
        disabled: !hasPrivacyLens,
        children: sortedPrivacyLensList,
      },
      {
        title: 'Diagnose Dashboard Metrics',
        value: 'Diagnose Dashboard',
        key: 'Diagnose Dashboard',
        children: sortedDiagnoseMetricsList,
      },
      ...sortedOtherPermissionsList,
    ].sort((a, b) => a.title.localeCompare(b.title));;
  }, [permissionsList, hasPrivacyLens]);


  useEffect(() => {
    let updateduserFeatureAccess = userFeatureAccess
    if (updateduserFeatureAccess && updateduserFeatureAccess?.size > 0) {
      if (updateduserFeatureAccess.includes('Privacy Lens - Basic')) {
        updateduserFeatureAccess = updateduserFeatureAccess.concat('Privacy Lens - Group')
      }
    }

    setSelectedFeaturesData(updateduserFeatureAccess)

  }, [userFeatureAccess]);

  const computeSelectedFeatures = (featureAccess) => {
    let updatedFeatures = List(featureAccess); // Convert featureAccess to an Immutable List

    const conditionalAddPrivacyLensFeature = () => {
      // select privacy lens default option
      return permissionsList.some(p => p.name === 'Privacy Lens - Basic')
        ? updatedFeatures.concat('Privacy Lens - Basic')
        : updatedFeatures.concat('Privacy Lens - Read Only');
    };

    // if (!updatedFeatures || updatedFeatures.includes('All access')) {
    //   // If "All access" is selected, select all features
    //   updatedFeatures = List(permissionsList.map((p) => p.name));
    //   updatedFeatures = updatedFeatures.concat(updatedFeatures, 'All access');
    // } else {

    if (updatedFeatures.includes('Privacy Lens - Group')) {
      //updatedFeatures = updatedFeatures.remove(updatedFeatures.indexOf('Privacy Lens - Group'));
      updatedFeatures = conditionalAddPrivacyLensFeature()
    } else if (!updatedFeatures.includes('Privacy Lens - Group')) {
      if (updatedFeatures.indexOf('Privacy Lens - Basic') !== -1) {
        updatedFeatures = updatedFeatures.remove();
      }
    }



    const originalSize = permissionsList?.size - (privacyLensFeatures.length + diagnoseDashboardMetricsFeatures.length) + 2

    if (!updatedFeatures.includes('All access') && featureAccess.length === originalSize) {
      // If "All access" is deselected, remove all permissions
      updatedFeatures = updatedFeatures.clear();
    }

    return updatedFeatures; // Return as Immutable List
  };


  const handleChange = (selectedValues) => {
    const updatedFeatures = computeSelectedFeatures(selectedValues);
    setSelectedFeatures(updatedFeatures);
  };

  const getListOfFeatures = (featureAccess) => {
    let listOfFeatures;
    if (!featureAccess || featureAccess.includes('All access')) {
      listOfFeatures = <p className="all-access">All access</p>;
    } else {
      const filterUserFeatureAccess = permissionsList?.filter(feature => featureAccess?.includes(feature.name)).map(f => f.name);
      listOfFeatures = (
        <div className="sites-container flex-row">
          <div className="site">{filterUserFeatureAccess.slice(0, 1)}</div>
          {filterUserFeatureAccess.size > 1 ? (
            <div className="more">{`+ ${filterUserFeatureAccess.size - 1
              } more`}</div>
          ) : null}
        </div>
      );
    }
    return listOfFeatures;
  };




  if (editing) {
    return (<TreeSelect
      treeData={treeData}
      value={selectedFeatures?.toJS()}
      onChange={handleChange}
      maxTagCount={2}
      treeCheckable
      showCheckedStrategy={TreeSelect.SHOW_PARENT}
      placeholder="Select Permissions"
      style={{ width: '100%' }}
      switcherIcon={({ isLeaf, expanded }) =>
        isLeaf ? null : expanded ? <MinusSquareOutlined /> : <PlusSquareOutlined />
      }
    />)
  } else {
    return getListOfFeatures(selectedFeatures)
  }

};

FeatureAccessTreeSelect.propTypes = {
  permissionsList: ImmutablePropTypes.listOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    })
  ).isRequired,
  editing: PropTypes.bool,
  userFeatureAccess: ImmutablePropTypes.listOf(PropTypes.string).isRequired,
  setSelectedFeatures: PropTypes.func.isRequired,
  hasPrivacyLens: PropTypes.bool,
};

export default React.memo(FeatureAccessTreeSelect);
