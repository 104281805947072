import { checkStatus,DIAGNOSE_SDK_BASEURL } from '../helper.js';

const getBaseUrl = () => {
    return `${DIAGNOSE_SDK_BASEURL}/api/`;
};

export function createDiagnosePropertyAjax(property_domain , accountId) {
    const url = 'createProperty';
    return fetch(getBaseUrl()+ url,
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                property_domain,
                account_id:accountId,
            }),
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on create 'Alerts Email Preferences' data: status ${err.response.status}` : err);
    });
}

export async function importDiagnosePropertyAjax(property_list , accountId) {
    const url = 'importExistingProperties';
    try {
        const response = await fetch(getBaseUrl() + url,
            {
                method: 'POST',
                credentials: 'include',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    account_id:accountId,
                    property_list: property_list,
                }),
            });
        const resp = await checkStatus(response);
        return resp.json();
    } catch (err) {
        console.log(err);
        alert(err.response ? `Error on diagnose property' data: status ${err.response.status}` : err);
    }
}

export function getAllPropertiesListAjax(accountId) {
    const url = 'getProperty/';
    return fetch(getBaseUrl()+ url,
        {
            method: 'POST',
            credentials: 'include',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                account_id:accountId,
                type: 'app'
            }),
        },
    )
    .then(checkStatus)
    .then((resp) => resp.json())
    .catch((err) => {
        console.log(err);
        alert(err.response ? `Error on create Get properties lists data: status ${err.response.status}` : err);
    });
}