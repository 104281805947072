import { Modal, Radio, Flex, Tag, Typography, theme, Space } from "antd";
import React, { useState } from "react";
import SVG from "react-inlinesvg";
import { renderInfoTip } from "../helper";

const { Text } = Typography;

const FrameworkSelectionModal = (props) => {
  const [selectedFramework, setSelectedFramework] = useState(null);

  const handleOnOk = () => {
    props.handleCreate(selectedFramework);
  };

  const handleFrameworkChange = (e) => {
    setSelectedFramework(e.target.value);
  };

  const { token } = theme.useToken();

  return (
    <Modal
      title="Select US Framework"
      open={props.isModalOpen}
      onOk={handleOnOk}
      okText="Start regulation setup"
      okButtonProps={{disabled: !selectedFramework}}
      onCancel={props.onCancel}
      cancelButtonProps={{type: "link"}}
      width={520}
    >
      <Radio.Group
        onChange={handleFrameworkChange}
        value={selectedFramework}
        className="framework-selection-radio-group"
      >
        <Radio value={"standard"}>
          <Flex vertical={true} gap={token.marginSM} align='center'>
            <SVG src="/svg_icons/us-map-plain-empty.svg" />
            <Space direction="vertical" size={token.marginXXS}>
              <Text>Standard</Text>
              <Text type="secondary">Build own US privacy</Text>
              <Text type='secondary'>Customizable</Text>
            </Space>
          </Flex>
        </Radio>
        <Radio value={"national"}>
          <Tag color="orange">IAB</Tag>
          <Flex vertical={true} gap={token.marginSM} align='center'>
            <SVG src="/svg_icons/us-map-plain-filled.svg" className="filled-us-map"/>
            <Space direction="vertical" size={token.marginXXS}>
              <Text>National</Text>
              <Text type="secondary">Nationwide coverage {renderInfoTip("Sets the IAB GPP US National String in all applied territories")}</Text>   
              <Text type='secondary'>Applies strictest laws</Text>
            </Space>
          </Flex>
        </Radio>
        {/* <Radio autoFocus value={"state"}>
          <Tag color="orange">IAB</Tag>
          <Flex vertical={true} gap={token.marginSM} align='center'>
            <SVG src="/svg_icons/us-map-state-borders.svg" />
            <Space direction="vertical" size={token.marginXXS}>
              <Text>State</Text>
              <Text type="secondary">Tailored for state privacy </Text>
              <Text type='secondary'>State-specific laws</Text>
            </Space>
          </Flex>
        </Radio> */}
      </Radio.Group>
    </Modal>
  );
};

export default FrameworkSelectionModal;
