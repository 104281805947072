import React, { useEffect, useMemo, useState } from "react";
import { Spin, Input } from "antd";
import { useDebouncedSearch } from "../../../../../../hooks";
import VendorsTable from "./VendorsTable";
import { SearchOutlined } from "@ant-design/icons";

const  AddSystemVendors = (props) => {
  const [searchValue, setSearchValue] = useState('');
  const [vendorTableFilters, setVendorTableFilters] = useState({});

  useEffect(() => {
    if(!props.pendingRequestsMap.get('globalVendors') && props.globalVendors.size){
      let vendorsHashMap = props.vendorsHashMap
      props.globalVendors?.forEach(vendor => {
        vendorsHashMap[vendor.id] = props.categories.map(cat => cat.id ?? cat.privacyChoice);
      })
      props.setVendorsHashMap(vendorsHashMap);
    }
  }, [props.globalVendors]);

  const dataSource = useMemo(() => {
    if(!props.pendingRequestsMap.get('globalVendors') && props.globalVendors.size){
      let dataSource = props.globalVendors?.toJS().filter( vendor => !props.existingVendorIds.includes(vendor.id)).map(vendor => ({...vendor, vendorId: vendor.id, id: null}));
      dataSource = searchValue.trim().length ? dataSource?.filter(vendor => vendor.vendorId.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1 || vendor.name.toLowerCase().indexOf(searchValue.trim().toLowerCase()) !== -1) : dataSource
      return dataSource;
    }
    return [];
  },[searchValue, props.globalVendors])

  //debouncing search for performance
  const debouncedChangeHandler = useDebouncedSearch(setSearchValue, 700);
  const emptyTableTextCheck = vendorTableFilters.name || vendorTableFilters.categories || searchValue.trim().length;

  return props.pendingRequestsMap.get('globalVendors') ? <Spin tip={"Loading"}/> : (
    <div>
      <div className="vendor-management-table-header-bar">
        <Input.Search
          placeholder="Search vendor name or ID"
          type="search"
          onChange={({ target: { value } }) => {
            debouncedChangeHandler(value);
          }}
          style={{width: 360}}
        />
      </div>
      <div>
        <VendorsTable
          isAddSysVendorsMode
          dataSource={dataSource}
          setSelectedVendors={props.setSelectedVendorIds}
          isLoading={props.pendingRequestsMap.get('globalVendors')}
          categoryOptions={props.categories.map(cat => ({value: cat.id ?? cat.privacyChoice, label: cat.privacyChoice ?? cat.name}))}
          vendorsHashMap={props.vendorsHashMap}
          updateInstantly={false}
          selectedVendors={props.selectedVendorIds}
          setVendorTableFilters={setVendorTableFilters}
          emptyTableTextCheck={emptyTableTextCheck}
        />
      </div>
    </div>  
  );
}

export default AddSystemVendors;