import PropTypes from "prop-types";
import React from "react";
import { Router, Route, browserHistory, Redirect } from "react-router";
import { List } from "immutable";
import { connect } from "react-redux";
import { getParameterByName } from './utils';

import { User } from "../records/account_records";

import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";

import HomePage from "./HomePage";
import Layout from "./layout/MainLayout";
import DialogueLayout from "./layout/DialogueLayout";
import ConsentLayout from "./layout/ConsentLayout";
import SdksLayout from "./layout/SdksLayout";
import ReportsLayout from "./layout/ReportsLayout";
import CCPALayout from "./layout/CCPALayout";

import EditAccount from "./user/EditAccount";
import CreateAccount from "./admin/CreateAccount";
import Domains from "./users/Domains";
import ManageUsers from "./admin/ManageUsers";
import AdminSettingsPage from "./admin/AdminSettingsPage";
import ChangeLog from "./admin/ChangeLogPage";
import SysTemplates from "./admin/SystemTemplates/SysTemplates";

import Zendesk from "./integrations/Zendesk";
import ZendeskLogout from "./integrations/ZendeskLogout";

import DashboardPage from "./dashboard/DashboardPage";
import SitesPage from "./dialogue/sites/SitesPage.js.jsx";
import PropertiesPage from "./dialogue/properties/PropertiesPage.js.jsx";
import CampaignsPage from "./dialogue/campaigns/CampaignsPage.js.jsx";
import CampaignsPageV2 from "./dialogue/campaigns_v2/CampaignsPage.js.jsx";
import PartitionsetsPage from "./dialogue/partitionsets/PartitionsetsPage.js.jsx";
import ScenariosPage from "./dialogue/scenarios/ScenariosPage.js.jsx";
import ScenarioDashboard from "./dialogue/scenariosNew/ScenarioDashboard.jsx";
import TemplatePage from "./dialogue/scenariosNew/TemplateBuilder/TemplatePage";
import MessagesPage from "./dialogue/messages/MessagesPage.js.jsx";
import MessageEditPage from "./dialogue/messages/MessageEditPage.js.jsx";
import MessagePreviewPage from "./dialogue/messages/MessagePreviewPage";
import StatusPage from "./status/StatusPage";

import MessagesV2 from "./v2/messages/Messages";

import VendorLists from "./consent/vendor_list/VendorLists.jsx";
import SdkLists from "./sdks/sdk_list/SdkLists.jsx";
import VendorListEdit from "./consent/vendor_list/edit/VendorListEdit.jsx";
import VendorListEditV2 from "./consent/vendor_list_v2/edit/VendorListEdit.jsx";
import VendorListsV2 from "./consent/vendor_list_v2/VendorLists.jsx";
import VendorListsV3 from "./consent/vendor_list_v2/VendorListsV3.jsx";

import CCPAVendorLists from "./ccpa/vendor_list/VendorLists.jsx";
import USPrivacyRegulationList from "./consent/us_privacy_regulation/USPrivacyRegulationList";
import USPrivacyRegulationCreate from "./consent/us_privacy_regulation/NewPrivacyRegulation";
import USPrivacyRegulationEdit from "./consent/us_privacy_regulation/EditPrivacyRegulation"
import CCPADataRequest from "./ccpa/dataprivacy/DataPrivacy.jsx";
import CCPADataReporting from "./ccpa/reporting/Reporting.jsx";

import CCPAVendorListEdit from "./ccpa/vendor_list/edit/VendorListEdit.jsx";
import CCPAPrivacyManagerList from "./ccpa/privacy_manager/PrivacyManagerList.jsx";
import CCPAPrivacyManagerEdit from "./ccpa/privacy_manager/PrivacyManagerEdit.jsx";
import CCPAConsentPerformanceReport from "./ccpa/performance/ConsentPerformanceReport.jsx";
import CCPARequests from "./ccpa/requests/RequestList.jsx";

import PrivacyManagerList from "./consent/privacy_manager/PrivacyManagerList.jsx";
import PrivacyManagerEdit from "./consent/privacy_manager/PrivacyManagerEdit.jsx";
import ConsentPerformanceReport from "./consent/performance/ConsentPerformanceReport.jsx";
import ConsentPerformanceReportNew from "./consent/performanceNew/ConsentPerformanceReport.jsx";
import ConsentPerformanceReportV2 from "./consent/gdpr_performance_tcfv2/ConsentPerformanceReportV2.jsx";
import ConsentPerformanceReportTcfV2 from "./consent/gdpr_performance_tcfv2_v2/ConsentPerformanceReportV2.jsx";
import CCPAReporting from "./ccpa/ccpa_reporting/CCPAReporting.jsx";

import ConsentCompensationList from "./consent/consent_compensation/ConsentCompensationList.jsx";
import ConsentCompensationEdit from "./consent/consent_compensation/edit/ConsentCompensationEdit.jsx";

import Adblock from "./reports/Adblock";
import AltPayPage from "./reports/AltPayPage";
import Whitelist from "./reports/Whitelist";
import CustomReport from "./reports/CustomReport";
import ManageReports from "./reports/manage_reports/ManageReports";
import CreateReport from "./reports/manage_reports/CreateReport";

import SdkAndroid from "./sdks/sdk_list/SdkAndroid.jsx";
import SdkIos from "./sdks/sdk_list/SdkIos.jsx";

import ConsentQualityLayout from "./layout/ConsentQualityLayout";
import DomainSets from "./consent_quality/domain_set/DomainSetsPage";
import ScoringResults from "./consent_quality/scoring_results/ScoringResultsPage";
import ScoringResultsDetail from "./consent_quality/scoring_results/DetailedResults/DetailedResults";
import Standards from "./consent_quality/standard/StandardsPage";
import ManageGoldenSets from "./consent_quality/golden_score/ManageGoldenSets";
import RulePerformance from "./consent_quality/golden_score/RulePerformance";
import GoldenSetComparison from "./consent_quality/golden_score/GoldenSetComparison";
import GoldenSetDetail from "./consent_quality/golden_score/GoldenSetDetail";
import Admin from "./consent_quality/admin/AdminPage";
import AdminAccuracy from "./consent_quality/admin/accuracy/AdminAccuracy";
import SnapshotAndEvolution from "./consent_quality/admin/accuracy/SnapshotAndEvolution";
import ManualScoring from "./consent_quality/admin/accuracy/ManualScoring";
import InaccuracyReport from "./consent_quality/admin/accuracy/InaccuracyReport";

import MasqPage from "./masq/MasqPage";

import { getUserDetails, setMasqUserName } from "../actions/account_actions";
import activeCampaignWrapper from "./dialogue/activeCampaignWrapper";

import "../assets/stylesheets/dashboard.less";
import DiagnoseDashboardLayout from "./layout/Diagnose/DashboardLayout";
import AppDashboardLayout from "./layout/Diagnose/AppDashboardLayout";
import VendorTraceLayout from "./layout/Diagnose/VendorTraceLayout";
import DiagnoseDashboard from "./diagnose/dashboard/DiagnoseDashboard";
import AppMetricsDashboard from "./diagnose/dashboard/AppMetricsDashboard";
import NonDisclosedVendorsObserved
  from "./diagnose/dashboard/details_pages/non_disclosed_vendors_observed/NonDisclosedVendorsObserved";
import VendorsTriggeredPriorToConsent
  from "./diagnose/dashboard/details_pages/vendors_triggered_prior_to_consent/VendorsTriggeredPriorToConsent";
import DisclosedVendors
  from "./diagnose/dashboard/details_pages/disclosed_vendors/DisclosedVendors";
import PossibleFingerprinting
  from "./diagnose/dashboard/details_pages/possible_fingerprinting/PossibleFingerprinting";
import DataLeavingTheEEA
  from "./diagnose/dashboard/details_pages/data_leaving_the_EEA/DataLeavingTheEEA";
import CookiesWithLongLifespans
  from "./diagnose/dashboard/details_pages/cookies_with_long_lifespans/CookiesWithLongLifespans";
import AllCookiesFound
  from "./diagnose/dashboard/details_pages/all_cookies_found/AllCookiesFound";
import FreeScanSignUp from "./free_scan/FreeScanSignUp.jsx";
import VendorTraceDashboard from "./diagnose/vendor_trace/VendorTraceDashboard";
import VendorsAfterTargetedAdvertisingOptOut from "./diagnose/dashboard/details_pages/vendors_after_opt_out/VendorsAfterOptOut";
import DiagnoseNotifications from "./diagnose/notifications/Notifications";
import CreateConfiguration from "./consent/preferences/CreateConfiguration.jsx";
import PreferencesHomePage from "./consent/preferences/PreferencesHomePage.jsx";
import EditConfigurations from "./consent/preferences/EditConfiguration.jsx";
import ErrorPage from './500/ErrorPage.jsx';
import ErrorBoundary from './500/ErrorHandling.jsx';
import OauthRedirectUrl from "./consent/preferences/IntegrationHub/OauthRedirectUrl.jsx";
import VendorsTriggeredPriorToConsentApp
  from "./diagnose/dashboard/app_details_page/vendors_triggered_prior_to_consent/VendorsTriggeredPriorToConsent"
import VendorsAfterTargetedAdvertisingOptOutApp from "./diagnose/dashboard/app_details_page/vendors_after_opt_out/VendorsAfterOptOut";
import AllVendorsFound from "./diagnose/dashboard/app_details_page/all_vendors_found/AllVendorsFound.jsx";
import VendorsAfterRejectAll from "./diagnose/dashboard/app_details_page/vendors_after_reject_all/VendorsAfterRejectAll.jsx";
import TrackingTechDetected from "./diagnose/dashboard/details_pages/tracking_tech_detected/TrackingTechDetected.jsx";
import VendorsDetails from "./diagnose/dashboard/details_pages/tracking_tech_detected/VendorDetails.jsx";

import AppProperties from '../components/diagnose/app_properties/PropertiesDashboard.jsx'

const AUTH_ROUTES = ['/users/sign_in'];

class App extends React.Component {
  static propTypes = {
    getUserDetails: PropTypes.func.isRequired,
    currentUser: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      siteAccess: PropTypes.arrayOf(PropTypes.string),
      isAdmin: PropTypes.bool,
      id: PropTypes.number,
      accountId: PropTypes.number,
      accountName: PropTypes.string,
      swithUser: PropTypes.bool
    }),
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.currentUser && this.props.currentUser) {
      // debugger
      // this.checkAndRedirect();
    }
  }

  componentDidMount() {
    const passwordReset = getParameterByName('password_reset', window.location);
    if (passwordReset) {
      browserHistory.push(`/users/sign_in${window.location.search}`);
      return;
    }
    if (location.pathname !== '/sign_up' && location.pathname !== '/free_scan') {
      this.props.getUserDetails().then(resp => {
        if (!resp) {
          let newLocation = '/users/sign_in'
          if (window.location.pathname.indexOf(newLocation) !== 0) {
            newLocation += `?redirect_to=${encodeURIComponent(window.location.pathname + window.location.search)}`
          }
          browserHistory.push(newLocation)
        } else {
          this.checkAndRedirect();
        }
      });
    }
  }

  checkAndRedirect = () => {
    if (AUTH_ROUTES.includes(location.pathname)) {
      browserHistory.push('/');
      return;
    }

    const { query: { site_group_id: siteGroupId, site_id: siteId, } } = browserHistory.getCurrentLocation();
    const { site_access: siteAccess } = this.props.currentUser;

    const hasAllSitesInSiteGroup = (siteGroupId) => {
      const siteGroup = this.props.siteGroups.find(sg => sg.id === siteGroupId);
      if (siteGroup) {
        return siteGroup.toJS().siteIds.every(id => siteAccess.map(s => +s).includes(+id));
      };

      return true;
    };

    const isProhibitedUrlForAccountFeatures = (pageUrl, feature, userFeature = null) => {
      const currentUser = this.props.currentUser;
      return location.pathname.includes(pageUrl) && !(currentUser.accountFeatures.includes(feature) && (
        (!userFeature || currentUser.allFeatureAccess || currentUser.featureAccess?.includes(userFeature))
      ));
    };

    // if doesnt have access to site group or the site, redirect home
    if ((siteAccess && !hasAllSitesInSiteGroup(siteGroupId)) || (!siteGroupId && siteId && siteAccess && !siteAccess.includes(+siteId))) {
      return browserHistory.push('/');
    }
    if (
      isProhibitedUrlForAccountFeatures('diagnose_dashboard', 'diagnose_dashboard', 'Diagnose Dashboard')
      || isProhibitedUrlForAccountFeatures('vendor_trace', 'diagnose_vendor_trace')
      || isProhibitedUrlForAccountFeatures('messages-custom', 'custom_messages')
      || isProhibitedUrlForAccountFeatures('non_disclosed_vendors_observed', 'diagnose_metric_non_disclosed_vendors', 'Diagnose - Non disclosed vendors observed')
      || isProhibitedUrlForAccountFeatures('vendors_triggered_prior_to_consent', 'diagnose_metric_vendors_prior_to_consent', 'Diagnose - Vendors triggered prior to consent')
      || isProhibitedUrlForAccountFeatures('disclosed_vendors', 'diagnose_metric_disclosed_vendors', 'Diagnose - Disclosed Vendors')
      || isProhibitedUrlForAccountFeatures('possible_fingerprinting', 'diagnose_metric_fingerprinting', 'Diagnose - Possible Fingerprinting')
      || isProhibitedUrlForAccountFeatures('data_leaving_the_EEA', 'diagnose_metric_data_leaving_eea', 'Diagnose - Data leaving the EEA')
      || isProhibitedUrlForAccountFeatures('cookies_with_long_lifespans', 'diagnose_metric_cookies_with_long_lifespans', 'Diagnose - Cookies with long lifespans')
      || isProhibitedUrlForAccountFeatures('all_cookies_found', 'diagnose_metric_all_cookies_found', 'Diagnose - All Cookies Found')
      || isProhibitedUrlForAccountFeatures('vendors_after_opt_out', 'diagnose_metric_vendors_after_opt_out', 'Diagnose - Vendors after opt out')
      || isProhibitedUrlForAccountFeatures('tracking_tech_detected', 'diagnose_metric_tracking_tech_detected', 'Diagnose - Tracking tech detected')
      || isProhibitedUrlForAccountFeatures('diagnose/alerts', 'diagnose_dashboard') && isProhibitedUrlForAccountFeatures('diagnose/alerts', 'diagnose_vendor_trace')
    ) {
      return browserHistory.push('/');
    }

  }

  render() {
    const privacyLensRoutes = (
      <Route
        component={ConsentQualityLayout}
      >
        <Route
          path="/privacy_lens/admin/accuracy"
          component={AdminAccuracy}
        />
        <Route
          path="/privacy_lens/admin/accuracy/snapshot_and_evolution"
          component={SnapshotAndEvolution}
        />
        <Route
          path="/privacy_lens/admin/accuracy/manual_scoring"
          component={ManualScoring}
        />
        <Route
          path="/privacy_lens/admin/accuracy_inaccuracy_report"
          component={InaccuracyReport}
        />
        <Route
          path="/privacy_lens/scoring_results"
          component={ScoringResults}
        />
        <Route
          path="/privacy_lens/scoring_results/detail"
          component={ScoringResultsDetail}
        />
        <Route
          path="/privacy_lens/domain_sets"
          component={DomainSets}
        />
        <Route
          path="/privacy_lens/standards"
          component={Standards}
        />
        <Route
          path="/privacy_lens/admin"
          component={Admin}
        />
        <Route
          path="/privacy_lens/golden_score"
          component={ManageGoldenSets}
        />
        <Route
          path="/privacy_lens/golden_score/golden_set"
          component={GoldenSetDetail}
        />
        <Route
          path="/privacy_lens/golden_score/rule_performance"
          component={RulePerformance}
        />
        <Route
          path="/privacy_lens/golden_score/golden_set_comparison"
          component={GoldenSetComparison}
        />
      </Route>
    );

    return (
      <Router history={browserHistory}>
        <Route component={ErrorBoundary}>
          <Route
            path="/"
            component={HomePage}
          />
          <Route
            path="/users/sign_in"
            component={SignIn}
          />
          <Route
            path="/sign_up"
            component={SignUp}
          />
          <Route
            path="/free_scan"
            component={FreeScanSignUp}
          />
          <Route path="/diagnose_dasboard/time_pass" component={() => "Hello World"}></Route>
         

          <Route
            path="/us_privacy/regulations/create"
            component={USPrivacyRegulationCreate}
          />
          <Route
            path="/preferences/create"
            component={CreateConfiguration}
          />
          <Route
            path="/preferences/oauth-redirect"
            component={OauthRedirectUrl}
          />
          <Route
            component={Layout}

            isMasq={!!this.props.masqUserName}
            isLocalEnv={!!this.props.isLocalEnv}
            isCanary={!!this.props.isCanary}
          >
            <Route
              path="account/edit"
              component={EditAccount}
            />
            <Route
              path="/new_account"
              component={CreateAccount}
            />
            <Route
              path="/account/domains"
              component={Domains}
            />
            <Route
              path="/user/invite"
              component={ManageUsers}
            />
            <Route
              path="admin/masq"
              component={MasqPage}
            />
            <Route
              path="admin/admin_settings"
              component={AdminSettingsPage}
            />
            <Route
              path="admin/change_log"
              component={ChangeLog}
              masqUserName={this.props.masqUserName}
            />
            <Route
              path="admin/system_templates"
              component={SysTemplates}
              masqUserName={this.props.masqUserName}
            />
            <Route
              path="/integrations/zendesk"
              component={Zendesk}
            />
            <Route
              path="/integrations/zendesk/logout"
              component={ZendeskLogout}
            />
            <Route component={DialogueLayout}>
           
              <Route
                path="/dialogue/sites"
                component={PropertiesPage}
                isMasq={!!this.props.masqUserName}
              />
              <Route
                path="/dialogue/campaigns"
                component={activeCampaignWrapper(CampaignsPage)}
                masq={!!this.props.masqUserName}
              />
              <Route
                path="/dialogue/campaigns_v2"
                component={CampaignsPageV2}
                masq={!!this.props.masqUserName}
              />
              <Route
                path="/dialogue/partitions"
                component={activeCampaignWrapper(PartitionsetsPage)}
              />{" "}
              <Route
                path="/dialogue/scenarios"
                component={activeCampaignWrapper(ScenariosPage)}
              />
              <Route
                path="/dialogue/scenariosv3"
                component={activeCampaignWrapper(ScenarioDashboard)}
              />
              <Route
                path="/dialogue/scenarios/create"
                component={activeCampaignWrapper(TemplatePage)}
              />
              <Route
                path="/dialogue/scenarios/edit"
                component={activeCampaignWrapper(TemplatePage)}
              />
              <Route
                path="/dialogue/messages"
                component={activeCampaignWrapper(MessagesPage)}
              />
              <Route
                path="/dialogue/messages-adblock"
                component={activeCampaignWrapper(MessagesPage)}
              />
              <Route
                path="/dialogue/messages/edit"
                component={activeCampaignWrapper(MessageEditPage)}
                editing
              />
              <Route
                path="/dialogue/messages/view"
                component={activeCampaignWrapper(MessageEditPage)}
                editing={false}
              />
              <Route
                path="/dialogue/message_preview"
                component={activeCampaignWrapper(MessagePreviewPage)}
              />
              <Route
                path="/dialogue/messagesV2"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="notice"
              />
              <Route
                path="/dialogue/messages/us_privacy"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="notice"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/us_privacy'],
                  ['Privacy Manager', '/dialogue/messages/us_privacy_pm']
                ]}
                v2
              />
              <Route
                path="/dialogue/messages/us_privacy_pm"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="ccpa_pm"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/us_privacy'],
                  ['Privacy Manager', '/dialogue/messages/us_privacy_pm']
                ]}
                v2
              />
              <Route
                path="/dialogue/messages/usnat_fl"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="usnat"
                messageSubcategory="usnat_notice"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/usnat_fl'],
                  ['Privacy Manager', '/dialogue/messages/usnat_pm']
                ]}
                v2
              />
              <Route
                path="/dialogue/messages/preferences"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="preferences"
                messageSubcategory="preferences"
                v2
              />
              <Route
                path="/dialogue/messages/usnat_pm"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="usnat"
                messageSubcategory="us_pm"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/usnat_fl'],
                  ['Privacy Manager', '/dialogue/messages/usnat_pm']
                ]}
                v2
              />
              <Route
                path="/dialogue/sar"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="sar"
              />
              <Route
                path="/dialogue/dsar-retrieval"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="dsarRetrieval"
              />
              <Route
                path="/dialogue/messages/tcfv2"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="notice-tcf-v2"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/tcfv2'],
                  ['Privacy Manager', '/dialogue/privacy-manager/tcfv2']
                ]}
                v2
              />
              <Route
                path="/dialogue/messages/non-tcf"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="notice_non_iab"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/non-tcf'],
                  ['Privacy Manager', '/dialogue/privacy-manager/non-tcf']
                ]}
              />
              <Route
                path="/dialogue/privacy-manager/non-tcf"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="privacy_manager_non_iab"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/non-tcf'],
                  ['Privacy Manager', '/dialogue/privacy-manager/non-tcf']
                ]}
                v2
              />
              <Route
                path="/dialogue/adblock"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="adblock"
                messageSubcategory="notice"
                v2
              />
              <Route
                path="/dialogue/privacy-manager/tcfv2"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="privacy-manager"
                messageTabs={[
                  ['First Layer', '/dialogue/messages/tcfv2'],
                  ['Privacy Manager', '/dialogue/privacy-manager/tcfv2']
                ]}
                v2
              />
              <Route
                path="/dialogue/privacy-manager/ott"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="privacy-manager-ott"
                v2
              />
              <Route
                path="/dialogue/messages-ios"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ios14"
                messageSubcategory="ios"
                v2
              />
              <Route
                path="/dialogue/messages-custom"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="custom"
                messageSubcategory="custom"
                v2
              />
              <Route
                path="/dialogue/native-message-gdpr"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="native"
              />
              <Route
                path="/dialogue/native-ott"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="gdpr"
                messageSubcategory="native-ott"
              />
              <Route
                path="/dialogue/native-message-us-privacy"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="native"
              />
              <Route />
              <Route
                path="/dialogue/us-privacy-ott"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="ccpa_ott"
              />
              <Route />
              <Route
                path="/dialogue/native-ott-us-privacy"
                component={activeCampaignWrapper(MessagesV2)}
                messageCategory="ccpa"
                messageSubcategory="native-ott"
              />
            </Route>
            <Route component={ConsentLayout} >
              <Route
                path="/preferences"
                component={PreferencesHomePage}
              />
              <Route
                path="/preferences/edit"
                component={EditConfigurations}
              />
              <Route
                path="/consent/vendor_lists"
                component={VendorLists}
              />
              <Route
                path="/consent/vendor_lists/edit"
                component={VendorListEdit}
              />
              <Route
                path="/consent/vendor_lists/create"
                component={VendorListEdit}
              />
              <Route
                path="/consent/privacy_manager"
                component={PrivacyManagerList}
              />
              <Route
                path="/consent/privacy_manager/edit"
                component={PrivacyManagerEdit}
              />
              <Route
                path="/consent/privacy_manager/create"
                component={PrivacyManagerEdit}
              />
              <Route
                path="/consent/privacy_manager/create"
                component={PrivacyManagerEdit}
              />
              <Route
                path="/consent/performance_report"
                component={ConsentPerformanceReport}
              />
              <Route
                path="/consent_v2/vendor_lists"
                component={VendorListsV3}
                v2
              />
              <Route
                path="/us_privacy/regulations"
                component={USPrivacyRegulationList}
                usnat
              />
              <Route
                path="/us_privacy/regulations/edit"
                component={USPrivacyRegulationEdit}
              />
              <Route
                path="/consent_v2/vendor_lists/edit"
                component={VendorListEditV2}
                v2
              />
              <Route
                path="/consent_v2/vendor_lists/create"
                component={VendorListEditV2}
                v2
              />
              <Route
                path="/non-iab/vendor_lists"
                component={VendorListsV3}
                v2
                nonIab
              />
              <Route
                path="/consent_v2/compensation_list"
                component={ConsentCompensationList}
              />
              <Route
                path="/consent_v2/compensation_list/edit"
                component={ConsentCompensationEdit}
              />
              <Route
                path="/consent_v2/compensation_list/create"
                component={ConsentCompensationEdit}
              />
              <Route
                path="/consent_v2/performance_report"
                component={ConsentPerformanceReport}
                v2
              />
            </Route>
            <Route component={CCPALayout} >
              <Route
                path="/us_privacy/vendor_lists"
                component={CCPAVendorLists}
              />
              <Route
                path="/us_privacy/reporting_link"
                component={CCPADataReporting}
              />
              <Route
                path="/us_privacy/vendor_lists/edit"
                component={CCPAVendorListEdit}
              />
              <Route
                path="/us_privacy/vendor_lists/create"
                component={CCPAVendorListEdit}
              />
              <Route
                path="/us_privacy/privacy_manager"
                component={CCPAPrivacyManagerList}
              />
              <Route
                path="/us_privacy/performance_report"
                component={CCPAConsentPerformanceReport}
              />
              <Route
                path="/us_privacy/privacy_manager/edit"
                component={CCPAPrivacyManagerEdit}
              />
              <Route
                path="/us_privacy/privacy_manager/create"
                component={CCPAPrivacyManagerEdit}
              />
              <Route
                path="/us_privacy/privacy_manager/create"
                component={CCPAPrivacyManagerEdit}
              />
              <Route
                path="/us_privacy/privacy_manager/create"
                component={CCPAPrivacyManagerEdit}
              />
              <Route
                path="/us_privacy/requests"
                component={CCPARequests}
              />
            </Route>
            <Route component={SdksLayout}>
              <Route path="/sdks/sdk_lists" component={SdkLists} />
              <Route path="/sdk/sdk_android" component={SdkAndroid} />
              <Route path="sdk/sdk_ios" component={SdkIos} />
            </Route>
            CCPALayout
            <Route
              component={ReportsLayout}
              isMasq={!!this.props.masqUserName}
            >
              <Route
                path="/adblock_dashboard"
                component={DashboardPage}
              />
              <Route
                path="/adblock"
                component={Adblock}
              />
              <Route
                path="/altpay"
                component={AltPayPage}
              />
              <Route
                path="/whitelist"
                component={Whitelist}
              />
              <Route
                path="/consent_performance"
                component={ConsentPerformanceReportNew}
              />
              <Route
                path="/gdprv2_report"
                component={ConsentPerformanceReportV2}
                currentUser={this.props.currentUser}
              />
              <Route
                path="/gdprtcfv2_report"
                component={ConsentPerformanceReportTcfV2}
              />
              <Route
                path="/manage_reports"
                component={ManageReports}
              />
              <Route
                path="/manage_reports/:reportId"
                component={CreateReport}
              />
              <Route
                path="/us_privacy_report"
                component={CCPAReporting}
              />
              <Route
                path="/us_privacy/datarequest"
                component={CCPADataRequest}
              />
              <Route
                path="/custom_report"
                component={CustomReport}
                isMasq={!!this.props.masqUserName}
              />
            </Route>
            {privacyLensRoutes}
            <Route path="/status" component={StatusPage} />
            <Route component={DiagnoseDashboardLayout} >
              <Route path="/diagnose_dashboard" component={DiagnoseDashboard} />
              <Route path="/diagnose_dashboard/non_disclosed_vendors_observed" component={NonDisclosedVendorsObserved} />
              <Route path="/diagnose_dashboard/vendors_triggered_prior_to_consent" component={VendorsTriggeredPriorToConsent} />
              <Route path="/diagnose_dashboard/disclosed_vendors" component={DisclosedVendors} />
              <Route path="/diagnose_dashboard/possible_fingerprinting" component={PossibleFingerprinting} />
              <Route path="/diagnose_dashboard/data_leaving_the_EEA" component={DataLeavingTheEEA} />
              <Route path="/diagnose_dashboard/cookies_with_long_lifespans" component={CookiesWithLongLifespans} />
              <Route path="/diagnose_dashboard/all_cookies_found" component={AllCookiesFound} />
              <Route path="/diagnose_dashboard/vendors_after_opt_out" component={VendorsAfterTargetedAdvertisingOptOut} />    
              <Route path="/diagnose_dashboard/tracking_tech_detected" component={TrackingTechDetected} />
            </Route>
            <Route component={AppDashboardLayout}>
              <Route path="/app_metrics" component={AppMetricsDashboard} />
              <Route path="/app_metrics/vendors_after_opt_out" component={VendorsAfterTargetedAdvertisingOptOutApp} />
              <Route path="/app_metrics/vendors_triggered_prior_to_consent" component={VendorsTriggeredPriorToConsentApp} />
              <Route path="/app_metrics/all_vendors_found" component={AllVendorsFound} />
              <Route path="/app_metrics/vendors_after_reject_all" component={VendorsAfterRejectAll} />
            </Route>
            <Route path="/diagnose_dashboard/tracking_tech_detected/vendor_details" component={VendorsDetails}></Route>         
            <Route component={VendorTraceLayout} >
              <Route path="/vendor_trace" component={VendorTraceDashboard} />
            </Route>
            <Route path="/diagnose/alerts" component={DiagnoseNotifications} />
            <Route path="/diagnose/app_properties" component={AppProperties} />
            <Route path="/500" component={ErrorPage} />
          </Route>
        </Route>
      </Router>
    );
  }
}

const mapStateToProps = ({ accountState, siteState }) => ({
  currentUser: accountState.getIn(["userDetails", "value"]),
  currentUserPending: accountState.getIn(["userDetails", "pending"]),
  siteGroups: siteState.getIn(['siteGroups', 'value']),
});

export default connect(mapStateToProps, { getUserDetails, setMasqUserName })(
  App,
);
