import React, { useState } from "react";
import { Map, fromJS } from 'immutable';
import { Tag, Typography, Button, Tooltip } from 'antd';
import { message } from "../../../../../styleguide"
import { channelOptions,capitalize, ConfirmationWrapper } from "../../../helper";
import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import AddCategoryModal from './AddCategoryModal';
import CategoryTable from './CategoryTable';
import { Category } from "../../../../../records/preferences_records";

const { Text, Paragraph } = Typography;

const AddCategories = (props) => {
  const { configuration, categories, updateConfiguration,readOnly } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(new Category({}))
  const [mode, setMode] = useState(null)

  const handleCancel = () => {
    setShowAddCategoryModal(false)
    setCurrentCategory(new Category({}))
    if (props.onEditScreen) props.setHideParentModal(false)
  }

  const handleShowAddcategory = (mode, record) => {
    const currentCategory = categories.find((cat) => cat.get("categoryId") == record.categoryId) 
    setCurrentCategory(currentCategory ?? new Category({}))
    setShowAddCategoryModal(true)
    setMode(mode)
    if (props.onEditScreen) props.setHideParentModal(true)
  }

  const bulkDelete = (selectedRowKeys) => {
    if (selectedRowKeys.length === 0) {
      message.error("Please select rows to delete.");
      return;
    }
    let updatedCategories;
    let updatedConfiguration = categories;

    let tempIds = selectedRowKeys.filter(id => id.includes('custom_'));
    updatedCategories = updatedConfiguration.filter(cat => !tempIds.includes(cat.get("categoryId")));


    let existingIds = selectedRowKeys.filter(id => !id.includes('custom_'));
    updatedCategories = updatedCategories.map(category => {
      if (existingIds.includes(category.get('categoryId'))) {
        return category.set('dateDeleted', new Date().toISOString());
      }
      return category;
    });

    updatedConfiguration = configuration.set('categories', updatedCategories);
    updateConfiguration(updatedConfiguration)
    setSelectedRowKeys([]);
  };

  const categoryTableConfig = {
    isBulkEdit: {
      isDelete: true,
      isEdit: false
    },
    checkStrictly: false,
    btnConfig: {
      title: '+ Add Category',
      action: handleShowAddcategory,
    }
  }

  const channelOptionsMap = channelOptions.reduce((map, item) => {
    map[item.value] = item.label;
    return map;
  }, {});


  const columns = [
    {
      title: 'Category',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (name, record) => <Paragraph ellipsis={{rows: 2, tooltip: {title: name}}}>{name}</Paragraph>
    },
    {
      title: 'Description',
      dataIndex: 'description',
      width: '30%',
      key: 'description',
      render: (name, record) => <Paragraph ellipsis={{rows: 2, tooltip: {title: name}}}>{name}</Paragraph>
    },
    {
      title: 'Channels',
      dataIndex: 'channels',
      width: '30%',
      key: 'channel',
      render: (channels) => {
        const labels = channels.map(value => channelOptionsMap[value]);
        return labels.map(channel => {
          return (
            <Tag>{capitalize(channel)}</Tag>
          )
        })
      }
    },
  ];

  if (!readOnly) {
    columns.push({
      title: 'Action',
      width: 150,
      fixed: 'right',
      render: (record) => (
        <>
          <Tooltip title="Edit"><Button icon={<EditOutlined/>} type="link" onClick={() => handleShowAddcategory("Edit", record)}/></Tooltip>
          <ConfirmationWrapper
            actionDescription="delete this Category"
            deleteHandler={() => deleteCategory(record)}
            deleteBtn={<Button type="link" icon={<Tooltip title="Delete"><DeleteOutlined/></Tooltip>}/>}
          />
        </>
      ),
    })
  }

  const addCategory = (newCategory, mode) => {
    const categoryIndex = categories.findIndex(cat => cat.get('categoryId') === newCategory.get('categoryId'));

    let updatedConfiguration;
    if (categoryIndex !== -1) {
      updatedConfiguration = configuration.updateIn(['categories', categoryIndex], () => newCategory);
    } else {
      updatedConfiguration = configuration.update('categories', categories => categories.push(newCategory));
    }
    updateConfiguration(updatedConfiguration)
  }

  const deleteCategory = (cat) => {
    const catId = cat?.categoryId?.toString();
    if(catId){
      const isTempId = catId?.includes('custom_')
      let updatedCategories;
      let updatedConfiguration;
      if (isTempId) {
         updatedCategories = categories.filter(category => category.get('categoryId') !== cat?.categoryId);
         updatedConfiguration = configuration.set('categories', updatedCategories);
      } else {
        let existingCategoryToBeDeleted = cat;
        existingCategoryToBeDeleted.dateDeleted = new Date().toISOString()
        let categoryIndex = categories.findIndex(cat => cat.get('categoryId') === existingCategoryToBeDeleted.categoryId);
        updatedConfiguration = configuration.updateIn(['categories', categoryIndex], (category) => category.set('dateDeleted', new Date().toISOString()));
      }
      updateConfiguration(updatedConfiguration)
    }
  }

  const currentCategories = categories.filter(item => !item.get('dateDeleted'))

  return (
    <>
      {props.readOnly || props.onEditScreen ? null : (
        <div className="step-header">
          <Typography.Title level={4}>{"Add Categories"}</Typography.Title>
        </div>
      )}
      <div className="step-breif">
        {"Categories are the subscriptions, topics, or preferences that are displayed to the user in your preference center. Create your categories and choose the relevant channels to display to users."}
      </div>
      <div className={`${props.showError ? 'validation-error' : null}`}>
        {currentCategories.size <= 0 && (
          <div className="empty-div">
            <Typography.Title level={5} type="secondary">Start by adding your first category</Typography.Title>
            <Button icon={<PlusOutlined />} type="primary" onClick={() => handleShowAddcategory('Add',{})}> Add Category</Button>
            {props.showError ? <Text type="danger" strong>{"Please add at least one category"}</Text> : null}
          </div>
        )}
        {currentCategories.size > 0 && (
          <CategoryTable
            dataSource={currentCategories?.toJS()}
            columns={columns}
            tableConfig={!readOnly ? categoryTableConfig: null}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            bulkDelete={bulkDelete}
            readOnly={readOnly}
          />
        )}
      </div>
      {showAddCategoryModal ? 
        <AddCategoryModal
          showAddCategoryModal={showAddCategoryModal}
          handleCancel={handleCancel}
          addCategory={addCategory}
          mode={mode}
          currentCategory={currentCategory}
          initialValues={{ name: '', description: '', channels: [] }}
          onEditScreen={props.onEditScreen}
        />
      : null}
    </>
  );
}

export default AddCategories;